import { useContext } from "react";
import { UserProfileContext } from "../../../contexts/User/Profile/context";
import { UserProfileContextType } from "../../../contexts/User/Profile/type";
import LudzLoading from "../../../ludz.one_ui/Loading";
import { Containergeral } from "../../style";
import LudzTabView from "./TabView";

export default function ProfileScreens() {
  const { userprofile } = useContext(
    UserProfileContext
  ) as UserProfileContextType;

  return (
    <Containergeral>
      {userprofile ? (
        <>
          <LudzTabView />
        </>
      ) : (
        <LudzLoading />
      )}
    </Containergeral>
  );
}
