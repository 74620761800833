import { createContext } from "react";
import { KanbanCardSubzoneState } from "./KanbanCard/state";
import { KanbanSubzoneState } from "./state";
import { KanbanSubzonaContextType } from "./type";

export const KanbanSubzonaContext =
  createContext<KanbanSubzonaContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const KanbanSubonaProvider = ({ children }: Props) => {
  const {
    handleKanbanCard,
    initialValueCard,
    schemaCard,
    handleDeleteCard,
    moveCard,
    moveMouserCard,
    moveMouserColumn,
    toast
  } = KanbanCardSubzoneState();

  const {
    handleKanban,
    initialValue,
    schema,
    kanban,
    setkanban,
    handleDeleteKanban,
    CreateTag,
    kanbanTags,
    deleteTags,
    handleUpdateKanban,
    toastKanbanList
  } = KanbanSubzoneState();

  return (
    <KanbanSubzonaContext.Provider
      value={{
        handleKanban,
        initialValue,
        schema,
        kanban,
        setkanban,
        handleKanbanCard,
        initialValueCard,
        schemaCard,
        handleDeleteCard,
        handleDeleteKanban,
        moveCard,
        CreateTag,
        kanbanTags,
        deleteTags,
        handleUpdateKanban,
        moveMouserCard,
        moveMouserColumn, 
        toast,
        toastKanbanList
      }}
    >
      {children}
    </KanbanSubzonaContext.Provider>
  );
};

export default KanbanSubonaProvider;
