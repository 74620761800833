import { useMutation } from "react-query";
import queryClient from "../../../services/react-query";
import { ForgetNewPasswordRequest, ForgetPasswordSendEmailRequest } from "./request";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export const ForgetPasswordController = ({ setError }: { setError?: any | undefined }) => {

  const history = useNavigate()

  const ForgetPasswordMutation = useMutation((data: { email: string }) => ForgetPasswordSendEmailRequest(data), {
    onMutate: () => {
      queryClient.refetchQueries()
    },
    onError: (error: any) => {
      setError(error.response.data.message);
    },
    onSuccess: (result, variables, context) => {
      console.log(result.data.message)
      Swal.fire({
        icon: "success",
        title: "E-mail enviado",
        showConfirmButton: false,
        timer: 2000
      });
    },
  });

  const ForgetNewPasswordMutation = useMutation(({ data, body }: { data: { userId: string, token: string }, body: { newPassword: string } }) => ForgetNewPasswordRequest(data.userId, data.token, body), {
    onMutate: () => {
      queryClient.refetchQueries()
    },
    onError: (error: any) => {
      alert(error.response.data.message);
    },
    onSuccess: (result, variables, context) => {
      history("/login")
    },
  });

  return { ForgetPasswordMutation, ForgetNewPasswordMutation }
}