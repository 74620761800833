import { useMutation } from "react-query";
import { DeleteTaskRequest, SubzoneTaskRequest, UpdateIsFinishSubzoneTaskRequest } from "./request";
import { PropsSubzoneTasks } from "./type";
import queryClient from "../../../services/react-query";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../../components/Alert";

export const TaskSubzoneController = ({
  toast,
}: {
  toast?: RefObject<Toast>;
}) => {

    

    const TaskSubzoneMutation = useMutation((data: PropsSubzoneTasks) => SubzoneTaskRequest(data), {
        onMutate: () => {
        //   setLoading(true);
        //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
          Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result) => {
          Alert({
            detail: "Tarefa criada!",
            severity: "success",
            summary: "Sucesso",
            toast: toast
          }).show();
          queryClient.refetchQueries("UseSubzoneObjectiveBff");

        },
      });


      interface PropsUpdateIsFinish {
        id: number,
        body: {is_finished: boolean}
    }
      const TaskIsFinishSubzoneMutation = useMutation(({body, id}:PropsUpdateIsFinish ) => {
        // Chame a função UpdateIsFinishSubzoneTaskRequest com os dados
        return UpdateIsFinishSubzoneTaskRequest(body, id);
      }, {
        onMutate: () => {
          // Coloque aqui a lógica que deseja executar antes da mutação, se necessário
        },
        onError: (error: any) => {
          Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();

          console.log(error);
        },
        onSuccess: (result) => {
          Alert({
            detail: "Tarefa atualizada!",
            severity: "success",
            summary: "Sucesso",
            toast: toast
          }).show();
          queryClient.refetchQueries("UseSubzoneObjectiveBff");

          // Coloque aqui a lógica para lidar com o sucesso da mutação
        },
      });

      const TaskDeleteSubzoneMutation = useMutation((id: number ) => {
        // Chame a função UpdateIsFinishSubzoneTaskRequest com os dados
        return DeleteTaskRequest(id);
      }, {
        onMutate: () => {
          // Coloque aqui a lógica que deseja executar antes da mutação, se necessário
        },
        onError: (error: any) => {
          Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();

          console.log(error);
        },
        onSuccess: (result) => {
          Alert({
            detail: "Tarefa excluida!",
            severity: "success",
            summary: "Sucesso",
            toast: toast
          }).show();
          queryClient.refetchQueries("UseSubzoneObjectiveBff");

          // Coloque aqui a lógica para lidar com o sucesso da mutação
        },
      });



    return{
        TaskSubzoneMutation, TaskIsFinishSubzoneMutation, TaskDeleteSubzoneMutation
    }
}


