import { useMutation } from "react-query";
import queryClient from "../../../services/react-query";
import { AddSubZoneUser, RemoveUserSubZone } from "./request";
import { TypeMembersSubZone } from "./type";
import { useNavigate } from "react-router-dom";
import { ExitUserSubZone } from "../request";

export const ControllerSubZoneUser = () => {

    const history = useNavigate()
    interface PropsRemoveUserZone {
        id: string,
        idSubZone: string
    }

    const RemoveUserSubZoneMutation = useMutation(({idSubZone, id}: PropsRemoveUserZone) => RemoveUserSubZone(id, idSubZone), {
        onMutate: () => {

        }, 
        onError: (error: any) => {
            console.log(error)
        },
        onSuccess: (result, veriables, context) => {
            queryClient.refetchQueries("UseSubzoneBff");

        }
    });

    // interface PropsUpdateAvatarZone {
    //     body: FormData,
    //     id: string
    // }

    const CreateSubZoneUserMutation = useMutation((body: TypeMembersSubZone) => AddSubZoneUser(body), {
        onMutate: () => {

        }, 
        onError: (error: any) => {
            console.log(error)
        },
        onSuccess: (result, veriables, context) => {
            queryClient.refetchQueries("UseSubzoneBff");
        }
    });

    const ExitUserSubzoneMutation = useMutation((idSubZone: string) => ExitUserSubZone(idSubZone), {
            onMutate: () => {
                
            }, 
            onError: (error: any) => {
                console.log(error)
            },
            onSuccess: (result, veriables, context) => {
                history("/home")
            }
        });
        

    return {
        RemoveUserSubZoneMutation, CreateSubZoneUserMutation, ExitUserSubzoneMutation
    }
}