import { CSSProperties } from "react";
import { Tooltip } from "..";

interface PropsIcon {
    icon?: string,
    size?: "1rem" | "0.5rem" | "2rem" | string, 
    onClick?: any,
    style?: CSSProperties | undefined,
    tooltip?: string
}

const LudzIcon = ({icon, size, onClick, style, tooltip}: PropsIcon) => {
    return(
       <Tooltip title={tooltip}><> <i className={icon} typeof="button" onClick={onClick} style={{ fontSize: size || '1.5rem', ...style }}  /></></Tooltip>
    )
}

export default LudzIcon;