import { Padding } from "../../../../../../ludz.one_ui/styles/styled-components";
import Item from "./Item";

interface ItemType {
  name: string;
  onClick: any;
  icon: string;
}

const InsiderOverlayPanel = ({
  item
}: {
  item: ItemType[];
}) => {
  return (
    <Padding padding="4px">
      {item.map((item, index) => {
        return (
          <Item key={index} icon={item.icon} name={item.name} onClick={item.onClick} />
        );
      })}
    </Padding>
  );
};

export default InsiderOverlayPanel;
