import { useContext } from "react"
import { KanbanCardSubzonaContext } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context"
import { KanbanCardSubzonaContextType } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type"
import { Icon } from "../../../../../../../ludz.one_ui"
import { AddButton, WrapMembers } from "../style"

const MemberCard = () => {


    const { card, deleteMemberCard, visibleMember, setvisibleMember } = useContext(KanbanCardSubzonaContext) as KanbanCardSubzonaContextType

    return (
        <div>
            <h4>Membros</h4>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "8px 8px",
                }}
            >
                <>
                    {card.members.map((item: any, index: number) => {
                        return (
                            <div
                                // onClick={
                                //     () => HandleDeleteUser(item)}
                                style={{ cursor: "pointer" }}
                                key={index}
                            >
                                <WrapMembers>
                                    {/* <ImageUser card={item} index={index} /> */}
                                    <img alt="" key={index} onClick={() => deleteMemberCard(item.id)} title={`Remover membro - ${item.member.username}`} src={item.member.aws.url} />
                                </WrapMembers>
                            </div>
                        );
                    })}
                </>
                <AddButton onClick={() => setvisibleMember(!visibleMember)}>
                    <Icon size="1rem" icon="pi pi-plus" style={{ margin: "auto" }} />
                </AddButton>
            </div>
            
        </div>
    )
}

export default MemberCard