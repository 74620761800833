import { ControllerEditPassword } from "../../../../ludzoneSDK/Profile/ProfileEditPassword/controller";
import * as Yup from "yup";
import { PropsEditPassword, PropsInitialValue } from "./type";
import { useContext, useRef } from "react";
import { UserProfileContext } from "../context";
import { UserProfileContextType } from "../type";
import { getId } from "../../../../services/localStorage";
import Swal from "sweetalert2";
import { Toast } from "primereact/toast";

export const StateEditPassword = () => {
  const toast = useRef<Toast>(null);

  const { EditPasswordMutation } = ControllerEditPassword({ toast: toast });

  const { refetch, userprofile } = useContext(
    UserProfileContext
  ) as UserProfileContextType;

  const EditPasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Campo Obrigatório"),
    newPassword: Yup.string()
      .label("Nova senha")
      .required("Campo Obrigatório"),
    passwordConfirm: Yup.string()
      .label("Confirmar senha")
      .required("Campo Obrigatório")
      .oneOf([Yup.ref("newPassword")], "Senhas diferentes"),
  });

  const initialValues: PropsInitialValue = {
    oldPassword: "",
    newPassword: "",
    
  };

  const HandleEditPassword = (body: PropsEditPassword) => {
    if (userprofile?.id.toString() === getId()) {
      const data = {
        oldPassword: body.oldPassword,
        newPassword: body.newPassword,
      };

      EditPasswordMutation.mutate({ body: data });
      refetch();
    } else {
      Swal.fire({
        icon: "warning",
        title: "Usuário não pode alterar os dados",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return {
    EditPasswordSchema,
    initialValues,
    HandleEditPassword,
    toast,
  };
};
