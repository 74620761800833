import http from "../../../services/api";
import { logout } from "../../../services/localStorage";

export const UpdateNotification = async (id:string | null, body: {isViewed: boolean}) => {
    return await http.put(`/direct/notifications/${id}`, body).then(response => response.data)
    .catch(err => {
         if (err.response.status === 401) {
              logout()
              window.location.reload()
         }
         alert(err);
         throw err;
     })
}

export const UpdateAllNotification = async () => {
     return await http.put(`/bff/notification-bff/mark-all-as-viewed`)
     .catch(err => {
          if (err.response.status === 401) {
               logout()
               window.location.reload()
          }
          alert(err);
          throw err;
     })
}    

export const DeleteNotificationRequest = async (id: string) => {
     return await http.delete(`/direct/notifications/${id}`).then(response => response.data)
         .catch(err => {
             if (err.response.status === 401) {
                 logout()
                 window.location.reload()
             }
             alert(err)
             throw err;
         });
 }