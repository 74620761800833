import React from "react";
import styled from "styled-components";
import { Tooltip } from "..";

interface PropsColor {
    color?: string
}

export const ButtonOption = styled.div<PropsColor>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.color || "#449E48"};;
  padding: 10px;
  border-radius: 10px;
  color: ${props => props.color || "#449E48"};
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

    :hover{
        background-color: ${props => props.color || "#449E48"};
        opacity: 0.5;
    }
`;
 
interface ButtonAttributis {
    title?: string,
    onClick?: any,
    fontSize?: string,
    children?: React.ReactNode,
    className?: string,
    style?: React.CSSProperties,
    color?: string,
    arrow?: boolean;
    placement?: "top" | "bottom" | "left" | "right" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start";
}

export const ButtonPrimeIcon = ({ 
    title, 
    onClick, 
    fontSize, 
    children, 
    className, 
    style,
    color,
    arrow,
    placement
}: ButtonAttributis) => {

    return (
    <Tooltip title={title} arrow={arrow} placement={placement}>
    <ButtonOption className={className} style={{ fontSize: fontSize, justifyContent: "center", borderRadius: '10px', cursor: 'pointer', ...style }} color={color} onClick={onClick}>
                {children}
    </ButtonOption>
    </Tooltip>
    )
}