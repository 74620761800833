import React from 'react';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';

interface PropsCheckBox {
    checked: boolean,
    onChange?(event: CheckboxChangeEvent): void
    style?: React.CSSProperties
}

const CheckboxLudz =  ({checked, onChange, style}: PropsCheckBox) => {
    return(
        <Checkbox 
            checked={checked} onChange={onChange} style={{...style}}
        />
    )
}

export default CheckboxLudz
        