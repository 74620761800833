
import { QueryClientProvider } from 'react-query';
import Routers from './routers/routes';
import GlobalStyle from './styles/globalStyles';
import './assets/cssfreya/App9.0.0.scss'

import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css"; 
import "/node_modules/primeflex/primeflex.css";

import 'primeicons/primeicons.css';
        
import queryClient from './services/react-query';
                                   
        

function App() {
  return (
    <QueryClientProvider client={queryClient} >
      <GlobalStyle />
      <Routers />
    </QueryClientProvider >
  );
}

export default App;
