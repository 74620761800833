import { Checkbox } from "primereact/checkbox";
import { useContext } from "react";
import { Padding } from "../../../../../../../../../ludz.one_ui/styles/styled-components";
import { Row } from "../../../../../../../../../styles/globalStyles";
import { CreateContent, CreateContentContext } from "../../../CreateContent/context";

// import { Container } from './styles';

const PlaylistsInsider = ({ item }: any) => {

    const {AddVideo, setFieldValue,video} = useContext(CreateContentContext) as CreateContent
  return (
    <>
      {item?.contents.map((item: any, index: any) => {
        return (
          <>
            <Row>
              <Checkbox
                value={item.id}
                name="playlist_content"
                onChange={() => {AddVideo(setFieldValue, item)}}
                checked={item.id === video?.id}
              />
              <h4>{item.name}</h4>
            </Row>
            <Padding padding="8px"/>
          </>
        );
      })}
    </>
  );
};

export default PlaylistsInsider;
