import { Icon } from "../../../../../../../ludz.one_ui"
import { Column, Row } from "../../../../../../../styles/globalStyles"
import { ItemStyle } from "./style"

interface PropsItem {
    icon: string,
    name: string,
    onClick?: any
}

const Item = ({ icon, name, onClick }: PropsItem) => {
    return (
        <ItemStyle onClick={onClick}>
            <Row style={{padding: "4px"}}>
                <Column id="center">
                    <Icon icon={icon} size="1rem" />
                </Column>
                <h4>{name}</h4>
            </Row>
        </ItemStyle>
    )
}

export default Item