import { Popover } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { RxLink1 } from "react-icons/rx";
import { Button } from "../../..";
import { Row } from "../../../../styles/globalStyles";
import Input from "../../../Inputs/InputText";
import styles from "../../../styles";
import { Padding } from "../../../styles/styled-components";
import BubbleButtons from "../BubbleButton";

const BubbleLink = ({ editor }: { editor: any }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [url, setUrl] = useState();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setUrl(editor.getAttributes("link").href);
  }, [editor]);


  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const setLink = useCallback(() => {
    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor, url]);
  return (
    <>
      <BubbleButtons
        isActive={editor.isActive("strike")}
        onClick={handleClick}
        icon={<RxLink1 size={16} />}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Padding
          padding="8px"
          style={{ backgroundColor: styles.colors.grayClearOne }}
        >
          <span className="p-input-icon-left">
            <i className="pi pi-link" />
            <Input
              placeholder="Link"
              value={url}
              onChange={(e: any) => setUrl(e.target.value)}
            />
          </span>
          <Padding />
          <Row id="end">
            <Button
              icon="pi pi-trash"
              text
              onClick={() => {
                editor
                  .chain()
                  .focus()
                  .extendMarkRange("link")
                  .unsetLink()
                  .run();
                setUrl(undefined);
              }}
            />
            <Button title="Set link" onClick={setLink} />
          </Row>
        </Padding>
      </Popover>
    </>
  );
};

export default BubbleLink;
