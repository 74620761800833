import styled from "styled-components";
import styles from "../../styles";

interface PropsButton {
  isActive?: boolean;
}

export const ButtonBubble = styled.button<PropsButton>`
  border: none;
  cursor: pointer;
  padding: 8px;
  background-color: ${(props) =>
    props.isActive
      ? styles.colors.grayClearOneHover
      : styles.colors.grayClearOne};
  &:hover {
    background-color: ${styles.colors.grayClearOneHover};
  }
`;
