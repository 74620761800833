import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import queryClient from '../../services/react-query';
import { ConfirmInviteZone } from "./request";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../components/Alert";

export const ConfirmEmailZoneController = ({
    toast,
  }: {
    toast?: RefObject<Toast>;
  }) => {


    interface PropsConfirmEmail {
        id: string | null,
        idzone: string | null,
        token: string | null
    }

    const history = useNavigate()
    const ConfirmEmailzoneMutation = useMutation(({ id, idzone, token }: PropsConfirmEmail) => ConfirmInviteZone(id, idzone, token), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            console.log(error)
        },
        onSuccess: (result, variables, context) => {
            history(`/login`)
        },
    });

    const ConfirmInvitesZoneMutation = useMutation(({ id, idzone, token }: PropsConfirmEmail) => ConfirmInviteZone(id, idzone, token), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            Alert({ detail: "Error", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result, variables, context) => {
            Alert({
                detail: "Convite aceito com sucesso!",
                severity: "success",
                summary: "Sucess",
                toast: toast
              }).show();
              queryClient.refetchQueries("UseZoneIdBff");
              queryClient.refetchQueries("UseAllInvite");
              queryClient.refetchQueries("UseUserInvites")
        },
    });


    return {
        ConfirmEmailzoneMutation,
        ConfirmInvitesZoneMutation,
    }
}