import { createContext } from "react";
import { SubzonaContextType } from "./type";
import { SubzoneState } from "./state";

export const SubzonaContext = createContext<SubzonaContextType | null>(null);

interface Props {
    children: React.ReactNode
}


const SubzonaProvider = ({ children }: Props) => {

    const { subzone, container, adminorowner, refetch, openMenu, setOpen, HandleRemoveMember, HandleExitMember } = SubzoneState();

    return (
        <SubzonaContext.Provider value={{ subzone, container, adminorowner, refetch, openMenu, setOpen, HandleRemoveMember, HandleExitMember }}>
            {children}
        </SubzonaContext.Provider>
    )
}

export default SubzonaProvider;