import http from "../../../../services/api";
import { logout } from "../../../../services/localStorage";

export const SessionZoneWatchedRequest = async (body: {
  sessionContentId: number;
}) => {
  return await http
    .post("/bff/watched-session-contents-bff", body)
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      alert(err);
      throw err;
    });
};

export const BffGetZoneSessionsWatchedRequest = async (id: number) => {
  if (id) {
    return await http
      .get("/bff/watched-session-contents-bff/findOne", {
        params: {
          sessionContentId: id,
        },
      })
      .then((response) => response.data)
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
          window.location.reload();
        }
        throw err;
      });
  }
};

export const DeleteSessionWatchedZoneRequest = async (id: string) => {
  return await http
    .delete(`/bff/watched-session-contents-bff/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      alert(err);
      throw err;
    });
};
