import http from "../../services/api";
import { logout } from "../../services/localStorage";
import { PropsSignUp } from "./types";

export const SignUpRequest = async (body: PropsSignUp) => {
  return await http.post("/direct/users", body);
};

export const SignUpInviteZoneRequest = async (body: PropsSignUp) => {
  return await http.post("/bff/aux-bff/confirm-invite-user-platform", body);
};

export const getAllUsersRequest = async () => {
  return await http
    .get("/bff/users-bff/validated-users")
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      alert(err.response.data.message);
      throw err;
    });
};

export const GetUserId = async (
  id: string | null,
  query: object | undefined
) => {
  const params = encodeURIComponent(
    `{"zoneUser": {"include": {"zone": true}}}`
  );
  return await http
    .get(`/direct/users/${id}?include=${params}`)
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      throw err;
    });
};

export const GetUserHome = async () => {
  return await http
    .get("/bff/aux-bff/home")
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      throw err;
    });
};

export const GetUserInvites = async () => {
  return await http
    .get("/bff/invites-bff")
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      throw err;
    });
};

export const GetUserNotifications = async () => {
  return await http
    .get("/bff/notification-bff")
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      throw err;
    });
};

export const GetUserBusinessChannel = async (role: string) => {
  if (role === "BUSINESS") {
    return await http
      .get("/bff/channel-bff/all")
      .then((response) => response.data)
      .catch((err) => {
        if (err.response.status === 401) {
          // logout()
          // window.location.reload()
        }
        throw err;
      });
  } 
  return await http
      .get("/bff/users-bff/find-all-bought-channels")
      .then((response) => response.data)
      .catch((err) => {
        if (err.response.status === 401) {
          // logout()
          // window.location.reload()
        }
        throw err;
      });

};

export const GetUserChannel = async () => {
 
  return await http
      .get("/bff/users-bff/find-all-bought-channels")
      .then((response) => response.data)
      .catch((err) => {
        if (err.response.status === 401) {
          // logout()
          // window.location.reload()
        }
        throw err;
      });

};
