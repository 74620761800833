import { useMutation } from "react-query";
import { useFetchZoneSessionsContents } from "./query";
import { EditTextSessionContentZoneRequest, SessionContentsZoneRequest } from "./request";
import queryClient from "../../../../services/react-query";
import { PropsSessionContentText } from "./type";

export const SessionContentsZoneController = (id?: number) => {

    const { data: sessionsContentsRequest, } = useFetchZoneSessionsContents(id)

    const SessionContentsZoneMutation = useMutation(({data, file}:{data: PropsSessionContentText, file?: File}) => SessionContentsZoneRequest(data, file), {
        onMutate: () => {
        },
        onError: (error: any) => {
            console.log(error)
        },
        onSuccess: (result: any) => {
            queryClient.refetchQueries("UseZoneSessionBff");
        },
    });

    return {
        SessionContentsZoneMutation, sessionsContentsRequest
    }
}

interface PropsEditSessionContentsText {
    id: number,
    body: { textContent?: string | null, title?: string }
}

export const EditSessionContentsText = () => {

    const EditSessionContentsZoneMutation = useMutation(({ id, body }: PropsEditSessionContentsText) => EditTextSessionContentZoneRequest(body, id), {
        onMutate: () => {
        },
        onError: (error: any) => {
            console.log(error)
        },
        onSuccess: (result: any) => {
            queryClient.refetchQueries("UseZoneSessionBff");
            queryClient.refetchQueries("UseZoneSessionContents");
            
        },
    });

    return {
        EditSessionContentsZoneMutation
    }
}