import { ProgressSpinner } from 'primereact/progressspinner';
import { Column, Row } from "../styles/styled-components";

interface PropsLoading {
    style?: React.CSSProperties
}

const LudzLoading = ({style}: PropsLoading) => {
    return (
        <Column id="center">
            <Row id="center">
                <ProgressSpinner strokeWidth='6' color="#F59E0B" style={style} />
            </Row>
        </Column>
    )
}

export default LudzLoading;