import * as Yup from 'yup';
import { PropsInitialValue } from './type';
import { ForgetPasswordController } from '../../../ludzoneSDK/Login/ForgetPassword/controller';

export const ForgetPasswordState = () => {

    const ForgetPasswordSchema = Yup.object().shape({
        newPassword: Yup.string().required('Campo Obrigatório'),
        passwordConfirm: Yup.string().label('Confirmar senha').required("Campo Obrigatório").oneOf([Yup.ref('newPassword')], 'Senhas diferentes'),
      });

      const initialValue : PropsInitialValue = {
        newPassword: "",
        passwordConfirm: ""
      }
      const {ForgetNewPasswordMutation} = ForgetPasswordController({});

      const ForgetPassword = (data: {userId: string, token: string}, body: {newPassword: string}) => {
        ForgetNewPasswordMutation.mutate({data: data, body: body})
      }

    return{
        ForgetPasswordSchema, initialValue, ForgetPassword
    }
}