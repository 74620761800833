import { useContext, useState } from "react";
import { Accordion, Button } from "../../../../ludz.one_ui";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import { Column } from "../../../../styles/globalStyles";
import CreateObjectiveKey from "./CreateObjectivekey";
import ObjectiveKey from "./Objectivekey";
import HeaderAccordion from "./Objectivekey/HeaderAccordion";
import { AddObjective } from "./style";
import { ObjectiveSubzonaContext } from "../../../../contexts/Subzone/ObjectiveSubzone/context";
import { ObjectiveSubzonaContextType } from "../../../../contexts/Subzone/ObjectiveSubzone/type";
import { Toast } from "primereact/toast";
import { ObjectiveKeySubzonaContextType } from "../../../../contexts/Subzone/ObjectiveSubzone/ObjectiveKeySubzone/type";
import { ObjectiveKeySubzonaContext } from "../../../../contexts/Subzone/ObjectiveSubzone/ObjectiveKeySubzone/context";

const BodyObjective = ({ item }: any) => {

    const [visible, setVisible] = useState(false);

    

    const { accordionObjetivekey } = useContext(ObjectiveSubzonaContext) as ObjectiveSubzonaContextType
    const { toast } = useContext(ObjectiveKeySubzonaContext) as ObjectiveKeySubzonaContextType

    return (
        <>
        <Toast ref={toast} />
            <h3>
                Metas
            </h3>
            <Padding />
            <Accordion key={item.id} disabled={accordionObjetivekey} header={HeaderAccordion} data={item!.objectiveKeys} body={ObjectiveKey} />
            <Padding padding="36px" />
            {true ? (
                <AddObjective onClick={() => setVisible(!visible)}>
                    <Button icon="pi pi-plus" rounded aria-label="Filter" />
                    <Padding />

                    <Column id="center">
                        <h3>Adicionar Metas</h3>
                    </Column>
                </AddObjective>
            ) : null}
            <CreateObjectiveKey visible={visible} onOpen={() => setVisible(!visible)} id={item.id!} />
        </>
    )
}

export default BodyObjective