import React, { createContext } from "react";
import { StateSignUp } from "./state";
import { SignUpContextType } from "./types";

export const SignUpContext = createContext<SignUpContextType | null>(null);

interface Props {
    children: React.ReactNode
}

const SignUpProvider = ({ children }: Props) => {

    const { initialValues, handleSignUp, SignupSchema, error, loading, handleSignUpInviteZone } = StateSignUp();
    return (
        <SignUpContext.Provider value={{ initialValues, handleSignUp, SignupSchema, error, loading, handleSignUpInviteZone }}>
            {children}
        </SignUpContext.Provider>
    )
}

export default SignUpProvider;