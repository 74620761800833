import React from "react";
import { Skeleton } from 'primereact/skeleton';


const LudzLoadingSkeleton = ({shape, width, height}) => {
    return(
        <Skeleton shape={shape} width={width} height={height}></Skeleton>
    )
}

export default LudzLoadingSkeleton;