import http from "../../../../../services/api";
import { logout } from "../../../../../services/localStorage";
import { PropsMetasCard } from "./type";
 
export const SubzoneMetasKanbanCardRequest = async (body: PropsMetasCard) => {
    return await http.post("/bff/kanban-card-objectives-bff", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const DeleteMetasCardRequest = async (id: number) => {

    return await http.delete(`/direct/kanban-card-objectives/${id}`).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

