import { Form, Formik } from "formik";
import { useContext } from "react";
import { ObjectiveSubzonaContext } from "../../../../../contexts/Subzone/ObjectiveSubzone/context";
import { ObjectiveSubzonaContextType } from "../../../../../contexts/Subzone/ObjectiveSubzone/type";
import { Button, Input, Modal } from "../../../../../ludz.one_ui";
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../../styles/globalStyles";

interface PropsModalEditObjetive {
    item: any,
    visible: boolean,
    onOpen: any
}

const ModalEditObjetive = ({ item, onOpen, visible }: PropsModalEditObjetive) => {
    const { handleUpdateObjective, schema } = useContext(ObjectiveSubzonaContext) as ObjectiveSubzonaContextType
    return (
        <Modal title={"Editar objetivo"} visible={visible} onOpen={onOpen}>
            <Formik initialValues={{ name: item?.name || '' }} validationSchema={schema} onSubmit={(values) => {
                handleUpdateObjective(values, item.id)
                onOpen()
            }}>
                {({ values, handleChange, errors, touched, handleSubmit }) => {

                    return (
                        <Form onSubmit={handleSubmit}>
                            <Column>
                                <Input name="name" value={values.name} onChange={handleChange}
                                 onKeyDown={(e: any) => {
                                    if (e.key === ' ') {
                                        e.stopPropagation();
                                    }
                                }}
                                label="Nome do Objetivo" />
                            </Column>
                            <Padding padding="16px" />
                            {errors.name && touched.name ? (
                                <div style={{ color: "red" }}>{errors.name.toString()}</div>
                            ) : null}
                            <Row id="center">
                                <Button title="Salvar" type="submit" onClick={() => {
                                    handleUpdateObjective(values, item.id)
                                    onOpen()
                                }} />
                            </Row>
                        </Form>
                    )
                }}
            </Formik>

        </Modal>
    )
}

export default ModalEditObjetive;