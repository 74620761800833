import http from "../../../../../services/api";
import { logout } from "../../../../../services/localStorage";
import { uploadArchive } from "../../../../../services/upload-archives";

export const ArchivesKanbanCardRequest = async (file: File, id: string) => {
    return await uploadArchive(file, "/bff/kanban-card-archives-bff", {kanbanCardId: id}, "POST")
}

export const DeleteArchivesKanbanCardRequest = async (id: string) => {
    return await http.delete("/bff/kanban-card-archives-bff",{params: {kanbanCardArchivesId : id}}).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

