import { createContext } from "react";
import { ZonePublicState } from "./state";
import { ZonePublicContextType } from "./type";

export const ZonePublicContext = createContext<ZonePublicContextType | null>(null);

interface Props {
    children: React.ReactNode
}


const ZonePublicProvider = ({ children }: Props) => {

    const { zonepublic, container, adminorowner, refetch, openMenu, setOpen, HandleRemoveMember, HandleUpdateMember, HandleExitZone, HandleInviteEmailMember } = ZonePublicState();


    return (
        <ZonePublicContext.Provider value={{ zonepublic, container, adminorowner, refetch, openMenu, setOpen, HandleRemoveMember, HandleUpdateMember, HandleExitZone, HandleInviteEmailMember }}>
            {children}
        </ZonePublicContext.Provider>
    )
}

export default ZonePublicProvider;