import { useMutation } from "react-query";
import { useFetchZonePublic } from "./query";
import { BuyZonePostRequest } from "./request";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

export const ZonesPublicController = (page: number, zonename: string) => {
    const history = useNavigate();
    const { id } = useParams()

    const { data: zones, isLoading: isLoadingZones, error: errorZones, refetch } = useFetchZonePublic(page, zonename)

    const PostBuyZoneMutation = useMutation((data: any) => BuyZonePostRequest(data), {
        onMutate: () => {
        //   setLoading(true);
        //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
        //   setError(error.response.data.message);
        //   setLoading(false);
            console.log(error)
            Swal.fire('Aconteceu um erro!', error);
        },
        onSuccess: (result) => {
            Swal.fire({
                icon: "success",
                title: "Entrou na zona com sucesso!",
                showConfirmButton: false,
                timer: 2000
              });
            history(`/zona/${id}/painel`)
         refetch()
        },
      });

    return {
        zones, isLoadingZones, errorZones, refetch, PostBuyZoneMutation
    }
}