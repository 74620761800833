import React from "react";
import { Navigate } from "react-router-dom";
import LayoutTwoColumn from "../styles/Layout/LayoutTwoColumn";
import { isAuthenticated } from "../services/localStorage";
import PrivateRoutesProvider from "../contexts/PrivateRoutes/context";
import LayoutZoneColumn from "../styles/Layout/LayoutZone";
import ZonaProvider from "../contexts/Zone/Zone/context";
import LayoutSubzoneColumn from "../styles/Layout/LayoutSubzone";
import LayoutProfile from "../styles/Layout/LayoutProfile";
import UserProfileProvider from "../contexts/User/Profile/context";

type PropsPrivate = {
  Component: React.ReactNode;
  typeLayout?: "profile" | "zone" | "home" | "subzone";
};

const PrivateRoute = ({ Component, typeLayout }: PropsPrivate) => {
  const auth = isAuthenticated(); //your logic

  return auth ? (
    <PrivateRoutesProvider>
      {typeLayout === "profile" ? (
        <UserProfileProvider>
          <LayoutProfile>{Component}</LayoutProfile>
        </UserProfileProvider>
      ) : typeLayout === "home" ? (
        <LayoutTwoColumn>{Component}</LayoutTwoColumn>
      ) : typeLayout === "zone" ? (
        <ZonaProvider>
          <LayoutZoneColumn>{Component}</LayoutZoneColumn>
        </ZonaProvider>
      ) : typeLayout === "subzone" ? (
        <ZonaProvider>
          <LayoutSubzoneColumn>{Component}</LayoutSubzoneColumn>
        </ZonaProvider>
      ) : null}
    </PrivateRoutesProvider>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
