import { Form, Formik, FormikErrors } from "formik";
import { useContext, useState } from "react";
import { KanbanSubzonaContext } from "../../../../../../../contexts/Subzone/KanbanSubzone/context";
import { KanbanSubzonaContextType } from "../../../../../../../contexts/Subzone/KanbanSubzone/type";
import {
  Button,
  Dropdown,
  ModalInsiderKanbanCard,
} from "../../../../../../../ludz.one_ui";
import { Padding } from "../../../../../../../ludz.one_ui/styles/styled-components";
import { PropsSubzoneKanbanBFF } from "../../../../../../../ludzoneSDK/Subzone/Kanban/type";
import { Column, Grid, Row } from "../../../../../../../styles/globalStyles";

interface PropsMoveCard {
  visible: boolean;
  setvisible: any;
  card: any;
}

interface PropsChoiceList {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<{
    cardId: any;
    newKanbanId: any;
    newIndex: any;
  }>>;
  e: any;
}

interface PropsiniialValue {
  cardId: any;
  newKanbanId?: number;
  newIndex?: { index: number; name: string };
}

const MoveCard = ({ visible, setvisible, card }: PropsMoveCard) => {
  const { kanban, moveCard } = useContext(
    KanbanSubzonaContext
  ) as KanbanSubzonaContextType;
  const [listSelection, setlistSelection] = useState<PropsSubzoneKanbanBFF>();

  const choiceList = ({ e, setFieldValue }: PropsChoiceList) => {
    setlistSelection(e.target.value);
    setFieldValue("newKanbanId", e.target.value);
  };

  const positions = () => {
    const test: any = [];
    if (!listSelection) {
      return undefined;
    }
    if (listSelection.id === card.kanban_fk) {
      listSelection!.kanbanCards.forEach((element, index) => {
        if (element.id === card.id) {
          test.push({ index: index, name: `${index + 1} (atual)` });
        } else {
          test.push({ index: index, name: `${index + 1}` });
        }
      });
    } else {
      listSelection!.kanbanCards.forEach((element, index) => {
        test.push({ index: index, name: `${index + 1}` });
      });
      test.push({
        index: listSelection!.kanbanCards.length + 1,
        name: `${listSelection!.kanbanCards.length + 1}`,
      });
    }

    return test;
  };

  const iniialValue: PropsiniialValue = {
    cardId: card.id,
    newKanbanId: undefined,
    newIndex: undefined,
  };

  return (
    <ModalInsiderKanbanCard
      visible={visible}
      title={"Mover"}
      onOpen={() => setvisible(!visible)}
    >
      <Padding padding="8px" />
      <Formik
        initialValues={iniialValue}
        onSubmit={(values) => {
          moveCard({
            ...values,
            newKanbanId: listSelection!.id ?? 0,
            newIndex: values?.newIndex!.index,
          });
          setvisible(!visible);
        }}
      >
        {({ values, handleChange, setFieldValue, errors, touched }) => {
          const position = positions();
          return (
            <Column>
              <Form style={{ width: "100%" }}>
                <Grid checkMockup={[{}, {}]}>
                  <Column style={{ width: "100%" }}>
                    <Dropdown
                      label="Lista"
                      optionLabel="title"
                      options={kanban}
                      value={values.newKanbanId}
                      placeholder="listas"
                      onChange={(e: any) => choiceList({ e, setFieldValue })}
                    />
                    <Padding />
                    {errors.newKanbanId && touched.newKanbanId && (
                      <div style={{ color: "red" }}>
                        {errors.newKanbanId}
                        <Padding />
                      </div>
                    )}
                  </Column>
                  <Column style={{ width: "100%" }}>
                    <Dropdown
                      label="Posição"
                      name="newIndex"
                      optionLabel="name"
                      options={position}
                      value={values.newIndex}
                      onChange={handleChange}
                      placeholder="posição"
                    />
                  </Column>
                </Grid>
                <Padding padding="8px" />
                <Row id="center">
                  <Button title="Mover" type="submit" />
                </Row>
              </Form>
            </Column>
          );
        }}
      </Formik>
    </ModalInsiderKanbanCard>
  );
};

export default MoveCard;
