import HomeContextProvider from "../../contexts/Home/context"
import HomeScreen from "../../screens/User/Home"


const Home = () => {
    return(
        <HomeContextProvider>
            <HomeScreen />
        </HomeContextProvider>
    )
}

export default Home;