import ChannelsPublicContextProvider from "../../contexts/Channel/ChannelPublic/context";
import ChannelsPublicScreen from "../../screens/Channel/ChannelsPublic";

const ChannelsPublic = () => {
  return (
    <ChannelsPublicContextProvider>
      <ChannelsPublicScreen />
    </ChannelsPublicContextProvider>
  );
};

export default ChannelsPublic;
