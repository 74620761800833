import http from "../../services/api";

export const ConfirmInviteZone = async (id: string | null, idzone: string | null, token: string | null) => {
    return await http.put(`/bff/zone-user-bff/confirm-invite/${id}/${idzone}/${token}`).then(response => response.data)
        .catch(err => {
            
            throw err;
        });
}

