import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  transition: all 0.5s ease-out;
`;

export const AreaImage = styled.figure`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;

  input {
    display: none;
  }
`;

export const AreaButtons = styled.div`
  display: flex;
  position: fixed;
  top: auto;
  margin-top: 90px;
`;
