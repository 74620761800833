import React, { useContext } from "react";

// import { Container } from './styles';

import * as C from "../ProfileInfo/style";
import background from "../../../../assets/images/Logo2.png";
import { Column, Row } from "../../../../styles/globalStyles";
import { EditPasswordContext } from "../../../../contexts/User/Profile/ProfileEditPassword/context";
import { EditPasswordContextType } from "../../../../contexts/User/Profile/ProfileEditPassword/type";
import { Formik } from "formik";
import { Button, Input } from "../../../../ludz.one_ui";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import { Toast } from "primereact/toast";

interface Props {
  userprofile: any;
}

const ProfileConfig: React.FC<Props> = ({ userprofile }) => {
  const { EditPasswordSchema, initialValues, HandleEditPassword, toast } =
    useContext(EditPasswordContext) as EditPasswordContextType;

  return (
    <C.Container>
      <Toast ref={toast} />
      <Column>
        <Row id="center">
          <img
            src={background}
            style={{ width: "128px", height: "100px" }}
            alt="imagem ilustrativa"
          />
        </Row>
        <h2>Altere aqui suas Configurações da Conta</h2>
        <Padding padding="20px" />

        <Formik
          initialValues={{ ...initialValues, passwordConfirm: "" }}
          validationSchema={EditPasswordSchema}
          onSubmit={(values) => HandleEditPassword(values)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => {
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <h3>Alterar senha</h3>
                  <Padding padding="4px" />
                  <C.Line />
                  <Padding padding="8px" />
                  <Input
                    label="Senha Antiga *"
                    type="password"
                    name="oldPassword"
                    value={values.oldPassword}
                    onChange={handleChange}
                    placeholder="Digite a senha antiga"
                    className={
                      errors.oldPassword && touched.oldPassword
                        ? "p-invalid"
                        : ""
                    }
                  />
                  <Padding padding="8px" />
                  {errors.oldPassword && touched.oldPassword && (
                    <div style={{ color: "red" }}>{errors.oldPassword}</div>
                  )}
                  <Padding />
                  <Input
                    label="Nova Senha *"
                    type="password"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    placeholder="Digite a senha nova"
                    className={
                      errors.newPassword && touched.newPassword
                        ? "p-invalid"
                        : ""
                    }
                  />
                  <Padding padding="8px" />

                  {errors.newPassword && touched.newPassword && (
                    <div style={{ color: "red" }}>{errors.newPassword}</div>
                  )}
                  <Padding />
                  <Input
                    label="Confirme nova Senha *"
                    type="password"
                    name="passwordConfirm"
                    value={values.passwordConfirm}
                    onChange={handleChange}
                    placeholder="Confirme nova Senha"
                    className={
                      errors.passwordConfirm && touched.passwordConfirm
                        ? "p-invalid"
                        : ""
                    }
                  />
                  <Padding padding="8px" />
                  {errors.passwordConfirm && touched.passwordConfirm && (
                    <div style={{ color: "red" }}>{errors.passwordConfirm}</div>
                  )}
                  <Padding padding="20px" />
                  <Button
                    style={{ width: "100%" }}
                    title="Alterar"
                    type="submit"
                  />
                  <Padding />
                </form>
              </>
            );
          }}
        </Formik>
      </Column>
    </C.Container>
  );
};

export default ProfileConfig;
