import React, { useContext, useState } from 'react';
import { TypeTagZone } from '../../../../../ludzoneSDK/Zone/type';
import EditTags from '../EditTags';
import { ZonaInfoContext } from '../../../../../contexts/Zone/ZoneInfo/context';
import { ZonaInfoContextType } from '../../../../../contexts/Zone/ZoneInfo/type';
import { Tooltip } from '../../../../../ludz.one_ui';
import { Tag } from 'primereact/tag';

// import { Container } from './styles';

interface Props {
    tags: TypeTagZone,
    adminorowner?: any,
    isediting?: boolean
}

const ChipTags = ({ tags, adminorowner, isediting }: Props ) => {
    const [visibleEditTag, setVisibleEditTag] = useState(false);
    const { DeleteTag } = useContext(ZonaInfoContext) as ZonaInfoContextType
    
  return (
    <div>
        {adminorowner ? 
            <Tag className='p-2' rounded style={{ background: '#E5E6EA', color: 'black' }}>
            <div className="flex align-items-center gap-2">
                <span className="text-base">{tags.name}</span>
               {isediting && 
               <>
                <Tooltip title='Editar Tag'>
                <i className="pi pi-pencil text-xs cursor-pointer" onClick={() => setVisibleEditTag(!visibleEditTag)}></i>
                </Tooltip>
                <Tooltip title='Excluir Tag'>
                <i className="pi pi-times-circle text-xs cursor-pointer" onClick={() => DeleteTag(tags.id)}></i>
                </Tooltip>
               </>
               }
            </div>
            </Tag>
        : 
        <Tag className='mt-1' rounded style={{ background: '#E5E6EA', color: 'black' }}>
        <div className="flex align-items-center gap-2">
            <span className="text-base">{tags.name}</span>
        </div>
        </Tag>
        }

    <EditTags
        item={tags}
        onOpen={() => {
            setVisibleEditTag(!visibleEditTag);

        }}
        visible={visibleEditTag}
      />
    </div>

);
}

export default ChipTags;