import { Grid } from "@mui/material";
import { useContext, useState } from "react";
import { KanbanCardSubzonaContext } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context";
import { KanbanCardSubzonaContextType } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type";
import { Icon } from "../../../../../../../ludz.one_ui";
import ConfirmationDialog from "../../../../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes";
import { Padding } from "../../../../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../../../../styles/globalStyles";
import { AddButton } from "../style";
import ChecklistEdit from "./ChecklistEdit";
import ChecklistItems from "./ChecklistItems";
import * as C from "./style";

const ChecklistsCard = () => {
  const [visible, setVisible] = useState<any>(false);


  const {
    visibleChecklist,
    setvisibleChecklist,
    card,
    deleteChecklists,
    handleUpdateChecklists,
    handleChecklistItem,
    handleUpdateChecklistItem,
    deleteChecklistItems,
  } = useContext(KanbanCardSubzonaContext) as KanbanCardSubzonaContextType;

  return (
    <div>
      <Row style={{ alignItems: "center" }}>
        <h4>Checklists</h4>
        <AddButton
          style={{ marginLeft: "5px" }}
          onClick={() => setvisibleChecklist(!visibleChecklist)}
        >
          <Icon size="1rem" icon="pi pi-plus" style={{ margin: "auto" }} />
        </AddButton>
      </Row>

      <Padding padding="10px" />
      <C.Container>
        <Grid container spacing={2}>
          {card?.checklists.map((item: any, key: number) => {

            return (
              <Grid item xs={12}>
                <C.AreaChecklists>
                  <Row style={{ justifyContent: "space-between" }}>
                    <ChecklistEdit item={item} handleUpdateChecklists={handleUpdateChecklists} />
                    <Column id="center">
                      <Icon size="1rem" style={{
                        cursor: "pointer",
                      }} onClick={() => setVisible(item)} icon="pi pi-trash" />
                    </Column>
                  </Row>
                  <Padding />
                  {/* <Line /> */}
                  <Padding />
                  <ChecklistItems
                    data={item}
                    handleChecklistItem={handleChecklistItem}
                    handleUpdateChecklistItem={handleUpdateChecklistItem}
                    deleteChecklistItems={deleteChecklistItems}
                  />
                </C.AreaChecklists>
              </Grid>
            );
          })}
        </Grid>
        <ConfirmationDialog
                  accept={() => deleteChecklists(visible!.id)}
                  visible={visible}
                  reject={() => setVisible(true)}
                  message="Deseja Excluir?"
                  onHide={() => setVisible(false)}
                />
      </C.Container>
    </div>
  );
};

export default ChecklistsCard;
