import styled from "styled-components";
import styles from "../../../ludz.one_ui/styles";

export const Text = styled.h3`
display: flex;
flex-direction: row;
font-size: 16px;
            cursor: pointer;
            color: ${styles.colors.grayClearOne};
`;


interface PropsContainer {
    active?: string
}
export const Container = styled.div<PropsContainer>`
cursor: pointer;
border-radius: ${props => props.active === "true" ? "8px" : "0"};
background-color: ${props => props.active === "true" ? "rgba(127, 100, 170, 0.30)" : "transparent"};
width: 100%;
    &:hover{
        border-radius: 8px;
        background-color: rgba(127, 100, 170, 0.30);
        color: ${styles.colors.gray};
    }
`;