import styled from "styled-components";

export const Container = styled.div`
    left: 0.3%;
    height: auto;
    position: fixed;
    background-color: white;
    padding: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: start;
    align-self: flex-end;
    bottom: 3px;
    width: 99.4%;
    border: 1px solid #33b270;
    border-radius: 5px;

   text{
    text-align: start;
    font-size: 20px;
    font-family: var(--font-familyS);
    color: black;
    margin-bottom: 5px;
   } 

   p{
    text-align: start;
    font-size: 15px;
    font-family: var(--font-familyS);
    color: gray;
    width: 90%;
   }

   button{
      background-color: var(--tertiary);
      color: white;
      border: none;
      width: 200px;
      height: 30px;
      border-radius: 15px;
      margin-right: 20px;
      cursor: pointer;
      :hover{
        background-color: var(--secondary);
      }
   }

   @media (max-width: 600px)
      {
      flex-direction: column;
      }
`;