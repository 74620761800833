import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ControllerSubZoneUser } from "../../../ludzoneSDK/Subzone/SubzoneUser/controller";
import { BffSubzoneController } from "../../../ludzoneSDK/Subzone/controller";
import { TypeSubzone } from "../../../ludzoneSDK/Subzone/type";
import { getId } from "../../../services/localStorage";
import queryClient from "../../../services/react-query";

export const SubzoneState = () => {

    const { idSubzone, container } = useParams();
    const [subzone, setSubzone] = useState<TypeSubzone>()
    const [adminorowner, setAdmin] = useState<boolean>(false);
    const [openMenu, setOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)


    const { data, refetch } = BffSubzoneController(idSubzone!)

    const {RemoveUserSubZoneMutation, ExitUserSubzoneMutation} = ControllerSubZoneUser()

    const HandleRemoveMember = (id: string, idSubZone: string) => {
        RemoveUserSubZoneMutation.mutate({id: id, idSubZone: idSubZone})
    }

    const HandleExitMember = (id: string, idSubZone: string) => {
        ExitUserSubzoneMutation.mutate(idSubZone)
    }

    
    useEffect(() => {
        queryClient.removeQueries({ queryKey: "UseSubzoneBff" })
        setSubzone(undefined)
        setLoading(true);
      }, [])
      
   

    useEffect(() => {
        if (data && loading) {
            setSubzone({
                id: data.id,
                description: data.description,
                name: data.name,
                archives: data.archives,
                members: data.members,
                aws: data.aws,
                user_owner: data.user_owner
            })
            setAdmin(data.user_owner_fk === parseInt(getId()!))

        }
    }, [data, loading])

    return {
        subzone, container, adminorowner, refetch, openMenu, setOpen, HandleRemoveMember, HandleExitMember
    }
}
