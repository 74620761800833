
import { useContext } from "react"
import { Button, ModalInsiderKanbanCard, UploadFile } from "../../../../../../../../ludz.one_ui"
import { Padding } from "../../../../../../../../ludz.one_ui/styles/styled-components"
import { Row } from "../../../../../../../../styles/globalStyles"
import { KanbanCardSubzonaContext } from "../../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context"
import { KanbanCardSubzonaContextType } from "../../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type"

interface PropsDashbordModal {
    visible?: boolean,
    onOpen?: any,
}


const ModalArchives = ({ visible, onOpen}: PropsDashbordModal) => {

    const { setFiles, handleArchives, card } = useContext(KanbanCardSubzonaContext) as KanbanCardSubzonaContextType


   
    return (
        <ModalInsiderKanbanCard visible={visible} onOpen={onOpen} title={"Anexar ao Painel"}>
            <Padding padding="16px" />
           <UploadFile setFile={setFiles} />
           <Padding padding="16px" />
           <Row id="center">
                <Button title="Enviar" onClick={() => {
                    handleArchives(card?.id); 
                    onOpen()}} />
           </Row>
        </ModalInsiderKanbanCard>
    )
}

export default ModalArchives