import { CreateZoneController } from "../../../ludzoneSDK/Zone/controller"
import { PropsInitialValue } from "./type"
import * as Yup from 'yup';

export const CreateZoneState = () => {

    const initialValues: PropsInitialValue = {
        name: "",
        description: "",
        isPublic: false,
        price: 0,
        zoneType: 0
    }

    const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        description: Yup.string().required('A descrição é obrigatória'),
      });

    const { CreateZoneMutation } = CreateZoneController();

    const handleCreateZone = (body: PropsInitialValue) => {
        CreateZoneMutation.mutate(body)
    }


    return {
        initialValues, handleCreateZone, schema
    }
}