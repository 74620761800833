import { createContext } from "react";
import { InviteZonaContextType } from "./type";
import { InviteZoneState } from "./state";

export const InviteZonaContext = createContext<InviteZonaContextType | null>(null);

interface Props {
    children: React.ReactNode
}


const InviteZonaProvider = ({ children }: Props) => {

    const {handleInvite, invites, handleDeleteInvite, toastInvite} = InviteZoneState()


    return (
        <InviteZonaContext.Provider value={{ handleInvite, invites, handleDeleteInvite, toastInvite }}>
            {children}
        </InviteZonaContext.Provider>
    )
}

export default InviteZonaProvider;