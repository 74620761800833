import React, { useState } from "react";

import { Column } from "../../styles/globalStyles";
import CustomImageInput from "../CustomImage";
import * as C from "./styles";

interface AvatarEditorProps {
  initialAvatarUrl: string | undefined;
  isediting: boolean | undefined;
  setImage: React.Dispatch<React.SetStateAction<File | undefined>>
}

const EditAvatar: React.FC<AvatarEditorProps> = ({
  initialAvatarUrl,
  setImage
  
}) => {
  const [avatarUrl, setAvatarUrl] = useState(initialAvatarUrl);

  return (
    <C.Container onMouseOver={() => {}}>
      <Column>
          <>
            <CustomImageInput
              name="files"
              url={avatarUrl}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.files) {
                  const file = event.target.files[0];
                  setAvatarUrl(URL.createObjectURL(file));
                  setImage(file);
                }
              }}
            />
          </>
      </Column>
    </C.Container>
  );
};

export default EditAvatar;
