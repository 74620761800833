import { useDebounce } from "primereact/hooks";
import { useEffect, useState } from "react";
import {
  ChannelPublicController,
  ChannelsPublicController,
} from "../../../ludzoneSDK/PublicChannel/controller";
import { useParams } from "react-router";
import { Channel } from "./type";

export const StateChannelsPublic = () => {
  const { id } = useParams();

  const [page, setPage] = useState(+1);
  const [channelsPublics, setChannelsPublics] = useState();
  const [channelPublic, setChannelPublic] = useState<Channel | undefined>();
  const [channelall, setChannelAll] = useState(0);
  const [inputValue, debouncedValue, setInputValue] = useDebounce("", 500);
  const { channels, isLoadingChannels, errorChannels, refetch } =
    ChannelsPublicController(page, debouncedValue);

  const {
    channel,
    isLoadingChannel,
    errorChannel,
    refetchschannel,
    BuyChannelMutation,
  } = ChannelPublicController(id);

  const BuyChannel = (idChannel: number, idUser: number) => {
    BuyChannelMutation.mutate({
      channel: idChannel,
      user: idUser,
      validDate: new Date(Date.now()).toISOString(),
    });
  };

  useEffect(() => {
    if (channels) {
      setChannelPublic(channel);
      setChannelsPublics(channels?.channels);
      setChannelAll(channels?.countChannelsPublic);
    }
    if (page) {
      setPage(page);
    }
    if (debouncedValue !== inputValue && channelsPublics) {
      setPage(1);
      setChannelsPublics(undefined);
    }
  }, [
    channels,
    page,
    channelall,
    refetch,
    debouncedValue,
    channelsPublics,
    inputValue,
    channel,
  ]);

  return {
    channelsPublics,
    isLoadingChannels,
    errorChannels,
    page,
    setPage,
    channelall,
    refetch,
    setInputValue,
    inputValue,
    channelPublic,
    isLoadingChannel,
    errorChannel,
    refetchschannel,
    BuyChannel
  };
};
