import { useNavigate } from "react-router-dom";
import { Container, ImageRoom } from "./style";
import LudzTooltip from "../../../ludz.one_ui/Tooltip";

interface PropsZonaMenu {
  zona?: any;
}

const ZonaMenu = ({ zona }: PropsZonaMenu) => {
  let history = useNavigate();

  function linkHistory() {
    history(`/zona/${zona.id ? zona.id : zona.zone.id}/painel`);
  }


  return (
    <Container>
      <LudzTooltip title={zona?.zone?.name ?? zona?.name} placement="right" arrow>
        <ImageRoom onClick={linkHistory}>
          <img src={zona!.zone ? zona?.zone?.aws?.url : zona?.aws?.url} alt="" />
        </ImageRoom>
      </LudzTooltip>
    </Container>
  );
};

export default ZonaMenu;
