import { useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ControllerInformation } from "../../../ludzoneSDK/Subzone/Information/controller";
import { SubzonaContext } from "../Subzone/context";
import { SubzonaContextType } from "../Subzone/type";
import { PropsInitialValueSubzone } from "./type";
import { SubzoneArchivesController } from "../../../ludzoneSDK/Subzone/Archives/controller";
import { Toast } from "primereact/toast";

export const SubZoneInfoState = () => {
  const { idSubzone } = useParams();

  const [files, setFiles] = useState();

  const toast = useRef<Toast>(null);

  const {
    UpdateAvatarSubZoneMutation,
    UpdateSubZoneMutation,
    RemoveAvatarSubZoneMutation,
    DeleteSubZoneMutation,
  } = ControllerInformation({ toast: toast });

  const { CreateSubZoneArchivesMutation, DeleteSubZoneArchivesMutation } =
    SubzoneArchivesController();

  const { subzone, refetch } = useContext(SubzonaContext) as SubzonaContextType;

  const [subzonename, setSubZoneName] = useState(subzone?.name);
  const [subzonedescription, setSubZoneDescription] = useState(
    subzone?.description
  );
  const [isediting, setIsEditing] = useState(false);

  const initialValues: PropsInitialValueSubzone = {
    name: subzonename,
    description: subzonedescription,
  };

  const HandleRemoveAvatarInfo = () => {
    RemoveAvatarSubZoneMutation.mutate(
      { id: idSubzone! },
      {
        onSuccess: (data, variables, context) => {
          // I will fire second!
          refetch();
        },
        onError: (error, variables, context) => {
          // I will fire second!
        },
      }
    );
  };

  const HandleSaveAvatarInfo = (image: File | null, set: any) => {
    if (image != null) {
      UpdateAvatarSubZoneMutation.mutate(
        { file: image, id: idSubzone! },
        {
          onSuccess: (data, variables, context) => {
            // I will fire second!
            refetch();
          },
          onError: (error, variables, context) => {
            // I will fire second!
          },
        }
      );
    }
    set(null);
  };

  const HandleSaveInfo = (body: PropsInitialValueSubzone) => {
    UpdateSubZoneMutation.mutate({ body: body, id: idSubzone! });
    refetch();
    setIsEditing(false);
  };

  const HandleDeleteSubZone = (id: string) => {
    DeleteSubZoneMutation.mutate(id);
  };

  const HandleArchivesSubzone = (id: number) => {
    if (files) {
    
      var arq = files[0];

      CreateSubZoneArchivesMutation.mutate({data: arq, id: id.toString()});
    }
  };

  const HandleDeleteArchives = (id: string) => {
    DeleteSubZoneArchivesMutation.mutate(id);
  };

  return {
    HandleSaveInfo,
    HandleSaveAvatarInfo,
    subzonename,
    setSubZoneName,
    subzonedescription,
    setSubZoneDescription,
    HandleRemoveAvatarInfo,
    isediting,
    setIsEditing,
    initialValues,
    HandleDeleteSubZone,
    setFiles,
    HandleArchivesSubzone,
    HandleDeleteArchives,
    toast,
  };
};
