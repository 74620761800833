import { useMediaQuery } from "@mui/material";
import { DataView } from "primereact/dataview";
import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";
import { useNavigate } from "react-router-dom";
import CardChannel from "../../../../components/Cards/CardChannel";
import {
  ChannelBusiness,
  ChannelsBusiness
} from "../../../../contexts/PrivateRoutes/types";
import { Button } from "../../../../ludz.one_ui";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../styles/globalStyles";

const MyChannel = ({
  name,
  channels,
}: {
  name: string;
  channels: ChannelsBusiness | undefined;
}) => {

  const history = useNavigate();

  const matchesDesktop = useMediaQuery("(min-width: 1450px)");
  const matchesTablet = useMediaQuery(
    "(min-width: 481px) and (max-width: 1024px)"
  );

  const itemTemplate = (channel: ChannelBusiness) => {
    return (
      <>
        {channels ? (
          <>
            {channels.length !== 0 ? (
              <div
                className={
                  matchesDesktop
                    ? "col-12 md:col-3"
                    : matchesTablet
                    ? "col-12 md:col-6"
                    : "col-12 md:col-4"
                }
              >
                <CardChannel
                  channel={channel.channel ? channel.channel : channel}
                  contentQnt={channel.contentQnt}
                  playlistsQnt={channel.playlistsQnt}
                />
              </div>
            ) : (
              <div style={{ margin: "32px 0" }}>
                <Row>
                  <Column id="center">
                    <h2>Crie</h2>
                  </Column>
                  <Padding />
                  <Button
                    onClick={() => history("/criar/zona")}
                    rounded
                    icon="pi pi-plus"
                  />
                </Row>
                <Padding padding="8px" />
                <Row>
                  <Column id="center">
                    <h2>Pesquisar Canais</h2>
                  </Column>
                  <Padding />
                  <Button rounded icon="pi pi-search" />
                </Row>
              </div>
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <ProgressSpinner />
          </div>
        )}
        {/* {totalZonas ? (
            <CardZona zona={blog} />
        ) : null} */}
      </>
    );
  };

  const dataViewHeader = (
    <div className="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3">
      <span className="text-xl text-900 font-semibold">{name}</span>
    </div>
  );

  return (
    <React.Fragment>
      <div className="card lg:p-4">
        <DataView
          value={channels}
          paginator
          rows={matchesDesktop ? 4 : matchesTablet ? 2 : 3}
          emptyMessage="Obtenha um novo canal em explorar canais"
          layout={"grid"}
          itemTemplate={itemTemplate}
          header={dataViewHeader}
        ></DataView>
      </div>
    </React.Fragment>
  );
};

export default MyChannel;
