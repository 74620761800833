import { useMutation } from "react-query";
import { PropsFormBusiness } from "./type";
import { FormBusinessRequest } from "./request";
export const FormBusinessController = () => {

    const FormBusinessMutation = useMutation((data: PropsFormBusiness) => FormBusinessRequest(data), {
        onMutate: () => {
        //   setLoading(true); 
        //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
        //   setError(error.response.data.message);
        //   setLoading(false);

            console.log(error)
        },
        onSuccess: (result, variables, context) => {

        },
      });

    return {
        FormBusinessMutation,
    }
}