import { AvatarGroup } from "primereact/avatargroup";
import { OverlayPanel } from "primereact/overlaypanel";
import { useContext, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { KanbanSubzonaContext } from "../../../../../contexts/Subzone/KanbanSubzone/context";
import { KanbanSubzonaContextType } from "../../../../../contexts/Subzone/KanbanSubzone/type";
import {
  Avatar,
  Icon,
  OverlayPanelLudz,
  Tooltip,
} from "../../../../../ludz.one_ui";
import ConfirmationDialog from "../../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes";
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../../styles/globalStyles";
import MoveCard from "./InsiderCard/MoveCard";
import InsiderOverlayPanel from "./InsiderOverlayPanel";
import { ItemDate, Label, ObjectiveLine, Status } from "./style";

interface PropsCardKanban {
  item: any;
}

const CardKanban = ({ item }: PropsCardKanban) => {
  const history = useNavigate();

  const { idSubzone, id } = useParams();
  const [visible, setVisible] = useState(false);
  const [visibleMove, setVisibleMove] = useState(false);

  const dateTime = item ? item.expectedDate : null;

  const data = new Date(`${dateTime}`);

  let dia = data.getDate(); // retorna o dia do mês
  let mes = data.getMonth() + 1; // retorna o mês (0 é Janeiro, então adicionamos 1)
  let ano = data.getFullYear(); // retorna o ano

  // Adiciona um zero à esquerda se o dia ou o mês for menor que 10
  if (dia < 10) dia = 0 + dia;
  if (mes < 10) mes = 0 + mes;

  let dataFormatada = dia + "/" + mes + "/" + ano;

  const { handleDeleteCard } = useContext(
    KanbanSubzonaContext
  ) as KanbanSubzonaContextType;

  const op = useRef<OverlayPanel>(null);

  const handlePanelContentClick = () => {
    if (op.current) {
      op.current.hide();
    }
  };

  const objetiveCard = item?.objectives[0]?.objective_key;

  // Função para renderizar membros
  const totalMembros = () => {
    let total = item?.members?.length;
    let valor;

    if (total > 3) {
      return (valor = total - 3);
    } else {
      return valor;
    }
  };

  const displayedMembers = item?.members?.slice(0, 3);

  return (
    <>
      <div
        onClick={(e) => {
          // e.stopPropagation();
          history(`/zona/${id}/subzona/${idSubzone}/kanban/${item.id}`);
        }}
      >
        {item?.labels?.length > 0 ? (
          <>
            <Row>
              {item?.labels?.map((item: any, index: number) => {
                return <Label color={item?.label?.color} />;
              })}
            </Row>
            <Padding />{" "}
          </>
        ) : null}
        <Row id="space-between">
          <Tooltip placement="top" arrow title={item?.title}>
            <Row>
              {item?.title?.length > 30
                ? item?.title.substring(0, 30) + "..."
                : item?.title}
            </Row>
          </Tooltip>
          <Row>
            <Column>
              {item.is_finished ? (
                <Status isStatus={item?.is_finished}>
                  <Column
                    id="center"
                    style={{ alignItems: "center", height: "100%" }}
                  >
                    <Icon size="0.8rem" icon="pi pi-check-square" />
                  </Column>{" "}
                </Status>
              ) : null}
            </Column>
            <Column>
              <OverlayPanelLudz
                ref={op}
                styleButton={{
                  background: "#e4e6e7",
                  height: "24px",
                  width: "24px",
                  borderRadius: "50%",
                  display: "flex",
                }}
                button={
                  <Icon
                    icon="pi pi-ellipsis-v"
                    size="1rem"
                    style={{ margin: "auto", cursor: "pointer" }}
                  />
                }
                components={
                  <InsiderOverlayPanel
                    item={[
                      {
                        icon: "pi pi-arrow-right",
                        name: "Mover",
                        onClick: () => {
                          setVisibleMove(true);
                        },
                      },
                      {
                        icon: "pi pi-trash",
                        name: "Excluir",
                        onClick: () => {
                          setVisible(true);
                        },
                      },
                    ]}
                  />
                }
              />
            </Column>
          </Row>
        </Row>
        <>
          {item.members.length > 0 || item?.expectedDate ? (
            <Row id="end" style={{ marginTop: "8px" }}>
              {item.members.length > 0 ? (
                <>
                  {" "}
                  <Padding />
                  <Row>
                    {item.members.length <= 3 ? (
                      <>
                        {item.members.map((item: any, index: number) => {
                          return (
                            <AvatarGroup>
                              <Avatar
                                img={item.member.aws.url}
                                size="normal"
                                shape="circle"
                              ></Avatar>
                            </AvatarGroup>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <AvatarGroup>
                          {displayedMembers.map((item: any, index: any) => {
                            return (
                              <Avatar
                                img={item.member.aws.url}
                                size="normal"
                                shape="circle"
                              ></Avatar>
                            );
                          })}
                          <Avatar
                            label={`+${totalMembros()}`}
                            shape="circle"
                            size="normal"
                            style={{
                              backgroundColor: "#9c27b0",
                              color: "#ffffff",
                            }}
                          ></Avatar>
                        </AvatarGroup>
                      </>
                    )}
                  </Row>
                </>
              ) : null}
              {item?.expectedDate ? (
                <ItemDate
                  color={new Date(item?.expectedDate) > new Date(Date.now())}
                >
                  <i className="pi pi-clock" />
                  {dataFormatada}
                </ItemDate>
              ) : null}
            </Row>
          ) : null}
          {item?.objectives?.length > 0 ? (
            <>
              <Padding />
              <ObjectiveLine color={objetiveCard?.color} />
            </>
          ) : null}
        </>
      </div>
      <ConfirmationDialog
        accept={() => {
          handleDeleteCard!(item.id);
          handlePanelContentClick();
        }}
        visible={visible}
        reject={() => setVisible(true)}
        message="Deseja Excluir?"
        onHide={() => setVisible(false)}
      />
      <MoveCard card={item} visible={visibleMove} setvisible={setVisibleMove} />
    </>
  );
};

export default CardKanban;
