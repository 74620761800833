import http from "../../../../services/api";
import { logout } from "../../../../services/localStorage";
import { PropsSubzoneObjectiveKey } from "./type";

export const SubzoneObjectiveKeyRequest = async (body: PropsSubzoneObjectiveKey) => {
     return await http.post("/direct/sub-zone-objective-key", body).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               alert(err.response.data.message)
               throw err;
          });
}

export const UpdateSubzoneObjectiveKeyRequest = async (body: any, id: string) => {
     return await http.put(`/direct/sub-zone-objective-key/${id}`, body).then((response) => response.data)
    .catch (err => {
     if (err.response.status === 401) {
          logout()
          window.location.reload()
     }
     alert(err.response.data.message)
     throw err;
});
}

export const DeleteSubzoneObjectiveKeyRequest = async (id: string) => {
     return await http.delete(`/direct/sub-zone-objective-key/${id}`).then((response) => response.data)
    .catch (err => {
     if (err.response.status === 401) {
          logout()
          window.location.reload()
     }
     alert(err.response.data.message)
     throw err;
});
}



export const GetSubzoneObjectiveKeyRequest = async (id: number) => {
     return await http.get("/direct/sub-zone-objective-key", {
          params: {
               objective_fk: id,
          }
     }).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               throw err;
          });
} 