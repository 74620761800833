import { useNavigate } from "react-router-dom";
import {
  Notifications,
  PrivateRoutesContextType,
} from "../../../contexts/PrivateRoutes/types";
import CardInvite from "../../../ludz.one_ui/Card/CardInvite";
import { Column } from "../../../styles/globalStyles";
import { useContext } from "react";
import { PrivateRoutesContext } from "../../../contexts/PrivateRoutes/context";
import styles from "../../../ludz.one_ui/styles";

interface Props {
  notification: Notifications;
  showElement: boolean;
}

const CardNotifications = ({ notification, showElement }: Props) => {
  const { UpdateNotifcation } = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;

  const history = useNavigate();
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const date = new Date(notification?.createdAt);

  var day: any = date.getDate();

  if (day < 10) {
    day = "0" + day;
  }

  var postDate = day + " de " + months[date.getMonth()].substring(0, 3);


  const Redirect = () => {
    if (notification.fromLocal === 0 && notification?.links[0]?.zone_fk) {
      UpdateNotifcation(notification.id.toString(), { isViewed: true });
      history(`/zona/${notification?.links[0].zone_fk}/mural`);
    }
    if (notification.fromLocal === 3 && notification?.links[0]?.zone_fk) {
      UpdateNotifcation(notification.id.toString(), { isViewed: true });
      history(`/zona/${notification?.links[0].zone_fk}/painel`);
    }
    if (notification.fromLocal === 1 && notification?.links[0]?.subZone_fk) {
      UpdateNotifcation(notification.id.toString(), { isViewed: true });
      history(
        `/zona/${notification?.links[0].zone_fk}/subzona/${notification?.links[0]?.subZone_fk}/mural`
      );
    }
    if (notification.fromLocal === 2 && notification?.links[0]?.subZone_fk) {
      UpdateNotifcation(notification.id.toString(), { isViewed: true });
      history(
        `/zona/${notification?.links[0].zone_fk}/subzona/${notification?.links[0]?.subZone_fk}/kanban/${notification?.links[0]?.kanban_card_fk}`
      );
    }
  };
  return (
    <div className="w-full flex justify-content-between align-items-center gap-2 ">
      <CardInvite
        onClick={Redirect}
        style={{
          width: '100%', background: notification?.isViewed! ? styles.colors.grayClearOne : "",
        }}
      >
        <Column id="space-between" style={{ height: "100%" }}>
          <h4>{notification.whatMod}</h4>
          <p>{postDate}</p>
        </Column>
      </CardInvite>
    </div>
  );
};

export default CardNotifications;
