import { Form, Formik } from "formik";
import { ChangeEvent, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import LudzModalMentions from "../../../../../../../components/Mentions/ModalMentions";
import { PrivateRoutesContext } from "../../../../../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../../../../../../contexts/PrivateRoutes/types";
import { KanbanCardSubzonaContext } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context";
import { KanbanCardSubzonaContextType } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type";
import { SubzonaContext } from "../../../../../../../contexts/Subzone/Subzone/context";
import { SubzonaContextType } from "../../../../../../../contexts/Subzone/Subzone/type";
import { Avatar, Button, Icon, Input } from "../../../../../../../ludz.one_ui";
import { Padding } from "../../../../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../../../../styles/globalStyles";
import Comment from "./Comment";
import { AddDescription } from "./style";
import { ButtonPrimeIcon } from "../../../../../../../ludz.one_ui/Buttons/ButtonPrimeIcon";

const CommentsCard = () => {
  const [visible, setVisible] = useState(false);
  const { userProvider } = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;
  const { handleComments, card } = useContext(
    KanbanCardSubzonaContext
  ) as KanbanCardSubzonaContextType;
  const { subzone } = useContext(SubzonaContext) as SubzonaContextType;
  const { idcard } = useParams();

  const [mentions, setMentions] = useState(false);
  const HandleKeyUp = (e: any) => {
    if (e.shiftKey === true && e.keyCode === 50) {
      return setMentions(true);
    } else {
      setMentions(false);
    }
  };

  const [charCount, setCharCount] = useState(0);

const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setCharCount(event.target.value.length);
};

  const cardReverse = card ? [...card.comments]?.reverse() : [];

  return (
    <Column>
      <Row>
        <Row>
          <Column id="center">
            <Icon icon="pi pi-comments" size="1rem" />
          </Column>
          <Padding padding="1px" />
          <Column id="center">
            <h3>Comentarios</h3>
          </Column>
        </Row>
        <Padding padding="1px" />
      </Row>
      <Padding padding="8px" />
      {visible ? (
        <Column>
          <Formik
            initialValues={{ content: "", mentions: [] }}
            onSubmit={(values) => {
              handleComments({
                content: values.content,
                kanbanCard: parseInt(idcard!),
                mentions: values.mentions,
              });
              setVisible(!visible);
              setCharCount(0)
            }}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form className="flex flex-column">
                  <Input
                    onKeyDown={HandleKeyUp}
                    name="content"
                    maxLength={255}
                    value={values.content}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      handleTextChange(e);
                    }}
                  />
                  <p style={{ alignSelf: 'flex-end' }} className="font-semibold">{charCount} / 255</p>
                  {mentions && (
                    <LudzModalMentions
                      input
                      ids={values.mentions}
                      onClose={() => setMentions(false)}
                      members={subzone?.members}
                      value={values.content}
                      onChange={setFieldValue}
                    />
                  )}
                  <Padding />
                  <Row>
                    <Button title="Enviar" type="submit" />
                    <Button
                      title="Cancelar"
                      type="button"
                      text
                      onClick={() => setVisible(!visible)}
                    />
                    <ButtonPrimeIcon
                      className="pi pi-at"
                      fontSize="1.2rem"
                      style={{
                        marginLeft: "5px",
                        alignSelf: "center",
                        padding: "8px",
                      }}
                      onClick={() => setMentions(!mentions)}
                    ></ButtonPrimeIcon>
                  </Row>
                </Form>
              );
            }}
            {/* <Mentions value={descriptionState} members={subzone?.members} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setdescriptionState(e.target.value)} placeholder="Enter @ to mention people"  /> */}
          </Formik>
        </Column>
      ) : (
        <Column style={{ width: "100%" }}>
          <Row style={{ width: "100%" }}>
            <Avatar img={userProvider!.aws.url} shape="circle" />
            <AddDescription
              style={{ width: "90%" }}
              onClick={() => setVisible(!visible)}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: "Adicionar comentario",
                }}
              />
            </AddDescription>
          </Row>
        </Column>
      )}
      <Padding />

      {cardReverse?.map((item: any, index: number) => {
        return <Comment item={item} />;
      })}
    </Column>
  );
};

export default CommentsCard;
