import { Form, Formik } from "formik"
import { useContext } from "react"
import { ObjectiveSubzonaContext } from "../../../../../contexts/Subzone/ObjectiveSubzone/context"
import { ObjectiveSubzonaContextType } from "../../../../../contexts/Subzone/ObjectiveSubzone/type"
import { Button, Input, Modal } from "../../../../../ludz.one_ui"
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components"
import { Column, Row } from "../../../../../styles/globalStyles"
import { useParams } from "react-router-dom"

interface PropsCreateSection {
    visible?: boolean,
    onOpen?: any,
    data: any,
    id?: number
}

const CreateTask = ({ visible, onOpen,  data, id }: PropsCreateSection) => {

    
    
    const { handleTask, initialValueTask, schemaTask } = useContext(ObjectiveSubzonaContext) as ObjectiveSubzonaContextType
    
    const {idSubzone} = useParams()

 

    return (
        <Modal visible={visible} onOpen={onOpen} title={"Criar Tarefa"}>
            <Formik initialValues={initialValueTask} validationSchema={schemaTask} onSubmit={(values) => { 
                handleTask({title: values.title, objectiveId: id!, objectiveKeyId: data.id, subZoneId: parseInt(idSubzone!)}, onOpen ) 
                }}>
                {({ values, handleChange, errors, touched, handleSubmit }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Column>
                                <Input name="title" value={values.title} onChange={handleChange} label="Nome da tarefa" />
                            </Column>
                            <Padding />
                            {errors.title && touched.title ? (
                                <div style={{ color: "red" }}>{errors.title.toString()}</div>
                            ) : null}
                            <Padding padding="8px" />
                            <Padding padding="16px" />
                            <Row id="center">
                                <Button title="Criar" type="submit" />
                            </Row>
                        </Form>
                    )
                }}
            </Formik>

        </Modal>
    )
}

export default CreateTask