import styled from "styled-components";
import styles from "../../styles/index"


export const Card = styled.div`
    display: flex;
    flex-direction: row;
   // border-style: solid;
    //border-width: 0.5px;
    padding: 10px;
    border-radius: 10px;
    margin: 16px 0 0 0;
    cursor: pointer;
    :hover{
        color: ${styles.colors.primary};

    }
`;