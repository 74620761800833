import { Column } from "../../../styles/globalStyles";
import { PrivacyAttributis } from "../types/Types";
import { Container } from "./style";



const PrivacyNotice = ({set}: PrivacyAttributis) => {
    // const matches = useMediaQuery('(max-width:769px)');
    return(
        <Container>
            
            <Column style={{marginBottom: '15px'}}>
                <text>
                    Ludz.One
                </text>
            <p>
            Nós utilizamos cookies e outras tecnologias semelhantes para melhorar sua experiência em nossos serviços e recomendar conteúdo de seu interesse.

Ao utilizar nossos serviços, você aceita a política de monitoramento de cookies. Para mais informações, consulte nossa Política de cookies.            </p>
            </Column>
            <Column id="center">
                <button onClick={() =>{
                    set(false)
                }}>
                    Aceito todos os Cookies
                </button>
            </Column>
            
        </Container>
    )
}

export default PrivacyNotice;