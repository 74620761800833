import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ZoneBffController } from "../../../ludzoneSDK/Zone/controller";
import { ZonaPublicType } from "../../../ludzoneSDK/Zone/type";
import { ControllerZoneUser } from "../../../ludzoneSDK/Zone/ZoneUser/controller";
import { getId } from "../../../services/localStorage";
import queryClient from "../../../services/react-query";

export const ZonePublicState = () => {

    const { id, container } = useParams();
    const [zonepublic, setZonePublic] = useState<ZonaPublicType>()
    const [adminorowner, setAdmin] = useState<boolean>(false);
    const [openMenu, setOpen] = useState<boolean>(false)

    const [loading, setLoading] = useState(false)
    useEffect(() => {
        queryClient.removeQueries({ queryKey: "UseZoneIdBff" })
        setLoading(true);
    }, [])


    const { data, refetch } = ZoneBffController(id)

    const toast = useRef<Toast>(null)

    const { RemoveUserZoneMutation, UpdateZoneUserMutation, ExitUserZoneMutation,   InviteforEmailZoneUserMutation } = ControllerZoneUser({toast: toast});


    const HandleRemoveMember = (idZone: string, id: string) => {
        RemoveUserZoneMutation.mutate({ id: id, idZone: idZone })
    }

    const HandleExitZone = (id: string, idZone: string) => {
        ExitUserZoneMutation.mutate({ id: id, idZone: idZone })
    }

    const HandleUpdateMember = (id: number, idZone: number, isAdmin: boolean) => {
        UpdateZoneUserMutation.mutate({ isAdmin: isAdmin, user: id, zone: idZone })
    }

    const HandleInviteEmailMember = (email: string, idZone: number) => {
        InviteforEmailZoneUserMutation.mutate({ email: email, zoneId: idZone })
    }

    useEffect(() => {
        if (data && loading) {
            setZonePublic(data)
            const isSeachUser = data?.zoneMembers?.find((props: any) => (props.user.id === parseInt(getId()!) && props.isAdmin === true))
            setAdmin(isSeachUser ? true : false)
        }
    }, [data, loading])

    return {
        zonepublic, HandleExitZone, container, adminorowner, refetch, openMenu, setOpen, HandleRemoveMember, HandleUpdateMember, HandleInviteEmailMember, toast
    }
}