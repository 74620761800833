import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ZoneBffController } from "../../../ludzoneSDK/Zone/controller";
import { ZonaType } from "../../../ludzoneSDK/Zone/type";
import { getId } from "../../../services/localStorage";
import { ControllerZoneUser } from "../../../ludzoneSDK/Zone/ZoneUser/controller";
import queryClient from "../../../services/react-query";
import { useMediaQuery } from "@mui/material";
import { Toast } from "primereact/toast";

export const ZoneState = () => {
  const matches = useMediaQuery("(max-width:750px)");

  const { id, container } = useParams();
  const [zone, setZone] = useState<ZonaType>();
  const [adminorowner, setAdmin] = useState<boolean>(false);
  const [openMenu, setOpen] = useState<boolean>(matches ? true : false);
  const { data, refetch, LastAcess } = ZoneBffController(id);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    queryClient.removeQueries({ queryKey: "UseZoneIdBff" });
    setLoading(true);
    LastAcess.mutate({zoneId: parseInt(id!)})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const toastZone = useRef<Toast>(null)

  const {
    RemoveUserZoneMutation,
    UpdateZoneUserMutation,
    ExitUserZoneMutation,
    InviteforEmailZoneUserMutation,
  } = ControllerZoneUser({toast: toastZone});

  const HandleRemoveMember = (idZone: string, id: string) => {
    RemoveUserZoneMutation.mutate({ id: id, idZone: idZone });
  };

  const HandleExitZone = (id: string, idZone: string) => {
    ExitUserZoneMutation.mutate({ id: id, idZone: idZone });
  };

  const HandleUpdateMember = (id: number, idZone: number, isAdmin: boolean) => {
    UpdateZoneUserMutation.mutate({ isAdmin: isAdmin, user: id, zone: idZone });
  };

  const HandleInviteEmailMember = (email: string, idZone: number) => {
    InviteforEmailZoneUserMutation.mutate({ email: email, zoneId: idZone });
  };

  useEffect(() => {
    if (data && loading) {
      setZone({
        name: data.name,
        aws: data.aws,
        aws_2: data.aws_2,
        description: data.description,
        isPublic: data.isPublic,
        subzones: data.subZones,
        zoneMembers: data.zoneMembers,
        isIntelligent: data.isIntelligent,
        id: data.id,
        posts: data.posts,
        user_owner_fk: data.user_owner_fk,
        default: data.default,
        tags: data.tags
      });
      const isSeachUser = data?.zoneMembers?.find(
        (props: any) =>
          props.user.id === parseInt(getId()!) && props.isAdmin === true
      );
      setAdmin(isSeachUser ? true : false);
    }
  }, [data, loading]);

  return {
    zone,
    HandleExitZone,
    container,
    adminorowner,
    refetch,
    openMenu,
    setOpen,
    HandleRemoveMember,
    HandleUpdateMember,
    HandleInviteEmailMember,
    toastZone
  };
};
