import { ProgressBar } from "primereact/progressbar"
import { useContext, useState } from "react"
import { ObjectiveSubzonaContext } from "../../../../../../contexts/Subzone/ObjectiveSubzone/context"
import { ObjectiveSubzonaContextType } from "../../../../../../contexts/Subzone/ObjectiveSubzone/type"
import { Icon, Tooltip } from "../../../../../../ludz.one_ui"
import { Padding } from "../../../../../../ludz.one_ui/styles/styled-components"
import { Column, Row } from "../../../../../../styles/globalStyles"
import ModalDetailsObjetiveKey from "../ModalDetalisObjectiveKey"
import { Circle } from "../style"
import { ObjectiveKeySubzonaContext } from "../../../../../../contexts/Subzone/ObjectiveSubzone/ObjectiveKeySubzone/context"
import { ObjectiveKeySubzonaContextType } from "../../../../../../contexts/Subzone/ObjectiveSubzone/ObjectiveKeySubzone/type"
import ConfirmationDialog from "../../../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes"
import { useMediaQuery } from "@mui/material"
import { Knob } from 'primereact/knob';



type PropsHeaderAccordion = {
    item: any,
}

const HeaderAccordion = ({ item }: PropsHeaderAccordion) => {
    const matches = useMediaQuery("(max-width: 520px)")
    const [visible, setVisible] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const { accordionObjetivekey, setAccordionObjetivekey } = useContext(ObjectiveSubzonaContext) as ObjectiveSubzonaContextType

    const { handleDeleteObjectivekey } = useContext(ObjectiveKeySubzonaContext) as ObjectiveKeySubzonaContextType

    const IsFinish = () => {

        if (item.kanbanCardObjectives) {
            var count = 0;
            item.kanbanCardObjectives?.forEach((element: any) => {
                if (element.kanbanCard.is_finished) {
                    count++;
                }
            });

            return item.kanbanCardObjectives.length === 0 ? 0 : ((count / item.kanbanCardObjectives.length) * 100).toFixed(1);

        }
        return 0
    }



    return (
        <Column style={{ height: matches ? 'auto' : "36px" }} id='center'>
            <Row id="space-between" style={{ flexDirection: matches ? 'column' : 'row' }}>
                <Row style={{ marginBottom: matches ? '10px': '0px'  }}>
                    {/* <Column id="center">
                        <CheckboxLudz checked={item.isFinished} />
                    </Column> */}
                    {/* <Padding /> */}
                    <Column id="center">
                        <Circle color={item.color} />
                    </Column>
                    <Padding />
                    <Tooltip title={item.content}>
                        <Column id="center">
                            {matches ? <h3>{item.content.substring(0, 15)}</h3> : <h3>{item.content.substring(0, 100)}</h3>}
                        </Column>
                    </Tooltip>
                </Row>
                <Column id="center">
                    <Row>
                        <Column id="center">
                            {!matches ? <><div style={{ width: "200px" }}></div>
                                <ProgressBar value={IsFinish()}></ProgressBar></> :
                                <Knob value={parseInt(IsFinish().toString())} size={60} />}
                        </Column>
                        <Column id='center'>
                            <Row>
                                <Icon size="1.2rem" onClick={(e: any) => { e.stopPropagation(); setVisibleModal(true); setAccordionObjetivekey(!accordionObjetivekey) }} icon="pi pi-eye mr-2"></Icon>
                                <Icon size="1.2rem" onClick={(e: any) => { e.stopPropagation(); setVisible(true) }} icon="pi pi-trash mr-2"></Icon>
                            </Row>
                        </Column>
                        {/* <Padding /> */}
                        {/* {item.isFinished ? <Status isStatus={item.isFinished}>Finalizado </Status> : <Status isStatus={item.isFinished}>Pedente</Status>} */}
                    </Row>
                </Column>
            </Row>
            {/* <Row id='space-between' >
                <h3 className="vertical-align-middle">{item!.content}</h3>
                <Row>
                    <Padding />
                    <Column id='center'>
                        <i onClick={(e) => { setVisible(true); e.stopPropagation() }} className="pi pi-trash mr-2"></i>
                    </Column>
                </Row>
            </Row> */}
            <ConfirmationDialog accept={() => handleDeleteObjectivekey!(item.id)} visible={visible} reject={() => setVisible(true)} message="Deseja Excluir?" onHide={() => setVisible(false)} />

            <ModalDetailsObjetiveKey visible={visibleModal} onOpen={() => { setVisibleModal(!visibleModal); setAccordionObjetivekey(!accordionObjetivekey) }} item={item} />
        </Column>
    )
}

export default HeaderAccordion;