import { useMutation } from "react-query";
import queryClient from "../../../../services/react-query";
import { DeleteKanbanTagsRequest, SubzoneKanbanTagsRequest } from "./request";
import { PropsKanbanTags } from "./type";

export const KanbanTagsController = () => {



    const KanbanTagsSubzoneMutation = useMutation((data: PropsKanbanTags) => SubzoneKanbanTagsRequest(data), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            console.log(error)
        },
        onSuccess: (result) => {

            queryClient.refetchQueries("UseSubzoneKanbanTagsbff")
            queryClient.refetchQueries("UseSubzoneKanbanbff")
        },
    });


    const DeleteKanbanTagsSubzoneMutation = useMutation((id: number) => DeleteKanbanTagsRequest(id), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            console.log(error)
        },
        onSuccess: (result) => {

            queryClient.refetchQueries("UseSubzoneKanbanTagsbff")
            queryClient.refetchQueries("UseSubzoneKanbanbff")
        },
    });

    

    return {
        KanbanTagsSubzoneMutation, DeleteKanbanTagsSubzoneMutation
    }
}