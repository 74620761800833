import { useContext, useState } from "react";
import { ObjectiveKeySubzonaContext } from "../../../../../../contexts/Subzone/ObjectiveSubzone/ObjectiveKeySubzone/context";
import { ObjectiveKeySubzonaContextType } from "../../../../../../contexts/Subzone/ObjectiveSubzone/ObjectiveKeySubzone/type";
import {
  Button,
  Input,
  InputTextArea,
  Modal,
} from "../../../../../../ludz.one_ui";
import { Padding } from "../../../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../../../styles/globalStyles";
import Task from "../../Tasks";
import { AddObjective } from "../../style";
import { ObjectiveSubzonaContext } from "../../../../../../contexts/Subzone/ObjectiveSubzone/context";
import { ObjectiveSubzonaContextType } from "../../../../../../contexts/Subzone/ObjectiveSubzone/type";
import { useParams } from "react-router-dom";

interface PropsCreateSectionContent {
  visible?: boolean;
  onOpen?: any;
  item: any;
}

const ModalDetailsObjetiveKey = ({
  visible,
  onOpen,
  item,
}: PropsCreateSectionContent) => {
  const [title, settitle] = useState("");

  const [visibleCreatetask, setvisibleCreatetask] = useState(false);

  const [content, setcontent] = useState(item.content);
  const [description, setdescription] = useState(item.description);

  const { handleUpdateObjectiveKey } = useContext(
    ObjectiveKeySubzonaContext
  ) as ObjectiveKeySubzonaContextType;

  const { handleTask } = useContext(
    ObjectiveSubzonaContext
  ) as ObjectiveSubzonaContextType;

  const { idSubzone } = useParams();

  return (
    <Modal visible={visible} onOpen={onOpen} title={"Meta"}>
      <div style={{ height: "4px", backgroundColor: item.color }} />
      <Padding padding="8px" />

      <Input
        label="Meta"
        value={content}
        onChange={(e: any) => setcontent(e.target.value)}
        onKeyDown={(e: any) => {
          if (e.key === " ") {
            e.stopPropagation();
          }
        }}
        name="content"
      />
      <Padding />
      <InputTextArea
        value={description}
        onChange={(e: any) => setdescription(e.target.value)}
        onKeyDown={(e: any) => {
            if (e.key === " ") {
                e.stopPropagation();
              } 
        }}
        name="description"
        style={{ width: "100%" }}
        label="Descrição"
      />
      <Padding />
      {item.kanbanCardObjectives?.map((data: any) => {
        return <Task item={data} idObjetictive={item?.objective_fk!} />;
      })}
      <Padding />
      {visibleCreatetask ? (
        <Row>
          <Column>
            <Input
              label="Tarefa"
              value={title}
              onChange={(e: any) => settitle(e.target.value)}
            />
          </Column>
          <Padding />{" "}
          <Column id="end">
            <Button
              title="Criar"
              type="button"
              onClick={() =>
                handleTask(
                  {
                    title: title,
                    objectiveId: item.objective_fk,
                    objectiveKeyId: item.id,
                    subZoneId: parseInt(idSubzone!),
                  },
                  setvisibleCreatetask
                )
              }
            />
          </Column>{" "}
          <Padding />{" "}
          <Column id="end">
            <Button
              title="Cancelar"
              severity="danger"
              type={"button"}
              onClick={() => setvisibleCreatetask(!visibleCreatetask)}
            />
          </Column>
        </Row>
      ) : null}
      <Padding />
      {!visibleCreatetask ? (
        <AddObjective onClick={() => setvisibleCreatetask(!visibleCreatetask)}>
          <Button
            icon="pi pi-plus"
            rounded
            style={{ width: "30px", height: "30px" }}
            aria-label="Filter"
          />
          <Padding />
          <Column id="center">
            <h4>Adicionar Tarefas</h4>
          </Column>
        </AddObjective>
      ) : null}
      <Padding padding="8px" />
      <Row id="center">
        <Button
          title="Salvar"
          onClick={() => {
            handleUpdateObjectiveKey(
              { content: content, description: description },
              item.id
            );
            onOpen();
          }}
        />
      </Row>
    </Modal>
  );
};

export default ModalDetailsObjetiveKey;
