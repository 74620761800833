import { Grid, useMediaQuery } from "@mui/material";
import LudzLoadingSkeleton from "../../../../../../ludz.one_ui/Loading/LoadingSkeleton";


const SkeletonSubzona = () => {
    const matches = useMediaQuery("(max-width:1160)");
    return (
        <Grid container direction="row" spacing={2}>
            <Grid item md={matches ? 6 : 4} xs={12}>
                <LudzLoadingSkeleton width="100%" height="5rem" />
            </Grid>
            <Grid item md={matches ? 6 : 4} xs={12}>
                <LudzLoadingSkeleton width="100%" height="5rem" />
            </Grid>
            <Grid item md={matches ? 6 : 4} xs={12}>
                <LudzLoadingSkeleton width="100%" height="5rem" />
            </Grid>
            <Grid item md={matches ? 6 : 4} xs={12}>
                <LudzLoadingSkeleton width="100%" height="5rem" />
            </Grid>
            <Grid item md={matches ? 6 : 4} xs={12}>
                <LudzLoadingSkeleton width="100%" height="5rem" />
            </Grid>
            <Grid item md={matches ? 6 : 4} xs={12}>
                <LudzLoadingSkeleton width="100%" height="5rem" />
            </Grid>
        </Grid>
    )
}

export default SkeletonSubzona;