import { createContext } from "react";
import { FormBusinessContextType } from "./type";
import { FormBusinessState } from "./state";

export const FormBusinessContext = createContext<FormBusinessContextType | null>(null);

interface Props {
    children: React.ReactNode
}


const FormBusinessProvider = ({ children }: Props) => {

    const { initialValues, handleFormBusiness, schema, displayModalForm, setDisplayModalForm } = FormBusinessState();


    return (
        <FormBusinessContext.Provider value={{ initialValues, handleFormBusiness, schema, displayModalForm, setDisplayModalForm }}>
            {children}
        </FormBusinessContext.Provider>
    )
}

export default FormBusinessProvider;