import { useEffect } from "react"
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { login } from "../../../services/localStorage";


const Pass = () => {

    const { token, id } = useParams()
    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        login(token)
        setTimeout(() => {
            if (location.pathname.includes('profile')) {
                navigate(`/perfil/${id}`);
            } else {
                navigate("/home");
            }
          }, 300);
    }, [navigate, token, location, id])


    return (
        <div>
            <h1>Redirected...</h1>
        </div>
    )
}

export default Pass