import styled from "styled-components";
import color from "../../../../../../../ludz.one_ui/styles/colors";

export const ItemStyle = styled.div`
  padding: 4px;
  cursor: pointer;
  :hover{
    background-color: ${color.grayClearOneHover};
  }
`;
