import { Form, Formik } from "formik"
import { useContext } from "react"
import { ObjectiveKeySubzonaContext } from "../../../../../contexts/Subzone/ObjectiveSubzone/ObjectiveKeySubzone/context"
import { ObjectiveKeySubzonaContextType } from "../../../../../contexts/Subzone/ObjectiveSubzone/ObjectiveKeySubzone/type"
import { Button, Input, InputTextArea, Modal } from "../../../../../ludz.one_ui"
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components"
import { Column, Row } from "../../../../../styles/globalStyles"
import LudzColors from "../../../../../ludz.one_ui/LudzColors"

interface PropsCreateSection {
    visible?: boolean,
    onOpen?: any,
    id?: number
}

const CreateObjectiveKey = ({ visible, onOpen, id }: PropsCreateSection) => {


    const { initialValue, schema, handleObjectiveKey, setColor } = useContext(ObjectiveKeySubzonaContext) as ObjectiveKeySubzonaContextType




    return (
        <Modal visible={visible} onOpen={onOpen} title={"Criar Tarefa"}>
            <Formik initialValues={initialValue} validationSchema={schema} onSubmit={(values) => { handleObjectiveKey(values, id!, onOpen) }}>
                {({ values, handleChange, errors, touched, handleSubmit }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Column>
                                <Input name="content" value={values.content} onChange={handleChange} label="Nome da tarefa" />
                            </Column>
                            <Padding />
                            {errors.content && touched.content ? (
                                <div style={{ color: "red" }}>{errors.content.toString()}</div>
                            ) : null}
                            <Padding padding="8px" />
                            <Column>
                                <InputTextArea name="description" value={values.description} onChange={handleChange} label="Descrição tarefa" />
                            </Column>
                            <Padding />
                            {errors.description && touched.description ? (
                                <div style={{ color: "red" }}>{errors.description.toString()}</div>
                            ) : null}
                            <Padding padding="8px"/>
                            <Column>
                                <h5>Selecione a cor:</h5>
                                <Padding padding="8px"/>
                                <LudzColors setColor={setColor}/>
                            </Column>
                            <Padding padding="8px" />
                            <Padding padding="16px" />
                            <Row id="center">
                                <Button title="Criar" type="submit" />
                            </Row>
                        </Form>
                    )
                }}
            </Formik>

        </Modal>
    )
}

export default CreateObjectiveKey