import { useMutation } from "react-query";
import { UpdateNotification, UpdateAllNotification, DeleteNotificationRequest } from "./request";
import queryClient from "../../../services/react-query";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../../components/Alert";

export const UpdateNotificationController = ({
    toast,
  }: {
    toast?: RefObject<Toast>;
  }) => {

    interface PropsUpdate {
        id?: string,
        body: {isViewed: boolean}
    }
    const UpdateNotificationMutation = useMutation(({ body, id }: PropsUpdate) => UpdateNotification(id!, body), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()

        },
        onError: (error: any) => {
             Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast })?.show();
            console.log(error)
        },
        onSuccess: (result, variables, context) => {
            queryClient.refetchQueries("UseUserNotifications")
            queryClient.refetchQueries("UseZoneIdBff");  
        },
    });

    const UpdateAllNotificationMutation = useMutation(() => UpdateAllNotification(), {
        onError: (error: any) => {   Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast })?.show(); },
        
        onSuccess: () => {
            Alert({
                detail: "Notificação atualizada!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              })?.show();
            queryClient.refetchQueries("UseZoneIdBff");            
            queryClient.refetchQueries("UseUserNotifications")
    },
        
    } ); 

    const DeleteNotificationMutation = useMutation((id: string) => DeleteNotificationRequest(id), {
        onMutate: () => {
        },
        onError: (error: any) => {
             Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast })?.show();
        },
        onSuccess: (result) => {
            Alert({
                detail: "Notificação Removida!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              })?.show();
            queryClient.refetchQueries("UseZoneIdBff");            
            queryClient.refetchQueries("UseUserNotifications")
        },
    });

    return {
        UpdateNotificationMutation, UpdateAllNotificationMutation, DeleteNotificationMutation
    }
}
