import { useMutation } from "react-query";
import queryClient from "../../../../../services/react-query";
import { DeleteSubzoneChecklistItemsKanbanCardRequest, DeleteSubzoneChecklistKanbanCardRequest, SubzoneChecklistItemsKanbanCardRequest, SubzoneChecklistKanbanCardRequest, UpdateSubZoneChecklistItemsKanbanCardRequest, UpdateSubZoneChecklistsKanbanCardRequest } from "./request";
import { PropsKanbanCardChecklist, PropsKanbanCardChecklistItems } from './type';

export const ChecklistsKanbanCardController = () => {

    const KanbanCardChecklistsSubzoneMutation = useMutation((data: PropsKanbanCardChecklist) => SubzoneChecklistKanbanCardRequest(data), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            console.log(error)
        },
        onSuccess: (result) => {
            queryClient.refetchQueries("UseSubzoneKanbanbff")
            queryClient.refetchQueries("UseSubzoneKanbanCard")

        },
    });

    interface PropsUpdateChecklists {
        body: PropsKanbanCardChecklist
        id: string,
    }

    const UpdateKanbanCardChecklistsSubZoneMutation = useMutation(
        ({ body, id }: PropsUpdateChecklists) => UpdateSubZoneChecklistsKanbanCardRequest(id, body),
        {
          onMutate: () => {
          },
          onError: (error: any) => {
            console.log(error);
          },
          onSuccess: (result, veriables, context) => {
            queryClient.refetchQueries("UseSubzoneKanbanbff")
            queryClient.refetchQueries("UseSubzoneKanbanCard")
          },
        }
      );

    const DeleteKanbanCardChecklistsSubzoneMutation = useMutation((id: string) => DeleteSubzoneChecklistKanbanCardRequest(id), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            console.log(error)
        },
        onSuccess: (result) => {
            queryClient.refetchQueries("UseSubzoneKanbanbff")
            queryClient.refetchQueries("UseSubzoneKanbanCard")

        },
    });

    //Controller dos items do checklist
    
    const KanbanCardChecklistItemsSubzoneMutation = useMutation((data: PropsKanbanCardChecklistItems) => SubzoneChecklistItemsKanbanCardRequest(data), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            console.log(error)
        },
        onSuccess: (result) => {
            queryClient.refetchQueries("UseSubzoneKanbanbff")
            queryClient.refetchQueries("UseSubzoneKanbanCard")

        },
    });

    interface PropsUpdateChecklistItem {
        body: PropsKanbanCardChecklistItems
        id: string,
    }

    const UpdateKanbanCardChecklistItemsSubZoneMutation = useMutation(
        ({ body, id }: PropsUpdateChecklistItem) => UpdateSubZoneChecklistItemsKanbanCardRequest(id, body),
        {
          onMutate: () => {
          },
          onError: (error: any) => {
            console.log(error);
          },
          onSuccess: (result, veriables, context) => {
            queryClient.refetchQueries("UseSubzoneKanbanbff")
            queryClient.refetchQueries("UseSubzoneKanbanCard")
          },
        }
      );

      const DeleteKanbanCardChecklistItemsSubzoneMutation = useMutation((id: string) => DeleteSubzoneChecklistItemsKanbanCardRequest(id), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            console.log(error)
        },
        onSuccess: (result) => {
            queryClient.refetchQueries("UseSubzoneKanbanbff")
            queryClient.refetchQueries("UseSubzoneKanbanCard")

        },
    });

    return {
        KanbanCardChecklistsSubzoneMutation,
        DeleteKanbanCardChecklistsSubzoneMutation,
        KanbanCardChecklistItemsSubzoneMutation,
        UpdateKanbanCardChecklistItemsSubZoneMutation,
        DeleteKanbanCardChecklistItemsSubzoneMutation,
        UpdateKanbanCardChecklistsSubZoneMutation
    }
}