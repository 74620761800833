import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ZonaContext } from "../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../contexts/Zone/Zone/type";
import { Tooltip } from "../../../ludz.one_ui";
import LudzLoading from "../../../ludz.one_ui/Loading";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import IconsMenu from "../IconsMenu";
import LogoLudus from "../Logo";
import SubzonasMenu from "../Subzonas";
import ZonaMenu from "../Zonas";
import { MenuLeftStyle, Separator } from "../style";

const MenuZone = () => {
  const { zone, refetch } = useContext(ZonaContext) as ZonaContextType;
  const history = useNavigate();

  // const subzonesApproved = zone ? zone!.subzones.filter(props => props.status === 1) : [];

  return (
    <MenuLeftStyle>
      {/* <Tooltip title="Inicio" arrow="right"> */}
      {/* <IconButton> */}
      <Tooltip title="Tela Inicial" placement="right">
        <Link to={"/home"}>
          <LogoLudus logo />
        </Link>
      </Tooltip>
      {zone ? (
        <Tooltip title="Tela Inicial" arrow placement="right">
          <ZonaMenu zona={zone} />
        </Tooltip>
      ) : (
        <LudzLoading style={{ height: "30px", width: "30px", marginTop: "20px" }} />
      )}
      <Separator />
      {zone ? (
        <>
          {zone!?.subzones?.map((item, index) => {
            return (
              <SubzonasMenu key={index} subzona={item} refetch={refetch} />
            );
          })}
        </>
      ) : (
        <LudzLoading style={{ height: "30px", width: "30px", marginTop: "20px" }} />
      )}
      <IconsMenu
        onClick={() => history(`/zona/${zone?.id}/criar-subzona`)}
        icon="addSubZona"
      />
      <Separator />
      <Tooltip title="Explorar Zonas">
        <IconsMenu icon="explore" onClick={() => history("/home/zonaspublicas")} />
      </Tooltip>
      <IconsMenu icon="channels" onClick={() => history("/home/canaispublicos")} />
      <IconsMenu icon="help" />
      <Padding padding="8px" />
    </MenuLeftStyle>
  );
};

export default MenuZone;
