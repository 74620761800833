import styled from "styled-components";
import styles from "../../../../../../ludz.one_ui/styles";

export const AddButton = styled.div`
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: ${styles.colors.grayClearOne};
  color: black;
  cursor: pointer;
  justify-content: center;
  align-self: center;

  &:hover {
    background: ${styles.colors.grayClearOneHover};
  }

  @media (max-width: 520px) {
    align-self: baseline;
  }

  @media (max-width: 360px) {
    height: 30px;
    width: 30px;
  }
`;

export const ButtonFunctions = styled.div`
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  height: auto;
  padding: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const IconText = styled.div`
  display: flex;
  flex-direction: row;
  color: #808080;

  p {
    margin: auto;
    text-align: center;
    margin-left: 5px;
    font-weight: 600;
  }
`;

export const ButtonUser = styled.div`
padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  cursor: pointer; 

  &:hover {
    background-color: #f1f1f1;
  }
`;


export const ButtonTags = styled.div`
  padding: 8px; 
  display: flex; 
  width: 100%;
  align-items: center;
  flex-direction: row; 
  justify-content: space-between; 
  border-radius: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: pointer;

  h5{
    margin-right: 10px;
    color: ${styles.colors.white};
  }
`;

export const WrapMembers = styled.div`
  display: flex;
  flex-direction: row !important;

  img {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background: white;
    margin-right: 3px;
    object-fit: cover;
  }

  @media (max-width: 360px) {
    img {
      width: 30px;
      height: 30px;
    }
  }
`;



interface PropsLabels {
  color: string
}
export const Label = styled.div<PropsLabels>`
  display: flex;
  flex-direction: column;
  width: 100px;
  max-width: 90px;
  height: 35px;
  background: ${(props) => props.color};
  top: 160px;
  border-radius: 5px;
  margin-right: 5px;
  padding: 5px;
  color: ${styles.colors.white};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 360px) {
    h4 {
      font-size: 14px;
      
    }
  }
`;


interface PropsStatus {
  isStatus?: boolean
}

export const Status = styled.div<PropsStatus>`
color: white;
border-radius: 8px;
font-weight: 700;
padding: 4px 16px;
  background-color: ${props => props.isStatus ? styles.colors.greenSucess : styles.colors.yellow};
`;