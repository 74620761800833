
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import MembersSubZonaProvider from "../../../contexts/Subzone/MemberSubzone/context";
import { SubzonaContext } from "../../../contexts/Subzone/Subzone/context";
import { SubzonaContextType } from "../../../contexts/Subzone/Subzone/type";
import { ZonaContext } from "../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../contexts/Zone/Zone/type";
import { Menu } from "../../../ludz.one_ui";
import ModalInvite from "../../../ludz.one_ui/Modais/ModalInvite";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import Item from "../Item";
import User from "../User";
import { Container } from "../style";




const AccordionSubzone = () => {

  const { subzone, HandleRemoveMember } = useContext(SubzonaContext) as SubzonaContextType
  const { openMenu, zone, adminorowner } = useContext(ZonaContext) as ZonaContextType


  const { container, idSubzone } = useParams();

  const [viewdUser, setViewdUser] = useState(false);
  const [visible, setVisible] = useState(false);

  return (
    <Container>
      <Padding padding="32px 16px" style={{ display: openMenu === false ? "" : "none" }}>
        <Padding padding="8px" />
        <Item animation path={`/subzona/${idSubzone}/ludzia`} active={container === "ludzia" ? "true" : "false"} text="LudzFlow" />
        <Padding />
        <Item icon="pi pi-info-circle" path={`/subzona/${idSubzone}/painel`} active={container === "painel" ? "true" : "false"} text="Painel" />
        <Padding />
        <Item icon="pi pi-comment" path={`/subzona/${idSubzone}/mural`} active={container === "mural" ? "true" : "false"} text="Mural" />
        <Padding />
        <Item icon="pi pi-list-check" path={`/subzona/${idSubzone}/tarefas`} active={container === "tarefas" ? "true" : "false"} text="Metas e Tarefas" />
        <Padding />
        <Item icon="pi pi-sitemap" path={`/subzona/${idSubzone}/kanban`} active={container === "kanban" ? "true" : "false"} text="Quadro de Tarefas" />
        <Padding />
        <Item icon="pi pi-users" onClick={() => setViewdUser(!viewdUser)} add onClickAdd={(e: any) => { e.stopPropagation(); setVisible(!visible) }} active={viewdUser.toString()} text="Membros" />
        <Padding />
        {viewdUser && subzone ? <>
          {subzone.members.map((item, index) => {
            let items = [
              // { label: `${item.isAdmin ? "Remover" : "Tornar"} administrador`, command: () => { HandleUpdateMember(item.user.id, zone.id, !item.isAdmin) }, icon: 'pi pi-fw pi-pencil' },
              { label: 'Remover da subzona', command: () => { HandleRemoveMember(item.user.id.toString(), subzone.id.toString()) }, icon: 'pi pi-fw pi-trash' },
            ]
            return (
              <div key={index}>
                {(subzone.user_owner.id !== item.user.id) || adminorowner ? <Menu items={items} width="15rem" direction="bottom" key={index}
                  button={
                    <div>
                      <User id={item.user.id} avatar={item.user.aws.url} isAdmin={item.isAdmin} nome={item.user.username} />
                    </div>
                  } /> : <User id={item.user.id} avatar={item.user.aws.url} isAdmin={item.isAdmin} nome={item.user.username} />}
              </div>
            )
          })}
        </> : null}
        {zone ? <MembersSubZonaProvider><ModalInvite users={zone?.zoneMembers} onOpen={() => setVisible(!visible)} visible={visible} /></MembersSubZonaProvider> : null}

      </Padding>
    </Container>
  );
};

export default AccordionSubzone;

