
import { OverlayPanel } from 'primereact/overlaypanel';
import { CSSProperties, useRef } from 'react';

interface PropsOverlayPanel {
    components: React.ReactNode,
    button: React.ReactNode
    styleButton?: CSSProperties | undefined, ref?: any
}

const OverlayPanelLudz = ({ components, button, styleButton, ref }: PropsOverlayPanel) => {


    const op = useRef<OverlayPanel>(null);

 
    return (
        <div className="flex flex-column align-items-center gap-3" onClick={(e: any) => { e.stopPropagation() }}>
            <div style={styleButton} onClick={(e: any) => { e.stopPropagation(); op.current?.hide(); op.current?.toggle(e) }}>
                {button}
            </div>
            <OverlayPanel ref={ref ?? op} closeIcon >
                    {components}
            </OverlayPanel>
        </div>
    );
}

export default OverlayPanelLudz