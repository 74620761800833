import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import queryClient from "../../services/react-query";
import { useFetchChannelPublic, useFetchChannelsPublic } from "./query";
import { CreateZoneRequest } from "./request";
import { BuyChannelUser } from "./type";
import Swal from "sweetalert2";

export const ChannelsPublicController = (page: number, channelname: string) => {
    const { data: channels, isLoading: isLoadingChannels, error: errorChannels, refetch } = useFetchChannelsPublic(page, channelname)

    return {
        channels, isLoadingChannels, errorChannels, refetch
    }
}

export const ChannelPublicController = (id: string | undefined) => {
    const { data: channel, isLoading: isLoadingChannel, error: errorChannel, refetch: refetchschannel } = useFetchChannelPublic(id)
    const history = useNavigate()

    const BuyChannelMutation = useMutation((data: BuyChannelUser) => CreateZoneRequest(data), {
        onMutate: () => {
        //   setLoading(true); 
        //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
        //   setError(error.response.data.message);
        //   setLoading(false);

        Swal.fire({
            icon: "warning",
            title: "Aconteceu algum problema, tente novamente",
            showConfirmButton: false,
            timer: 2000
          });
        },
        onSuccess: (result, variables, context) => {
            history("/home")
            queryClient.refetchQueries("UseUserChannel")
            Swal.fire({
                icon: "success",
                title: "Canal adquirido com sucesso",
                showConfirmButton: false,
                timer: 2000
              });
        },
      });
    return {
        channel, isLoadingChannel, errorChannel, refetchschannel, BuyChannelMutation
    }
}