import { createContext } from "react";
import { KanbanCardSubzonaContextType } from "./type";
import { InsiderCardState } from "./state";


export const KanbanCardSubzonaContext = createContext<KanbanCardSubzonaContextType | null>(null);

interface Props {
    children: React.ReactNode
}


const KanbanCardSubonaProvider = ({ children }: Props) => {


    const { handleArchives,card, deleteLabels, deleteComments, handleComments, setvisibleMetas, visibleMetas, handleUpdateCard, handleAddMember, deleteMemberCard, visibleMember, setvisibleMember, setvisibleTags, visibleTags, handleLabels, deleteMetas, handleMetas, setvisibleArchives, visibleArchives, setFiles, deleteArchives, handleChecklist, visibleChecklist, setvisibleChecklist, handleUpdateChecklists, deleteChecklists, handleChecklistItem, handleUpdateChecklistItem, deleteChecklistItems,  handleUpdateDate, visibleDate, setvisibleDate, toast, toastKanbanCard} = InsiderCardState()




    return (
        <KanbanCardSubzonaContext.Provider value={{
            handleArchives,
            card,
            deleteLabels,
            handleUpdateCard,
            handleAddMember,
            deleteMemberCard,
            visibleMember,
            setvisibleMember,
            setvisibleTags,
            visibleTags,
            handleLabels,
            setvisibleMetas,
            visibleMetas,
            deleteMetas,
            handleMetas,
            deleteComments, 
            handleComments, 
            setvisibleArchives, 
            visibleArchives, 
            deleteArchives,
            setFiles, 
            handleChecklist,
            visibleChecklist, 
            setvisibleChecklist,
            handleUpdateChecklists,
            deleteChecklists,
            handleChecklistItem,
            handleUpdateChecklistItem,
            deleteChecklistItems,
            handleUpdateDate,
            visibleDate, 
            setvisibleDate,
            toast,
            toastKanbanCard
        }}>
            {children}
        </KanbanCardSubzonaContext.Provider>
    )
}

export default KanbanCardSubonaProvider;