import { useContext } from "react";
import { useParams } from "react-router-dom";
import WriteHere from "../../../components/Mural/WriteHere";
import PostMural from "../../../components/Mural/post";
import { PostSubzonaContext } from "../../../contexts/Subzone/MuralSubzone/context";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { Container } from "../../style";
import { PostSubzonaContextType } from "../../../contexts/Subzone/MuralSubzone/type";
import { ZonaContext } from "../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../contexts/Zone/Zone/type";
import { SubzonaContext } from "../../../contexts/Subzone/Subzone/context";
import { SubzonaContextType } from "../../../contexts/Subzone/Subzone/type";
import LudzScrollTop from "../../../ludz.one_ui/LudzScrollTop";
import { Toast } from "primereact/toast";

const MuralSubzone = () => {
  const {
    handlePost,
    initialValue,
    posts,
    setFile,
    handleEditPost,
    initialValueEdit,
    handleDeletePost,
    handleSendComments,
    initialValueComment,
    handleEditPostComment,
    handleDeletePostComment,
    toast
  } = useContext(PostSubzonaContext) as PostSubzonaContextType;

  const { idSubzone } = useParams();

  const { adminorowner } = useContext(ZonaContext) as ZonaContextType;

  const { subzone } = useContext(SubzonaContext) as SubzonaContextType;

  const postReverse = posts ? [...posts].reverse() : [];

  return (
    <Container>
      <Toast ref={toast}/>
      <WriteHere
        handle={handlePost}
        members={subzone?.members}
        initialValues={initialValue}
        setFile={setFile}
        id={parseInt(idSubzone!)}
      />
      <Padding padding="16px" />
      {posts && (
        <>
          {postReverse?.map((item: any, index: number) => {
            return (
              <PostMural
                members={subzone?.members}
                key={index}
                data={item}
                admin={adminorowner}
                handleEditPost={handleEditPost}
                handleDeletePost={handleDeletePost}
                initialValueEdit={initialValueEdit}
                handleSendComments={handleSendComments}
                initialValueComment={initialValueComment}
                handleEditPostComment={handleEditPostComment}
                handleDeletePostComment={handleDeletePostComment}
              />
            );
          })}
        </>
      )}
      <LudzScrollTop style={{ right: "0.5%", padding: "15px" }} />
    </Container>
  );
};

export default MuralSubzone;
