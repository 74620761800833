import styled from 'styled-components';

export const Container = styled.div`
    justify-content: center;
    align-items: center;

    #span-element{
    font-size: 12px;
    width: auto;
    position: absolute;
    background-color: rgba(127, 100, 170, 0.8);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: block;
    justify-content: center;
    text-align: center;
    padding: 3px 2px;
    color: white;
    border-radius: 5px;

    @media (max-width: 1024px){
      margin-left: 14.5vw;
      z-index: 1;
    }
  }
`; 


export const Button = styled.div`

  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #7F64AA;
  transition: all ease 1s;
  :hover {
    transition: all ease 1s;
      border-radius: 35%;
      background-color: white;
      cursor: pointer;
      color: var(--tertiary);
      }
`;

export const InitialName = styled.div`
    font-size: 30px;
    font-weight: bold;
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff; 
    transition: all ease 1s;
    :hover{
      transition: all ease 1s;
        color: var(--tertiary);
       font-size: 35px;
    }

    #span-element{
    font-weight: normal;   
    font-size: 12px;
    width: 7.320644216691069vw;
    position: absolute;
    margin-left: 12.5vw;
    margin-bottom: 0.5vh;
    background-color: rgba(127, 100, 170, 0.8);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 3px 2px;
    color: white;
    border-radius: 5px;

    @media (max-width: 1024px){
      margin-left: 14.5vw;
      z-index: 1;
    }
  }
`;

export const ImageRoom = styled.div`

  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: '-8';
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #7F64AA;
  transition: all ease 1s;
  :hover {
    transition: all ease 1s;
      border-radius: 35%;
      background-color: white;
      cursor: pointer;
      color: var(--tertiary);
      }

    img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        transition: all ease 1s;
        :hover {
          transition: all ease 1s;
            border-radius: 35%;
            cursor: pointer;
        }
    }

    #span-element{
    font-size: 12px;
    width: 7.320644216691069vw;
    position: absolute;
    margin-left: 12.5vw;
    background-color: rgba(127, 100, 170, 0.8);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 3px 2px;
    color: white;
    border-radius: 5px;

    @media (max-width: 1024px){
      margin-left: 14.5vw;
      z-index: 1;
    }


  }

      @media (max-width: 360px) {
      width: 46px;
      height: 46px;
      img {
        width: 46px;
        height: 46px;
      }
    }
`;
