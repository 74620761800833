import { createContext } from "react";
import { ObjectiveSubzoneState } from "./state";
import { ObjectiveSubzonaContextType } from "./type";
import { TaskSubzoneState } from "./Tasks/state";

export const ObjectiveSubzonaContext = createContext<ObjectiveSubzonaContextType | null>(null);

interface Props {
    children: React.ReactNode
}


const ObjectiveSubonaProvider = ({ children }: Props) => {

    const { handleObjective, initialValue, schema, objective, setObjective, handleDeleteObjective, accordionObjetivekey, setAccordionObjetivekey, handleUpdateObjective, setVisibleEditObjetive, visibleEditObjetive, toast } = ObjectiveSubzoneState()

    const { handleTask, initialValueTask, schemaTask, handleIsFinishTaskTask, handleDeleteTask, toastTask } = TaskSubzoneState()

    return (
        <ObjectiveSubzonaContext.Provider value={{ setVisibleEditObjetive, visibleEditObjetive,handleUpdateObjective, handleObjective, initialValue, schema, objective, setObjective, handleTask, initialValueTask, schemaTask, handleIsFinishTaskTask, handleDeleteObjective, accordionObjetivekey, setAccordionObjetivekey, handleDeleteTask, toast, toastTask }}>
            {children}
        </ObjectiveSubzonaContext.Provider>
    )
}

export default ObjectiveSubonaProvider;