
import MenuLeft from "../../../components/MenuLeft/MenuHome";
import TopBar from "../../../components/Topbar";
import { GridProfile } from "./style";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { useMediaQuery } from "@mui/material";
import LudzScrollTop from "../../../ludz.one_ui/LudzScrollTop";



type PropsLayoutTwoColumn = {
  children?: React.ReactNode;
};

const LayoutProfile = ({ children }: PropsLayoutTwoColumn) => {

  const matches = useMediaQuery("(max-width: 520px)");

  return (
    <GridProfile>
      <MenuLeft />
      <TopBar />
      <Padding style={{  overflowY: 'auto' }} padding={matches ? "10px" : "40px"}>
      <div style={{ display: 'grid', gridArea: 'children' }}>
        {children}
      </div>
      <LudzScrollTop />
      </Padding>
    </GridProfile>
  );
};

export default LayoutProfile;
