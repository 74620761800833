import { useContext } from "react";
import AccordionZone from "../../../components/AccordionZone/Zone";
import MenuZone from "../../../components/MenuLeft/MenuZone";
import TopBar from "../../../components/Topbar";
import { ZonaContext } from "../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../contexts/Zone/Zone/type";
import { GridZoneColumn } from "./style";

type PropsLayoutZoneColumn = {
    children?: React.ReactNode;
}

const LayoutZoneColumn = ({ children }: PropsLayoutZoneColumn) => {

    const { openMenu, setOpen } = useContext(ZonaContext) as ZonaContextType
    return (
        <GridZoneColumn openMenu={!openMenu} >
            <MenuZone />
            <TopBar openModal={openMenu} setOpen={setOpen} /> 
            <AccordionZone />
            {children}
        </GridZoneColumn>
    )
}

export default LayoutZoneColumn;