import { useEffect, useState } from "react";
import {
  ChannelBusiness,
  ChannelUser,
} from "../../../../../../../../contexts/PrivateRoutes/types";
import { Accordion, Progress } from "../../../../../../../../ludz.one_ui";
import { useFetchChannelComplet } from "../../../../../../../../ludzoneSDK/Channel/query";
import HeaderAccordion from "../../../../../../BuyZone/HeaderAccordion";
import PlaylistsInsider from "./InsiderPlaylist";

const PlayListState = (id: number) => {
  const [playList, setPlaylist] = useState<any>();
  const { data: playListChannel } = useFetchChannelComplet(id);

  useEffect(() => {
    if (playListChannel) {
      setPlaylist(playListChannel.playlists);
    }
  }, [playListChannel]);

  return {
    playList,
  };
};

const PlayLists = ({ item }: { item: ChannelUser | ChannelBusiness | any }) => {
  const props = PlayListState(item.channel?.id ?? item.id);
  return (
    <>
      {props?.playList ? (
        <>
          {props?.playList && (
            <Accordion
              body={PlaylistsInsider}
              header={HeaderAccordion}
              data={props?.playList}
            />
          )}
          {props?.playList.length === 0 && (
            <h4>Canal sem videos</h4>
          )}
        </>
      ) : (
        <Progress />
      )}
    </>
  );
};

export default PlayLists;
