import React, { createContext } from "react";
import { loginState } from "./state";
import { LoginContextType } from "./types";

export const LoginContext = createContext<LoginContextType | null>(null);

interface Props {
    children: React.ReactNode
}

const LoginProvider = ({ children }: Props) => {

    const { initialValues, error, loading, handleLogin, LoginSchema, ForgetPassword } = loginState();

    return (
        <LoginContext.Provider value={{ initialValues, error, loading, handleLogin, LoginSchema, ForgetPassword }}>
            {children}
        </LoginContext.Provider>
    )
}

export default LoginProvider;