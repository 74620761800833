const TOKEN_KEY = "ludz-token";
const id_key = "ludz-id";
const id_zone = "ludz-zone";
const ludz_flow = "ludz-flow";
const ludz_flow_top = "ludz-flow-top";

export const isAuthenticated = () => {
  return localStorage.getItem(TOKEN_KEY) !== null;
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const login = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const setLudzflow = (message: any) => {
  const jsonValue = JSON.stringify(message);
  localStorage.setItem(ludz_flow, jsonValue);
};

export const getLudzFlow = () => {
  const jsonValue = localStorage.getItem(ludz_flow);
  return jsonValue ? JSON.parse(jsonValue) : null;
}

export const removeLudzFlow = () => {
  localStorage.removeItem(ludz_flow);
};

export const setLudzflowTop = (message: any) => {
  const jsonValue = JSON.stringify(message);
  localStorage.setItem(ludz_flow_top, jsonValue);
};

export const getLudzFlowTop = () => {
  const jsonValue = localStorage.getItem(ludz_flow_top);
  return jsonValue ? JSON.parse(jsonValue) : null;
}

export const removeLudzFlowTop = () => {
  localStorage.removeItem(ludz_flow_top);
};


export const logout = () => {
  localStorage.removeItem(ludz_flow);
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(id_key);
  localStorage.clear();
};

export const idUser = (id: string) => {
  localStorage.setItem(id_key, id);
}


export const getId = () => {
  return localStorage.getItem(id_key);
}

export const idZone = (id: string) => {
  localStorage.setItem(id_zone, id);
}

export const getIdZone = () => {
  return localStorage.getItem(id_zone);
}

