import http from "../../../services/api"
import { logout } from "../../../services/localStorage";
import { uploadArchive } from "../../../services/upload-archives";
import { PropsSubZonePostComment, PropsSubzonePost, SubZonaTypePost, SubZonaTypePostComment } from "./type"

export const SubzonePostRequest = async (body: PropsSubzonePost,
    file?: File) => {
        console.log(file);

        
        if(file){
            return await uploadArchive(file, "/bff/sub-zone-post-bff", body, "POST");
        }
    return await http.post("/bff/sub-zone-post-bff", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const UpdateSubZonePost = async (id: string | null, body: SubZonaTypePost) => {
    return await http.put(`/direct/sub-zone-post/${id}`, body).then(response => response.data)
    .catch(err => {
         if (err.response.status === 401) {
              logout()
              window.location.reload()
         }
         alert(err.response.data.message)
         throw err;
    })
}

export const DeleteSubZonePost = async (id: string | null) => {
    return await http.delete(`/bff/sub-zone-post-bff`, {params: {subZonePostId : id}}).then(response => response.data)
    .catch(err => {
         if (err.response.status === 401) {
              logout()
              window.location.reload()
         }
         alert(err.response.data.message)
         throw err;
    })
}

export const GetSubZonePostBffRequest = async (id: string | undefined) => {
     
    return await http.get(`/bff/sub-zone-post-bff`, {
         params: {
              subZoneId: id,
         }
     }).then(response => response.data)
         .catch(err => {
              if (err.response.status === 401) {
                   logout()
                   window.location.reload()
              }
              throw err;
         });
}

export const GetSubzonePostRequest = async (id: number) => {
    return await http.get("/direct/sub-zone-post", {
        params: {
            include: JSON.stringify({
                owner: true
            }),
            subZone_fk: id,
        }
    }).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            throw err;
        });
} 

// Requisições de Comentários

export const SubZonePostCommentRequest = async (body: PropsSubZonePostComment) => {
    return await http.post("/bff/sub-zone-post-comments-bff", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const UpdateSubZonePostComment = async (id: string | null, body: SubZonaTypePostComment) => {
    return await http.put(`/direct/sub-zone-post-comments/${id}`, body).then(response => response.data)
    .catch(err => {
         if (err.response.status === 401) {
              logout()
              window.location.reload()
         }
         alert(err.response.data.message)
         throw err;
    })
}

export const DeleteSubZonePostComment = async (id: string | null) => {
    return await http.delete(`/direct/sub-zone-post-comments/${id}`,).then(response => response.data)
    .catch(err => {
         if (err.response.status === 401) {
              logout()
              window.location.reload()
         }
         alert(err.response.data.message)
         throw err;
    })
}