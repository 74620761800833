import { ChangeEvent } from "react";
import { Button, Input } from "../../../ludz.one_ui";
import { InputContainer } from "../IAstyles";

interface ChatInputProps {
  inputValue: string;
  setInputValue: (value: string) => void;
  handleSend: (text: string) => void;
  loading: boolean | undefined
}

const ChatInput = ({ inputValue, setInputValue, handleSend, loading }: ChatInputProps) => {
  return (
    <InputContainer >
      <Input
        type="text"
        value={inputValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
        onKeyDown={(e: any) => e.key === "Enter" && handleSend(inputValue)}
        placeholder="Escreva sua mensagem aqui..."
        style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}
      />
      <Button
        onClick={() => handleSend(inputValue)}
        style={{
          width: "100%",
          height: "84%",
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
          borderTopRightRadius: '10px', borderBottomRightRadius: '10px',
          padding: "15px",
        }}
        icon={loading ? "pi pi-stop-circle" : "pi pi-send"}
      />
    </InputContainer>
  );
};

export default ChatInput;
