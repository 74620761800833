import { useContext } from "react";
import AccordionSubzone from "../../../components/AccordionZone/Subzona";
import MenuZone from "../../../components/MenuLeft/MenuZone";
import TopBar from "../../../components/Topbar";
import { ZonaContext } from "../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../contexts/Zone/Zone/type";
import { GridZoneColumn } from "../LayoutZone/style";
import SubzonaProvider from "../../../contexts/Subzone/Subzone/context";

type PropsLayoutZoneColumn = {
    children?: React.ReactNode;
}

const LayoutSubzoneColumn = ({ children }: PropsLayoutZoneColumn) => {

    const { openMenu, setOpen } = useContext(ZonaContext) as ZonaContextType
    return (
        <SubzonaProvider>
            <GridZoneColumn openMenu={!openMenu} >
                <MenuZone />
                <AccordionSubzone />
                <TopBar openModal={openMenu} setOpen={setOpen} />
                {children}
            </GridZoneColumn>
        </SubzonaProvider>
    )
}

export default LayoutSubzoneColumn;