import { useContext, useState } from "react"
import { Avatar } from "../../../../../../../../ludz.one_ui"
import ConfirmationDialog from "../../../../../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes"
import { Padding } from "../../../../../../../../ludz.one_ui/styles/styled-components"
import { Column, Row } from "../../../../../../../../styles/globalStyles"
import { AddDescription } from "../style"
import { KanbanCardSubzonaContext } from "../../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context"
import { KanbanCardSubzonaContextType } from "../../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type"
import ViewHTMLComents from "../../../../../../../../components/ViewHTMLComents"


interface PropsComment {
    item: any
}
const Comment = ({ item }: PropsComment) => {
    const months = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'];

    const date = new Date(item!.createdAt);

    var day: any = date.getDate()

    if (day < 10) {
        day = '0' + day;
    }

    var commentDate = day + " de " + months[date.getMonth()].substring(0, 3);
    const [visible, setVisible]= useState(false)
    const {deleteComments} = useContext(KanbanCardSubzonaContext) as KanbanCardSubzonaContextType
    return (
        <Padding padding="8px 0">
            <Column>
                <Row style={{ width: "100%" }}>
                    <Avatar img={item.owner.aws.url} shape="circle" />
                    <Column style={{ width: "90%" }}>
                        <Row>
                        <h4>{item.owner.name}</h4>
                        -
                        <Column id="center">
                        <p style={{fontSize: "14px"}}>{commentDate}</p>
                        </Column>
                        </Row>
                        <Padding />
                        <AddDescription style={{ width: "100%", wordBreak: 'break-all' }}>
                        <ViewHTMLComents item={item.content} />
                        </AddDescription>
                        <p onClick={() => setVisible(true)} style={{fontSize: "12px", marginTop: "4px", cursor: "pointer"}}>Excluir</p>
                    </Column>
                </Row>
            </Column>
            <ConfirmationDialog accept={() => deleteComments(item!.id)} visible={visible} reject={() => setVisible(true)} message="Deseja Excluir?" onHide={() => setVisible(false)} />

        </Padding>
    )
}

export default Comment