import { useContext } from "react";
import { KanbanCardSubzonaContext } from "../../../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context";
import { KanbanCardSubzonaContextType } from "../../../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type";
import { ButtonUser } from "../../../style";
import { Icon } from "../../../../../../../../../ludz.one_ui";

interface PropsUser {
    item: any,
}

const User = ({ item }: PropsUser) => {

    const { card, handleAddMember, deleteMemberCard } = useContext(KanbanCardSubzonaContext) as KanbanCardSubzonaContextType
    const isAdd = card.members.find((i: any) => i.member.id === item.user.id)

    return (<>
        {isAdd ? <ButtonUser onClick={() => deleteMemberCard(isAdd.id)}>
            {item?.user.name.length > 29 ? <h5>
                {item?.user.name.substring(0, 30)}...
            </h5> : <h5>
                {item?.user.name}
            </h5>}
            <Icon icon="pi pi-check" size="1rem" />
        </ButtonUser> : <ButtonUser onClick={() => handleAddMember(item.user.id, card.id)}>
            {item?.user.name.length > 29 ? <h5>
                {item?.user.name.substring(0, 30)}...
            </h5> : <h5>
                {item?.user.name}
            </h5>}
        </ButtonUser>}
    </>
    )
}

export default User