import styled from "styled-components";
import styles from "../../../../ludz.one_ui/styles";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 2% 6%;
  grid-area: "children";
  display: flex;
  flex-direction: column;
  align-items: center;
  ::-webkit-scrollbar {
    display: none;
  }




  img{
    height: 200px;
    width: 100%;
    object-fit: contain;
  }

  @media (max-width: 520px) {
    padding: 5px;
    h1{
      font-size: x-large;
    }
    h2{
      text-align: center;
    }
  }

`;

export const Line = styled.div`
height: 1px;
background-color: ${styles.colors.grayClearOne};
`;


export const AreaButtons = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 5px 0px 5px 0px;

`;