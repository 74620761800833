import * as Yup from 'yup';
import { TaskSubzoneController } from '../../../../ludzoneSDK/Subzone/Task/controller';
import { PropsSubzoneTasks } from '../../../../ludzoneSDK/Subzone/Task/type';
import { useRef } from 'react';
import { Toast } from 'primereact/toast';


export const TaskSubzoneState = () => {

    const toastTask = useRef<Toast>(null)

    const { TaskSubzoneMutation, TaskIsFinishSubzoneMutation, TaskDeleteSubzoneMutation } = TaskSubzoneController({toast: toastTask})

    const initialValueTask = {
        title: ""
    }

    const schemaTask = Yup.object().shape({
        title: Yup.string().required('Nome é obrigatória'),
    });

    const handleTask = (data: PropsSubzoneTasks, onOpen: any) => {
     
        TaskSubzoneMutation.mutate({ subZoneId: data.subZoneId, title: data.title, objectiveId: data.objectiveId, objectiveKeyId: data.objectiveKeyId })
        onOpen(false)
    }

    const handleIsFinishTaskTask = (data: {is_finished: boolean}, id: number, set: any, objevtive: any, idObjective: number) => {
        
        // const updatedObjetivekey = objevtive.map((item: any) => {
        //     if (item.id === idObjective) {
        //       return item;
        //     }
        //     return{ }
        //   });

        //   console.log(updatedObjetivekey)

        // const cardIndex = updatedObjetivekey[0].kanbanCardObjectives.findIndex(
        //     (cardObjective: any) => cardObjective.kanbanCard.id === id
        // );


        // if (cardIndex !== -1) {
        //     updatedObjetivekey[0].kanbanCardObjectives[cardIndex].kanbanCard.is_finished = data.is_finished;
        //     set(updatedObjetivekey); 
        // }

        TaskIsFinishSubzoneMutation.mutate({ body: {is_finished: data.is_finished}, id: id })
    }



    const handleDeleteTask = (id: number) => {
        
        TaskDeleteSubzoneMutation.mutate(id)
    }
    return {
        handleTask, initialValueTask, schemaTask, handleIsFinishTaskTask, handleDeleteTask, toastTask
    }
}