import { useContext } from "react";
import { Container } from "../../style";
import MyChannel from "./MyChannel";
import MyRoom from "./MyRoom";
import { PrivateRoutesContext } from "../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../../contexts/PrivateRoutes/types";
import LudzScrollTop from "../../../ludz.one_ui/LudzScrollTop";

const HomeScreen = () => {
  const props = useContext(PrivateRoutesContext) as PrivateRoutesContextType;
  const { zonesProvider } = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;

  // Filtra as zonas que pertencem ao usuário logado
  const userZones = zonesProvider?.filter(
    (item) => item.zone.user_owner_fk === props?.userProvider?.id
  );

  // Filtra as zonas que não pertencem ao usuário logado
  const otherZones = zonesProvider?.filter(
    (item) => item.zone.user_owner_fk !== props?.userProvider?.id
  );

  return (
    <Container>
      <MyRoom zones={userZones} name="Minhas Zonas" />
      <MyRoom zones={otherZones} name="Zonas" />
      
      {props.userProvider?.role === "BUSINESS" && <MyChannel channels={props.userBusinessChannel} name="Meus Canais"/>}
      <MyChannel name="Canais" channels={props.userChannel} />


      {/* <MyChannel /> */}
      <LudzScrollTop style={{ padding: '15px', right: '0.1px' }}/>
    </Container>
  );
};

export default HomeScreen;
