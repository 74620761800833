import http from "../../../services/api"

export const ForgetPasswordSendEmailRequest = async (body: {email: string}) => {
    return await http.post("/bff/users-bff/recover-password", body)

}

export const ForgetNewPasswordRequest = async (userId: string, token: string, body: {newPassword: string}) => {
    return await http.put(`/bff/users-bff/reset-password/${userId}/${token}`, body)

}


