import styled from "styled-components";

export const Container = styled.div`


  @media (max-width: 520px) {
    h1{
      font-size: x-large;
    }
  }

  @media (max-width: 360px) {
    h1{
      font-size: large;
    }
  }
`;

export const AreaInfo = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0px 6px;

  span {
    color: #b4b2b2;
    font-size: small;
  }

  @media (max-width: 520px) {
    span{
      font-size: x-small;
    }
  }
`;

interface PropsIconEdit {
  color?: string
}

export const IconEdit = styled.div<PropsIconEdit>`
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    cursor: pointer;
  }

  &:hover{
    color: ${props => props.color}
  }
`;


export const AreaButtons = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 5px 0px 5px 0px;

`;

export const EditAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :hover{
    cursor: pointer;
  }
`;

export const AreaOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;


  @media (max-width: 520px) {
    flex-direction: column;
  }
`;