import http from "../../services/api";
import { logout } from "../../services/localStorage";
import { PropsFormBusiness } from "./type";

export const FormBusinessRequest = async (body: PropsFormBusiness) => {
    return await http.post("/bff/business-bff/request-business-account", body).then(response => response.data)
         .catch(err => {
              if (err.response.status === 401) {
                   logout()
                   window.location.reload()
              }
              alert(err.response.data.message)
              console.log(err)
              throw err;
         });
}