import { useContext } from "react";
import { PrivateRoutesContext } from "../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../../contexts/PrivateRoutes/types";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { Row } from "../../../styles/globalStyles";
import CardsInvites from "../../Cards/CardInvites";

const Invites = () => {

    const { invites } = useContext(
        PrivateRoutesContext
    ) as PrivateRoutesContextType;
    return (
        <div style={{ maxWidth: "300px", minWidth: "200px", maxHeight: "300px", overflowY: "auto", padding: "16px" }}>
            <h3>Convites de Zonas</h3>
            {invites?.map((item, index) => {
                return (
                    <CardsInvites key={index} invites={item} />
                )
            })}

            {invites?.length === 0 ? <>
                <Padding />
                <Row id="center"><h5 style={{ margin: "auto" }}>Sem convites</h5></Row>
                <Padding />
            </>
                : null}
        </div>
    )
}

export default Invites