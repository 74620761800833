import styled from "styled-components";

export const ContainerModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 12px;
    background-color: #f0f0f0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    #contentUserMark{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: flex-start;
    }

    #contentUserFixed{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: flex-start;
      width: 100%;
    }

    h3 {
      align-self: flex-start;
      margin-top: 10px;
    }

    @media (min-width: 1440px) and (max-width: 1920px) {
      input{
        width: 15vw;
      }
    }

    @media (min-width: 1366px) and (max-width: 1440px){
      input {
        width: 14vw;
      }
    }

    @media (max-width: 950px) {
      p{
        font-size: 14px;
      }
    }

    @media (max-width: 520px) {
      input{
        font-size: 14px;
      }
    }
   
    @media (max-width: 375px) {
      #contentUserMark{
        margin-right: 8px;
      }

      #contentUserFixed{
        h4{
          font-size: small;
        }
        p{
          font-size: x-small;
        }
      }
    }
`;

export const HeaderModal = styled.header`
    display: flex;
    h2{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 8px;
        line-height: 19px;
        /* identical to box height */
        color: #58595B;
    }

    p{
      position: absolute;
      font-size: 16px;
      align-self: flex-start;
      left: 15vw;
      top: 1vh;
      margin-bottom: 10px;
      font-family: var(--font-familyS);
      color: #58595B;
      cursor: pointer;
      &:hover{
        color: #E14444;
      }
    }

    @media (max-width: 1024px){

      p{
        left: 17vw;
      }
    }

    @media (max-width: 820px){

      p{
        left: 24vw;
      }
    }

    @media (max-width: 425px){
      h2{
        font-size: 14px;
      }
      p{
        left: 38vw;
      }
    }
`;

export const DataUsers = styled.div`
    display: flex;
    padding: 1%;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    cursor: pointer;
    p{
      font-size: 14px;
      
    }

    &:hover{
      background-color: #C0C0C0;
      border-radius: 5px;
    }

    span {
      margin-right: 10px;
    }

    @media (max-width: 520px) {
      flex-direction: column;
      align-items: flex-start;
      

      p{
        font-size: 16px;
      }
      span{
        font-size: 14px;
      }
    }

    @media (max-width: 360px) {
      p{
        font-size: 14px;
      }
      span{
        font-size: 12px;
      }
    }
`;