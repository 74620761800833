import { createContext } from "react";
import { ContentCompletType, PropsContentProvider } from "./type";
import { ChannelCompletState } from "./state";

export const ChannelCompletContext = createContext<ContentCompletType | null>(
  null
);

const ChannelCompletContextProvider = ({ children }: PropsContentProvider) => {
  const { contentChannel, playlistChannel, allplaylistChannel } = ChannelCompletState();
  return (
    <ChannelCompletContext.Provider value={{ contentChannel, playlistChannel, allplaylistChannel }}>
      {children}
    </ChannelCompletContext.Provider>
  );
};

export default ChannelCompletContextProvider;
