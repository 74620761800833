import styled from "styled-components";

interface PropsGridZoneColumn {
  openMenu?: boolean
}

export const GridZoneColumn = styled.div<PropsGridZoneColumn>`
    display: grid;
    position: fixed;


    grid-template-columns: ${props => props.openMenu ? `65px 225px auto` : `65px 0px auto`};
    grid-template-rows: 55px auto;
   grid-template-areas:
    "Menu topbar topbar"
    "Menu accordion children";
    width: 100vw;
    height: 100vh;
`;