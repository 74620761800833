import { Form, Formik } from "formik";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { SessionZonaContext } from "../../../../../../contexts/Zone/SessionZone/context";
import { SessionZonaContextType } from "../../../../../../contexts/Zone/SessionZone/type";
import { Button, EditorText, Modal } from "../../../../../../ludz.one_ui";
import { Padding } from "../../../../../../ludz.one_ui/styles/styled-components";
import { PropsSessionContent } from "../../../../../../ludzoneSDK/Zone/Session/type";
import { Column, Row } from "../../../../../../styles/globalStyles";


interface PropsCreateSectionContent {
    visible?: boolean,
    onOpen?: any,
    data?: PropsSessionContent,
    setData: Dispatch<SetStateAction<PropsSessionContent | undefined>>,
    set?: any
}

const EditTextSession = ({ visible, onOpen, data, set, setData }: PropsCreateSectionContent) => {


    const { handleEditSessionContent, schema } = useContext(SessionZonaContext) as SessionZonaContextType

    const [currentText, setCurrentText] = useState(data?.textContent ?? "");


    const editorText = (e: any, setFieldValue: any) => {
        setFieldValue("textContent", e.editor.getHTML())
        setCurrentText(e.editor.getHTML());
    }


    return (
        <Modal visible={visible} onOpen={onOpen} title={"Editar"}>
            <Formik
                initialValues={{ textContent: currentText }}
                validationSchema={schema}
                onSubmit={(values) => {
                    handleEditSessionContent(values, data?.id!);
                    set(false);
                        if (data) {
                            setData((prevState: any) => ({
                              ...prevState,
                              textContent: currentText, // Use values.textContent to update the textContent property
                            }));
                          }
                    

                }}>
                {({ values, errors, touched, setFieldValue }) => {
                    return (
                        <Form>
                            <Column>
                            <EditorText  name="textContent" value={currentText} onTextChange={(e: any) => editorText(e, setFieldValue)}  />
                            </Column>
                            {errors.textContent && touched.textContent ? (
                                <div style={{ color: "red" }}>{errors.textContent.toString()}</div>
                            ) : null}
                            <Padding padding="16px" />
                            <Row id="center">
                                <Button title="Editar" type="submit" />
                            </Row>
                        </Form>
                    )
                }}
            </Formik>
        </Modal>
    )
}

export default EditTextSession