import background from "../../../../assets/images/Logo2.png";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../styles/globalStyles";

import { Grid, useMediaQuery } from "@mui/material";
import CardsInvites from "../../../../components/Cards/CardInvites";
import { Line } from "../ProfileInfo/style";
import * as C from "./style";

interface Props {
  userprofile: any;
}

export default function ProfileInvites({ userprofile }: Props) {
  const matches = useMediaQuery("(max-width:1320px)");

  return (
    <C.Container>
      <Column id="center" style={{width: "100%"}}>
        <Row id="center">
          <img src={background} style={{ width: "128px", height: "100px" }} alt="imagem ilustrativa" />
        </Row>
        <Row id="center">
        <h2>Aqui será exibido seus convites de zonas</h2>

        </Row>
        <Padding padding="10px" />
        <Line />
          {userprofile.invites.length > 0 ? <>
            <Padding padding="20px" />
            <Grid container direction="row" spacing={2} columns={12}>
              {userprofile.invites.map((item: any, key: any) => (
                <Grid key={key} item md={!matches ? 4 : 6} xs={12}>
                  <CardsInvites invites={item} key={key} />
                </Grid>
              ))}
            </Grid>
          </> : <div><Padding padding="20px" /><h3>Sem Convites</h3></div>}
      </Column>
    </C.Container>
  );
}
