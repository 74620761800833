import { Form, Formik } from "formik"
import { useContext } from "react"
import { SessionZonaContext } from "../../../../../contexts/Zone/SessionZone/context"
import { SessionZonaContextType } from "../../../../../contexts/Zone/SessionZone/type"
import { Button, Input, Modal } from "../../../../../ludz.one_ui"
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components"
import { Column, Row } from "../../../../../styles/globalStyles"

interface PropsCreateSection {
    visible?: boolean,
    onOpen?: any,
    item?: any
}

const EditSession = ({ visible, onOpen, item }: PropsCreateSection) => {

    const { handleEditSession } = useContext(SessionZonaContext) as SessionZonaContextType


    return (
        <Modal visible={visible} onOpen={onOpen} title={"Editar Seção"}>
            <Formik initialValues={{ name: item.name ?? "" }} onSubmit={(values) => { handleEditSession(values, item.id); }}>
                {({ values, handleChange, errors, touched, handleSubmit }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Column>
                                <Input name="name" value={values.name} onChange={handleChange} label="Nome da seção" />
                            </Column>
                            <Padding padding="16px" />
                            {errors.name && touched.name ? (
                                <div style={{ color: "red" }}>{errors.name.toString()}</div>
                            ) : null}
                            <Padding />
                            <Row id="center">
                                <Button title="Editar" type="button" onClick={(e: any) => {
                                    e.stopPropagation()
                                    handleEditSession(values, item.id)
                                    onOpen()
                                }} />
                            </Row>
                        </Form>
                    )
                }}
            </Formik>

        </Modal>
    )
}

export default EditSession