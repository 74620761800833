import { useMediaQuery } from "@mui/material";
import { Item } from "../../../../../contexts/Channel/ChannelComplet/type"
import { Row } from "../../../../../styles/globalStyles"
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components";

const CardVideo = ({ item }: { item: Item }) => {

    const matchesSmall = useMediaQuery("(min-width: 800px)");
    return (
        <div className="card" style={{cursor: "pointer"}}>
            <Row id="center">

                <img src={!matchesSmall ? item.snippet.thumbnails.default.url : item.snippet.thumbnails.default.url} alt="" />
            </Row>
            <Padding />
            <Row id="center">
                <h4>
                    {item.snippet.title}
                </h4>
            </Row>
        </div>
    )
}

export default CardVideo