import { useParams } from "react-router-dom";
import { CreateSubZoneController } from "../../../ludzoneSDK/Subzone/controller";
import { PropsInitialValue } from "./types";
import { useState } from "react";
import * as Yup from 'yup';
import { ZonaContext } from "../Zone/context";
import { ZonaContextType } from "../Zone/type";
import {useContext} from "react"



export const CreateSubzonaState = () => {

  const [file, setFile] = useState();

  const { id } = useParams()

  const initialValues: PropsInitialValue = {
    name: "",
    description: "",
    zone: parseInt(id!),
    status: 1
}

const schema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  description: Yup.string().required('A descrição é obrigatória'),
});
const { refetch } = useContext(ZonaContext) as ZonaContextType


  const { CreateSubZoneMutation } = CreateSubZoneController({refetch: refetch})

  const HandleSubmitEnviar = async (data: PropsInitialValue) => {

      CreateSubZoneMutation.mutate({body: {description: data.description, name: data.name, status: data.status, zone: data.zone}, file: file ?  file![0] : undefined})

 
    // setFile(undefined)
  };

  return {
    HandleSubmitEnviar, initialValues, setFile, schema
  }
}