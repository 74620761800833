import React, { createContext } from "react";
import { ForgetPasswordContextType } from "./type";
import { ForgetPasswordState } from "./state";


export const ForgetPasswordContext = createContext<ForgetPasswordContextType | null>(null);

interface Props {
    children: React.ReactNode
}

const ForgetPasswordProvider = ({ children }: Props) => {

    const { ForgetPasswordSchema, initialValue, ForgetPassword } = ForgetPasswordState();

    return (
        <ForgetPasswordContext.Provider value={{ ForgetPasswordSchema, initialValue, ForgetPassword }}>
            {children}
        </ForgetPasswordContext.Provider>
    )
}

export default ForgetPasswordProvider;