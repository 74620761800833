import http from "../../../services/api";
import { logout } from "../../../services/localStorage";

export const GetZoneStatusSubzoneRequest = async (id: number) => {
    return await http.get("/bff/sub-zone-bff/status", {
        params: {
            zoneId: id,
        }
    }).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            throw err;
        });
} 