import { useEffect, useState } from "react";
import { useFetchChannelContent } from "../../../ludzoneSDK/Channel/Content/query";
import { Content } from "./type";

export const ChannelContentState = (id: number) => {
  const [contentChannel, setcontentChannel] = useState<Content | undefined>();
  const { data: content } = useFetchChannelContent(id);
  useEffect(() => {
    if (content) {
      setcontentChannel(content);
    }
  }, [content]);

  return {
    contentChannel,
  };
};
