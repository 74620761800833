import http from "../../services/api";
import { logout } from "../../services/localStorage";
import { PropsBuyZone } from "./type";

export const GetZonePublicBff = async (page: number | undefined, zonename: string) => {
    return await http.get("/bff/zone-bff/public-zones", {
     params: {
          whichPage: page,
          perPage: 12,
          zoneName: zonename
     }
    }).then(response => response.data)
         .catch(err => {
              if (err.response.status === 401) {
                   logout()
                   window.location.reload()
              }
              throw err;
         });
}

export const BuyZonePostRequest = async (body: PropsBuyZone) => {
     return await http.post("/bff/zone-user-bff/entry-public-zone", {...body, zoneId: parseInt(body.zoneId!)}).then(response => response.data)
         .catch(err => {
             if (err.response.status === 401) {
                 logout()
                 window.location.reload()
             }
             alert(err.response.data.message)
             throw err;
         });
 }