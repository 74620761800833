import background from "../../../../assets/images/Logo2.png";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../styles/globalStyles";
import CardNotifications from "../../../../components/Cards/CardNotifications";
import { Line } from "../ProfileInfo/style";
import * as C from "./style";
import { useContext, useEffect, useState } from "react";
import { PrivateRoutesContext } from "../../../../contexts/PrivateRoutes/context";
import {
  PrivateRoutesContextType,
} from "../../../../contexts/PrivateRoutes/types";
import { Button } from "../../../../ludz.one_ui";
import ModalNotifyFilter from "./ModalNotifyFilter";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import SkeletonSubzona from "../../../Zone/Zone/SubzoneStatus/Components/SkeletonSubzona";
import CheckboxLudz from "../../../../ludz.one_ui/Checkboxs";
import { UserType } from "../../../../ludzoneSDK/Profile/types";
import { Toast } from "primereact/toast";

interface Props {
  userprofile: UserType | any;
}

export default function ProfileNotifications({ userprofile }: Props) {
  const { UpdateAllNotification, DeleteSelectionNotifications, toast } = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;

  const [value, setValue] = useState("Todos");
  const [selectedZone, setSelectedZones] = useState<any>();
 
  const [notificationfilter, setNotificationFilter] = useState<any>([]);
  const [selectedNotifications, setSelectedNotifications] = useState<number[]>(
    []
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [visible, setVisible] = useState<{ [key: number]: boolean }>({});
  const [allSelected, setAllSelected] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(9);

  const resetFilters = () => {
    setNotificationFilter(userprofile?.notifications); // Redefine para todas as notificações
  };

  useEffect(() => {
    let filteredNotifications = userprofile?.notifications; // Começa com todas as notificações

    // Filtra por 'Lidas' ou 'Não Lidas', se especificado
    if (value === "Lidas") {
      filteredNotifications = filteredNotifications.filter(
        (props: any) => props.isViewed
      );
    } else if (value === "Não Lidas") {
      filteredNotifications = filteredNotifications.filter(
        (props: any) => !props.isViewed
      );
    }

    // Filtra por zona, se uma zona foi selecionada e não é "Sem Filtro"
    if (selectedZone && selectedZone.name !== "Sem Filtro" && value !== "") {
      filteredNotifications = filteredNotifications.filter(
        (props: any) => selectedZone.id === props?.links[0]?.zone_fk
      );
    }

    // Se o valor é "Todos" ou a zona selecionada é "Sem Filtro", retorna todas as notificações
    if (
      value === "Todos" ||
      (selectedZone && selectedZone.name === "Sem Filtro")
    ) {
      resetFilters();
    }

    // Atualiza o estado com as notificações filtradas
    setNotificationFilter(filteredNotifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userprofile?.notifications, value, selectedZone]);

  useEffect(() => {
    const currentPageNotifications = notificationfilter.slice(
      first,
      first + rows
    );
    const allSelectedInPage = currentPageNotifications.every(
      (notification: any) => selectedNotifications.includes(notification.id)
    );
    setAllSelected(allSelectedInPage);
  }, [selectedNotifications, notificationfilter, first, rows]);

  // Area dataview
  const [layout, setLayout] = useState<
    "grid" | "list" | (string & Record<string, unknown>)
  >("list");
  const [sortField] = useState("");

  // Componente Header
  const dataViewHeader = (
    <div className="flex flex-column md:flex-row md:justify-content-between gap-2">
      <Toast ref={toast}  />
      <div>
        <ModalNotifyFilter
          value={value}
          setValue={setValue}
          selectedZone={selectedZone}
          setSelectedZones={setSelectedZones}
          resetFilters={resetFilters}
        />
      </div>
      <DataViewLayoutOptions
        layout={layout}
        onChange={(e: any) => setLayout(e.value)}
      />
    </div>
  );

  const handleSelectAll = (e: any) => {
    const checked = e.target.checked;
    const currentPageNotifications = notificationfilter.slice(
      first,
      first + rows
    );
    if (checked) {
      const currentIds = currentPageNotifications.map(
        (notification: any) => notification.id
      );
      const newSelected = Array.from(
        new Set([...selectedNotifications, ...currentIds])
      );
      setSelectedNotifications(newSelected);
    } else {
      const currentIds = currentPageNotifications.map(
        (notification: any) => notification.id
      );
      const newSelected = selectedNotifications.filter(
        (id: number) => !currentIds.includes(id)
      );
      setSelectedNotifications(newSelected);
    }
    setAllSelected(checked);
  };

  //Função para manipular a seleção de notificações
  const handleSelectionChange = (id: number) => {
    setSelectedNotifications((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  //Função para manipular a visibilidade do dialogo de confirmação
  // const handleVisibilityChange = (id: number, visibility: boolean) => {
  //   setVisible((prevVisible) => ({
  //     ...prevVisible,
  //     [id]: visibility,
  //   }));
  // };

  //Componente de visualização em Lista

  const dataviewListItem = (data: any) => {
    return (
      <div className="col-12">
        <div
          style={{ gap: "16px" }}
          className="flex flex-column md:flex-row align-items-center  p-3 w-full"
        >
          <CheckboxLudz
            checked={selectedNotifications.includes(data.id)}
            onChange={() => handleSelectionChange(data.id)}
          />
          <C.AreaDescriptionList className="flex-1 flex flex-column text-justify md:text-justify">
            <CardNotifications notification={data} showElement={true} />
          </C.AreaDescriptionList>

          {/* <ConfirmationDialog
            accept={() => {
              DeleteNotification(data.id);
              handleVisibilityChange(data.id, false);
            }}
            visible={visible[data.id] || false}
            reject={() => handleVisibilityChange(data.id, false)}
            message="Deseja Excluir?"
            onHide={() => handleVisibilityChange(data.id, false)}
          /> */}
          <div className="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0"></div>
        </div>
      </div>
    );
  };

  // Componente de visualização em GRID

  const dataviewGridItem = (data: any) => {
    return (
      <div className="col-12 lg:col-4">
        <C.CardGrid className="m-3 border-1 surface-border p-2 border-round-2xl">
          <div className="flex flex-wrap gap-2 align-items-center justify-content-between mb-2">
            <CheckboxLudz
              checked={selectedNotifications.includes(data.id)}
              onChange={() => handleSelectionChange(data.id)}
            />
          </div>
          <div className="flex flex-column align-items-center text-justify mb-3">
            <CardNotifications notification={data} showElement={true} />
          </div>

          <div
            className="flex"
            style={{ alignItems: "flex-end", justifyContent: "flex-end" }}
          ></div>
        </C.CardGrid>
      </div>
    );
  };

  const itemTemplate = (
    data: any,
    layout: "grid" | "list" | (string & Record<string, unknown>)
  ) => {
    if (!data) {
      return;
    }

    if (layout === "list") {
      return dataviewListItem(data);
    } else if (layout === "grid") {
      return dataviewGridItem(data);
    }
  };

  const deleteSelectedNotifications = () => {
    DeleteSelectionNotifications(selectedNotifications);
    setSelectedNotifications([]);
  };

  const handlePageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <C.Container>
      <Column id="center" style={{ width: "100%" }}>
        <Row id="center">
          <img
            src={background}
            style={{ width: "128px", height: "100px" }}
            alt="imagem ilustrativa"
          />
        </Row>
        <Row id="center">
          <h2>Aqui será exibido as notificações do usuário na plataforma</h2>
        </Row>
        <Padding padding="8px" />
        <Row id="center">
          <Button
            title="Marcar todas"
            onClick={UpdateAllNotification}
          />
          <Button
            title="Excluir Seleção"
            onClick={deleteSelectedNotifications}
            disabled={selectedNotifications.length === 0}
          />
        </Row>
        <Padding padding="16px" />
        <Line />
        <Padding padding="16px" />
        {notificationfilter?.length > 0 ? (
          <>
            <Padding padding="16px" />
            <div className="grid p-3">
              <div className="col-12">
                <div className="card">
                  <Row>
                    <CheckboxLudz
                      checked={allSelected}
                      onChange={handleSelectAll}
                    />
                    <label className="font-semibold" style={{ paddingLeft: "8px" }}>
                      Selecionar todas
                    </label>
                  </Row>
                  <Padding padding="10px"/>
                  {notificationfilter ? (
                    <DataView
                      value={notificationfilter}
                      layout={layout}
                      paginator
                      rows={rows}
                      first={first}
                      onPage={handlePageChange}
                      sortField={sortField}
                      itemTemplate={itemTemplate}
                      header={dataViewHeader}
                    ></DataView>
                  ) : (
                    <SkeletonSubzona />
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <DataView
            value={notificationfilter}
            layout={layout}
            paginator
            rows={rows}
            first={first}
            onPage={handlePageChange}
            sortField={sortField}
            itemTemplate={itemTemplate}
            header={dataViewHeader}
          ></DataView>
        )}
      </Column>
    </C.Container>
  );
}
