import React, { useState } from 'react'
import MenuPrime from '../../../../ludz.one_ui/Menu/MenuPrime'
import ConfirmationDialog from '../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes'

interface Props {
  data: any,
  handleDeletePost: any,
  setEdit: any,
}

export default function EditPost({ data, handleDeletePost, setEdit }: Props) {

  const [visible, setvisible] = useState(false)

  let items = [
    {
      label: "Editar",
      icon: "pi pi-fw pi-pencil",
      command: () => setEdit(true),
    },
    {
      label: "Excluir",
      icon: "pi pi-fw pi-trash",
      command: () =>
        setvisible(!visible),
    },
  ]

  let itemsarq = [
    {
      label: data.content
        ? "Editar"
        : "Adicionar Texto",
      icon: "pi pi-fw pi-pencil",
      command: () => setEdit(true),
    },
    {
      label: "Excluir",
      icon: "pi pi-fw pi-trash",
      command: () =>
        setvisible(!visible),
    },
  ]

  return (
    <div>
      {!data.archives ?
        <MenuPrime items={items} />
        :
        <MenuPrime items={itemsarq} />
      }
      <ConfirmationDialog accept={() => handleDeletePost({ idpost: data.id })} visible={visible} reject={() => setvisible(true)} message="Deseja Excluir?" onHide={() => setvisible(false)} />

    </div>
  )
}
