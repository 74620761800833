import Swal from "sweetalert2";
import { FormBusinessController } from "../../ludzoneSDK/LandingPage/controller";
import { PropsInitialValue } from "./type"
import * as Yup from 'yup'
import { useState } from "react";


export const FormBusinessState = () => {

    const [displayModalForm, setDisplayModalForm] = useState(false);
    const initialValues: PropsInitialValue = {
        name: "",
        company: "",
        email: "",
        phone: "",
    }

    const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        email: Yup.string().required('O email é obrigatória'),
        phone: Yup.string().required('O telefone é obrigatória'),
      });

      const { FormBusinessMutation } = FormBusinessController();

      const handleFormBusiness = (body: PropsInitialValue) => {
        
        FormBusinessMutation.mutate(body)
        setDisplayModalForm(false)
        Swal.fire({
            icon: "success",
            title: "Seu formulário foi enviado, Entraremos em contato em breve!",
            showConfirmButton: false,
            timer: 3500
          });

    }

    return {
        initialValues, schema, handleFormBusiness, displayModalForm, setDisplayModalForm
    }
}