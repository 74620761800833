import styled from "styled-components";
import styles from "../../../../ludz.one_ui/styles";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 2% 4%;
  grid-area: "children";
  display: flex;
  flex-direction: column;
  align-items: center;
  ::-webkit-scrollbar {
    display: none;
  }

  img {
    height: 200px;
    width: 100%;
    object-fit: contain;
  }

  hr {
    background-color: ${styles.colors.grayClearOne};
  }

  h1 {
    text-align: center;
  }

  @media (max-width: 520px) {
    h1 {
      font-size: x-large;
    }
    h2{
      text-align: center;
    }
  }
`;

export const AreaCards = styled.div`
  display: grid;
  gap: 20px;
  cursor: pointer;
  justify-content: center;

  grid-template-columns: auto;
  grid-template-rows: auto;

  grid-template-areas: "CT CT CT";

  text-align: justify;

  @media (max-width: 1024px) {
    grid-template-areas: "CT CT";
  }

  @media (max-width: 520px) {
    grid-template-areas: "CT";
  }
`;
