import { useContext, useState } from "react";
import ObjectiveKeySubonaProvider from "../../../contexts/Subzone/ObjectiveSubzone/ObjectiveKeySubzone/context";
import { ObjectiveSubzonaContext } from "../../../contexts/Subzone/ObjectiveSubzone/context";
import { ObjectiveSubzonaContextType } from "../../../contexts/Subzone/ObjectiveSubzone/type";
import { Accordion, Button } from "../../../ludz.one_ui";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { Container } from "../../style";
import BodyObjective from "./BodyObjctive";
import CreateObjective from "./CreateObjective";
import HeaderAccordion from "./HeaderAccordion";
import LudzScrollTop from "../../../ludz.one_ui/LudzScrollTop";
import { Toast } from "primereact/toast";

const ObjectiveSubzone = () => {
  const [visibleCreateObjective, setVisibleCreateObjective] = useState(false);

  const { objective, visibleEditObjetive, toast } = useContext(
    ObjectiveSubzonaContext
  ) as ObjectiveSubzonaContextType;

  return (
    <Container>
      <Toast ref={toast}/>
      <h2>Objetivos</h2>
      <Padding padding="16px" />
      <Button
        title="Criar Objetivo"
        onClick={() => setVisibleCreateObjective(!visibleCreateObjective)}
      />
      <Padding padding="8px" />
      <ObjectiveKeySubonaProvider>
        {objective && (
          <Accordion
            header={HeaderAccordion}
            body={BodyObjective}
            data={objective}
            disabled={visibleEditObjetive}
          />
        )}
      </ObjectiveKeySubonaProvider>
      <CreateObjective
        visible={visibleCreateObjective}
        onOpen={() => setVisibleCreateObjective(!visibleCreateObjective)}
      />
      <LudzScrollTop style={{ right: "0.4%", padding: "15px" }} />
    </Container>
  );
};

export default ObjectiveSubzone;
