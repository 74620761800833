import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { ChatBox, Container } from './style';
import { Toast } from 'primereact/toast';

import { PrivateRoutesContext } from '../../contexts/PrivateRoutes/context';
import { PrivateRoutesContextType } from '../../contexts/PrivateRoutes/types';
import LogoLudz from "../../assets/images/CamaleãoPB.png";
import ChatOptions, { OptionsAwnsers } from './ChatOptions';
import { Row } from '../../styles/globalStyles';
import Lottie from 'lottie-react';
import ChatInput from './ChatInput';
import LoadingIA from "../../assets/loadingIA.json";
import { ChatMessageType } from './ChatMessage/types';
import ChatMessage from './ChatMessage';
import IsNotAcess from './IsNotAcess';

// import { Container } from './styles';

interface ChatGPTInterface {
    messages: ChatMessageType[],
    setMessages?: Dispatch<SetStateAction<ChatMessageType[]>>,
    handleCopy?:  any,
    handleShare?:  any,
    handleSendButton: (text: string) => void,
    inputValue: string, 
    setInputValue: Dispatch<SetStateAction<string>>,
    handleSend: (text: string) => void
    loading: boolean,
    setLoading?: Dispatch<SetStateAction<boolean>>,
    optionsAwnsers: OptionsAwnsers[],
    toast: any,
    chatBoxRef: any,
    isAcesso: boolean,
    clearHistory?: any
}

const ChatGPT = ({ isAcesso,messages, setMessages, handleCopy, handleSend, handleSendButton, handleShare, inputValue, loading, setInputValue, setLoading, optionsAwnsers, toast, chatBoxRef, clearHistory }: ChatGPTInterface) => {
    const propsPrivate = useContext(PrivateRoutesContext) as PrivateRoutesContextType;

    useEffect(() => {
      if (chatBoxRef.current) {
          chatBoxRef.current.scrollTo({
              top: chatBoxRef.current.scrollHeight,
              behavior: 'smooth'
          });
      }
  }, [chatBoxRef, messages]);

if(!isAcesso) return <IsNotAcess />


    return (
    <Container>
    <Toast ref={toast} position="center" />
    <ChatBox ref={chatBoxRef}>
      {messages.length > 1 ? (
        messages.map((message, index) => (
          <ChatMessage
            key={index}
            message={message}
            index={index}
            userImage={propsPrivate?.userProvider?.aws.url}
            handleCopy={handleCopy}
            handleShare={handleShare}
          />
        ))
      ) : (
        <div className="flex flex-column" style={{ alignItems: "center", marginTop: "4%" }}>
          <img src={LogoLudz} alt="Imagem de logo" style={{ height: "40px" }} className="mb-4 lg:mb-6" />
          <ChatOptions handleSendButton={handleSendButton} optionsAwnsers={optionsAwnsers}  />
        </div>
      )}
    </ChatBox>
    {loading && (
      <Row id="start">
        <Lottie style={{ marginBottom: "8px", height: "50px" }} className="lottie-avatar" animationData={LoadingIA} />
      </Row>
    )}
    <ChatInput inputValue={inputValue} setInputValue={setInputValue} handleSend={handleSend} loading={loading} />
    {clearHistory ? <h4 className='text-gray-500 mb-2 cursor-pointer'  onClick={clearHistory} >Limpar histórico da conversa.</h4> : null}
    <h5 className='text-gray-500 font-medium'>LudzFlow pode cometer erros. Considere verificar informações importantes.</h5>
  </Container>  
);
}

export default ChatGPT;