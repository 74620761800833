import { useEffect, useState } from "react";
import { useFetchChannelContent } from "../../../ludzoneSDK/Channel/Content/query";
import { useParams } from "react-router-dom";
import { Channel, Content } from "./type";
import { useFetchChannelComplet } from "../../../ludzoneSDK/Channel/query";

export const ChannelCompletState = () => {
  const [contentChannel, setcontentChannel] = useState<Content | undefined>();
  const [playlistChannel, setPlaylistChannel] = useState<Channel | undefined>();
  const [allplaylistChannel, setallPlaylistChannel] = useState<Array<{id: number}> | undefined>();


  const { id, idVideo } = useParams();

  const { data: playlists } = useFetchChannelComplet(parseInt(id!));
  const { data: content } = useFetchChannelContent(
    idVideo ? parseInt(idVideo!) : playlistChannel?.playlists[0]?.contents[0]?.id
  );

  


  useEffect(() => {
    if (content) {
      setcontentChannel(content);
    }
    if (playlists) {
      setPlaylistChannel(playlists);
      var array: Array<{id: number}> = [];
      playlistChannel?.playlists.forEach((element) => {
        element.contents.forEach((i) => {
          array.push({ id: i.id });
        });
      });
      setallPlaylistChannel(array);
    }
  }, [content, playlists, playlistChannel?.playlists]);

  return {
    contentChannel,
    playlistChannel,
    allplaylistChannel
  };
};
