import { useContext, useState } from "react"
import { ObjectiveSubzonaContext } from "../../../../contexts/Subzone/ObjectiveSubzone/context"
import { ObjectiveSubzonaContextType } from "../../../../contexts/Subzone/ObjectiveSubzone/type"
import ConfirmationDialog from "../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes"
import { Column, Row } from "../../../../styles/globalStyles"
import ModalEditObjetive from "./ModalEditObjetive"


type PropsHeaderAccordion = {
    item: any
}

const HeaderAccordion = ({ item }: PropsHeaderAccordion) => {

    const { handleDeleteObjective, visibleEditObjetive, setVisibleEditObjetive } = useContext(ObjectiveSubzonaContext) as ObjectiveSubzonaContextType
    const [visible, setVisible] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)



    return (
        <Column style={{ height: "36px" }} id='center'>
            <Row id='space-between' >
                <h3 className="vertical-align-middle">{item!.name}</h3>
                <Row>
                    <Column id='center'>
                        <i onClick={(e) => { setVisibleModal(true); setVisibleEditObjetive(true)}} className="pi pi-pencil mr-2"></i>
                    </Column>
                    <Column id='center'>
                        <i onClick={(e) => { setVisible(true); e.stopPropagation() }} className="pi pi-trash mr-2"></i>
                    </Column>
                </Row>
            </Row>
            <ConfirmationDialog accept={() => handleDeleteObjective!(item.id)} visible={visible} reject={() => setVisible(true)} message="Deseja Excluir?" onHide={() => setVisible(false)} />
            {visibleModal ? <ModalEditObjetive item={item!} onOpen={() => {setVisibleEditObjetive(!visibleEditObjetive); setVisibleModal(!visibleModal)}} visible={visibleModal}/> : null} 
        </Column>
    )
}

export default HeaderAccordion;