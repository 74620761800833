import { createContext } from "react";
import { CreateSubzonaState } from "./state";
import { CreateSubZonaContextType } from "./types";

export const CreateSubZonaContext = createContext<CreateSubZonaContextType | null>(null);

interface Props {
    children: React.ReactNode
}


const CreateSubZonaProvider = ({ children }: Props) => {

    const { initialValues, HandleSubmitEnviar,setFile,schema } = CreateSubzonaState();

    return (
        <CreateSubZonaContext.Provider value={{ initialValues, HandleSubmitEnviar, setFile,schema }}>
            {children}
        </CreateSubZonaContext.Provider>
    )
}

export default CreateSubZonaProvider;