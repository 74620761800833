import { useMediaQuery } from "@mui/material";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { Tooltip } from "../../../../../../../../../ludz.one_ui";
import * as C from "../style";

interface PropsInplace {
  data: any;
  item: any;
  handleUpdateChecklistItem: any;
}

const InplaceItem = ({
  data,
  item,
  handleUpdateChecklistItem,
}: PropsInplace) => {
  const [text, setText] = useState(item.description);
  const [isediting, setIsEditing] = useState(false);
  const matches = useMediaQuery("(max-width: 520px)");

  const handleSend = (e: any) => {
    if (e.keyCode === 13) {
      const body = {
        checklist: data.id,
        isDone: item.isDone,
        description: text,
      };
      const id = item.id.toString();
      handleUpdateChecklistItem(id, body);
      setIsEditing(false);
    }
  };

  

  return (
    <>
      {isediting ? (
        <C.AreaInplace>
          <InputText
            style={{ width: matches ? "80%" : "100%" }}
            value={text}
            onKeyUp={handleSend}
            onChange={(e) => setText(e.target.value)}
          />
          <p
            onClick={() => setIsEditing(false)}
            style={{
              fontSize: "12px",
              marginTop: "4px",
              cursor: "pointer",
              marginLeft: '5px'
            }}
          >
            <i id="additemedit" className="pi pi-times"></i>
          </p>
        </C.AreaInplace>
      ) : (
        <C.Title decoration={item.isDone} onClick={() => setIsEditing(true)}>
          <Tooltip title={item.description} style={{ wordBreak: 'break-all' }}>{item.description}</Tooltip>
        </C.Title>
      )}
    </>
  );
};

export default InplaceItem;
