
import { useContext, useState } from "react";
import { Icon, Input } from "../../../../../../../ludz.one_ui";
import { Padding } from "../../../../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../../../../styles/globalStyles";
import { KanbanCardSubzonaContext } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context";
import { KanbanCardSubzonaContextType } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type";
import { useParams } from "react-router-dom";

interface PropsNameCard {
    name: string
}

const NameCard = ({ name }: PropsNameCard) => {

    const { handleUpdateCard } = useContext(KanbanCardSubzonaContext) as KanbanCardSubzonaContextType

    const [title, settitle] = useState(name)

    const { idcard } = useParams()


    return (
        <Row>
            <Column id="center">
                <Icon icon="pi pi-credit-card" />
            </Column>
            <Padding />
            <Input value={title}
                onBlur={() => handleUpdateCard({ title: title }, parseInt(idcard!))}
                onChange={(e: any) => settitle(e.target.value)} />
        </Row>
    )
}

export default NameCard;