import { Icon, Tooltip } from "../../../ludz.one_ui";
import { Button, IconBottom } from "./style";

interface PropsMenu {
  icon:
    | "help"
    | "addIdea"
    | "addZona"
    | "addSubZona"
    | "ideaSituation"
    | "channels"
    | "explore";
  onClick?: () => void;
}

const IconsMenu = ({ icon, onClick }: PropsMenu) => {
  return (
    <Button onClick={onClick}>
      {icon === "help" && (
        <Tooltip title="Ajuda" placement="right" arrow>
          <IconBottom>
            <Icon icon="pi pi-question" />
          </IconBottom>
        </Tooltip>
      )}
      {icon === "addIdea" && (
        <IconBottom>
          <Icon icon="pi pi-plus" />
        </IconBottom>
      )}
      {icon === "addZona" && (
        <Tooltip title="Criar Zona" placement="right" arrow>
          <IconBottom>
            <Icon icon="pi pi-plus" />
          </IconBottom>
        </Tooltip>
      )}
      {icon === "addSubZona" && (
        <Tooltip title="Criar Subzona" placement="right" arrow>
        <IconBottom>
          <Icon icon="pi pi-plus" />
        </IconBottom>
      </Tooltip>
      )}
      {icon === "ideaSituation" && (
        <IconBottom>
          <Icon icon="pi pi-star" />
        </IconBottom>
      )}
      {icon === "channels" && (
        <Tooltip title="Explorar Canais" placement="right" arrow>
          <IconBottom>
            <Icon icon="pi pi-video" />
          </IconBottom>
        </Tooltip>
      )}
      {icon === "explore" && (
        <Tooltip title="Explorar Zonas" placement="right" arrow>
          <IconBottom>
            <Icon icon="pi pi-compass" />
          </IconBottom>
        </Tooltip>
      )}
    </Button>
  );
};

export default IconsMenu;
