import styled from "styled-components";

export const IconCircler = styled.div`
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 45px;
  width: 45px;
  display: flex;

  align-items: center;
  justify-content: center;

  transition: all 0.5s ease;

  &:hover {
    transition: all 0.5s ease;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const Inline = styled.div`
  height: 1px;
  margin: 16px 0;
  background-color: #d5d5d5;
`;