import http from "../../../services/api";
import { logout } from "../../../services/localStorage";
import { TypeMembersSubZone } from "./type";

export const AddSubZoneUser = async (body: TypeMembersSubZone) => {
     return await http.post(`/direct/sub-zone-user`, body).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               alert(err.response.data.message)
               throw err;
          })
}



export const RemoveUserSubZone = async (id: string | null, idSubZone: string) => {
     return await http.delete(`/bff/sub-zone-user-bff/delete-member`, {params: {userId: id, subZoneId: idSubZone}}).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               alert(err.response.data.message)
               throw err;
          })
}