import { createContext } from "react";
import { StatusSubzonaContextType } from "./types";
import { StatusSubzoneState } from "./state";

export const StatusSubonaContext = createContext<StatusSubzonaContextType | null>(null);

interface Props {
    children: React.ReactNode
}


const StatusSubzonaProvider = ({ children }: Props) => {

    const { setStatusSelect, status, statusSelect, handleAvaliate, deleteSubzona, subzone, toast } = StatusSubzoneState();

    return (
        <StatusSubonaContext.Provider value={{ setStatusSelect, status, statusSelect,handleAvaliate, deleteSubzona, subzone, toast}}>
            {children}
        </StatusSubonaContext.Provider>
    )
}

export default StatusSubzonaProvider;