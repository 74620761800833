import styled from "styled-components";

interface Background {
  background: string;
}

export const Container = styled.div`
    padding: 32px;
  @media (max-width: 520px) {
    padding: 12px;
    h1 {
      font-size: large;
    }
  }

  @media (max-width: 360px) {
    h1, h2 {
      font-size: normal;
    }

    p{
      font-size: small;
    }
  }
`;

export const CapaChannel = styled.img<Background>`
     background-image: url(${props => props.background}); 
     width: 100%;
     height: 220px;
     border: 1px solid "#c3c3c3";
     background-position: center; /* Centraliza a imagem */
    background-repeat: no-repeat; /* Evita que a imagem se repita */
    background-size: cover;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;

    @media (min-width: 1368px) and (max-width: 2000px) {
      height: 250px;
    }

    @media (min-width: 2560px) and (max-width: 2600px) {
      height: 22rem;
    }

    @media (max-width: 1024px) {
    height: 180px;
  }
  
  @media (max-width: 912px) {
    height: 160px;
  }

`;

export const AreaInfo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0px 6px;
   color: #b4b2b2;

  span {
    font-size: small;
  }

  @media (max-width: 520px) {
    span {
      font-size: x-small;
    }
  }
`;

interface PropsIconEdit {
  color?: string;
}

export const IconEdit = styled.div<PropsIconEdit>`
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    cursor: pointer;
  }

  &:hover {
    color: ${(props) => props.color};
  }
`;

export const AreaButtons = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 5px 0px 5px 0px;
`;

export const AreaOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 520px) {
    flex-direction: column;
    margin-left: 8.6%;
  }

  @media (max-width: 360px) {
    margin-left: 2%;
  }
`;

export const AvatarContainer = styled.div`
  position: relative;
  margin-top: -12%;



  @media (min-width: 2500px) and (max-width: 3000px) {
    margin-top: -6%;
  }

  @media (min-width: 1920px) and (max-width: 2000px) {
    margin-top: -8%;
  }

  @media (min-width: 1380px) and (max-width: 1900px) {
    margin-top: -12%;
  }

  @media (max-width: 1024px) {
    margin-top: -18%;
  }

  @media (max-width: 920px) {
    margin-top: -14%;
  }

  @media (max-width: 820px) {
    margin-top: -16%;
  }

  @media (max-width: 768px) {
    margin-top: -17%;
  }

  @media (max-width: 520px) {
    margin-top: -26%;
  }

  @media (max-width: 380px) {
    margin-top: -28%;
  }
`;