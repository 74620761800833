import * as Yup from 'yup';
import { KanbanCardSubzoneController } from '../../../../ludzoneSDK/Subzone/Kanban/KanbanCard/controller';
import { PropsMoveSubzoneKanbanCard, PropsMoveSubzoneKanbanColumn, PropsSubzoneKanbanCard } from '../../../../ludzoneSDK/Subzone/Kanban/KanbanCard/type';
import { useRef } from 'react';
import { Toast } from 'primereact/toast';


export const KanbanCardSubzoneState = () => {
    const toast = useRef<Toast>(null)

    const { KanbanCardSubzoneMutation, DeleteKanbanCardSubzoneMutation, MoveKanbanCardSubzoneMutation, MoveMouseKanbanCardSubzoneMutation, MoveMouseKanbanColumnSubzoneMutation } = KanbanCardSubzoneController({toast: toast})

    const initialValueCard = {
        title: ""
    }

    const schemaCard = Yup.object().shape({
        title: Yup.string().required('Campo obrigatório'),
    });

    const handleKanbanCard = (data: PropsSubzoneKanbanCard, id: number, index: number) => {
        // setList(
        //     {
        //         ...list!,
        //         kanbanCards: [...list.kanbanCards, { kanban: id, title: data.title, index: index }]
        //     }
        // )
        KanbanCardSubzoneMutation.mutate({ kanban: id, title: data.title, index: index })
    }

    const handleDeleteCard = (id: number) => {
        DeleteKanbanCardSubzoneMutation.mutate(id)
    }

    const moveCard = (data: PropsMoveSubzoneKanbanCard) => {
        MoveKanbanCardSubzoneMutation.mutate(data)
    }

    const moveMouserCard = (data: PropsMoveSubzoneKanbanCard) => {
        MoveMouseKanbanCardSubzoneMutation.mutate(data)
    }

    const moveMouserColumn = (data: PropsMoveSubzoneKanbanColumn) => {
        MoveMouseKanbanColumnSubzoneMutation.mutate(data)
    }

    return {
        handleKanbanCard, initialValueCard, schemaCard, handleDeleteCard, moveCard, moveMouserCard, moveMouserColumn, toast
    }
}