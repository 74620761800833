import { Toast } from "primereact/toast";
import { ReactNode, RefObject } from "react";

export const Alert = ({
  severity,
  detail,
  summary,
  toast,
}: {
  severity?: "success" | "info" | "warn" | "error" | undefined;
  summary?: ReactNode;
  detail?: ReactNode;
  toast?: RefObject<Toast>;
}) => {
  const show = () => {
    if (toast) {
      toast.current!.show({
        severity: severity,
        summary: summary,
        detail: detail,
      });
    }
  };

  return { toast, show };
};
