import { useDebounce } from 'primereact/hooks';
import { useEffect, useState } from "react";
import { ZonesPublicController } from "../../../ludzoneSDK/PublicZone/controller";
import { PropsBuyZone } from "../../../ludzoneSDK/PublicZone/type";

export const StateZonesPublic  = () => {

    const [page, setPage] = useState(+1);
    const [zonasPublics, setZonasPublics] = useState();
    const [zonaall, setZonaAll] = useState(0);
    const [inputValue, debouncedValue, setInputValue] = useDebounce('', 500);
    const { zones, isLoadingZones, errorZones, refetch, PostBuyZoneMutation } = ZonesPublicController(page, debouncedValue)
    useEffect(() => {
        if (zones) {
            
            setZonasPublics(zones?.zones)
            setZonaAll(zones?.countZonesPublic)
        }
        if(page){
            setPage(page)
        }
        if(debouncedValue !== inputValue && zonasPublics){ 
            setPage(1)
            setZonasPublics(undefined)
        }
    }, [zones, page, zonaall, refetch, debouncedValue, zonasPublics, inputValue])

    const handleEntryZone = (body: PropsBuyZone) => {

        PostBuyZoneMutation.mutate(body)
    }

    return {
        zonasPublics, isLoadingZones, errorZones, page, setPage, zonaall, refetch, handleEntryZone, setInputValue, inputValue
    }
}