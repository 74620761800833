import LoginProvider from "../../../contexts/User/Login/context";
import LoginScreen from "../../../screens/User/Login";

const Login = () => {

    return (
        <LoginProvider>
            <LoginScreen />
        </LoginProvider>
    )
}

export default Login;