import { useMutation } from "react-query";
import { useFetchZoneBff } from "./query";
import { CreateZoneRequest, PutLastAcess } from "./request";
import { PropsCreateZone } from "./type";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export const CreateZoneController = () => {

    const history = useNavigate()

    const CreateZoneMutation = useMutation((data: PropsCreateZone) => CreateZoneRequest(data), {
        onMutate: () => {
        //   setLoading(true); 
        //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
        //   setError(error.response.data.message);
        //   setLoading(false);
        Swal.fire({
               
            icon: "warning",
            title: "Erro de servidor, tente novamente!",
            showConfirmButton: false,
            timer: 2000
          });
            console.log(error)
        },
        onSuccess: (result: any, variables, context) => {
            console.log(result)
            history("/home")
            Swal.fire({
               
                icon: "success",
                title: "Zona criada!",
                showConfirmButton: false,
                timer: 2000
              });
        },
      });

    return{
        CreateZoneMutation,
    }
}



export const ZoneBffController = (id: string | undefined) => {

    const { data, refetch } = useFetchZoneBff(id)

    const LastAcess = useMutation((data: {zoneId: number}) => PutLastAcess(data), {
        onMutate: () => {
        //   setLoading(true); 
        //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
        //   setError(error.response.data.message);
        //   setLoading(false);

            console.log(error)
        },
        onSuccess: (result, variables, context) => {
        },
      });

    return{
        data, refetch, LastAcess
    }
}