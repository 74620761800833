import { useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { Dialog } from "primereact/dialog";
import { InputMask } from "primereact/inputmask";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import { Button, Input } from "../../../ludz.one_ui";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { FormBusinessController } from "../../../ludzoneSDK/LandingPage/controller";
import { Row } from "../../../styles/globalStyles";


export const FormBusinessState = () => {

    const [displayModalForm, setDisplayModalForm] = useState(false);
    const initialValues = {
        name: "",
        company: "",
        email: "",
        phone: "",
    }

    const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        email: Yup.string().required('O email é obrigatória'),
        phone: Yup.string().required('O telefone é obrigatória'),
    });

    const { FormBusinessMutation } = FormBusinessController();

    const handleFormBusiness = (body: {
        name: string;
        company: string;
        email: string;
        phone: string;
    }) => {

        FormBusinessMutation.mutate(body)
        setDisplayModalForm(false)
        Swal.fire({
            icon: "success",
            title: "Seu formulário foi enviado, Entraremos em contato em breve!",
            showConfirmButton: false,
            timer: 3500
        });

    }

    return {
        initialValues, schema, handleFormBusiness, displayModalForm, setDisplayModalForm
    }
}



const IsNotAcess = () => {
    const pricingRef = useRef<HTMLDivElement>(null);


    const props = FormBusinessState()

    const matches = useMediaQuery("(max-width: 768px)");

    const showModalDialogForm = () => {
        props.setDisplayModalForm(true);
    };



    return (
        <div
            ref={pricingRef}
            id="pricing"
            className="landing-section px-2 md:px-4 py-4 mt-4"
        >
            <div
                className="surface-ground px-2 py-4 md:px-3 lg:px-4 bg-no-repeat bg-cover"
                style={{
                    borderRadius: "24px",
                    // background: "linear-gradient(to left bottom, #E7F2F3, #FCFEFE)",
                    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
                }}
            >
                <div className="flex flex-wrap align-items-center">
                    <div className="w-full lg:w-6 lg:pr-8">
                        <div className="text-900 font-normal text-4xl sm:text-6xl xl:text-7xl mb-4">
                            Planos
                        </div>
                        <h4
                            className="text-900 font-normal mb-4 lg:mb-0 line-height-3"
                            style={{ letterSpacing: "0.6px" }}
                        >
                            Os planos contemplam vários benefícios, escolha aquele que
                            melhor se adequar
                        </h4>
                    </div>
                </div>
                <div className="flex flex-wrap mt-5 -mx-3">
                    <div className="w-full lg:w-4 p-3">
                        <div
                            className="p-4 h-full surface-card"
                            style={{ borderRadius: "24px" }}
                        >
                            <div className="font-medium text-xl mb-5 text-900">
                                Básico
                            </div>
                            <div className="font-bold text-4xl mb-5 text-900">
                                Gratuito
                            </div>
                            <Link style={{ textDecoration: "none" }} to={"/home"}>
                                <Button
                                    style={{
                                        borderRadius: "10px",
                                        padding: "0.75rem 1.25rem",
                                    }}
                                    title="Tela inicial"
                                    icon="pi pi-arrow-right"
                                    iconPos="right"
                                    className="lg:w-full font-medium p-2 p-button-lg p-button-outlined"
                                ></Button>
                            </Link>
                            <p className="text-sm line-height-3 mb-3 mt-5 line-height-3">
                                Ideal para quem está começando a explorar as possibilidades
                                virtuais.
                            </p>
                            <div className="font-medium text-xl text-900">Inclui:</div>
                            <ul className="list-none p-0 m-0 mt-3">
                                <li className="flex align-items-center mb-4">
                                    <i className="pi pi-check text-primary-500 mr-3"></i>
                                    <span className="text-md">
                                        Criação de Zona e Subzonas
                                    </span>
                                </li>
                                <li className="flex align-items-center mb-4">
                                    <i className="pi pi-check text-primary-500 mr-3"></i>
                                    <span className="text-md">
                                        capacidade de armazenar arquivos
                                    </span>
                                </li>
                                <li className="flex align-items-center mb-4">
                                    <i className="pi pi-check text-primary-500 mr-3"></i>
                                    <span className="text-md">
                                        Acesso a Zonas públicas dentro da plataforma
                                    </span>
                                </li>
                                <li className="flex align-items-center mb-4">
                                    <i className="pi pi-check text-primary-500 mr-3"></i>
                                    <span className="text-md">
                                        Capacidade de aquisição de canais
                                    </span>
                                </li>
                                <li className="flex align-items-center mb-4">
                                    <i className="pi pi-check text-primary-500 mr-3"></i>
                                    <span className="text-md">
                                        Anexo de documentos (PDF, DOC, etc.)
                                    </span>
                                </li>
                            </ul>
                            <p className="text-sm line-height-3 mb-3 mt-5 line-height-3">
                                Perfeito para: Novos usuários que desejam começar a criar e
                                participar de espaços virtuais com facilidade.
                            </p>
                        </div>
                    </div>

                    <div className="w-full lg:w-4 p-3">
                        <div
                            className="p-4 h-full surface-card"
                            style={{ borderRadius: "24px" }}
                        >
                            <div className="font-medium text-xl mb-5 text-900">
                                Plus{" "}

                            </div>
                            <div className="flex align-items-center mb-5">
                                <span className="text-900 font-bold text-4xl">
                                    Contatar
                                </span>
                            </div>
                            <Button
                                style={{ borderRadius: "10px", padding: "0.75rem 1.25rem" }}
                                title="Entrar em contato"
                                icon="pi pi-arrow-right"
                                iconPos="right"
                                className="lg:w-full font-medium p-2 p-button-lg"
                                onClick={showModalDialogForm}
                            ></Button>
                            <p className="text-sm line-height-3 mb-3 mt-5">
                                Aumente sua capacidade e recursos para melhorar a
                                aprendizagem e colaboração.
                            </p>
                            <div className="font-medium text-xl text-900">Inclui:</div>
                            <ul className="list-none p-0 m-0 mt-3">
                                <li className="flex align-items-center mb-4">
                                    <i className="pi pi-check text-primary-500 mr-3"></i>
                                    <span className="text-md">Tudo do Plano Básico</span>
                                </li>
                                <li className="flex align-items-center mb-4">
                                    <i className="pi pi-check text-primary-500 mr-3"></i>
                                    <span className="text-md">
                                        Criação de Zonas e Subzonas
                                    </span>
                                </li>
                                <li className="flex align-items-center mb-4">
                                    <i className="pi pi-check text-primary-500 mr-3"></i>
                                    <span className="text-md">
                                        Acesso a recursos avançados de IA para personalização e
                                        análise
                                    </span>
                                </li>
                            </ul>
                            <p className="text-sm line-height-3 mb-3 mt-5 line-height-3">
                                Ideal para: Educadores, facilitadores de treinamentos e
                                grupos de estudo procurando expandir suas atividades e
                                aumentar o engajamento com conteúdo interativo e
                                personalizado.
                            </p>
                        </div>
                    </div>

                    <div className="w-full lg:w-4 p-3">
                        <div
                            className="p-4 h-full flex flex-column surface-card"
                            style={{ borderRadius: "24px" }}
                        >
                            <div className="flex flex-row justify-content-between mb-5 align-items-center">
                                <div className="text-900 text-xl font-medium">
                                    Business
                                </div>
                                {/* <span
                    className="500 text-green-500 font-semibold px-2 py-1 border-round"
                    style={{ backgroundColor: "rgba(76, 175, 80, 0.1)" }}
                  >
                    🎉 Save 20%
                  </span> */}
                            </div>
                            <div className="flex align-items-center mb-5">
                                <span className="text-900 font-bold text-4xl">
                                    Contatar
                                </span>
                            </div>
                            <Button
                                style={{ borderRadius: "10px", padding: "0.75rem 1.25rem" }}
                                title="Entrar em contato"
                                icon="pi pi-arrow-right"
                                iconPos="right"
                                className="lg:w-full font-medium p-2 p-button-lg"
                                onClick={showModalDialogForm}
                            ></Button>
                            <p className="text-sm line-height-3 mb-3 mt-5 ">
                                Solução personalizada para empresas que buscam
                                funcionalidades avançadas e suporte dedicado.
                            </p>
                            <div className="font-medium text-xl text-900">Inclui:</div>
                            <ul className="list-none p-0 m-0 mt-3">
                                <li className="flex align-items-center mb-4">
                                    <i className="pi pi-check text-primary-500 mr-3"></i>
                                    <span className="text-md">
                                        Funcionalidades customizáveis conforme as necessidades
                                    </span>
                                </li>
                                <li className="flex align-items-center mb-4">
                                    <i className="pi pi-check text-primary-500 mr-3"></i>
                                    <span className="text-md">
                                        Capacidade e armazenamento ajustáveis
                                    </span>
                                </li>
                                <li className="flex align-items-center mb-4">
                                    <i className="pi pi-check text-primary-500 mr-3"></i>
                                    <span className="text-md">
                                        Acesso a todas as funcionalidades do sistema
                                    </span>
                                </li>
                                <li className="flex align-items-center mb-4">
                                    <i className="pi pi-check text-primary-500 mr-3"></i>
                                    <span className="text-md">
                                        Suporte prioritário e dedicado
                                    </span>
                                </li>
                            </ul>
                            <p className="text-sm line-height-3 mb-3 mt-3 line-height-3">
                                Ideal para: Empresas e organizações que precisam de uma
                                solução abrangente, flexível e com suporte completo para
                                otimizar suas operações e projetos em larga escala.
                            </p>
                        </div>
                        <Dialog
                            pt={{
                                content: { className: "flex-grow:1" },
                            }}
                            header="Explore o futuro de conexões com Ludzone"
                            visible={props.displayModalForm}
                            onHide={() => props.setDisplayModalForm(false)}
                            modal
                            style={{ width: matches ? "90%" : "50%", height: "auto" }}
                            draggable={false}
                            resizable={false}
                        >
                            <Formik
                                initialValues={props.initialValues}
                                onSubmit={(values) => {
                                    props.handleFormBusiness(values);
                                    props.setDisplayModalForm(false);
                                }}
                                validationSchema={props.schema}
                            >
                                {({
                                    values,
                                    handleChange,
                                    setFieldValue,
                                    handleSubmit,
                                    errors,
                                    touched,
                                }) => {
                                    return (
                                        <form
                                            onSubmit={handleSubmit}
                                            style={{ overflowY: "auto" }}
                                        >
                                            <Input
                                                label="Nome"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                placeholder="Digite o nome da zona"
                                                className={
                                                    errors.name && touched.name ? "p-invalid" : ""
                                                }
                                            />
                                            <Padding />
                                            {errors.name && touched.name && (
                                                <div style={{ color: "red" }}>{errors.name}</div>
                                            )}
                                            <Padding padding="8px" />
                                            <Input
                                                label="Empresa"
                                                name="company"
                                                value={values.company}
                                                onChange={handleChange}
                                                placeholder="Digite o nome da empresa"
                                                className={
                                                    errors.company && touched.company
                                                        ? "p-invalid"
                                                        : ""
                                                }
                                            />
                                            <Padding />
                                            {errors.company && touched.company && (
                                                <div style={{ color: "red" }}>{errors.company}</div>
                                            )}
                                            <Padding padding="8px" />
                                            <Input
                                                label="E-mail"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                placeholder="Digite o seu e-mail"
                                                className={
                                                    errors.email && touched.email ? "p-invalid" : ""
                                                }
                                            />
                                            <Padding />
                                            {errors.email && touched.email && (
                                                <div style={{ color: "red" }}>{errors.email}</div>
                                            )}
                                            <Padding padding="8px" />
                                            <label htmlFor="phone" className="block mb-2">
                                                Telefone/Celular
                                            </label>
                                            <InputMask
                                                value={values.phone}
                                                onChange={handleChange}
                                                id="phone"
                                                className="w-full"
                                                mask="(99) 99999-9999"
                                                placeholder="(99) 99999-9999"
                                            />
                                            <Padding />
                                            {errors.phone && touched.phone && (
                                                <div style={{ color: "red" }}>{errors.phone}</div>
                                            )}
                                            <Padding padding="8px" />
                                            <Row id="end">
                                                <Row>
                                                    <Button title="Enviar" type="submit" />
                                                </Row>
                                            </Row>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </Dialog>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IsNotAcess