import styled from "styled-components";

interface Props {
  decoration: boolean;
  onClick: any;
}

export const Container = styled.div`
  width: 98%;
  align-self: center;

    #additem:hover{
    color: red;
  }
`;

export const AreaItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;

  #trash:hover{
    color: red;
  }
  
`;

export const Title = styled.h5<Props>`
  display: inline;
  cursor: pointer;
  font-weight: 500;
  color: #58595b;
  text-decoration: ${(props) =>
    props.decoration === true ? "line-through" : "none"};
    padding: 0.5rem 0.5rem;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

  &:hover {
    background-color: #3333;
  }
`;

export const AreaInplace = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;

  button {
    margin-left: 5px;
  }

  :hover{
    #additemedit {
      color: red;
    }
  }
`;

export const AreaTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
