import { CSSProperties } from "react"
import { Card } from "../CardSubzoneApproved/style"


interface CardLudzInvite {
    children?: React.ReactNode,
    onClick?: any,
    style?: CSSProperties | undefined
}
const CardInvite = ({children, onClick, style}: CardLudzInvite) => {
    
    return(
        <Card onClick={onClick} style={style} >
            {children}
        </Card>
    )
}

export default CardInvite