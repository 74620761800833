import { RefObject, useContext } from "react";
import { useMutation } from "react-query";
import { UpdateAvatarZone, UpdateZone, RemoveAvatarZone, DeleteZone, CreateZoneTagsRequest, EditTagZoneRequest, DeleteTagZoneRequest, UpdateCoverZone, RemoveCoverZone } from "./request";
import { PropsCreateTagsZone, ZonaTypeInformation } from "./type";
import { ZonaContext } from "../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../contexts/Zone/Zone/type";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import queryClient from "../../../services/react-query";
import { Toast } from "primereact/toast";
import { Alert } from "../../../components/Alert";

export const ControllerInformation = ({
  toast,
}: {
  toast?: RefObject<Toast>;
}) => {
    const history = useNavigate();
  interface PropsUpdateZone {
    body: ZonaTypeInformation;
    id: string;
  }

  interface PropsDeleteZone {
    id: string;
  }

  const { refetch } = useContext(ZonaContext) as ZonaContextType;

  const UpdateZoneMutation = useMutation(
    ({ body, id }: PropsUpdateZone) => UpdateZone(id, body),
    {
      onMutate: () => {
      },
      onError: (error: any) => {
         Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
      },
      onSuccess: (result, veriables, context) => {
        Alert({
          detail: "Zona editada!",
          severity: "success",
          summary: "Sucesso",
          toast: toast
        }).show();
        queryClient.refetchQueries("UseZoneIdBff")
        refetch();
      },
    }
  );

  const DeleteZoneMutation = useMutation(
    ({ id }: PropsDeleteZone) => DeleteZone(id),
    {
      onMutate: () => {
      },
      onError: (error: any) => {
         Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
      },
      onSuccess: (result, veriables, context) => {
        Swal.fire({
          title: "Zona Excluida com sucesso!",
          icon: "success",
          showConfirmButton: false,
          timer: 2000
        });
        history("/home");
      },
    }
  );

  interface PropsUpdateAvatarZone {
    file?: File;
    id: string;
  }

  const UpdateAvatarZoneMutation = useMutation(
    ({ file, id }: PropsUpdateAvatarZone) => UpdateAvatarZone(id, file),
    {
      onMutate: () => {},
      onError: (error: any) => {
         Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
      },
      onSuccess: (result, veriables, context) => {
        Alert({
          detail: "Imagem salva!",
          severity: "success",
          summary: "Sucesso",
          toast: toast
        }).show();
        queryClient.refetchQueries("UseZoneIdBff")
        refetch();
      },
    }
  );

  interface PropsRemoveAvatarZone {
    id: string;
  }

  const RemoveAvatarZoneMutation = useMutation(
    ({ id }: PropsRemoveAvatarZone) => RemoveAvatarZone(id),
    {
      onMutate: () => {},
      onError: (error: any) => {
         Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
      },
      onSuccess: (result, veriables, context) => {
        Alert({
          detail: "Imagem removida!",
          severity: "success",
          summary: "Sucesso",
          toast: toast
        }).show();
        queryClient.refetchQueries("UseZoneIdBff")
        refetch();

      },
    }
  );

  interface PropsUpdateCoverZone {
    body: File;
    id: string;
  }

  const UpdateCoverZoneMutation = useMutation(
    ({ body, id }: PropsUpdateCoverZone) => UpdateCoverZone(id, body),
    {
      onMutate: () => {},
      onError: (error: any) => {
         Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
      },
      onSuccess: (result, veriables, context) => {
        Alert({
          detail: "Capa salva!",
          severity: "success",
          summary: "Sucesso",
          toast: toast
        }).show();
        queryClient.refetchQueries("UseZoneIdBff")
        refetch();
      },
    }
  );

  interface PropsRemoveCoverZone {
    id: string;
  }

  const RemoveCoverZoneMutation = useMutation(
    ({ id }: PropsRemoveCoverZone) => RemoveCoverZone(id),
    {
      onMutate: () => {},
      onError: (error: any) => {
         Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
      },
      onSuccess: (result, veriables, context) => {
        Alert({
          detail: "Capa removida!",
          severity: "success",
          summary: "Sucesso",
          toast: toast
        }).show();
        queryClient.refetchQueries("UseZoneIdBff")
        refetch();
        window.location.reload()
      },
    }
  );

  const CreateZoneTagsMutation = useMutation((data: PropsCreateTagsZone) => CreateZoneTagsRequest(data), {
    onMutate: () => {
      //   setLoading(true); 
      //   queryClient.refetchQueries()
      },
      onError: (error: any) => {
         Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();

          console.log(error)
      },
      onSuccess: (result: any, variables, context) => {
        Alert({
          detail: "Tag criada!",
          severity: "success",
          summary: "Sucesso",
          toast: toast
        }).show();
        refetch()
      },
  })

  interface PropsUpdateTag {
    data: {name: string},
    id: string
}

  const UpdateTagZoneMutation = useMutation(({data, id}: PropsUpdateTag) => EditTagZoneRequest(data, id), {
    onMutate: () => {
    },
    onError: (error: any) => {
       Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
    },
    onSuccess: (result: any) => {
      Alert({
        detail: "Tag editada!",
        severity: "success",
        summary: "Sucesso",
        toast: toast
      }).show();
      refetch();
    },
});

const DeleteTagZoneMutation = useMutation((id: string) => DeleteTagZoneRequest(id), {
  onMutate: () => {
  },
  onError: (error: any) => {
     Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
  },
  onSuccess: (result: any) => {
    Alert({
      detail: "Tag removida!",
      severity: "success",
      summary: "Sucesso",
      toast: toast
    }).show();
      refetch()
  },
});

  return {
    UpdateZoneMutation,
    UpdateAvatarZoneMutation,
    RemoveAvatarZoneMutation,
    DeleteZoneMutation,
    CreateZoneTagsMutation,
    UpdateTagZoneMutation,
    DeleteTagZoneMutation,
    UpdateCoverZoneMutation,
    RemoveCoverZoneMutation
  };
};
