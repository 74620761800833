import styled from 'styled-components';



export const StyledColumn = styled.div`
  background-color: blue;
  width: 350px;
  height: 500px;
  max-height: 500px;
  border-radius: 0.375rem; 
  display: flex;
  flex-direction: column;
`;

export const StyledInter = styled.header`
  background-color: var(--mainBackgroundColor); /* Defina a variável de cor principal */
  color: var(--textColor); /* Defina a variável de cor do texto */
  font-size: 1rem; /* Tamanho do texto */
  height: 60px;
  min-width: 100%;
  cursor: grab;
  border-radius: 0.375rem; 
  border-bottom-left-radius: 0;
  padding: 0.75rem; 
  font-weight: bold;
  border: 4px solid var(--columnBackgroundColor); 
`;

export const StyledColumnJust = styled.div`
  border-radius: 0.375rem; /* Equivalente a rounded-md */
  display: flex; /* Equivalente a flex */
  flex-direction: column; /* Equivalente a flex-col */
`;


export const StyledListCard = styled.div`
  display: flex;

  flex-grow: 1;
  flex-direction: column;
  gap: 1rem; /* Gap-4 em Tailwind, multiplicado por 0.25rem */
  padding: 0.5rem; /* P-2 em Tailwind, multiplicado por 0.25rem */
  overflow-x: hidden;
  overflow-y: auto;
`;


export const StyledDragging = styled.div`
  opacity: 0.7; /* Correspondente a opacity-30 */
  background-color: #f0f0f0; /* Substitua #f0f0f0 pela cor desejada */ 
  border-width: 2px; /* Correspondente a border-2 */
  width: 350px; /* Correspondente a w-[350px] */
  height: 500px; /* Correspondente a h-[500px] */
  max-height: 500px; /* Correspondente a max-h-[500px] */
  border-radius: 0.375rem; /* Correspondente a rounded-md */
  border: 2px solid #7f64aa;
  cursor: grab; /* Correspondente a cursor-grab */
  position: relative;
`;

export const StyledDraggingCard = styled.div`
  opacity: 0.7; /* Correspondente a opacity-30 */
  background-color: #f0f0f0; /* Substitua #f0f0f0 pela cor desejada */ 
  border: 2px solid #7f64aa;

  padding: 0.625rem; /* Correspondente a p-2.5 */
  height: 100%; /* Correspondente a h-[100px] */
  min-height: 120px;
  max-height: 142px; /* Correspondente a min-h-[100px] */
  align-items: center; /* Correspondente a items-center */
  display: flex;
  text-align: left; /* Correspondente a text-left */
  border-width: 2px; /* Correspondente a border-2 */
  border-radius: 0.75rem; /* Correspondente a rounded-xl */
  cursor: grab; /* Correspondente a cursor-grab */
  position: relative;
`;

export const StyledParagraph = styled.div`
  margin-top: auto; /* Correspondente a my-auto */
  height: 90%; /* Correspondente a h-[90%] */
  width: 100%; /* Correspondente a w-full */
  overflow-y: auto; /* Correspondente a overflow-y-auto */
  overflow-x: hidden; /* Correspondente a overflow-x-hidden */
  white-space: pre-wrap; /* Correspondente a whitespace-pre-wrap */
`;

export const StyledButton = styled.button`
  position: absolute;
  /* right: 1rem; */
  top: 50%; 
  transform: translateY(-50%); /* Correspondente a -translate-y-1/2 */
  background-color: rgba(0, 0, 0, 0.4); /* Correspondente a bg-columnBackgroundColor */
  /* padding: 0.5rem; */
  border-radius: 0.375rem; /* Correspondente a rounded */
  opacity: 0.6; /* Correspondente a opacity-60 */
  transition: opacity 0.3s ease; /* Transição de opacidade ao passar o mouse */
  cursor: pointer;

  &:hover {
    opacity: 1; /* Correspondente a hover:opacity-100 */
  }
`;