import { RefObject, useContext } from "react";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { SubzonaContext } from "../../../contexts/Subzone/Subzone/context";
import { SubzonaContextType } from "../../../contexts/Subzone/Subzone/type";
import {
  DeleteSubZone,
  RemoveAvatarSubZone,
  UpdateAvatarSubZone,
  UpdateSubZone,
} from "./request";
import { SubZonaTypeInformation } from "./type";
import queryClient from "../../../services/react-query";
import { Toast } from "primereact/toast";
import { Alert } from "../../../components/Alert";

export const ControllerInformation = ({
  toast,
}: {
  toast?: RefObject<Toast>;
}) => {
  const history = useNavigate();

  const { id } = useParams();
  interface PropsUpdateSubZone {
    body: SubZonaTypeInformation;
    id: string;
  }

  const { refetch } = useContext(SubzonaContext) as SubzonaContextType;

  const UpdateSubZoneMutation = useMutation(
    ({ body, id }: PropsUpdateSubZone) => UpdateSubZone(id, body),
    {
      onMutate: () => {},
      onError: (error: any) => {
         Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
      },
      onSuccess: (result, veriables, context) => {
        Alert({
          detail: "SubZona editada!",
          severity: "success",
          summary: "Sucesso",
          toast: toast
        }).show();
        queryClient.refetchQueries("UseSubzoneBff")
        refetch();
      },
    }
  );

  const DeleteSubZoneMutation = useMutation((id: string) => DeleteSubZone(id), {
    onMutate: () => {},
    onError: (error: any) => {
       Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
    },
    onSuccess: (result, veriables, context) => {
      Alert({
        detail: "SubZona Excluida!",
        severity: "success",
        summary: "Sucesso",
        toast: toast
      }).show();
      history(`/zona/${id}/painel`);
      queryClient.refetchQueries("UseSubzoneBff")

      queryClient.refetchQueries("UseZoneIdBff");
      // window.location.reload();
    },
  });

  interface PropsUpdateAvatarSubZone {
    file: File;
    id: string;
  }

  const UpdateAvatarSubZoneMutation = useMutation(
    ({ file, id }: PropsUpdateAvatarSubZone) => UpdateAvatarSubZone(id, file),
    {
      onMutate: () => {},
      onError: (error: any) => {
         Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
      },
      onSuccess: (result, veriables, context) => {
        Alert({
          detail: "Imagem Salva!",
          severity: "success",
          summary: "Sucesso",
          toast: toast
        }).show();
        queryClient.refetchQueries("UseSubzoneBff")
      },
    }
  );

  interface PropsRemoveAvatarSubZone {
    id: string;
  }

  const RemoveAvatarSubZoneMutation = useMutation(
    ({ id }: PropsRemoveAvatarSubZone) => RemoveAvatarSubZone(id),
    {
      onMutate: () => {},
      onError: (error: any) => {
         Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
      },
      onSuccess: (result, veriables, context) => {
        Alert({
          detail: "Imagem Removida!",
          severity: "success",
          summary: "Sucesso",
          toast: toast
        }).show();
        queryClient.refetchQueries("UseSubzoneBff")
      },
    }
  );

  return {
    UpdateSubZoneMutation,
    UpdateAvatarSubZoneMutation,
    RemoveAvatarSubZoneMutation,
    DeleteSubZoneMutation,
  };
};
