import { Toast } from "primereact/toast";
import { useRef } from "react";

const useChatToast = () => {
  const toast = useRef<Toast>(null);

  const showSuccess = () => {
    toast.current?.show({
      severity: "success",
      summary: "Sucesso",
      detail: "Texto copiado para a área de transferência",
      life: 2000,
    });
  };

  const showError = () => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: "Falha ao copiar o texto",
      life: 2000,
    });
  };

  return { toast, showSuccess, showError };
};

export default useChatToast;
