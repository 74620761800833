import styled from "styled-components";
import styles from "../../../ludz.one_ui/styles";

export const UserContainer = styled.div`

    cursor: pointer;
    padding: 8px;
    &:hover{
        border-radius: 8px;
        background-color: rgba(127, 100, 170, 0.30);
        color: ${styles.colors.gray}; 
    }
`;
