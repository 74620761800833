import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Input, Tooltip } from "../../../../../../../../ludz.one_ui";
import * as C from "../style";

interface PropsChecklistEdit {
  handleUpdateChecklists: any;
  item: any;
}

const ChecklistEdit = ({
  handleUpdateChecklists,
  item,
}: PropsChecklistEdit) => {
  const [isediting, setIsEditing] = useState(false);

  const { idcard } = useParams();

  return (
    <>
      {isediting ? (
        <Formik
          initialValues={{ description: item.description }}
          onSubmit={(values) => {
            handleUpdateChecklists(item.id, {
              kanbanCard: parseInt(idcard!),
              isToShow: false,
              description: values.description,
            });
            setIsEditing(false);
          }}
        >
          {({ values, handleChange }) => {
            return (
              <Form
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "95%",
                }}
              >
                <Input
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  style={{ marginRight: "10px" }}
                />
                <C.Cancel onClick={() => setIsEditing(false)}>
                  <i id="cancel" className="pi pi-times"></i>
                </C.Cancel>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Tooltip title={item?.description}>
          <h4 onClick={() => setIsEditing(true)} style={{ cursor: "pointer" }}>
            {item?.description.length > 30
              ? item?.description.substring(0, 30) + "..."
              : item?.description}
          </h4>
        </Tooltip>
      )}
    </>
  );
};

export default ChecklistEdit;
