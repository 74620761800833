import { useContext, useState } from "react";
import { SubzonaContext } from "../../../../../../../../contexts/Subzone/Subzone/context";
import { SubzonaContextType } from "../../../../../../../../contexts/Subzone/Subzone/type";
import { Input, ModalInsiderKanbanCard } from "../../../../../../../../ludz.one_ui";
import User from "./User";

interface PropsModalMembers {
    visible: boolean,
    setvisible: any,
}

const ModalMembers = ({ visible, setvisible }: PropsModalMembers) => {
    const { subzone } = useContext(
        SubzonaContext
    ) as SubzonaContextType;



    const [searchUser, setSearchUser] = useState('')

    var UserSearch: any = subzone?.members;

    if (searchUser !== "") {
        const buscaLowerCase = searchUser.toLowerCase();

        UserSearch = UserSearch.filter((member: any) =>
            member.user.name.toLowerCase().includes(buscaLowerCase)
        );
    }
    return (
        <ModalInsiderKanbanCard visible={visible} title={"Membros"} onOpen={() => setvisible(!visible)}>
            <Input
                style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                }}
                onChange={(e: any) => setSearchUser(e.target.value)}
                placeholder="Pesquisar membros"
            />
            <h5 style={{ color: '#808080', marginBottom: '10px' }}>Membros do projeto</h5>

            {UserSearch.map((item: any, index: number) => {
                return (

                    <User item={item} key={index}/>
                )
            })}
        </ModalInsiderKanbanCard>

    )
}

export default ModalMembers