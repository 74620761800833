import { createContext } from "react";
import { ZonaInfoContextType } from "./type";
import { ZoneInfoState } from "./state";

export const ZonaInfoContext = createContext<ZonaInfoContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const ZonaInfoProvider = ({ children }: Props) => {
  const {
    HandleSaveAvatarInfo,
    zonename,
    setZoneName,
    zonedescription,
    setZoneDescription,
    HandleSaveInfo,
    HandleRemoveAvatarInfo,
    HandleDeleteZone,
    isediting,
    setIsEditing,
    initialValues,
    HandleCreateTag,
    schema,
    initialValuesTags,
    visibleTag,
    setVisibleTag,
    tags,
    setTags,
    handleEditTag,
    DeleteTag,
    toast,
    HandleSaveCoverZone,
    HandleRemoveCoverZone,
    fileAvatar,
    setFileAvatar,
    fileCover,
    setFileCover,
  } = ZoneInfoState();

  return (
    <ZonaInfoContext.Provider
      value={{
        HandleSaveAvatarInfo,
        zonename,
        setZoneName,
        zonedescription,
        setZoneDescription,
        HandleSaveInfo,
        HandleRemoveAvatarInfo,
        HandleDeleteZone,
        isediting,
        setIsEditing,
        initialValues,
        HandleCreateTag,
        schema,
        initialValuesTags,
        visibleTag,
        setVisibleTag,
        tags,
        setTags,
        handleEditTag,
        DeleteTag,
        toast,
        HandleSaveCoverZone,
        HandleRemoveCoverZone,
        fileAvatar,
        setFileAvatar,
        fileCover,
        setFileCover
      }}
    >
      {children}
    </ZonaInfoContext.Provider>
  );
};

export default ZonaInfoProvider;
