import { Button } from "primereact/button";
import {Container} from "./style";
import { useContext, useRef } from "react";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components";
import { Toast } from "primereact/toast";
import LudzDropdown from "../../../../../ludz.one_ui/Dropdown/Dropdown";
import { PrivateRoutesContext } from "../../../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType, Zone } from "../../../../../contexts/PrivateRoutes/types";
import { Tooltip } from "../../../../../ludz.one_ui";


interface Props {
    value: any;
    setValue: any;
    selectedZone: any; 
    setSelectedZones: any;
    resetFilters: () => void
}


const ModalNotifyFilter = ({value, setValue, selectedZone, setSelectedZones, resetFilters}: Props) => {
    const menuLeft: any = useRef(null);
    const toast: any = useRef<Toast>(null);
    const arraytest = ['Todos', 'Lidas', 'Não Lidas'];

    const { zonesProvider } = useContext(
        PrivateRoutesContext
      ) as PrivateRoutesContextType;

    const handleBlockClick = (event: any) => {
        event.stopPropagation();
      };

      let zones = zonesProvider?.map(item => item.zone)

      let allzones: Zone = {
          name: "Sem Filtro", id: "ALL",
          price: 0,
          description: "",
          aws_fk: 0,
          isPublic: false,
          default: false,
          zoneType: 0,
          schedule: undefined,
          createdAt: "",
          updatedAt: "",
          user_owner_fk: 0,
          aws: undefined,
          qntMembers: 0,
          qntSubZones: 0
      };

      if (Array.isArray(zones)) {
        zones = [allzones, ...zones]
      }
    

    const items: MenuItem[] = [
        {
            template: (item, options) => {
                return (
                  <Padding padding="8px">
                    <h4 style={{ textAlign: "center" }}>
                      Escolha uma opção de filtragem
                    </h4>
                  </Padding>
                );
              },
        },
        {
          template: (item, options) => {
            // filtro de zonas
            return (
              <Padding padding="6px 8px" onClick={handleBlockClick}>
                <LudzDropdown
                  label="Zonas"
                  value={selectedZone}
                  onChange={(e: any) => setSelectedZones(e.value)}
                  options={zones}
                  optionLabel="name"
                  placeholder="Zonas"
                  style={{
                    fontStyle:
                    selectedZone?.name === "Todas as zonas"
                        ? "italic"
                        : "normal",
                  }}
                />
              </Padding>
            );
          },
        },
        {
            template: () => {
                return (
                    <Padding padding="6px 8px">
                        <LudzDropdown
                        label="Tipos de notificação"
                        options={arraytest}
                        onChange={(e: any) => setValue(e.value)}
                        value={value}
                        
                        />
                    </Padding>
                )
            }
        },
        {
          template: (item, options) => {
            // botão de ação de limpar
            return (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                <Tooltip title="limpar filtragem">
                  <Button
                    size="small"
                    label="Limpar"
                    rounded
                    outlined
                    style={{ marginTop: "8px" }}
                    onClick={() => {
                      setValue('Todos')
                      setSelectedZones("")
                      resetFilters()
                    }}
                  />
                </Tooltip>
              </div>
            );
          },
        },
    ]


    return (
        <Container>
            <Toast ref={toast}></Toast>
            <Menu
                model={items}
                popup
                ref={menuLeft}
                id="popup_menu_left"
                style={{ width: "280px", padding: "8px", borderRadius: "16px" }}
             />
             
            <Button
                icon="pi pi-filter"
                rounded
                /* outlined={habilitebutton} */
                className="mr-2"
                onClick={(event) => menuLeft.current.toggle(event)}
                aria-controls="popup_menu_right"
                aria-haspopup
            />
        </Container>
    )
}

export default ModalNotifyFilter