import { createContext } from "react";
import { EditPasswordContextType } from "./type";
import { StateEditPassword } from "./state";

export const EditPasswordContext = createContext<EditPasswordContextType | null>(
  null
);

interface Props {
  children: React.ReactNode;
}

const EditPasswordProvider = ({ children }: Props) => {
  const { EditPasswordSchema, initialValues, HandleEditPassword, toast } =
    StateEditPassword();

  return <EditPasswordContext.Provider value={{ EditPasswordSchema, initialValues, HandleEditPassword, toast }}>{children}</EditPasswordContext.Provider>;
};


export default EditPasswordProvider;