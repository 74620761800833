import { ButtonAlternative, GridAwnsers } from "../IAstyles";


export interface OptionsAwnsers {
  icon: string;
  color: string;
  awnser: string;
}

interface ChatOptionsProps {
  handleSendButton: (text: string) => void;
  optionsAwnsers: OptionsAwnsers[]
}

const ChatOptions = ({ handleSendButton, optionsAwnsers }: ChatOptionsProps) => {
  return (
    <GridAwnsers>
      {optionsAwnsers.map((item: OptionsAwnsers, index: number) => (
        <ButtonAlternative key={index}>
          <i className={item.icon} style={{ color: item.color }}></i>
          <p onClick={() => handleSendButton(item.awnser)}>{item.awnser}</p>
        </ButtonAlternative>
      ))}
    </GridAwnsers>
  );
};

export default ChatOptions;
