import styled from "styled-components";
import styles from "../../../../ludz.one_ui/styles";

export const ContentCard = styled.div`
    padding: 4px;
    border-radius: 16px;
    cursor: pointer;
    :hover {
        background-color: ${styles.colors.grayClearOneHover};
    }
`;