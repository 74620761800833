import { useContext, useEffect, useState } from "react";
import { ZonaContext } from "../../../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../../../contexts/Zone/Zone/type";
import { Icon, Progress, Tooltip } from "../../../../../ludz.one_ui";
import { PropsGetBffSession, PropsSessionContent } from "../../../../../ludzoneSDK/Zone/Session/type";
import ContentText from "./ContentText";
import CreateSessionContents from "./ContentText/CreateContent";
import { IconCircler } from "./style";

interface PropsBodySession {
    item: PropsGetBffSession
}

const BodySession = ({item}: PropsBodySession) => {
    const [visibleText, setVisibleText] = useState<any>(false)
    const { adminorowner } = useContext(ZonaContext) as ZonaContextType
    const [sessionContents, setSessionContents] = useState<PropsSessionContent[]>([])


    useEffect(() => {
        if (item) {
            setSessionContents(item.contents)
        }
    }, [item])
    

    return (
        <>
            {sessionContents ?
                <>
                    {sessionContents!.map((item: PropsSessionContent) => {
                        // State to control the visibility of the ConfirmationDialog for each item
                        return (
                            <ContentText item={item} key={item.id} sessionContents={sessionContents} set={setSessionContents} />
                        )
                    })}
                </>
                : <Progress />}
            {adminorowner ? (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                    }}
                >
                    <Tooltip title="Adicionar conteúdo">
                        <IconCircler
                            style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setVisibleText(item);
                            }}
                        >
                            <Icon size="1.2rem" icon="pi pi-plus" />
                        </IconCircler>
                    </Tooltip>
                </div >
            ) : null}

            {
                sessionContents ?
                    <>
                        <CreateSessionContents visible={visibleText} onOpen={() => setVisibleText(!visibleText)} index={sessionContents!.length} data={sessionContents} set={setSessionContents} />
                    </>
                    : null
            }
        </>
    )
}

export default BodySession;