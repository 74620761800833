import { idUser, login, logout } from "../../services/localStorage";
import queryClient from "../../services/react-query";
import { LoginRequest } from "./request";
import { useMutation } from "react-query";
import { PropsLogin } from "./types";
import { useNavigate } from "react-router-dom";

export const LoginController = ({ setLoading, setError }: any) => {
  const history = useNavigate();

  const LoginMutation = useMutation((data: PropsLogin) => LoginRequest(data), {
    onMutate: () => {
      setLoading(true);
      queryClient.refetchQueries();
    },
    onError: (error: any) => {
      setError(error.response.data.message);
      setLoading(false);
    },
    onSuccess: (result, variables, context) => {
      setLoading(false);
      logout();
      login(result.data.access_token);
      idUser(result.data.user.id);

      if (result.data.user.role === "ADMIN") {
        window.location.href =
          process.env.REACT_APP_PATH_FRONT_ADMIN +
          `#/pass/${result.data.access_token}`;
        // window.location.reload()
      }
      if (result.data.user.role === "BUSINESS") {
        window.location.href =
          process.env.REACT_APP_PATH_FRONT_BUSINESS +
          `pass/${result.data.access_token}`;
        // window.location.reload()
      }
      if (result.data.user.role === "USER") {
        history("/home");
        window.location.reload();
      }

      setTimeout(() => {
        logout();
        // return swal({
        //   title: "Sessão expirada!",
        //   text: "Sua sessão expirou, faça o login novamente!",
        //   icon: "warning",
        //   confirm: true,
        // }).then((willDelete) => {
        //   if (willDelete) {
        //     window.location.reload();
        //   }
        // });
      }, 259200000);
    },
  });

  return { LoginMutation };
};
