import { Avatar, Icon } from "../../../ludz.one_ui";
import { Column, Row } from "../../../styles/globalStyles";
import { UserContainer } from "./style";

interface PropsMember{
    id?: number,
    nome?: string,
    avatar?: string | null,
    isAdmin?: boolean,
    isOwner?: boolean
}

const User = ({avatar, id, isAdmin, nome, isOwner} : PropsMember) => {
    return (
        <UserContainer >
            <Row id="space-between">
                <Row>
                    <Avatar shape="circle" img={avatar ?? avatar} label={!avatar ? nome?.substring(0,1) : null} size="normal"></Avatar>
                    <Column id="center">
                        <h4>{nome}</h4>
                    </Column>
                </Row>
                {isAdmin && <Column id="center">
                    <Icon  style={{ color: isOwner ? 'orange': ''}} icon={"pi pi-star-fill"} />
                </Column>}
            </Row>
        </UserContainer>
    )
}

export default User;