import styled from "styled-components";
import styles from "../../../../../ludz.one_ui/styles";

export const Card = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  position: relative;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
  background: #edf0f3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  h1 {
    font-size: 18px;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  @media (max-width: 360px) {
    h1{
      font-size: 16px;
    }
  }
`;

interface PropsLabel {
  color: string
}

export const Label = styled.span<PropsLabel>`
  width: 30px;
  height: 10px;
  transform: translateX(-4px);
  border-radius: 20px;
  display: inline-block;
  background: ${({ color }) => color};
`;

export const ObjectiveLine = styled.div<PropsLabel>`
  background: ${(props) => props.color};
  width: 100%;
  height: 3px;
  margin-top: 5px;
  border-radius: 50px;
`;

interface PropsStatus {
  isStatus?: boolean
}
export const Status = styled.div<PropsStatus>`
color: white;
border-radius: 8px;
font-weight: 700;
height: 24px;
width: 24px;
background-color: ${props => props.isStatus ? styles.colors.greenSucess : styles.colors.yellow};
`;

interface PropsItemDate {
  color?: boolean
}

export const ItemDate = styled.div<PropsItemDate>`
   display: flex;
  align-self: flex-end;
  background: ${props => props.color ? "#f5fffb" : styles.colors.yellow};
  justify-content: center;
  border-radius: 20px;
  font-size: 13px;
  padding: 4px 8px;
  align-items: center;

  i {
    margin-right: 5px;
  }

  @media (max-width: 360px) {
    width: 40%;
    font-size: 10px;
    i {
      height: 16px;
    }
  }
`;