import { InputSwitch } from 'primereact/inputswitch';
import { Padding } from '../styles/styled-components';


interface PropsLudzInputSwitch {
    checked: boolean,
    onChange?: any,
    style?: React.CSSProperties
    label?: string,
    name?: string,
    value?: any
}

const LudzInputSwitch = ({ checked, onChange, style, label, name, value }: PropsLudzInputSwitch) => {
    return (
        <div>
            {label ? <>
                <label>{label}</label>
                <Padding padding="2px" />
            </> : null}
            <InputSwitch value={value} name={name} checked={checked} onChange={onChange} style={{...style}}/>
        </div>
    );
}

export default LudzInputSwitch;
