import { useMutation } from "react-query";
import queryClient from "../../../../../services/react-query";
import { DeleteSubzoneCommentsKanbanCardRequest, SubzoneCommentsKanbanCardRequest } from "./request";
import { PropsKanbanCardComments } from "./type";

export const CommetsKanbanCarController = () => {

    const KanbanCardCommentsSubzoneMutation = useMutation((data: PropsKanbanCardComments) => SubzoneCommentsKanbanCardRequest(data), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            console.log(error)
        },
        onSuccess: (result) => {
            queryClient.refetchQueries("UseSubzoneKanbanbff")
            queryClient.refetchQueries("UseSubzoneKanbanCard")

        },
    });

    const DeleteKanbanCardCommentsSubzoneMutation = useMutation((id: number) => DeleteSubzoneCommentsKanbanCardRequest(id), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            console.log(error)
        },
        onSuccess: (result) => {
            queryClient.refetchQueries("UseSubzoneKanbanbff")
            queryClient.refetchQueries("UseSubzoneKanbanCard")

        },
    });
    return{
        KanbanCardCommentsSubzoneMutation, DeleteKanbanCardCommentsSubzoneMutation
    }
}