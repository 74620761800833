import styled from "styled-components";

export const ImageModal = styled.figure`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;

  input {
    display: none;
  }

  label {
    position: relative;
    height: 64px;
    width: 64px;
  }

  label .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: 0.5s ease;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */
  }

  label:hover .overlay {
    opacity: 1;
  }
`;
