
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { PrivateRoutesContext } from "../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../../contexts/PrivateRoutes/types";
import InviteZonaProvider from "../../../contexts/Zone/InviteZone/context";
import { ZonaContext } from "../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../contexts/Zone/Zone/type";
import { OverlayPanelLudz } from "../../../ludz.one_ui";
import ModalInvite from "../../../ludz.one_ui/Modais/ModalInvite";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { useFetchUserAll } from "../../../ludzoneSDK/User/query";
import Item from "../Item";
import User from "../User";
import {
  Container
} from "../style";
import OverlayPanelUserMember from "./OverlayPanel";


const AccordionZone = () => {

  const { zone, openMenu } = useContext(ZonaContext) as ZonaContextType

  const { container } = useParams();

  const [viewdUser, setViewdUser] = useState(false);
  const [visible, setVisible] = useState(false);

  const { data: user } = useFetchUserAll()

  const { adminorowner } = useContext(ZonaContext) as ZonaContextType

  const { userProvider } = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;

  const isDefault = () => {
    if (zone?.default) {
      if (userProvider?.role === "ADMIN") {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  return (
    <Container>
      <Padding padding="32px 16px" style={{ display: openMenu === false ? "" : "none" }}>
        <Padding padding="8px" />
        <Item animation path={`/ludzia`} active={container === "ludzia" ? "true" : "false"} text="LudzFlow" />
        <Padding />
        <Item icon="pi pi-info-circle" path="/painel" active={container === "painel" ? "true" : "false"} text="Painel" />
        <Padding />
        <Item icon="pi pi-book" path="/timeline" active={container === "timeline" ? "true" : "false"} text="Conteúdo" />
        <Padding />
        <Item icon="pi pi-comment" path="/mural" active={container === "mural" ? "true" : "false"} text="Mural" />
        <Padding />
        {isDefault() ? <><Item icon="pi pi-sitemap" path="/status-subzona" pathadd="/criar-subzona" add active={container === "status-subzona" || container === "criar-subzona" ? "true" : "false"} text="Subzonas" />
        <Padding /> </>: null}
        {isDefault() ? <Item icon="pi pi-users" onClick={() => setViewdUser(!viewdUser)} add={adminorowner} onClickAdd={(e: any) => { e.stopPropagation(); setVisible(!visible) }} active={viewdUser.toString()} text="Membros" />
          : null}
        <Padding />
        {viewdUser && zone ? <>
          {zone.zoneMembers.map((item, index) => {
           
            return (
              <div key={index}>
                {(adminorowner && !(zone.user_owner_fk === item.user.id)) ? <OverlayPanelLudz components={<OverlayPanelUserMember item={item} />} key={index}
                styleButton={{width: "100%"}}
                  button={
                      <User id={item.user.id} avatar={item.user.aws.url} isAdmin={item.isAdmin} nome={item.user.username} />
                  } /> : <User key={index} id={item.user.id} avatar={item.user.aws.url} isAdmin={item.isAdmin} isOwner={(zone.user_owner_fk === item.user.id)} nome={item.user.username} />}
              </div>
            )
          })}
        </> : null}
        {user ? <InviteZonaProvider><ModalInvite users={user} onOpen={() => setVisible(!visible)} visible={visible} /></InviteZonaProvider> : null}

      </Padding>
    </Container>
  );
};

export default AccordionZone;

