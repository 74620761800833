import http from "../../../../../services/api";
import { logout } from "../../../../../services/localStorage";
import { PropsKanbanCardChecklist, PropsKanbanCardChecklistItems } from './type';

export const SubzoneChecklistKanbanCardRequest = async (body: PropsKanbanCardChecklist) => {
    return await http.post("/direct/kanban-card-checklists", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const UpdateSubZoneChecklistsKanbanCardRequest = async (id:string | null, body: PropsKanbanCardChecklist) => {;
    return await http.put(`/direct/kanban-card-checklists/${id}`, body).then(response => response.data)
    .catch(err => {
         if (err.response.status === 401) {
              logout()
              window.location.reload()
         }
         alert(err.response.data.message)
         throw err;
    })
}


export const DeleteSubzoneChecklistKanbanCardRequest = async (id: string) => {
    return await http.delete(`/direct/kanban-card-checklists/${id}`).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const SubzoneChecklistItemsKanbanCardRequest = async (body: PropsKanbanCardChecklistItems) => {
    return await http.post("/direct/kanban-card-checklist-items", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const UpdateSubZoneChecklistItemsKanbanCardRequest = async (id:string | null, body: PropsKanbanCardChecklistItems) => {;
    return await http.put(`/direct/kanban-card-checklist-items/${id}`, body).then(response => response.data)
    .catch(err => {
         if (err.response.status === 401) {
              logout()
              window.location.reload()
         }
         alert(err.response.data.message)
         throw err;
    })
}

export const DeleteSubzoneChecklistItemsKanbanCardRequest = async (id: string) => {
    return await http.delete(`/direct/kanban-card-checklist-items/${id}`).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}