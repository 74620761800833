import { createGlobalStyle, styled } from "styled-components";
import styles from "../ludz.one_ui/styles";

const GlobalStyle = createGlobalStyle`
@layer primereact {
  

   * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
        font-family: 'Quicksand', 'Poppins',sans-serif;
    }
    html, body, #root, .App {
        height: 100%;
        width: 100%;
        background-color: var(--white);
        color: #333;
        -webkit-font-smoothing: antialiased !important;
        scroll-behavior: smooth;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .p-accordion-header-text{
      width: 100%;
    }
    .alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
    
    color: ${styles.colors.primary};
    }

    .p-overlaypanel .p-overlaypanel-content {
    padding: 0;
}
    .alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
    background-color: ${styles.colors.primary};
}

    @font-face {
  font-family: "Poppins Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Regular"),
    url("./../font/Poppins-Regular.woff") format("woff");
}

@font-face {
  font-family: "Poppins ExtraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins ExtraLight"),
    url("../font/Poppins-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "Poppins Light";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Light"), url("../font/Poppins-Light.woff") format("woff");
}

@font-face {
  font-family: "Poppins SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins SemiBold"),
    url("../font/Poppins-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Bold"), url("../font/Poppins-Bold.woff") format("woff");
}

@font-face {
  font-family: "Inter Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Inter Regular"), url("../font/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Varela Round";
  font-style: normal;
  font-weight: normal;
  src: local("Varela Round"), url("../font/VarelaRound-Regular.ttf") format("truetype");
}



    /* ul {
        list-style: none;
    } */

    :root {
        --primary: #7F64AA;
        --secondary: #006A58;
        --tertiary: #33B270;
        --quaternary:#201630;
        --white: #fff;
        --gray: #8a8c8a;
        --whiteButton: #E0E0E0;
        --hoverGrayButton: #C4C4C4;
        --font-familyP: 'Renogare', 'Roboto',sans-serif;
        --font-familyS: 'Quicksand', 'Poppins'sans-serif;
    }
  }
`;

export default GlobalStyle;

export const Column = styled.div<PropsRowColumn>`
  display: flex;
  flex-direction: column;

  justify-content: ${(props) => props.id};
`;

interface PropsRowColumn {
  id?: "center" | "start" | "end" | "space-between";
}

export const Row = styled.div<PropsRowColumn>`
  display: flex;
  flex-direction: row;
  gap: 5px;

  justify-content: ${(props) => props.id};
`;

export const RowReverse = styled.div<PropsRowColumn>`
  display: flex;
  flex-direction: row;
  gap: 5px;

  @media (max-width: 770px) {
   flex-direction: column;
  }

  justify-content: ${(props) => props.id};
`;

interface PropsGrid {
  checkMockup: Array<any>
}

export const Grid = styled.div<PropsGrid>`
    display: grid;
    column-gap: 15px;
    padding: 16px;
    grid-template-areas: "CD CD CD";
    grid-template-columns: ${(props) =>
        props.checkMockup.length === 4 ? "repeat(4, 24.5%)" : props.checkMockup.length === 3 ? "repeat(3, 33%)" : props.checkMockup.length === 2 ? "repeat(2, 50%)" : "repeat(2, 50%)"};

    @media (max-width: 1200px) {
        grid-template-areas: "CD CD";
        grid-template-columns: ${(props) =>
        props.checkMockup ? "repeat(2, 50%)" : "repeat(1, 100%)"};
    }

    @media (max-width: 800px) {
        grid-template-areas: "CD";
        padding-left: 1%;
        grid-template-columns: ${(props) =>
        props.checkMockup ? "repeat(1, 100%)" : "repeat(1, 100%)"};
    }


    @media (max-width: 360px) {
        padding-left: 0px;
        padding-right: 0px;
    }
  
`;


