import http from "../../../services/api";
import { logout } from "../../../services/localStorage";
import { uploadArchive } from "../../../services/upload-archives";
import { SubZonaTypeInformation } from "./type";

export const UpdateSubZone = async (id:string | null, body: SubZonaTypeInformation) => {
    return await http.put(`/direct/sub-zone/${id}`, body).then(response => response.data)
    .catch(err => {
         if (err.response.status === 401) {
              logout()
              window.location.reload()
         }
         alert(err.response.data.message)
         throw err;
    })
}

export const DeleteSubZone = async (id:string | null) => {
    return await http.delete(`/bff/sub-zone-bff/${id}`).then(response => response.data)
    .catch(err => {
         if (err.response.status === 401) {
              logout()
              window.location.reload()
         }
         alert(err.response.data.message)
         throw err;
    })
}

export const UpdateAvatarSubZone = async (id:string | null, file: File) => {
     return await uploadArchive(file, `/direct/sub-zone/${id}`, {}, "PUT")
}

export const RemoveAvatarSubZone = async (id: string | null) => {
    return await http.put(`/bff/sub-zone-bff/default-avatar/${id}`).then(response => response.data)
    .catch(err => {
         if (err.response.status === 401) {
              logout()
              window.location.reload()
         }
         alert(err.response.data.message)
         throw err;
    })
}