import React, { useState } from "react";
import MenuPrime from "../../../../Menu/MenuPrime";
import ConfirmationDialog from "../../../../Diaolog/ConfrimationDIalog/indes";

// import { Container } from './styles';

interface Props {
  data: any;
  handleDeletePostComment: any;
  setEdit?: any
}

const DeleteComments = ({ data, handleDeletePostComment, setEdit }: Props) => {
  const [visible, setvisible] = useState(false);
  let items = [
    {
        label: "Editar",
        icon: "pi pi-fw pi-pencil",
        command: () => setEdit(true),
      },
    {
      label: "Excluir",
      icon: "pi pi-fw pi-trash",
      command: () => setvisible(!visible),
    },
  ];

  return (
    <div>
      <MenuPrime items={items} />
      <ConfirmationDialog
        accept={() => handleDeletePostComment({ id: data.id })}
        visible={visible}
        reject={() => setvisible(true)}
        message="Deseja Excluir?"
        onHide={() => setvisible(false)}
      />
    </div>
  );
};

export default DeleteComments;
