import { useMutation } from "react-query";
import { DeleteSubzoneArchivesRequest, SubzoneArchivesRequest } from "./request";
import queryClient from "../../../services/react-query";

export const SubzoneArchivesController = () => {


    const CreateSubZoneArchivesMutation = useMutation(({data, id}: {data: File, id: string}) => SubzoneArchivesRequest(data, id), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            console.log(error)
        },
        onSuccess: (result, variables, context) => {
            queryClient.refetchQueries("UseSubzoneBff")

        },
    });

    const DeleteSubZoneArchivesMutation = useMutation((id: string) => DeleteSubzoneArchivesRequest(id), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            console.log(error)
        },
        onSuccess: (result, variables, context) => {
            queryClient.refetchQueries("UseSubzoneBff")
        },
    });


    return {
        CreateSubZoneArchivesMutation, DeleteSubZoneArchivesMutation
    }
}