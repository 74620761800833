import { createContext } from "react";
import { ObjectiveKeySubzonaContextType } from "./type";
import { ObjectivekeySubzoneState } from "./state";

export const ObjectiveKeySubzonaContext = createContext<ObjectiveKeySubzonaContextType | null>(null);

interface Props {
    children: React.ReactNode
}


const ObjectiveKeySubonaProvider = ({ children }: Props) => {

    const { handleObjectiveKey, initialValue, schema, setId, id, handleUpdateObjectiveKey, handleDeleteObjectivekey, color, setColor, toast} = ObjectivekeySubzoneState()


    return (
        <ObjectiveKeySubzonaContext.Provider value={{ handleObjectiveKey, initialValue, schema, setId, id,handleUpdateObjectiveKey, handleDeleteObjectivekey, color, setColor, toast }}>
            {children}
        </ObjectiveKeySubzonaContext.Provider>
    )
}

export default ObjectiveKeySubonaProvider;