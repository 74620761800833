import React, { useContext } from 'react';
import { ZonaInfoContext } from '../../../../../contexts/Zone/ZoneInfo/context';
import { ZonaInfoContextType } from '../../../../../contexts/Zone/ZoneInfo/type';
import { Button, Input, Modal } from '../../../../../ludz.one_ui';
import { Form, Formik } from 'formik';
import { Column, Row } from '../../../../../styles/globalStyles';
import { Padding } from '../../../../../ludz.one_ui/styles/styled-components';

// import { Container } from './styles';

interface PropsEditTag {
    visible?: boolean,
    onOpen?: any,
    item?: any, 
}

const EditTags = ({ visible, onOpen, item }: PropsEditTag) => {
    const { handleEditTag } = useContext(ZonaInfoContext) as ZonaInfoContextType;
    
    const id = item.id.toString()

    return (
        <Modal visible={visible} onOpen={onOpen} title={"Editar Tag"}>
        <Formik initialValues={{ name: item.name ?? "" }} onSubmit={(values) => { handleEditTag(values, id); onOpen() }}>
            {({ values, handleChange, errors, touched, handleSubmit }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        <Column>
                            <Input name="name" value={values.name} onChange={handleChange} label="Nome da tag" />
                        </Column>
                        <Padding padding="16px" />
                        {errors.name && touched.name ? (
                            <div style={{ color: "red" }}>{errors.name.toString()}</div>
                        ) : null}
                        <Padding />
                        <Row id="center">
                            <Button title="Editar" type="button" onClick={(e: any) => {
                                e.stopPropagation()
                                handleEditTag(values, id)
                                onOpen()
                            }} />
                        </Row>
                    </Form>
                )
            }}
        </Formik>

    </Modal>
    );
}

export default EditTags;