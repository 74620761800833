import styled from "styled-components";

import styles from "../../ludz.one_ui/styles";



export const ContainerLogin = styled.div`
  min-height: 100vh;
  height: 100%;
  background-size: cover;
  background-color: rgba(127, 100, 170, 0.09);
  background-repeat: no-repeat;
`;

export const P = styled.p`
  font-family: ${styles.typography.types.fontfamilyS};
  font-size: 14px;
  font-weight: 600;
  color: ${styles.colors.grayClear};
`;

export const Box = styled.div`
  height: auto;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  position: relative;
  @media (max-width: 1000px) {
    width: 40%
  }
  @media (max-width: 750px) {
    width: 70%
  }

  @media (max-width: 600px) {
    width: 80%
  }

`;

export const Scroll = styled.div`
  overflow-y: scroll;
  
  ::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: rgba(235, 235, 235, 0.8); /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }

   max-height: 300px;
`;

export const LinkText = styled.p`
  font-family: ${styles.typography.types.fontfamilyS};
  font-size: 14px;
  color: ${styles.colors.secondary};
  /* text-decoration: none; */
  font-weight: 600;

  cursor: pointer;
  :hover {
  /* text-decoration: underline; */
}
`;
