import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { useRef, useState } from 'react';
import LudzTooltip from '../Tooltip';
import { Column, Padding, Row } from '../styles/styled-components';
import { useMediaQuery } from '@mui/material';

const LudzFileUpload = ( {setFile}: any ) => {
    const larguraTela = window.innerWidth;
    const toast = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);
    const matchesMobile = useMediaQuery("(max-width: 520px)")

    const onTemplateSelect = (e: any) => {
        let _totalSize = totalSize;
        let files = e.files;
       
        Object.keys(files).forEach((key) => {
            setFile(files[key])
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e: any) => {
        let _totalSize = 0;

     
        e.files.forEach((file: any) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        // toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    const onTemplateRemove = (file: any, callback: any) => {
        setTotalSize(totalSize - file.size);
        setFile(undefined)
        callback();
    };

    const onTemplateClear = () => {
        setFile(undefined)
        setTotalSize(0);
    };

    const headerTemplate = (options: any) => {
        const { className, chooseButton, cancelButton } = options;
        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {cancelButton}
            </div>
        );
    };

    const itemTemplate = (file: any, props: any) => {



        return (
            <div className="flex align-items-center flex-wrap">
                <div className={matchesMobile ? "flex" : "flex align-items-center"} style={{ flexDirection: matchesMobile ? 'column' : 'row' }}>
                    {
                        file.objectURL && !(larguraTela < 780) ?
                            <img alt={file.name} role="presentation" src={file.objectURL} width={80} />
                            : !file.objectURL && !(larguraTela > 780) ? <i className="pi pi-file" style={{ 'fontSize': '1.5em' }}></i> : null
                    }
                    <Column id='center'>
                        <LudzTooltip arrow title={file.name}>
                            <h4 className={(larguraTela < 780) ? "flex flex-column text-left" : "flex flex-column text-left ml-3"}>
                                {(larguraTela < 780) ? `${file.name.substring(0, 15)}...` : file.name}
                            </h4>
                        </LudzTooltip>
                    </Column>
                </div>
                {/* <Tag value={props.formatSize} severity="warning" className="px-3 py-2" /> */}
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto ml-2" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <Column id='center'>
                <Row id='center'>
                    <Padding padding="16px">
                        <Column>
                            <Row id='center'>
                                <i className="pi pi-image mt-2 p-3" style={{ fontSize: '2em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                            </Row>
                            <Padding />
                            <span style={{ fontSize: '0.9em', color: 'var(--text-color-secondary)' }} className="my-5">
                                Arraste um arquivo
                            </span>
                        </Column>
                    </Padding>


                </Row>
            </Column>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };


    return (
        <div>
            <Toast ref={toast}></Toast>

            <Tooltip target=".custom-choose-btn" content="Fazer upload de um arquivo" position="bottom" />
            <Tooltip target=".custom-cancel-btn" content="Limpar" position="bottom" />

            <FileUpload ref={fileUploadRef} name="demo[]" maxFileSize={1000000}
                onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
        </div>
    )
}

export default LudzFileUpload;