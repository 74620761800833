import { createContext } from "react";
import { StateHome } from "./status";
import { HomeContextType, PropsHomeProvider } from "./type";

export const HomeContext = createContext<HomeContextType | null>(null);

const HomeContextProvider = ({ children }: PropsHomeProvider) => {
    const { user, errorUser, isLoadingUser } = StateHome()
    return (
        <HomeContext.Provider
            value={{ user, errorUser, isLoadingUser }}
        >
            {children}
        </HomeContext.Provider>
    )
}

export default HomeContextProvider;
