import styled from "styled-components";

export const ContainerList = styled.div`
  padding: 16px;
  width: 300px;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  white-space: normal;
  margin-right: 10px;
  height: auto;

  header {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    font-family: var(--font-familyS);
    line-height: 23px;
    color: #58595b;
    padding: 0 8px;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 5px;
  }

  ul {
    padding: 6px 1px;
    height: fit-content;
    margin: 20px 0px;
    max-height: 70vh;
    overflow-y: auto;

    header {
      position: absolute;
      top: -25px;
      left: 15px;
      display: flex;
      justify-content: space-between;
      width: calc(100% - 20px);
      justify-content: flex-end;
      font-size: 16px;

      svg {
        cursor: pointer;
        transform: translateY(-2px);
        color: black !important;
      }
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      display: flex;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
      background: rgba(9, 30, 66, 0.08);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #aaa;
      border-radius: 10px;
    }

    @media (max-height: 800px) {
      max-height: 58vh;
    }
  }

  @media (max-width: 360px) {
    -webkit-flex: 0 0 220px;
    -ms-flex: 0 0 220px;
    flex: 0 0 220px;
    width: 244px;

    header {
      font-size: 16px;
    }
  }
`;

export const CardStyle = styled.div`
  font-weight: 500;
  height: 100%;
  
  width: 100%;
  max-height: 160px;
  overflow-y: auto;
  line-height: 20px;
  user-select: none;
  cursor: grab;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
  background: #edf0f3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  border: 2px solid #edf0f3; // Alterado de 'transparent' para '#edf0f3'

  &:hover {
    border: 2px solid #7f64aa; // Alterado de 'transparent' para '#edf0f3'
  }

  h1 {
    font-size: 18px;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  @media (max-width: 360px) {
    h1 {
      font-size: 16px;
    }
  }
`;
