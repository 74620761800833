import { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import * as Yup from 'yup';
import { MuralSubzoneController } from "../../../ludzoneSDK/Subzone/PostMural/controller";
import { PropsSubZonePostComment, PropsSubzonePost, SubZonaTypePost, SubZonaTypePostComment } from "../../../ludzoneSDK/Subzone/PostMural/type";
import { Toast } from 'primereact/toast';


export const MuralSubzoneState = () => {

    const {idSubzone} = useParams()

    const [posts, setPosts] = useState();
    const [file, setFile] = useState();

    const toast = useRef<Toast>(null)

    const { PostSubzoneMutation, postsRequests, UpdateSubZonePostMutation, DeletePostSubZoneMutation, PostSubZoneCommentMutation, UpdateSubZonePostCommentMutation, DeletePostCommentSubZoneMutation } = MuralSubzoneController(parseInt(idSubzone!), {toast: toast})

    const initialValue = {
        content: "",
        subZone: 0,
        mentions: []
    }

    const initialValueEdit: SubZonaTypePost = {
        content: ""
      }


      const initialValueComment: SubZonaTypePostComment = {
        content: "",
        mentions: []
      }

    useEffect(() => {
     if(postsRequests){
        setPosts(postsRequests)
     }
    }, [postsRequests])
    

    const schema = Yup.object().shape({
        content: Yup.string().required('A descrição é obrigatória'),
      });
      
    const handlePost = (data: PropsSubzonePost, id: number) => {
            PostSubzoneMutation.mutate({body: {content: data.content.toString(),mentions: data.mentions, subZone: id }, file: file})
    }

    const handleEditPost = (idpost: string, body: SubZonaTypePost, setEdit: any) => {
        UpdateSubZonePostMutation.mutate({ body: body, id: idpost! });
        setEdit(false);
      
      }

      
  const handleDeletePost = (idpost: any) => {
    

    DeletePostSubZoneMutation.mutate({ id: idpost.idpost });
  }

// Estados de comentários

const handleSendComments = (idpost: number, data: PropsSubZonePostComment) => {
  const body = {
    subZonePost: idpost,
    content: data.content,
    mentions: data.mentions
  }


  PostSubZoneCommentMutation.mutate(body);
}

const handleEditPostComment = (id: string, body: SubZonaTypePostComment, setEdit: any) => {
  UpdateSubZonePostCommentMutation.mutate({ body: body, id: id! });
  setEdit(false);
}

const handleDeletePostComment = (id: any) => {

  DeletePostCommentSubZoneMutation.mutate({ id: id.id.toString() });
}

    return {
        handlePost, initialValue, schema, posts, setFile, handleEditPost, initialValueEdit, handleDeletePost, handleSendComments, initialValueComment, handleEditPostComment, handleDeletePostComment, toast
    }
}