
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';


interface PropsMenuPrime {
    items: MenuItem[],
    width?: string
}
export default function MenuPrime({items, width}: PropsMenuPrime) {
   
    return (
        <div className="flex justify-content-center">
            <Menu style={{width: width}} model={items} />
        </div>
    )
}
        