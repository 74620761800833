import React from 'react';
import ViewHTML from '../ViewHTML';

// import { Container } from './styles';

interface PropsActivities {
    item: any;
    key: any

}

const Activities = ({ item, key }: PropsActivities) => {


    const months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];

      const formatDate = () => {
        if (item.createdAt) {
          const date = new Date(item.createdAt);
    
          var day: any = date.getDate();
    
          if (day < 10) {
            day = "0" + day;
          }
    
          var postDate = day + " de " + months[date.getMonth()].substring(0, 3);
          return postDate;
        } else {
          return "Não possui data";
        }
      };

  return (
    <li className={item?.archives.length === 0 ?  "green" : "blue" }>
    <i className="pi pi-circle-on"></i>
    <div className="event-content">
      <span className="event-title">
       Autor: {item?.owner?.username}
      </span>
      <span>
        <ViewHTML item={item?.content.toString()} />
      </span>
      {item?.archives ? 
      item.archives.map((item: any, index:any) => {
        return (
          <span>
            <i>Arquivo</i> - {item?.original_name}
          </span>
        )
      })
      : null}
      <span className="time">{formatDate()}</span>
    </div>
  </li>
    );
}

export default Activities;