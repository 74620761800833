import { ControllerSubZoneUser } from "../../../ludzoneSDK/Subzone/SubzoneUser/controller"
import { TypeMembersSubZone } from "../../../ludzoneSDK/Subzone/SubzoneUser/type"

export const MemberZoneState = () => {

    const { CreateSubZoneUserMutation } = ControllerSubZoneUser()



    const handleMember = (data: TypeMembersSubZone) => {
        CreateSubZoneUserMutation.mutate(data)
    }


    return {
        handleMember
    }
}