import { useMutation } from "react-query";
import queryClient from "../../../../../services/react-query";
import { PropsKanbanCardLabels } from "./type";
import { DeleteSubzoneLabelsKanbanCardRequest, SubzoneLabelsKanbanCardRequest } from "./request";

export const LabelsKanbanCarController = () => {

    const KanbanCardLabelsSubzoneMutation = useMutation((data: PropsKanbanCardLabels) => SubzoneLabelsKanbanCardRequest(data), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            console.log(error)
        },
        onSuccess: (result) => {
            queryClient.refetchQueries("UseSubzoneKanbanbff")
            queryClient.refetchQueries("UseSubzoneKanbanCard")

        },
    });

    const DeleteKanbanCardLabelsSubzoneMutation = useMutation((id: number) => DeleteSubzoneLabelsKanbanCardRequest(id), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            console.log(error)
        },
        onSuccess: (result) => {
            queryClient.refetchQueries("UseSubzoneKanbanbff")
            queryClient.refetchQueries("UseSubzoneKanbanCard")

        },
    });

    return {
        KanbanCardLabelsSubzoneMutation, DeleteKanbanCardLabelsSubzoneMutation
    }
}