import { useContext } from "react"
import { SubzonaInfoContext } from "../../../../contexts/Subzone/SubzoneInfo/context"
import { SubZonaInfoContextType } from "../../../../contexts/Subzone/SubzoneInfo/type"
import { Button, Modal, UploadFile } from "../../../../ludz.one_ui"
import { Padding, Row } from "../../../../ludz.one_ui/styles/styled-components"
import { useParams } from "react-router"

interface PropsDashbordModal {
    visible?: boolean,
    onOpen?: any
}


const DashbordModalFiles = ({ visible, onOpen}: PropsDashbordModal) => {

   
    const {idSubzone} = useParams()
    const {setFiles, HandleArchivesSubzone} = useContext(SubzonaInfoContext) as SubZonaInfoContextType
    return (
        <Modal visible={visible} onOpen={onOpen} title={"Anexar ao Painel"}>
           <UploadFile setFile={setFiles} />
           <Padding padding="16px" />
           <Row id="center">
                <Button title="Enviar" onClick={() => {HandleArchivesSubzone(parseInt(idSubzone!)); onOpen()}} />
           </Row>
        </Modal>
    )
}

export default DashbordModalFiles