import React from "react";
import { Dropdown } from 'primereact/dropdown';
import { Column, Padding } from "../styles/styled-components";
import { SelectItemOptionsType } from "primereact/selectitem";

interface PropsDropdown {
    options?: SelectItemOptionsType,
    value?: any,
    label?: string,
    optionLabel?: string,
    placeholder?: string,
    onChange?: any, name?: string, style?: React.CSSProperties | undefined
}

const LudzDropdown = ({ options, value, onChange, placeholder, label, optionLabel, name, style }: PropsDropdown) => {

    return (
        <Column>
            {label ? <label>{label}</label> : null}
            <Padding />
            <Dropdown name={name} style={{ ...style, width: "100%" }} defaultValue={value} value={value} onChange={onChange} options={options} optionLabel={optionLabel}
                placeholder={placeholder} />
        </Column>
    )
}

export default LudzDropdown;