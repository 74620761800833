import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { KanbanCardSubzonaContext } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context";
import { KanbanCardSubzonaContextType } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type";
import { Button, EditorText, Icon } from "../../../../../../../ludz.one_ui";
import { Padding } from "../../../../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../../../../styles/globalStyles";
import { AddDescription } from "./style";
import ViewHTML from "../../../../../../../components/ViewHTML";

interface PropsDescriptionCard {
    description: string
}

const DescriptionCard = ({ description }: PropsDescriptionCard) => {
    const [visible, setVisible] = useState(false)
    const [charCount, setCharCount] = useState(0);
    const [descriptionState, setdescriptionState] = useState(description)
    const { handleUpdateCard } = useContext(KanbanCardSubzonaContext) as KanbanCardSubzonaContextType
    const { idcard } = useParams()

    const editorText = (e: any) => {
        const currentContent = e.editor.getHTML();
        const charCount = e.editor.getCharacterCount();
      
        if (charCount <= 500) {
            setdescriptionState(currentContent);
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = currentContent;
            const textContent = (tempDiv.textContent || '').trim()
              setCharCount(textContent.length);
        } else {
          // Remove o último caractere inserido para manter o limite
          const truncatedContent = descriptionState.slice(0, -1);
          e.editor.commands.setContent(truncatedContent);
          setdescriptionState(truncatedContent);
          setCharCount(500); // Atualiza o contador de caracteres para refletir o limite
        }
      };

      useEffect(() => {
        const tempDiv = document.createElement('div');
            tempDiv.innerHTML = descriptionState;
            const textContent = (tempDiv.textContent || '').trim()
              setCharCount(textContent.length);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [charCount])

    return (
        <Column>
            <Row>
                <Row>
                    <Column id="center">
                        <Icon icon="pi pi-align-left" size="1rem" />
                    </Column>
                    <Padding padding="1px" />
                    <Column id="center">
                        <h3>Descrição</h3>
                    </Column>
                </Row>
                <Padding padding="1px" />
            </Row>
            <Padding />
            
            {visible ?
                <Column>
                    <EditorText required value={descriptionState} onTextChange={(e: any) => editorText(e)} />
                    <p style={{ alignSelf: 'flex-end' }} className="font-semibold">{charCount} / 500</p>
                    <Padding />
                    <Row>
                        <Button title="Salvar" onClick={() => { handleUpdateCard({ description: descriptionState }, parseInt(idcard!)); setVisible(!visible) }} />
                        <Button title="Cancelar" text onClick={() => setVisible(!visible)} />
                    </Row>
                </Column> : <AddDescription onClick={() => setVisible(!visible)}><ViewHTML item={description} /></AddDescription>}
        </Column>
    )
}

export default DescriptionCard;