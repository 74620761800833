import { useContext } from "react";
import { useParams } from "react-router-dom";
import WriteHere from "../../../../components/Mural/WriteHere";
import PostMural from "../../../../components/Mural/post";
import { PostZonaContext } from "../../../../contexts/Zone/MuralZone/context";
import { PostZonaContextType } from "../../../../contexts/Zone/MuralZone/type";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import { Container } from "../../../style";
import { ZonaContext } from "../../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../../contexts/Zone/Zone/type";
import { Progress } from "../../../../ludz.one_ui";
import LudzScrollTop from "../../../../ludz.one_ui/LudzScrollTop";
import { Toast } from "primereact/toast";

const MuralZone = () => {
  const {
    handlePost,
    initialValue,
    posts,
    handleEditPost,
    handleDeletePost,
    handleSendComments,
    setFile,
    initialValueEdit,
    initialValueComment,
    handleEditPostComment,
    handleDeletePostComment,
    toast
  } = useContext(PostZonaContext) as PostZonaContextType;

  const { adminorowner, zone } = useContext(ZonaContext) as ZonaContextType;
  const { id } = useParams();

  const postReverse = posts ? [...posts].reverse() : [];

  return (
    <Container>
      <Toast ref={toast} />
      {adminorowner && zone && (
        <WriteHere
          members={zone!.zoneMembers}
          handle={handlePost}
          initialValues={initialValue}
          setFile={setFile}
          id={parseInt(id!)}
          isZone={true}
        />
      )}
      {!adminorowner && postReverse.length === 0 && (
        <h1>Mural sem publicações</h1>
      )}
      <Padding padding="16px" />
      {posts ? (
        <>
          {postReverse.map((item: any, index: number) => {
            return (
              <PostMural
                members={zone!.zoneMembers}
                key={index}
                data={item}
                admin={adminorowner}
                handleEditPostComment={handleEditPostComment}
                handleEditPost={handleEditPost}
                initialValueEdit={initialValueEdit}
                initialValueComment={initialValueComment}
                handleSendComments={handleSendComments}
                handleDeletePost={handleDeletePost}
                handleDeletePostComment={handleDeletePostComment}
              />
            );
          })}
        </>
      ) : (
        <Progress />
      )}
      <LudzScrollTop style={{ right: "0.5%", padding: "15px" }} />
    </Container>
  );
};

export default MuralZone;
