import React, { useEffect, useState } from 'react';
import { PropsGetBffSession, PropsSessionContent } from '../../../../ludzoneSDK/Zone/Session/type';
import { Progress } from '../../../../ludz.one_ui';
import ContentText from '../ContentText';

// import { Container } from './styles';

interface PropsBodySession {
    item: PropsGetBffSession
}

const BodySession = ({item}: PropsBodySession) => {
    const [sessionContents, setSessionContents] = useState<PropsSessionContent[]>([])

    useEffect(() => {
        if (item) {
            setSessionContents(item.contents)
        }
    }, [item])

  return (
    <>
    {sessionContents ? 
        <>
            {sessionContents.map((item: PropsSessionContent) => {
                return (
                    <ContentText item={item} key={item.id} />
                )
            })}
        </>
    : <Progress />}
    </>
);
}

export default BodySession;