import { useMutation } from "react-query";
import {
  DeleteSessionWatchedZoneRequest,
  SessionZoneWatchedRequest,
} from "./request";
import queryClient from "../../../../services/react-query";

export const SessionWatchedZoneController = () => {
  const SessionWatchedZoneMutation = useMutation(
    (data: { sessionContentId: number }) => SessionZoneWatchedRequest(data),
    {
      onMutate: () => {},
      onError: (error: any) => {
        console.log(error);
      },
      onSuccess: (result: any) => {
        queryClient.refetchQueries("UseZoneSessionWatchedBff");
      },
    }
  );

  const DeleteSessionWatchedContentsZoneMutation = useMutation(
    (id: string) => DeleteSessionWatchedZoneRequest(id),
    {
      onMutate: () => {},
      onError: (error: any) => {
        console.log(error);
      },
      onSuccess: (result: any) => {
        queryClient.refetchQueries("UseZoneSessionWatchedBff");
      },
    }
  );

  return {
    SessionWatchedZoneMutation,
    DeleteSessionWatchedContentsZoneMutation,
  };
};
