import SignUpProvider from "../../../contexts/User/SignUp/context";
import SignUpScreen from "../../../screens/User/SignUp";

const SignUp = () => {

    return (
        <SignUpProvider>
            <SignUpScreen />
        </SignUpProvider>
    )
}

export default SignUp;