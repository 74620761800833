import ListArchives from "../../../../components/Archives/ListArchives";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import { useFetchFileContent } from "../../../../ludzoneSDK/Channel/Content/query";
import { ArchivesType } from "../../../../ludzoneSDK/Channel/Content/type";

const Archives = ({ data }: { data: any }) => {
  const { data: files } = useFetchFileContent(data?.id!);

  return (
    <>
      <div>
        <Padding padding="8px"/>
        <h4>Arquivos</h4>
        <Padding />
        {files?.map((item: ArchivesType, index: number) => {
          return <div ><ListArchives item={item} /><Padding /></div>;
        })}{" "}
      </div>
    </>
  );
};

export default Archives;
