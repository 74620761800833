import { Grid, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import "primeicons/primeicons.css";
import { useRef, useState } from "react";
import * as Yup from "yup";
import { Button, EditorText, Modal, Tooltip } from "../../../ludz.one_ui";
import { ButtonPrimeIcon } from "../../../ludz.one_ui/Buttons/ButtonPrimeIcon";
import Chip from "../../../ludz.one_ui/Chip";
import LudzFileUpload from "../../../ludz.one_ui/FileUpload";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../styles/globalStyles";
import LudzModalMentions from "../../Mentions/ModalMentions";
import { AreaOptions, ButtonAdd, NewPost } from "./style";
import { Toast } from "primereact/toast";

interface PropsWriteHere {
  initialValues: any;
  handle: (data: any, id: number) => void;
  id?: number;
  setFile?: any;
  members?: any;
  isZone?: boolean | undefined
}

const WriteHere = ({
  initialValues,
  handle,
  id,
  setFile,
  members,
  isZone
}: PropsWriteHere) => {
  const [display, setDisplay] = useState(false);
  const [modalFile, setModalFile] = useState(false);
  const [mentions, setMentions] = useState(false);
  const [charCount, setCharCount] = useState(0);

  const matches = useMediaQuery("(max-width: 375px)");
  const matchesMobileMedium = useMediaQuery("(max-width: 520px)");

  const toastMention = useRef<Toast>(null);

  const show = () => {
    toastMention.current?.show({ severity: 'warn', summary: 'Atenção!', detail: 'Você precisa digitar para poder marcar alguém!' });
  };

  const schema = Yup.object().shape({
    content: Yup.string().required("A descrição é obrigatória"),
  });

  const editorText = (e: any, setFieldValue: any, values: any) => {
    const currentContent = e.editor.getHTML();
    const charCount = e.editor.getCharacterCount();
  
    if (charCount <= 2000) {
      setFieldValue("content", currentContent);
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = currentContent;
      const textContent = tempDiv.textContent || '';
      setCharCount(textContent.length);
    } else {
      const truncatedContent = values.content.slice(0, -1);
      e.editor.commands.setContent(truncatedContent);
      setFieldValue("content", truncatedContent);
      setCharCount(2000);
    }
  };

  const [visible, setVisible] = useState(false);
  const editorRef = useRef<any>(null);

  const validateContentBeforeMention = (content: string) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    const textContent = tempDiv.textContent || '';
    return textContent.trim().length > 0;
  };

  return (
    <Column style={{ width: "100%" }}>
      <ButtonAdd onClick={() => setDisplay(true)} display={display}>
        <p style={{ marginTop: "10px", marginBottom: "10px" }}>
          Escreva algo na {isZone ? 'Zona' : 'Subzona' }
        </p>
      </ButtonAdd>
      <NewPost display={display}>
        <Formik
          initialValues={initialValues}
          onSubmit={(value, { resetForm, setFieldValue }) => {
            handle(value, id!);
            setDisplay(false);
            resetForm();
            setFieldValue("mentions", []);
            setModalFile(!modalFile);
            setFile(undefined);
            setCharCount(0);
            setFieldValue("content", "");
            editorRef.current?.clearContent();
          }}
          validationSchema={schema}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => {
            const RemoveMarcation = (index: number) => {
              const filterDelete = [
                ...values.mentions.slice(0, index),
                ...values.mentions.slice(index + 1),
              ];
              setFieldValue("mentions", filterDelete);
            };

            return (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Grid container spacing={1}>
                    {values?.mentions?.map((item: any, index: number) => {
                      const filterUser = members?.find(
                        (props: any) => props?.user?.id === item
                      );

                      return (
                        <Grid item key={index}>
                          <Chip
                            onClick={() => RemoveMarcation(index)}
                            avatar={filterUser?.user?.aws?.url}
                            title={filterUser?.user?.username}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Row>
                <Padding />
                <EditorText
                  ref={editorRef}
                  value={values.content}
                  name="content"
                  onTextChange={(e: React.ChangeEvent<HTMLInputElement>) => editorText(e, setFieldValue, values)}
                />
                <p className="font-semibold">{charCount} / 2000</p>
                {mentions &&
                <LudzModalMentions
                  onClose={() => setMentions(false)}
                  members={members}
                  value={values.content}
                  onChange={setFieldValue}
                  ids={values.mentions}
                />
                }
                <Padding />
                {(errors.content && touched.content) &&  
                <div style={{ color: "red" }}>
                  {errors.content.toString()}
                </div>}
                {modalFile &&
                 <Padding padding="10px 0">
                    <LudzFileUpload setFile={setFile} />
                  </Padding>
                }
                
                <AreaOptions>
                  <Row id="start"
                    style={{ marginBottom: matchesMobileMedium ? '10px' : '' }}
                  >
                    
                    <ButtonPrimeIcon
                      title="upload arquivo"
                      className="pi pi-file-import"
                      fontSize="1.2rem"
                      onClick={() => {
                        setModalFile(!modalFile);
                        setFile(undefined);
                      }}
                    ></ButtonPrimeIcon>
                   <Toast ref={toastMention}/>
                    <ButtonPrimeIcon
                      title="marcação"
                      className="pi pi-at"
                      fontSize="1.2rem"
                      onClick={() => {
                        if (validateContentBeforeMention(values.content)) {
                          setMentions(!mentions);
                          setFieldValue("content", values.content + "@");
                        } else {
                          show();
                        }
                      }}
                    ></ButtonPrimeIcon>
                  </Row>
                  {matches &&
                  <>
                    <Padding padding="10px" />
                  </>
                  }
                  <Row id="end">
                    <Tooltip title="Cancelar">
                      <Row>
                        <Button
                          icon="pi pi-times"
                          type="button"
                          style={{ width: "100%", borderRadius: "10px", padding: '15px' }}
                          severity="danger"
                          className="h-3rem"
                          onClick={() => {
                            setDisplay(false);
                            setModalFile(false);
                          }}
                        />
                      </Row>
                    </Tooltip>
                    <Tooltip title="Postar">
                      <Row>
                        <Button
                          style={{ width: "100%", borderRadius: "10px", padding: '15px'}}
                          type="submit"
                          className="h-3rem"
                          icon="pi pi-send"
                        />
                      </Row>
                    </Tooltip>
                  </Row>
                </AreaOptions>
              </form>
            );
          }}
        </Formik>
      </NewPost>
      <Modal visible={visible} onOpen={() => setVisible(!visible)} />
    </Column>
  );
};

export default WriteHere;