import { useQuery } from "react-query";
import { GetOneZoneRequest, GetZoneBffRequest } from "./request";

export const useFetchZoneOne = (id: string | undefined, query: string) => {
    return useQuery(["UseZoneId", id], () => GetOneZoneRequest(id, query));
};

export const useFetchZoneBff = (id: string | undefined) => {
    return useQuery(["UseZoneIdBff", id], () => GetZoneBffRequest(id));
};

