import React, {useState} from "react";


import logoCa  from "../../../assets/images/LogoCamaleão.png"
import logoCaVerde from "../../../assets/images/CamaleãoLudz.png"
import { Button, Logo } from "./style";
import { useMediaQuery } from "@mui/material";

const LogoLudus = (
    logo: any,
) => {
    const [img, setImg] = useState(logoCa)
    const matchesMobileSmall = useMediaQuery("(max-width: 360px)");

    function imgCaVerde() {
        setImg(logoCaVerde);
    }
    function imgCa() {
        setImg(logoCa);
    }
   return(
       
       <Button 
        // onClick={removRoom}
        >
         {logo && 
             <Logo onMouseOver={imgCaVerde}
             onMouseOut={imgCa}>
            <img alt='logo' src={img} style={{width: matchesMobileSmall ? '40px' : '45px'}} 
          />
               </Logo>}
       </Button>
   )
}

export default LogoLudus;