import React from "react";
import { Paginator } from "primereact/paginator";

interface Props {
  first: any,
  totalRecords: any,
  onPageChange: any
  rows: any,
  template: any
}

export default function LudzPaginator({ first, totalRecords, onPageChange, template }: Props) {
  return (
    <div>
      <Paginator
        first={first}
        rows={1}
        totalRecords={totalRecords}
        onPageChange={onPageChange}
        template={template}
      />
    </div>
  );
}
