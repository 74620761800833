import CreateZonaProvider from "../../../contexts/Zone/CreateZone/context"
import CreateZoneScreen from "../../../screens/Zone/CreateZone"

const CreateZone = () => {
    return(
        <CreateZonaProvider>
            <CreateZoneScreen />
        </CreateZonaProvider>
    )
}

export default CreateZone;