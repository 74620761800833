import { Dialog } from "primereact/dialog";
import { Progress, Tooltip } from "../../ludz.one_ui";

// import { Container } from './styles';

interface Props {
  visible: boolean;
  setVisible: any;
  members: any;
}

const AreaMembers = ({ visible, setVisible, members }: Props) => {
  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">+Membros</span>
    </div>
  );

  return (
    <Dialog
      visible={visible}
      modal
      header={headerElement}
      style={{ width: "auto" }}
      onHide={() => setVisible(false)}
    >
      <div className="col-12  desktop-teams">
        <div className="team">
            <div className="peoples">
          {members ? (
            <>
              {members.map((item: any, index: any) => {
                return (
                  <Tooltip title={item?.user?.username} arrow>
                    <img
                      style={{
                        width: "45px",
                        height: "45px",
                        borderRadius: "15px",
                      }}
                      src={item?.user?.aws?.url}
                      alt="freya-layout"
                    />
                  </Tooltip>
                );
              })}
            </>
          ) : (
            <Progress />
          )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AreaMembers;
