import styled from 'styled-components';

export const AreaSobre = styled.div`
  height: 392px;
  overflow: auto;

  @media (max-width: 1024px) {
    height: 497px;
  }
`;
