import MenuLeft from "../../../components/MenuLeft/MenuHome"
import TopBar from "../../../components/Topbar"
import { GridTwoColumn } from "./style"

type PropsLayoutTwoColumn = {
    children?: React.ReactNode;
}

const LayoutTwoColumn = ({children}: PropsLayoutTwoColumn) => {
    return(
        <GridTwoColumn>
            <MenuLeft />
            <TopBar />
            <div style={{ display: "grid", gridArea: "children", overflowY: "auto"}}>{children}</div>
        </GridTwoColumn>
    )
}

export default LayoutTwoColumn;