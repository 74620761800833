import { ProgressSpinner } from "primereact/progressspinner";
import { useContext } from "react";
import { Input } from "../../../ludz.one_ui";
import LudzPaginator from "../../../ludz.one_ui/LudzPaginator";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import * as C from "./style";
import { PrivateRoutesContext } from "../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../../contexts/PrivateRoutes/types";
import { ChannelsPublicContext } from "../../../contexts/Channel/ChannelPublic/context";
import { ChannelPublicContextType } from "../../../contexts/Channel/ChannelPublic/type";
import CardChannel from "../../../components/Cards/CardChannel";

const LIMIT = 12;

export default function ChannelsPublicScreen() {;
  

  const { channelsPublics, page, setPage, refetch, channelall, setInputValue, inputValue } = useContext(
    ChannelsPublicContext
  ) as ChannelPublicContextType;

  

  const props = useContext(PrivateRoutesContext) as PrivateRoutesContextType;

  let filterchannelspublics;

  if (channelsPublics) {
    filterchannelspublics = channelsPublics.filter((itempublic: any) => 
          !props?.userChannel?.some((item: any) => item?.id === itempublic?.id)
      );
  }


  const pages = Math.ceil(channelall / LIMIT);

  const onPageChange = (event: any) => {
    setPage(event.first + 1);
    refetch();
  };

  return (
    <C.Container>
      <C.TitlePublicRoom>Encontre um Canal</C.TitlePublicRoom>
      <span className="p-input-icon-right">
        <i className="pi pi-search" />
        <Input
          className="p-inputtext-lg"
          value={inputValue}
          onChange={(e: any) => setInputValue(e.target.value)}
        />
      </span>
      <Padding padding="20px" />

      {channelsPublics ? (
        <>
          <C.WrapLista checkMockup={filterchannelspublics}>
            {filterchannelspublics.map((item: any, index: any) => {
              return <CardChannel channel={item} key={index} />;
            })}
          </C.WrapLista>
          {filterchannelspublics.length === 0 && <div
              style={{
                margin: "auto",
              }}
            >
              <h1>Sem zonas para serem exibidas</h1>
            </div>}
          <Padding padding="10px"/>
          <div style={{ marginBottom: "12px" }}>
            <LudzPaginator
              first={page - 1}
              rows={1}
              totalRecords={pages}
              onPageChange={onPageChange}
              template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            />
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <ProgressSpinner />
        </div>
      )}
    </C.Container>
  );
}
