import {
  BubbleMenu,
  EditorContent,
  FloatingMenu,
  useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { forwardRef, useImperativeHandle } from "react";
import {
  RiH1,
  RiH2,
  RiListCheck,
  RiListOrdered,
  RiListUnordered,
  RiParagraph,
} from "react-icons/ri";
import {
  RxFontBold,
  RxFontItalic,
  RxStrikethrough
} from "react-icons/rx";
import BubbleButtons from "./BubbleMenu/BubbleButton";
import { Container } from "./style";

import BulletList from "@tiptap/extension-bullet-list";
import { Color } from "@tiptap/extension-color";
import Heading from "@tiptap/extension-heading";
import Link from '@tiptap/extension-link';
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import TextStyle from "@tiptap/extension-text-style";
import { ColorPicker } from "primereact/colorpicker";
import { Column } from "../../styles/globalStyles";
import BubbleLink from "./BubbleMenu/BubbleLink";


const extensions = [
  StarterKit,
  ListItem,
  TextStyle,
  BulletList.configure({
    keepMarks: true,
    keepAttributes: true,
  }),
  Color,
  OrderedList,
  TaskList,
  TaskItem.configure({
    nested: true,
  }),

  Heading.configure({
    levels: [1, 2, 3],
  }),
  Link.configure({
    autolink: false,
  })
];

// const extensions = [StarterKit];

interface PropsEditorText {
  name?: string;
  value: any;
  onSubmit?: React.FormEventHandler<HTMLDivElement> | undefined;
  onTextChange?: any;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  required?: boolean | undefined;
  style?: React.CSSProperties;
}

const EditorText = forwardRef(({
  value,
  onTextChange,
  onKeyDown,
  onSubmit,
  name,
  required,
  style
}: PropsEditorText, ref) => {
  // const content = value;

  const editor = useEditor({
    extensions,
    content: value,
    onUpdate: onTextChange,
  });

  useImperativeHandle(ref, () => ({
    clearContent: () => {
      editor?.commands.clearContent();
    },
  }));

  if(!editor) return null

  return (
    <div>
      <Container className="tiptap" style={{...style}}>
        <EditorContent
          name={name}
          editor={editor}
          onChange={onTextChange}
          onKeyDown={onKeyDown}
          value={value}
        />
        <FloatingMenu editor={editor!}>
          Pressione / para navegar pelas opções
        </FloatingMenu>
        <FloatingMenu
          editor={editor!}
          className="BubbleMenu"
          shouldShow={({ state }) => {
            const from = state?.selection;
            const currentLineText = from?.$from.nodeBefore?.textContent;
            return currentLineText === "/";
          }}
        >
          <BubbleButtons
            isActive={editor.isActive("heading", { level: 1 })}
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }
            icon={<RiH1 size={16} />}
          />
          <BubbleButtons
            isActive={editor.isActive("heading", { level: 2 })}
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
            icon={<RiH2 size={16} />}
          />
          <BubbleButtons
            onClick={() => editor.commands.setParagraph()}
            icon={<RiParagraph size={16} />}
          />
          <BubbleButtons
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            isActive={editor.isActive("bulletList")}
            icon={<RiListUnordered size={16} />}
          />
          <BubbleButtons
            isActive={editor.isActive("orderedList")}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            icon={<RiListOrdered size={16} />}
          />
          <BubbleButtons
            isActive={editor.isActive("taskList")}
            onClick={() => editor.chain().focus().toggleTaskList().run()}
            icon={<RiListCheck size={16} />}
          />
        </FloatingMenu>
        {editor && (
          <BubbleMenu className="BubbleMenu" editor={editor}>
            {/* <BubbleButtons
              name="Text"
              icon={
                <RxChevronDown
                  size={16}
                  onClick={() => editor.chain().focus().toggleBold().run()}
                />
              }
            /> */}
            <BubbleButtons
              isActive={editor.isActive("heading", { level: 1 })}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 1 }).run()
              }
              icon={<RiH1 size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("heading", { level: 2 })}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
              icon={<RiH2 size={16} />}
            />
            <BubbleButtons
              onClick={() => editor.commands.setParagraph()}
              icon={<RiParagraph size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("bold")}
              onClick={() => editor.chain().focus().toggleBold().run()}
              icon={<RxFontBold size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("italic")}
              onClick={() => editor.chain().focus().toggleItalic().run()}
              icon={<RxFontItalic size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("strike")}
              onClick={() => editor.chain().focus().toggleStrike().run()}
              icon={<RxStrikethrough size={16} />}
            />
            
            <BubbleLink editor={editor} />
            <Column id="center" className=" px-2">
              <ColorPicker
                value={editor.getAttributes("textStyle").color}
                pt={{
                  input: { className: "w-1rem h-1rem" },
                }}
                onChange={(e) =>
                  editor
                    .chain()
                    .focus()
                    .setColor(`#` + e.target.value?.toString()!)
                    .run()
                }
              />
            </Column>
            <BubbleButtons
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              isActive={editor.isActive("bulletList")}
              icon={<RiListUnordered size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("orderedList")}
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              icon={<RiListOrdered size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("taskList")}
              onClick={() => editor.chain().focus().toggleTaskList().run()}
              icon={<RiListCheck size={16} />}
            />
          </BubbleMenu>
        )}
      </Container>
      {/* <Editor required={required} maxLength={1000} value={value} onSubmit={onSubmit} name={name} pt={{
                toolbar: { style: { backgroundColor: "#eff3f8" } },
                option: { style: { backgroundColor: "#eff3f8" } },
            }} onTextChange={onTextChange} headerTemplate={header} style={{ height: '256px', backgroundColor: "gray" }} onKeyDown={onKeyDown} tabIndex={0} /> */}
    </div>
  );
});

export default EditorText;
