import styled from "styled-components";

export const WrapCard = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 20px solid rgba(230, 236, 245, 0.4);
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

 
`;