import React from 'react';
import { CirclePicker } from 'react-color';

// import { Container } from './styles';
interface PropsColors {
    color?: string;
    setColor: any
}


const LudzColors = ({ color, setColor }: PropsColors) => {
    const colors = [
        "#1C1C1C",
        "#4F4F4F",
        "#C0C0C0",
        "#00008B",
        "#191970",
        "#0000CD",
        "#1E90FF",
        "#00FFFF",
        "#87CEEB",
        "#006400",
        "#228B22",
        "#008000",
        "#00FF00",
        "#7FFF00",
        "#ADFF2F",
        "#8B4513",
        "#D2691E",
        "#A0522D",
        "#4B0082",
        "#8A2BE2",
        "#8B008B",
        "#FF1493",
        "#F08080",
        "#8B0000",
        "#B22222",
        "#FF0000",
        "#FF4500",
        "#FFA500",
        "#FFFF00",
        "#DEB887",
      ]


  return (
    <CirclePicker
    width="100%"
    colors={colors}
    onChangeComplete={(color: any) => setColor(color.hex)}
  />
    );
}

export default LudzColors;