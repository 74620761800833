
import SessionZonaProvider from "../../contexts/Zone/SessionZone/context";
import ZonePublicProvider from "../../contexts/Zone/UniqueZonePublic/context";
import BuyZoneScreen from "../../screens/Zone/BuyZone";

const BuyZone = () => {
  return (

      <ZonePublicProvider>
      <SessionZonaProvider>
      <BuyZoneScreen />
      </SessionZonaProvider>
      </ZonePublicProvider>

  );
};

export default BuyZone;
