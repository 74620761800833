
import http from "../../../../services/api";
import { logout } from "../../../../services/localStorage";
import { PropsMoveSubzoneKanbanCard, PropsMoveSubzoneKanbanColumn, PropsSubzoneKanbanCard, PropsUpdateKanbanCard } from "./type";

export const SubzoneKanbanCardRequest = async (body: PropsSubzoneKanbanCard) => {
    return await http.post("/direct/kanban-card", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}


export const MoveKanbanCardRequest = async (body: PropsMoveSubzoneKanbanCard) => {
    return await http.put("/bff/kanban-bff", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const MoveKanbanColumnRequest = async (body: PropsMoveSubzoneKanbanColumn) => {
    return await http.put("/bff/kanban-bff/move-column", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}






export const GetSubzoneKanbanRequest = async (id: number) => {
    return await http.get("/bff/kanban-bff", {
        params: {
            subZoneId: id,
        }
    }).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            // alert(err)
            throw err;
        });
} 

export const GetSubzoneKanbaCardRequest = async (id: number) => {
    return await http.get(`/bff/kanban-card-bff/${id}`).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            // alert(err)
            throw err;
        });
} 

export const DeleteCardRequest = async (id: number) => {

    return await http.delete(`/direct/kanban-card/${id}`).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const UpdateKanbanCardRequest = async (body: PropsUpdateKanbanCard, id: number) => {

    return await http.put(`/direct/kanban-card/${id}`, body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}