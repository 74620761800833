import { Formik } from "formik";
import { Button, Input } from "../../../ludz.one_ui";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { Box, ContainerLogin } from "../style"
import { useContext } from "react";
import { ForgetPasswordContext } from "../../../contexts/User/ForgetPassword/context";
import { ForgetPasswordContextType } from "../../../contexts/User/ForgetPassword/type";
import { useParams } from "react-router-dom";
import { Column, Row } from "../../../styles/globalStyles";
import Logo from "../../../assets/images/Camaleão.png";


const ForgetPasswordPage = () => {
    const { id, token } = useParams()
    const { ForgetPasswordSchema, initialValue, ForgetPassword } = useContext(ForgetPasswordContext) as ForgetPasswordContextType
    return (
        <ContainerLogin>
            <Column id='center' style={{ height: "100%" }}>
                <Box style={{ marginTop: "0%" }}>
                    <Row id="center">
                        <Padding padding="16px">
                            <img style={{ width: "100px" }} src={Logo} alt="" />
                        </Padding>
                    </Row>
                    <Formik initialValues={initialValue} validationSchema={ForgetPasswordSchema} onSubmit={(values) => { ForgetPassword({ token: token!, userId: id! }, { newPassword: values.newPassword }) }}>
                        {({ values, errors, touched, handleChange, handleSubmit }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Padding />
                                    <Input
                                        type="password"
                                        name="newPassword"
                                        value={values.newPassword}
                                        onChange={handleChange}
                                        placeholder="Digite nova senha *"
                                        className={(errors.newPassword && touched.newPassword) ? "p-invalid" : ""}
                                    />
                                    <Padding />
                                    {errors.newPassword && touched.newPassword ? (
                                        <div style={{ color: "red" }}>{errors.newPassword}</div>
                                    ) : null}
                                    <Padding />
                                    <Input
                                        type="password"
                                        name="passwordConfirm"
                                        onChange={handleChange}
                                        placeholder="Confirmar nova senha *"
                                        className={(errors.passwordConfirm && touched.passwordConfirm) ? "p-invalid" : ""}
                                    />
                                    <Padding />
                                    {errors.passwordConfirm && touched.passwordConfirm ? (
                                        <div style={{ color: "red" }}>{errors.passwordConfirm}</div>
                                    ) : null}
                                    <Padding padding="16px" />
                                    <Button style={{ width: "100%" }} title="Confirmar" type="submit" />
                                    <Padding padding='16px' />
                                </form>
                            )
                        }}

                    </Formik>
                </Box>
            </Column>

        </ContainerLogin>
    )
}

export default ForgetPasswordPage;