import { Formik } from "formik";
import { Button } from "primereact/button";
import { ChangeEvent, useContext, useState } from "react";
import * as Yup from "yup";
import LudzModalMentions from "../../../../components/Mentions/ModalMentions";
import { PrivateRoutesContext } from "../../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../../../contexts/PrivateRoutes/types";
import LudzAvatar from "../../../Avatar";
import Input from "../../../Inputs/InputText";
import LudzSkeleton from "../../../Skeleton";
import { Column, Padding, Row } from "../../../styles/styled-components";
import { useMediaQuery } from "@mui/material";
import { ButtonPrimeIcon } from "../../../Buttons/ButtonPrimeIcon";

interface PropsComments {
  handleSendComments: any;
  initialValueComment: any;
  data: any;
  members: any;
}

const SendComments = ({
  handleSendComments,
  initialValueComment,
  data,
  members
}: PropsComments) => {
  // const [openModalMention, setOpenMention] = useState(false);
  // const [idusermaked, setIdUserMarked] = useState([]);
  // const [search, setSearch] = useState("");
  const { userProvider } = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;

  let user = userProvider;

  // // Funções para Marcar Usuário

  // const fewMembers = members ?? members.slice(0, 4);

  // const showAllMembers = members ?? members.map((member) => {
  //     return member._id;
  // });

  // const HandleKeyUp = (e) => {
  //     if (e.shiftKey === true && e.keyCode === 50) {
  //         return setOpenMention(true);
  //     } else {
  //         setOpenMention(false);
  //     }
  // };]

  const matches = useMediaQuery("(max-width: 950px)");
  const matchesMobile = useMediaQuery("(max-width: 520px)");

  const schema = Yup.object().shape({
    content: Yup.string().required("A descrição é obrigatória"),
  });

  const [mentions, setMentions] = useState(false);
  const [charCount, setCharCount] = useState(0);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCharCount(event.target.value.length);
  };
  const HandleKeyUp = (e: any) => {
    if (e.shiftKey === true && e.keyCode === 50) {
      return setMentions(true);
    } else {
      setMentions(false);
    }
  };

  return (
    <Padding padding={matches ? "0px" : "10px"}>
      <Formik
        initialValues={initialValueComment}
        onSubmit={(value, { resetForm }) => {
          handleSendComments(data.id!, value);
          resetForm();
          setCharCount(0);
        }}
        validationSchema={schema}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          handleChange,
          errors,
          touched,
        }) => {
          return (
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Row style={{ flexDirection: matchesMobile ? "column" : "row" }}>
                {user ? (
                  <>
                    <Column
                      id="center"
                      style={{ alignSelf: matchesMobile ? "center" : "normal" }}
                    >
                      {user.aws.url ? (
                        <LudzAvatar
                          img={user.aws.url}
                          shape="circle"
                          size="normal"
                        />
                      ) : (
                        <LudzAvatar
                          label={user.name.substring(0, 2)}
                          shape="circle"
                          size="large"
                        />
                      )}
                    </Column>
                    <Padding padding="5px" />

                    <Row
                      style={{
                        width: "100%",
                        flexDirection: matchesMobile ? "column" : "row",
                        marginTop: matchesMobile ? '6px' : '0px'
                      }}
                    >
                      {" "}
                      <>
                      <Input
                        value={values.content}
                        style={{ width: "100%" }}
                        name="content"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChange(e);
                          handleTextChange(e);
                        }}
                        maxLength={255}
                        onKeyDown={HandleKeyUp}
                        placeholder="Escreva seu comentário aqui!"
                        className={
                          errors.content && touched.content ? "p-invalid" : ""
                        }
                      />
                       {matchesMobile &&  <div
                          className="font-semibold"
                          style={{ alignSelf: 'flex-end', marginTop: "4px", marginBottom: '6px' }}
                        >
                          {charCount}/255
                        </div>}
                      </>
                    
                      <ButtonPrimeIcon
                        className="pi pi-at"
                        title="marcação"
                        arrow
                        fontSize="1.2rem"
                        style={{
                          marginLeft: matchesMobile ? '0px' : "8px",
                          marginBottom: matchesMobile ? '6px' : "2px",
                          alignSelf: "center",
                          width: matchesMobile ? '100%': 'auto' 
                        }}
                        onClick={() => setMentions(!mentions)}
                      ></ButtonPrimeIcon>
                      <Button
                        tooltip="Enviar"
                        icon="pi pi-send"
                        type="submit"
                        style={{
                          marginLeft: matchesMobile ? '0px' : "6px",
                          marginBottom: "2px",
                          padding: matchesMobile ? '0.6rem' : "0.20rem 1.25rem",
                          borderRadius: "10px",
                          width: matchesMobile ? '100%': 'auto'
                        }}
                      ></Button>
                    </Row>

                    {/* <LudzInputSimple placeholder="Escreva um comentário" value={newComment} onChange={(e) => setNewComment(e.target.value)} onKeyDown={HandleKeyUp} />
                                    <Button icon="pi pi-send" onClick={() => handleSendComments(data, newComment, setNewComment, idusermaked)} /> */}
                  </>
                ) : (
                  <div className="flex">
                    <LudzSkeleton
                      shape="circle"
                      size="4rem"
                      className="mr-2"
                    ></LudzSkeleton>
                    <div style={{ flex: "1" }}>
                      <LudzSkeleton
                        width="100%"
                        className="mb-2"
                      ></LudzSkeleton>
                      <LudzSkeleton width="75%"></LudzSkeleton>
                    </div>
                  </div>
                )}
              </Row>
              {mentions ? (
                <LudzModalMentions
                  input
                  onClose={() => setMentions(false)}
                  members={members}
                  value={values?.content}
                  ids={values?.mentions}
                  onChange={setFieldValue}
                />
              ) : null}
            </form>
          );
        }}
      </Formik>
      {!matchesMobile && (
        <div
          className="font-semibold"
          style={{ marginLeft: "42px", marginTop: "4px" }}
        >
          {charCount}/255
        </div>
      )}
    </Padding>
  );
};

export default SendComments;
