import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "../../../ludz.one_ui";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../styles/globalStyles";
import { Container, Text } from "./style";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import LudzIA from "../../../assets/LudzIA.json";
import { useEffect, useRef, useState } from "react";

interface PropsItem {
  icon?: string;
  path?: string;
  text?: string;
  active?: string;
  add?: boolean;
  pathadd?: string;
  onClick?: any;
  onClickAdd?: any;
  animation?: boolean;
}

const Item = ({
  icon,
  path,
  text,
  active,
  add,
  pathadd,
  onClick,
  onClickAdd,
  animation,
}: PropsItem) => {
  const { id } = useParams();
  const history = useNavigate();

  const lottieRef = useRef<LottieRefCurrentProps>(null);

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (lottieRef.current) {
      // Ajuste a velocidade da animação para durar 1,5 segundos
      lottieRef.current.setSpeed(1.5);
    }
  }, [lottieRef, isHovered]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (lottieRef.current) {
      lottieRef.current.play(); // Inicia a animação ao entrar no hover
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (lottieRef.current) {
      lottieRef.current.stop(); // Para a animação ao sair do hover
    }
  };

  return (
    <Container
      onClick={
        path
          ? (e: any) => {
              e.preventDefault();
              history(`/zona/${id}${path}`);
            }
          : onClick
      }
      active={active}
    >
      <Padding padding="8px">
        <Row id="space-between">
          <>
            <Text
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Column id="center">
                {animation ? (
                  <Lottie
                    style={{ height: "34px", margin: "-8px" }}
                    className="lottie-avatar"
                    loop={isHovered}
                    animationData={LudzIA}
                  />
                ) : (
                  <Icon size="1.2rem" icon={icon} />
                )}
              </Column>
              <Padding />
              {text}
            </Text>
          </>
          {add ? (
            <Text>
              <Column id="center">
                <Icon
                  size="1.0rem"
                  onClick={
                    pathadd
                      ? (e: any) => {
                          e.stopPropagation();
                          history(`/zona/${id}${pathadd}`);
                        }
                      : onClickAdd
                  }
                  icon=" pi pi-plus"
                />
              </Column>
            </Text>
          ) : null}
        </Row>
      </Padding>
    </Container>
  );
};

export default Item;
