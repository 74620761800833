import http from "../../../services/api"
import { logout } from "../../../services/localStorage";
import { PropsInvites } from "./type"

export const InviteZoneRequest = async (body: PropsInvites) => {
    return await http.post("/direct/invites", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const DeleteInviteZoneRequest = async (id: number) => {
    return await http.delete(`/direct/invites/${id}`).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}


export const GetAllInvites = async () => {
     
    return await http.get(`/direct/invites`).then(response => response.data)
         .catch(err => {
              if (err.response.status === 401) {
                   logout()
                   window.location.reload()
              }
              throw err;
         });
}
