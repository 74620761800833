import { useState } from "react";
import * as Yup from 'yup';
import { CreateUserController } from "../../../ludzoneSDK/User/controller";
import { PropsInitialValue, PropsSignUp } from "./types";

export const StateSignUp = () => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [error, setError] = useState<string | undefined | null>();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loading, setLoading] = useState<Boolean | undefined | null>(false);

    const {SignUpMutation, SignUpInviteZoneMutation} = CreateUserController({setError, setLoading});

    const SignupSchema = Yup.object().shape({
        name: Yup.string()
          .required('Campo Obrigatório'),
        username: Yup.string()
          .min(2, 'Muito curto!')
          .max(50, 'Muito Longo!')
          .required('Campo Obrigatório'),
        email: Yup.string().email('Invalid email').required('Campo Obrigatório'),
        password: Yup.string().required('Campo Obrigatório'),
        passwordConfirm: Yup.string().label('Confirmar senha').required("Campo Obrigatório").oneOf([Yup.ref('password')], 'Senhas diferentes'),
      });

    const initialValues: PropsInitialValue = {
        name: "",
        username: "",
        email: "",
        password: "",
        passwordConfirm: ""
    }

    const handleSignUp = (body: PropsSignUp) => {
        const data = {
            name: body.name,
            username: body.username,
            email: body.email,
            password: body.password
        }
        SignUpMutation.mutate(data)
    }

    const handleSignUpInviteZone = (body: PropsSignUp) => {
      const data = {
          name: body.name,
          username: body.username,
          email: body.email,
          password: body.password,
          token: body.token
      }
      SignUpInviteZoneMutation.mutate(data)
  }

    return{
        initialValues, handleSignUp, SignupSchema, error, loading, handleSignUpInviteZone
    }
}