import styled from "styled-components";
import styles from "../../styles";

export const Card = styled.div`
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    margin-bottom: 16px;
`;

export const Icon = styled.div`
  margin-left: 10px;
  cursor: pointer;
  :hover {
    color: gray;
  }

  @media (max-width: 500px) {
    svg {
      font-size: 18px;
    }
  }

    @media (max-width: 360px) {
    svg{
      width: 20px;
      height: 20px;
    }
  }
`;

export const Files = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  margin-top: 10px;
  max-width: 50px;
  border-style: solid;
  border: 1px;  
  cursor: pointer;
  :hover {
    color: var(--primary);
  }
`;

export const Imagem = styled.img`
    height: 40px; width: 40px; border-radius: 50%;
    max-height: 40px;
`;

export const P = styled.p`
    font-size: ${styles.typography.font.extraSmall};
`;

export const Line = styled.div`
    height: 1px;
    background-color: ${styles.colors.grayClearOne};
    width: 100%;
    margin: 0 0 16px 0
`;

export const MoreComments = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: auto;
    cursor: pointer;
    :hover{
        color: ${styles.colors.primary};
        font-weight: 600;
    }
`;