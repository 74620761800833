import http from "../../services/api";
import { logout } from "../../services/localStorage";
import { BuyChannelUser } from "./type";

export const GetChannelsPublicBff = async (page: number | undefined, channelname: string) => {
    return await http.get("/bff/channel-bff/allPublic", {
     params: {
          whichPage: page,
          perPage: 12,
          channelName: channelname
     }
    }).then(response => response.data)
         .catch(err => {
              if (err.response.status === 401) {
                   logout()
                   window.location.reload()
              }
              throw err;
         });
}

export const GetChannelBffRequest = async (id: string | undefined) => {
     

     return await http.get(`/bff/channel-bff`, {
          params: {
               channelId: id,
          }
      }).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               throw err;
          });
}

export const CreateZoneRequest = async (body: BuyChannelUser) => {
     return await http.post("/bff/bought-user-channels-bff/buy-channel", body).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               alert(err.response.data.message)
               console.log(err)
               throw err;
          });
}