import { createContext } from "react";
import { MembersZonaContextType } from "./type";
import { MemberZoneState } from "./state";

export const MembersSubZonaContext = createContext<MembersZonaContextType | null>(null);

interface Props {
    children: React.ReactNode
}


const MembersSubZonaProvider = ({ children }: Props) => {

    const {handleMember} = MemberZoneState()


    return (
        <MembersSubZonaContext.Provider value={{ handleMember }}>
            {children}
        </MembersSubZonaContext.Provider>
    )
}

export default MembersSubZonaProvider;