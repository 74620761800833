import { useContext } from "react"
import { Avatar, Button, Icon } from "../../.."
import { MembersSubZonaContext } from "../../../../contexts/Subzone/MemberSubzone/context"
import { MembersZonaContextType } from "../../../../contexts/Subzone/MemberSubzone/type"
import { SubzonaContext } from "../../../../contexts/Subzone/Subzone/context"
import { SubzonaContextType } from "../../../../contexts/Subzone/Subzone/type"
import { Column, Row } from "../../../../styles/globalStyles"
import { Padding } from "../../../styles/styled-components"

interface PropsMember {
    id?: number,
    nome?: string,
    avatar?: string | null,
    isAdmin?: boolean,
    isMember?: any
}

const UserSubzone = ({ avatar, id, isAdmin, nome }: PropsMember) => {

    const { subzone } = useContext(SubzonaContext) as SubzonaContextType

    const { handleMember } = useContext(MembersSubZonaContext) as MembersZonaContextType


    const verifyMember = () => {
        const verify = subzone!.members.find(props => props.user.id === id)
        return verify ? true : false
    }

    
    return (
        <Padding padding="16px 0">
            <Row id="space-between">
                <Row>
                    <Column id="center">
                        <Avatar img={avatar ?? avatar} label={!avatar ? nome?.substring(0, 1) : null} size="normal"></Avatar>
                    </Column>
                    <Column id="center">
                        <h4>{nome}</h4>
                    </Column>
                </Row>
                {isAdmin && <Column id="center">
                    <Icon icon="pi pi-star-fill" />
                </Column>}
                {verifyMember() ? <Column id="center">Já é membro</Column> : <Button onClick={() => handleMember({ subZone: subzone!.id, user: id! })} title="Adicionar" />}
            </Row>
        </Padding>
    )
}

export default UserSubzone