import { useMutation } from "react-query";
import {
  PropsSubzonePost,
  SubZonaTypePost,
  SubZonaTypePostComment,
} from "./type";
import { useFetchSubZonePostBff } from "./query";
import {
  DeleteSubZonePost,
  DeleteSubZonePostComment,
  SubZonePostCommentRequest,
  SubzonePostRequest,
  UpdateSubZonePost,
  UpdateSubZonePostComment,
} from "./request";
import queryClient from "../../../services/react-query";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../../components/Alert";

export const MuralSubzoneController = (
  id: number,
  {
    toast,
  }: {
    toast?: RefObject<Toast>;
  }
) => {
  const { data: postsRequests, refetch } = useFetchSubZonePostBff(
    id.toString()
  );

  const PostSubzoneMutation = useMutation(
    ({ body, file }: { body: PropsSubzonePost; file?: File }) =>
      SubzonePostRequest(body, file),
    {
      onMutate: () => {
        //   setLoading(true);
        //   queryClient.refetchQueries()
      },
      onError: (error: any) => {
        Alert({
          summary: "Erro",
          detail: "Aconteceu algum problema, tente novamente",
          severity: "error",
          toast: toast,
        }).show();
        console.log(error);
      },
      onSuccess: (result) => {
        Alert({
          detail: "Postagem realizada!",
          severity: "success",
          summary: "Sucesso",
          toast: toast,
        }).show();
        refetch();
        queryClient.refetchQueries("UseSubZonePostIdBff");
      },
    }
  );

  interface PropsUpdateSubZone {
    body: SubZonaTypePost;
    id: string;
  }

  const UpdateSubZonePostMutation = useMutation(
    ({ body, id }: PropsUpdateSubZone) => UpdateSubZonePost(id, body),
    {
      onMutate: () => {},
      onError: (error: any) => {
        console.log(error);
        Alert({
          summary: "Erro",
          detail: "Aconteceu algum problema, tente novamente",
          severity: "error",
          toast: toast,
        }).show();
      },
      onSuccess: (result, veriables, context) => {
        Alert({
          detail: "Postagem editada!",
          severity: "success",
          summary: "Sucesso",
          toast: toast,
        }).show();
        queryClient.refetchQueries("UseSubZonePostIdBff");
      },
    }
  );

  interface PropsDeleteSubZone {
    id: string | null;
  }

  const DeletePostSubZoneMutation = useMutation(
    ({ id }: PropsDeleteSubZone) => DeleteSubZonePost(id),
    {
      onMutate: () => {},
      onError: (error: any) => {
        Alert({
          summary: "Erro",
          detail: "Aconteceu algum problema, tente novamente",
          severity: "error",
          toast: toast,
        }).show();
      },
      onSuccess: (result, veriables, context) => {
        Alert({
          detail: "Postagem excluida!",
          severity: "success",
          summary: "Sucesso",
          toast: toast,
        }).show();
        queryClient.refetchQueries("UseSubZonePostIdBff");
      },
    }
  );

  // Controller de Comentários

  const PostSubZoneCommentMutation = useMutation(
    (data: any) => SubZonePostCommentRequest(data),
    {
      onMutate: () => {
        //   setLoading(true);
        //   queryClient.refetchQueries()
      },
      onError: (error: any) => {
        //   setError(error.response.data.message);
        //   setLoading(false);
        console.log(error);
        Alert({
          summary: "Erro",
          detail: "Aconteceu algum problema, tente novamente",
          severity: "error",
          toast: toast,
        }).show();
      },
      onSuccess: (result) => {
        Alert({
          detail: "Comentário realizado!",
          severity: "success",
          summary: "Sucesso",
          toast: toast,
        }).show();
        queryClient.refetchQueries("UseSubZonePostIdBff");
      },
    }
  );

  interface PropsUpdateSubZonePostComment {
    body: SubZonaTypePostComment;
    id: string;
  }

  const UpdateSubZonePostCommentMutation = useMutation(
    ({ body, id }: PropsUpdateSubZonePostComment) =>
      UpdateSubZonePostComment(id, body),
    {
      onMutate: () => {},
      onError: (error: any) => {
        console.log(error);
        Alert({
          summary: "Erro",
          detail: "Aconteceu algum problema, tente novamente",
          severity: "error",
          toast: toast,
        }).show();
      },
      onSuccess: (result, veriables, context) => {
        Alert({
          detail: "Comentário editado!",
          severity: "success",
          summary: "Sucesso",
          toast: toast,
        }).show();
        queryClient.refetchQueries("UseSubZonePostIdBff");
      },
    }
  );

  interface PropsDeleteSubZonePostComment {
    id: string | null;
  }

  const DeletePostCommentSubZoneMutation = useMutation(
    ({ id }: PropsDeleteSubZonePostComment) => DeleteSubZonePostComment(id),
    {
      onMutate: () => {},
      onError: (error: any) => {
        Alert({
          summary: "Erro",
          detail: "Aconteceu algum problema, tente novamente",
          severity: "error",
          toast: toast,
        }).show();
      },
      onSuccess: (result, veriables, context) => {
        Alert({
          detail: "Comentário excluido!",
          severity: "success",
          summary: "Sucesso",
          toast: toast,
        }).show();
        queryClient.refetchQueries("UseSubZonePostIdBff");
      },
    }
  );

  return {
    PostSubzoneMutation,
    postsRequests,
    UpdateSubZonePostMutation,
    DeletePostSubZoneMutation,
    PostSubZoneCommentMutation,
    UpdateSubZonePostCommentMutation,
    DeletePostCommentSubZoneMutation,
  };
};
