import axios from "axios";
import { Message } from "../../contexts/PrivateRoutes/types";

export const handleSendMessage = async (
  messages: Message[],
  setMessages: any,
  setInput: any,
  setLoading?: any
) => {
  await axios
    .post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-4o-mini",
        "messages": [
          { role: "system", content: "Você é o assistente com ID asst_1dU3EZD7Vv60s9Cs1AitGMYA" },
          ...messages,
        ],
        
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ` + process.env.REACT_APP_Path_OPENAI_API_KEY,
        },
      }
    )
    .then((response) => {
      setMessages([
        ...messages,
        {
          role: "assistant",
          content: response.data.choices[0].message.content,
        },
      ]);

      // Clear the input field
      setInput("");
      setLoading(false);
    })
    .catch((error) => alert(error));

  // Update the conversation history with the response from ChatGPT
};
