import http from "../../../../services/api";
import { logout } from "../../../../services/localStorage";
import { PropsKanbanTags } from "./type";

export const SubzoneKanbanTagsRequest = async (body: PropsKanbanTags) => {
    return await http.post("/direct/kanban-tags", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const DeleteKanbanTagsRequest = async (id: number) => {
    return await http.delete(`/direct/kanban-tags/${id}`).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}


export const GetSubzoneKanbanTagsRequest = async (id: number) => {
    return await http.get("/bff/kanban-tags-bff", {
        params: {
            subZoneId: id,
        }
    }).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            // alert(err)
            throw err;
        });
} 
