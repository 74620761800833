import { useContext } from "react";
import { Container } from "../../style";
import ReactPlayer from "react-player";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { formatDate } from "../../../services/functionGlobal";
import { Column, Row, RowReverse } from "../../../styles/globalStyles";
import { Avatar, Button, Icon, Tooltip } from "../../../ludz.one_ui";
import { ChannelCompletContext } from "../../../contexts/Channel/ChannelComplet/context";
import { ContentCompletType } from "../../../contexts/Channel/ChannelComplet/type";
import { Accordion, AccordionTab } from "primereact/accordion";
import Contents from "./Contents";
import styles from "../../../ludz.one_ui/styles";
import { useMediaQuery } from "@mui/material";
import LudzLoading from "../../../ludz.one_ui/Loading";
import Archives from "./Archives";
import { useNavigate, useParams } from "react-router-dom";
import YoutubeContent from "./YoutubeContent";
import { AreaInfo, CapaChannel } from "../BuyChannel/style";
import LudzScrollTop from "../../../ludz.one_ui/LudzScrollTop";
import { Tag } from "primereact/tag";

const ChannelPage = () => {
  const props = useContext(ChannelCompletContext) as ContentCompletType;
  const matchesSmall = useMediaQuery("(min-width: 800px)");
  const matchesExtraSmall = useMediaQuery("(min-width: 600px)");
  const matchesMobile = useMediaQuery("(max-width: 520px)");
  const matchesTablet = useMediaQuery("(max-width: 1024px)");
  const history = useNavigate();
  const { idVideo, id } = useParams();

  let names = `${props?.playlistChannel?.name}`;
  var initialName = names.substring(0, 2);

  const NameRoomFormat = (name: any) => {
    if (name?.length > 30) {
      return name?.substring(0, 30) + "...";
    }
    return name;
  };

  function encontrarIndices(array: any, elemento: number) {
    var indices = 0;
    for (let i = 0; i < array?.length; i++) {
      if (array![i].id === elemento) {
        indices = i;
      }
    }
    return indices;
  }

  const indice = encontrarIndices(props.allplaylistChannel, parseInt(idVideo!));
  const NextContent = () => {
    if (idVideo) {
      history(`/canal/${id}/${props.allplaylistChannel![indice! + 1].id}`);
    }
    if (props.allplaylistChannel![1] && !idVideo) {
      history(`/canal/${id}/${props.allplaylistChannel![1].id}`);
    }
    window.location.reload();
  };
  const BackContent = () => {
    if (idVideo) {
      const indice = encontrarIndices(
        props.allplaylistChannel,
        parseInt(idVideo)
      );
      history(`/canal/${id}/${props.allplaylistChannel![indice! - 1].id}`);
    }
    if (props.allplaylistChannel![1] && !idVideo) {
      history(`/canal/${id}/${props.allplaylistChannel![1].id}`);
    }
    window.location.reload();
  };

  // if(props.playlistChannel?.playlists) return <LudzLoading />

  console.log(props);

  // Verificar total de vídeos
  let totalVideos = 0;

  if (
    props?.playlistChannel?.playlists &&
    Array.isArray(props?.playlistChannel?.playlists)
  ) {
    props?.playlistChannel?.playlists.forEach((playlist) => {
      if (playlist.contents && Array.isArray(playlist.contents)) {
        totalVideos += playlist.contents.length;
      }
    });
  }
  // Fim do código

  return (
    <>
      {props.playlistChannel?.youtubeChannelId ? (
        <YoutubeContent id={props.playlistChannel?.youtubeChannelId} />
      ) : (
        <Container>
          <Column style={{ border: "1px solid #c3c3c3", borderRadius: "24px" }}>
            <CapaChannel
              background={props.playlistChannel?.aws_2?.url}
            ></CapaChannel>
            <Padding />
            <Avatar
              shape="circle"
              size={matchesMobile ? "large" : "xlarge"}
              label={!props.playlistChannel?.aws?.url ? initialName : undefined}
              img={
                props.playlistChannel?.aws?.url
                  ? props.playlistChannel.aws.url
                  : undefined
              }
              style={{
                border: "1px solid #CCC",
                marginLeft: "20px",
                marginTop: matchesMobile ? '-50px' : "-70px",
                width: matchesMobile
                  ? "80px"
                  : "120px",
                height: matchesMobile
                  ? "80px"
                  : "120px",
              }}
            />
            <Column
              id="space-between"
              className="mx-3 mt-2"
             
            >
              <Row style={{ display: "flex", alignItems: "center", marginLeft: '10px' }}>
                <Column>
                  <Tooltip title={props?.playlistChannel?.name}>
                    <h1
                      className="edit-in-place"
                      dangerouslySetInnerHTML={{
                        __html:
                          NameRoomFormat(props?.playlistChannel?.name) ||
                          "Escreva Alguma coisa",
                      }}
                    ></h1>
                  </Tooltip>
                  <AreaInfo>
                    <span>
                      {props?.playlistChannel?.playlists.length} Playlists
                    </span>
                    •<span>{totalVideos} vídeos</span>
                  </AreaInfo>
                </Column>
              </Row>
              <Padding padding="8px" />
              <div className="card mb-3">
                <h4 className="card-title mb-3">Descrição</h4>
                <p
                  className="edit-in-place"
                  dangerouslySetInnerHTML={{
                    __html:
                      props.playlistChannel?.description ||
                      "Escreva Alguma coisa",
                  }}
                />
              </div>

              {props.playlistChannel?.tags.length > 0 && (
                <div className="card mb-0">
                  <h4 className="card-title mb-3">Tags</h4>
                  <div className="flex justify-center items-center flex-wrap gap-2">
                    {props.playlistChannel?.tags?.map(
                      (item: any, index: number) => (
                        <Tag
                          className="p-2"
                          rounded
                          style={{ background: "#E5E6EA", color: "black" }}
                        >
                          <span className="text-base">{item.name}</span>
                        </Tag>
                      )
                    )}
                  </div>
                </div>
              )}
            </Column>
            <Padding padding="8px" />
            <Row id="center">
              {props.playlistChannel ? (
                <div className="card w-full mx-3 mb-3">
                  {props.playlistChannel?.playlists?.length !== 0 ? (
                    <>
                      <h4 className="mb-3">Playlists</h4>
                      <h2>{props?.contentChannel?.name}</h2>
                      <RowReverse style={{ flexDirection: matchesTablet ? 'column' : 'row'}}>
                        <div className="w-full md:w-12">
                          <Padding padding="8px" />
                          <Row>
                            <ReactPlayer
                              url={
                                props?.contentChannel?.video_url ??
                                props?.contentChannel?.aws_2.url
                              }
                              width={"100%"}
                              height={
                                matchesSmall
                                  ? 420
                                  : matchesExtraSmall
                                  ? 380
                                  : 260
                              }
                              controls
                            />
                          </Row>
                          <Padding />
                          <Padding padding="8px" />
                          <h4>Descrição</h4>
                          <Padding padding="8px" />
                          <p>{props?.contentChannel?.description}</p>
                          <Padding padding="8px" />
                          <Row>
                            <Icon icon="pi pi-clock" size="0.8rem" />
                            <h6>
                              {formatDate(props?.contentChannel?.createdAt!)}
                            </h6>
                          </Row>
                          <Archives data={props.contentChannel} />
                        </div>
                        <div className="md:w-12 w-full">
                          <Padding padding="8px" />
                          <div
                            style={{
                              borderLeft: `0.5px solid ${styles.colors.grayClearOne}`,
                              height: "100%",
                              overflowY: "auto",
                            }}
                          >
                            {props.playlistChannel?.playlists?.map(
                              (item, index) => {
                                return (
                                  <Accordion key={index} activeIndex={0}>
                                    <AccordionTab header={item.name}>
                                      <Contents item={item.contents} />
                                    </AccordionTab>
                                  </Accordion>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </RowReverse>
                      <Padding padding="16px" />
                      <Row id="center">
                        <Button
                          title="Voltar"
                          icon="pi pi-arrow-left"
                          pt={{ icon: { style: { marginRight: "8px" } } }}
                          disabled={indice === 0}
                          onClick={BackContent}
                        />
                        {props.allplaylistChannel ? (
                          <Button
                            title="Próximo"
                            icon="pi pi-arrow-right"
                            iconPos="right"
                            disabled={
                              indice === props.allplaylistChannel?.length - 1
                            }
                            onClick={NextContent}
                          />
                        ) : null}
                      </Row>
                    </>
                  ) : (
                    <h1>Sem conteúdo</h1>
                  )}
                </div>
              ) : (
                <LudzLoading />
              )}
            </Row>
          </Column>
          <LudzScrollTop style={{ right: "0.5%", padding: "15px" }} />
        </Container>
      )}
    </>
  );
};

export default ChannelPage;
