import { MouseEventHandler } from "react";
import { Row } from "../../../styles/styled-components";
import { ButtonBubble } from "../style";

const BubbleButtons = ({
  icon,
  onClick,
  name,
  isActive
}: {
  name?: string;
  icon?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  isActive?: boolean
}) => {
  return (
    <ButtonBubble
      type="button"
      onClick={onClick}
      isActive={isActive}
    >
      <Row>
        {name ? <p>{name}</p> : null}
        {icon}
      </Row>
    </ButtonBubble>
  );
};

export default BubbleButtons;
