import http from "../../../services/api"
import { logout } from "../../../services/localStorage"
import { PropsSubzoneKanban } from "./type"

export const SubzoneKanbanRequest = async (body: PropsSubzoneKanban) => {
    return await http.post("/direct/kanban", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const GetSubzoneKanbanRequest = async (id: number) => {
    return await http.get("/bff/kanban-bff", {
        params: {
            subZoneId: id,
        }
    }).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            // alert(err)
            throw err;
        });
} 

export const DeleteSubzoneKanbanRequest = async (id: number) => {
    return await http.delete(`/direct/kanban/${id}`).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const UpdateSubzoneKanbanRequest = async (id: number, body: {title: string}) => {
    return await http.put(`/direct/kanban/${id}`, body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}