import { useContext } from "react";
import { KanbanCardSubzonaContext } from "../../../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context";
import { KanbanCardSubzonaContextType } from "../../../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type";
import CheckboxLudz from "../../../../../../../../../ludz.one_ui/Checkboxs";
import { Column, Row } from "../../../../../../../../../styles/globalStyles";
import { ButtonTags } from "../../../style";

interface PropsMetas {
    item: any,
}

const Metas = ({ item }: PropsMetas) => {

    const { card, deleteMetas, handleMetas } = useContext(KanbanCardSubzonaContext) as KanbanCardSubzonaContextType

    const isCheck = card.objectives.find((i: any) => i.objective_key.id === item?.id)
    
    return (<>
        <Row>
            <Column id="center">
                <CheckboxLudz checked={isCheck ? true : false} onChange={() => { !isCheck ? handleMetas({ objective: item?.objective_fk, objective_key: item?.id, kanbanCard: card?.id }) : deleteMetas(isCheck?.id) }} />
            </Column>
            <Column style={{ width: "100%" }}>
                <ButtonTags
                    style={{ backgroundColor: `${item.color}` }}
                >
                    <h5>{item.content}</h5>
                </ButtonTags>
            </Column>
        </Row>
    </>
    )
}

export default Metas