import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: rgba(127, 100, 170, 0.15);
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 50px;

  img {
    width: 30vw;
  }

  h1,
  i {
    text-align: center;
  }

  i {
    font-size: 20px;
  }

  span {
    animation: blink 1s infinite;
  }

  @keyframes type {
    from {
      width: 0;
    }
  }

  @keyframes type2 {
    0% {
      width: 0;
    }
    50% {
      width: 0;
    }
    100% {
      width: 100;
    }
  }

  @keyframes blink {
    to {
      opacity: 0;
    }
  }

  ::selection {
    background: black;
  }

  @media (min-width: 2560px) and (max-width: 4160px) {
    h1{
        font-size: 80px;
    }
    i{
        font-size: 60px;
    }
  }

  @media (max-width: 768px) {
    img {
      width: 40vw;
    }
  }

  @media (max-width: 425px) {
    img {
      width: 50vw;
    }

    h1 {
      font-size: 25px;
    }
  }
`;
