import styled from "styled-components";
import styles from "../../../../../ludz.one_ui/styles";

interface PropsCircle {
    color: string
}

export const Circle = styled.div<PropsCircle>`
    height: 25px;
    width: 35px;
    border-radius: 8px;
    background-color: ${props => props.color};
`;

export const Box = styled.div`
padding: 16px;
`;


interface PropsStatus {
    isStatus?: boolean
}

export const Status = styled.div<PropsStatus>`
color: white;
border-radius: 8px;
font-weight: 700;
height: 30px;
width: 30px;
    background-color: ${props => props.isStatus ? styles.colors.greenSucess : styles.colors.yellow};
`;