import http from "../../../services/api";
import { logout } from "../../../services/localStorage";
import { uploadArchive } from "../../../services/upload-archives";
import { TypeProfileVisible } from "./type";

export const UpdateProfileUser = async (body: TypeProfileVisible) => {
    return await http.put(`/direct/users/`, body).then(response => response.data)
    .catch(err => {
         if (err.response.status === 401) {
              logout()
              window.location.reload()
         }
         
         throw err;
    })
}

export const UpdateAvatarProfile = async (file: File) => {
     return await uploadArchive(file, "/direct/users", {}, "PUT")
 }

 export const RemoveAvatarProfile = async () => {
     return await http.put(`/bff/users-bff/default-avatar/`).then(response => response.data)
     .catch(err => {
          if (err.response.status === 401) {
               logout()
               window.location.reload()
          }
          alert(err.response.data.message)
          throw err;
     })
}