import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { KanbanSubzonaContext } from "../../../../contexts/Subzone/KanbanSubzone/context";
import { KanbanSubzonaContextType } from "../../../../contexts/Subzone/KanbanSubzone/type";
import { Button, Icon, Input } from "../../../../ludz.one_ui";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../styles/globalStyles";
import { ContainerList } from "../style";
import { WrapCard } from "./style";

const AddList = () => {

    const [open, setopen] = useState(false)

    const { idSubzone } = useParams()

    const { initialValue, schema, handleKanban, kanban } = useContext(KanbanSubzonaContext) as KanbanSubzonaContextType


    return (
        <ContainerList>
            {open ? <>
                <WrapCard>
                    <Padding padding="8px">

                        <Formik validateYupSchema={schema} initialValues={initialValue} onSubmit={(values: any) => {
                            handleKanban(values, parseInt(idSubzone!), kanban!.length);
                            setopen(!open);
                        }}>
                            {({ values, handleChange, errors, touched, handleSubmit }) => {
                                return (
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}>
                                        <Input name="title" onChange={handleChange} value={values.title} placeholder="Insira o título da lista..." />
                                        <Padding padding="8px" />
                                        <Row id="space-between">
                                            <Button type="submit" title="Criar Lista" />
                                            <Column style={{ cursor: "pointer" }} onClick={() => setopen(!open)} id="center">
                                                <Icon icon="pi pi-times" />
                                            </Column>
                                        </Row>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Padding>
                </WrapCard>
            </> : <Button style={{ width: "100%" }} onClick={() => setopen(!open)} icon={"pi pi-plus"} iconPos="left" title="Nova Lista" />}
        </ContainerList>
    )
}

export default AddList;