import styled from "styled-components";
import styles from "../../ludz.one_ui/styles";

export const TopbarStyle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-height: 60px;
    grid-area: topbar;
    background-color: #FFFFFF;
    box-shadow: 0 10px 40px 0 rgba(41, 50, 65, 0.06);
`;

export const CircleOpenMenu = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${styles.colors.grayClearOne};
  }

  @media (max-width: 360px) {
    width: 30px;
    height: 30px;
  }
`;

export const ButtonIA = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 6px;
  border: 1px solid transparent;
  .lottie-button {
    height: 2.1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 50px;
    font-size: 1.4rem;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
  }

  &:hover {
    border: 1px solid #F1F3F5;
    background-color: #F1F3F5;
    border-radius: 35px;
  }
`;

export const ButtonIcon = styled.div`
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 80px;
  font-size: 1.4rem;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
`;