
export const formatDate = (dateFormat: string) => {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  if (dateFormat) {
    const date = new Date(dateFormat);

    var day: any = date.getDate();

    if (day < 10) {
      day = "0" + day;
    }

    var postDate = day + " de " + months[date.getMonth()].substring(0, 3);
    return postDate;
  } else {
    return "Não possui data";
  }
};
