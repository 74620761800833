import styled from "styled-components";
import styles from "../../styles";

export const ModalInfo = styled.div`
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 400px;

  h1{
    margin-bottom: 20px;
    text-align: center;
    font-size: 25px;
  }

  

  @media (max-width: 768px) {
    
    h1{
      font-size: 20px;
    }
  }
  
  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const ButtonUser = styled.div`
padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  cursor: pointer; 

  :hover {
    background-color: #f1f1f1;
  }
`;

export const Line = styled.div`
height: 1px;
background-color: ${styles.colors.grayClearOne};
`;