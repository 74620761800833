import styled from "styled-components";

interface PropsDisplay {
    display?: boolean
}

export const ButtonAdd = styled.div<PropsDisplay>`
    display: ${(props) => (props.display === false ? "flex" : "none")};
    
    font-weight: bold;
    text-align: center;
    padding: 24px;
    height: 40px;
    width: 100%;
    height: auto;
    border-radius: 24px;
    background: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    
    cursor: pointer;
    :hover{
      color: var(--primary);
    }

    @media (max-width: 360px) {
      width: 98%;
    }
`;


export const NewPost = styled.div<PropsDisplay>`
   display: ${(props) => (props.display === true ? "flex" : "none")};
   flex-direction: column;
   padding: 24px;
   width: 100%;
    height: auto;
    border-radius: 24px;
    background: #FFFFFF;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;


    .editingarea{
      height: 100px;
    }
  
  textarea {
    resize: none;
    height: 500px;
    width: 100%;
    padding: 10px;
    font-size: 15px;
    border-radius: 5px;
  }

  /* button {
    width: 30px;
    height: 30px;
    min-width: 40px;
    
    svg{
      font-size: 1.2vw;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      margin-left: 10px;
      color: #111111;
      font-weight: bold;
      :hover {
        color: green;
        background: white;
      }
    }

    @media (max-width: 950px){
     svg{
      font-size: 16px;
     }
    }

    @media (max-width: 425px){
     svg{
      font-size: 12px;
      margin-left: 0px;
     }
    }
  } */

  @media (max-width: 360px) {
    width: 98%;
    padding: 12px;
  }

`;

export const AreaOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 520px) {
    flex-direction: column;
    align-items: normal;
  }
`;

export const ButtonOption = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #449E48;
  padding: 10px;
  border-radius: 10px;
  color: #449E48;
  background-color: transparent;
  

  :hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #449E48;
  }
`;