import { useEffect, useRef, useState } from "react"
import { InviteZoneController } from "../../../ludzoneSDK/Zone/invite/controller"
import { PropsInvites } from "./type"
import { Toast } from "primereact/toast"

export const InviteZoneState = () => {

    const toastInvite = useRef<Toast>(null)

    const {InviteZoneMutation, allInvitesRequest, DeleteInviteZoneMutation} = InviteZoneController({toast: toastInvite})

    const [invites, setInvites] = useState<any[]>([])


    useEffect(() => {
        if (allInvitesRequest) {
            setInvites(allInvitesRequest)
        }
    }, [allInvitesRequest])

    
    const handleInvite = (data: PropsInvites) => {
        InviteZoneMutation.mutate(data)
    }

    const handleDeleteInvite = (id: number) => {
        DeleteInviteZoneMutation.mutate(id)
    }

    return {
        handleInvite, invites,handleDeleteInvite, toastInvite
    }
}