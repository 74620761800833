import styled from "styled-components";
import styles from "../../../../../../../ludz.one_ui/styles";

export const AddDescription = styled.div`
    min-height: 50px;
    height: auto;
    background-color: ${styles.colors.grayClearOne};
    border-radius: 4px;
    padding: 8px;

    &:hover{
        cursor: pointer;
        background-color: ${styles.colors.grayClearOneHover};
    }

`;