import styled from "styled-components";
import styles from "../../styles";

export const Card = styled.div`
    background-color: white;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 16px;
    font-family: ${styles.typography.types.fontfamilyS};
    height: 100%;
    cursor: pointer;
    &:hover {
        background-color: ${styles.colors.grayClearOne};
    }
    p {
        font-size: 12px;
        font-family: ${styles.typography.types.fontfamilyS};
        font-weight: 500;
    }
`;