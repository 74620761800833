import React, { useState, useContext } from 'react';
import { SubzonaInfoContext } from '../../../../../contexts/Subzone/SubzoneInfo/context';
import { SubZonaInfoContextType } from '../../../../../contexts/Subzone/SubzoneInfo/type';
import { Button } from '../../../../../ludz.one_ui';
import ConfirmationDialog from '../../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes';

// import { Container } from './styles';

interface Props {
    item: any
}

const DeleteFile = ({ item }: Props) => {
    const [visibleDeleteFile, setVisibleDeleteFile] = useState(false);

    const { HandleDeleteArchives } = useContext(
        SubzonaInfoContext
      ) as SubZonaInfoContextType;

  return (
    <>
                <Button
              onClick={() => setVisibleDeleteFile(!visibleDeleteFile)}
              icon="pi pi-trash"
              rounded
              text
              severity="danger"
              className="mr-2"
            ></Button>
            <ConfirmationDialog
              accept={() => HandleDeleteArchives(item!.id.toString())}
              visible={visibleDeleteFile}
              reject={() => setVisibleDeleteFile(true)}
              message="Deseja Excluir?"
              onHide={() => setVisibleDeleteFile(false)}
            />
    </>
    );
}

export default DeleteFile;