import { createContext } from 'react';
import { StateZonesPublic } from './state';
import { ZonePublicContextType, PropsZonePublicProvider } from './type';

export const ZonesPublicContext = createContext<ZonePublicContextType | null>(null);

const ZonesPublicContextProvider = ({ children }: PropsZonePublicProvider) => {

    const { zonasPublics, errorZones, isLoadingZones, page, setPage, zonaall, refetch, handleEntryZone, setInputValue, inputValue } = StateZonesPublic();

    return (
        <ZonesPublicContext.Provider
        value={{ zonasPublics, errorZones, isLoadingZones, page, setPage, zonaall, refetch, handleEntryZone, setInputValue, inputValue }}
        >
            {children}
        </ZonesPublicContext.Provider>
    )
}

export default ZonesPublicContextProvider