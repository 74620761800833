import React from 'react';
import { TypeTagZone } from '../../../../ludzoneSDK/Zone/type';
import { Tag } from 'primereact/tag';

// import { Container } from './styles';

interface Props {
    tags: TypeTagZone
}

const ChipTags = ({ tags }: Props) => {
  return (
    <div>
        <Tag className='mt-1' rounded style={{ background: '#E5E6EA', color: 'black' }}>
        <div className="flex align-items-center gap-2">
            <span className="text-base">{tags.name}</span>
        </div>
        </Tag> 
    </div>
);
}

export default ChipTags;