import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
`;

export const AreaChecklists = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 16px;

  #center i:hover{
    color: red;
  }
`;

export const Cancel = styled.p`
  font-size: 12px;
  cursor: pointer;

    #cancel:hover{
      color: red;
    }
`;