import { Avatar, Icon } from "..";
import { Column, Row } from "../../styles/globalStyles";
import { Padding } from "../styles/styled-components";
import { Container } from "./style";


interface PropsChip {
    avatar: string,
    title: string,
    onClick: any
}
const Chip = ({avatar, title, onClick}: PropsChip) => {
    return (
        <Container>
            <Row>
                <Avatar shape="circle" img={avatar} />
                <Column id="center">
                    <h4>{title}</h4>
                </Column>
                <Column id="center">
                <Icon onClick={onClick} style={{cursor: "pointer"}} icon="pi pi-trash" size="0.7rem" />
                </Column>
                <Padding padding="2px" />
            </Row>
        </Container>
    )
}

export default Chip;