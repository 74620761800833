import { createContext } from "react";
import { PostZonaContextType } from "./type";
import { MuralZoneState } from "./state";

export const PostZonaContext = createContext<PostZonaContextType | null>(null);

interface Props {
    children: React.ReactNode
}


const PostZonaProvider = ({ children }: Props) => {

    const { handlePost, initialValue, schema, posts, file, setFile, initialValueEdit, handleEditPost, handleDeletePost, handleSendComments, initialValueComment, handleEditPostComment, handleDeletePostComment, toast } = MuralZoneState()
    return (
        <PostZonaContext.Provider value={{ handlePost, initialValue, schema, posts, file, setFile, initialValueEdit, handleEditPost, handleDeletePost, handleSendComments, initialValueComment, handleEditPostComment, handleDeletePostComment, toast }}>
            {children}
        </PostZonaContext.Provider>
    )
}

export default PostZonaProvider;