import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { PropsSignUp } from "../../contexts/User/SignUp/types";
import { getId } from "../../services/localStorage";
import { useFetchUserHome, useFetchUserId, useFetchUserInvites, useFetchUserNotifications } from "./query";
import { SignUpInviteZoneRequest, SignUpRequest } from "./request";


interface PropsUserController {
    query?: object | undefined,
    setLoading?: any,
    setError?: any
}


export const UserController = ({ setLoading, setError, query }: PropsUserController) => {

    const { data: user } = useFetchUserId(getId(), query)



    return {
        user
    }
}

export const UserHomeController = () => {

    const { data: user } = useFetchUserHome()

    const { data: invitesRequest } = useFetchUserInvites()

    const { data: notificationsRequest } = useFetchUserNotifications()



    return {
        user, invitesRequest, notificationsRequest
    }
}

export const CreateUserController = ({ setLoading, setError }: PropsUserController) => {

    const history = useNavigate();

    const SignUpMutation = useMutation((data: PropsSignUp) => SignUpRequest(data), {
        onMutate: () => {
            setLoading(true);
        },
        onError: (error: any) => {
            console.log(error)
            setError(error.response.data.message);
            setLoading(false);
        },
        onSuccess: (result, variables, context) => {

            //   setError();
            //   swal("Confirme seu cadastro através do seu Email!");
            history("/login");
        },
    });

    const SignUpInviteZoneMutation = useMutation((data: PropsSignUp) => SignUpInviteZoneRequest(data), {
        onMutate: () => {
            setLoading(true);
        },
        onError: (error: any) => {
            console.log(error)
            setError(error.response.data.message);
            setLoading(false);
        },
        onSuccess: (result, variables, context) => {

            //   setError();
            //   swal("Confirme seu cadastro através do seu Email!");
            history("/login");
        },
    });

    return {
        SignUpMutation, SignUpInviteZoneMutation
    }
}
