import styled from "styled-components";

export const Container = styled.div`
  @media (max-width: 520px) {
    h1 {
      font-size: x-large;
    }
  }

  @media (max-width: 360px) {
    h1, h2 {
      font-size: large;
    }

    p{
      font-size: small;
    }
  }
`;

export const AreaInfo = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0px 6px;

  span {
    color: #b4b2b2;
    font-size: small;
  }

  @media (max-width: 520px) {
    span {
      font-size: x-small;
    }
  }
`;

interface PropsIconEdit {
  color?: string;
}

export const IconEdit = styled.div<PropsIconEdit>`
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    cursor: pointer;
  }

  &:hover {
    color: ${(props: any) => props.color};
  }
`;

export const AreaButtons = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0px 0px 10px 0px;
`;

export const EditAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`;

export const EditCover = styled.div`
  display: flex;
  flex-direction: column;
  
`;

export const ImageCover = styled.img`
  width: 100%;
  height: 220px;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 24px;

  @media (max-width: 1024px) {
    height: 180px;
  }
  
  @media (max-width: 912px) {
    height: 140px;
  }
`;

export const AreaOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 520px) {
    flex-direction: column;
    margin-left: 16%;
  }

  @media (max-width: 360px) {
    margin-left: 12%;
  }

    @media (max-width: 330px) {
    margin-left: 6%;
  }
`;
