import { createContext } from "react";
import { StateChannelsPublic } from "./state";
import { ChannelPublicContextType, PropsChannelPublicProvider } from "./type";

export const ChannelsPublicContext =
  createContext<ChannelPublicContextType | null>(null);

const ChannelsPublicContextProvider = ({
  children,
}: PropsChannelPublicProvider) => {
  const {
    channelsPublics,
    channelPublic,
    isLoadingChannels,
    errorChannels,
    page,
    setPage,
    channelall,
    refetch,
    setInputValue,
    inputValue,
    BuyChannel,
  } = StateChannelsPublic();

  return (
    <ChannelsPublicContext.Provider
      value={{
        channelsPublics,
        channelPublic,
        errorChannels,
        isLoadingChannels,
        page,
        setPage,
        channelall,
        refetch,
        setInputValue,
        inputValue,
        BuyChannel,
      }}
    >
      {children}
    </ChannelsPublicContext.Provider>
  );
};

export default ChannelsPublicContextProvider;
