import { useQuery } from "react-query";
import { GetZonePostRequest, GetZonePostBffRequest } from "./request";

export const useFetchZonePots = (id: number | undefined) => {
    return useQuery(["UseZonePosts", id], () => GetZonePostRequest(id!));
};

export const useFetchZonePostBff = (id: string | undefined) => {
    return useQuery(["UseZonePostIdBff", id], () => GetZonePostBffRequest(id));
};
