import http from "../../../services/api";
import { logout } from "../../../services/localStorage";
import { uploadArchive } from "../../../services/upload-archives";

export const SubzoneArchivesRequest = async (file: File, id: string) => {
    return await uploadArchive(file, "/bff/sub-zone-archives-bff", {subZoneId: id}, "POST")
}

export const DeleteSubzoneArchivesRequest = async (id: string) => {
    return await http.delete("/bff/sub-zone-archives-bff", {params: {
        subZoneArchiveId: id
    }}).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}