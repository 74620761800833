import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AreaInfo = styled.div`
  display: flex;
`;

export const AreaDataUser = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  @media (max-width: 520px) {
    margin-left: 10px;
    h1{
      font-size: large;
    }
    span{
      font-size: small;
    }
  }
`;

export const UserDatas = styled.div`
  display: flex;
  margin-left: 10px;
  margin-top: 34px;
  width: 50%;

  h4 {
    margin-right: 12px;
  }

  h4::after{
    margin-right: 0;
  }

  @media (max-width: 520px) {
    width: 100%;
    h4{
      font-size: 14px;
    }
  }
`;


export const EditAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70px;

  :hover{
    cursor: pointer;
  }
`;

