import { useContext } from "react";

import KanbanSubonaProvider from "../../contexts/Subzone/KanbanSubzone/context";
import PostSubonaProvider from "../../contexts/Subzone/MuralSubzone/context";
import ObjectiveSubonaProvider from "../../contexts/Subzone/ObjectiveSubzone/context";
import { SubzonaContext } from "../../contexts/Subzone/Subzone/context";
import { SubzonaContextType } from "../../contexts/Subzone/Subzone/type";
import LudzLoading from "../../ludz.one_ui/Loading";
import { Containergeral } from "../style";
import SubzoneInfo from "./Information";
import Kanban from "./Kanban";
import MuralSubzone from "./Mural";
import ObjectiveSubzone from "./Objective";
import SubZonaInfoProvider from "../../contexts/Subzone/SubzoneInfo/context";
import LudzIAZone from "../LudzIAZone";

const SubzoneScreens = () => {
  const { subzone, container } = useContext(
    SubzonaContext
  ) as SubzonaContextType;


  return (
    <Containergeral>
      {subzone ? (
        <>
          {container === "ludzia" ? (
            <LudzIAZone />
          ): 
          container === "painel" ? (
            <SubZonaInfoProvider>
              <SubzoneInfo />
            </SubZonaInfoProvider>
          )
          : container === "mural" ? (
            <PostSubonaProvider>
              <MuralSubzone />
            </PostSubonaProvider>
          ) : container === "tarefas" ? (
            <ObjectiveSubonaProvider>
              <ObjectiveSubzone />
            </ObjectiveSubonaProvider>
          ) : container === "kanban" ? (
            <KanbanSubonaProvider>
              <Kanban />
            </KanbanSubonaProvider>
          ) : null}
        </>
      ) : (
        <LudzLoading />
      )}
    </Containergeral>
  );
};


export default SubzoneScreens;
