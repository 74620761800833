import React, { createContext } from "react";
import { ProfileState } from "./state";
import { ProfileContextType } from "./type";

export const ProfileContext = createContext<ProfileContextType | null>(
  null
);

interface Props {
  children: React.ReactNode;
}

const ProfileInfoProvider = ({ children }: Props) => {
  const {
    name,
    setName,
    username,
    setUserName,
    isediting,
    setIsEditing,
    HandleSaveInfo,
    HandleSaveAvatar,
    HandleRemoveAvatar,
    initialValues,
    toast
  } = ProfileState();

  return (
    <ProfileContext.Provider
      value={{
        name,
        setName,
        username,
        setUserName,
        isediting,
        setIsEditing,
        HandleSaveInfo,
        HandleSaveAvatar,
        HandleRemoveAvatar,
        initialValues,
        toast
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileInfoProvider
