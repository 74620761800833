import { useMutation } from "react-query";
import { DeleteCardRequest, MoveKanbanCardRequest, MoveKanbanColumnRequest, SubzoneKanbanCardRequest, UpdateKanbanCardRequest } from "./request";
import { PropsMoveSubzoneKanbanCard, PropsMoveSubzoneKanbanColumn, PropsSubzoneKanbanCard, PropsUpdateKanbanCard } from "./type";
import queryClient from "../../../../services/react-query";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../../../components/Alert";

export const KanbanCardSubzoneController = ({
    toast,
  }: {
    toast?: RefObject<Toast>;
  }) => {

    // const {data: kanbanRequests, refetch} = useFetchSubzoneKanban(id)

    const KanbanCardSubzoneMutation = useMutation((data: PropsSubzoneKanbanCard) => SubzoneKanbanCardRequest(data), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result) => {
            Alert({
                detail: "Cartão criado!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
            queryClient.refetchQueries("UseSubzoneKanbanbff")
        },
    });

    const DeleteKanbanCardSubzoneMutation = useMutation((id: number) => DeleteCardRequest(id), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result) => {
            Alert({
                detail: "Cartão excluido!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
            queryClient.refetchQueries("UseSubzoneKanbanbff")
        },
    });

    const MoveKanbanCardSubzoneMutation = useMutation((data: PropsMoveSubzoneKanbanCard) => MoveKanbanCardRequest(data), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result) => {
            queryClient.refetchQueries("UseSubzoneKanbanbff")
            Alert({
                detail: "Cartão movido!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
        },
    });

    const MoveMouseKanbanCardSubzoneMutation = useMutation((data: PropsMoveSubzoneKanbanCard) => MoveKanbanCardRequest(data), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result) => {
        
        },
    });

    const MoveMouseKanbanColumnSubzoneMutation = useMutation((data: PropsMoveSubzoneKanbanColumn) => MoveKanbanColumnRequest(data), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result) => {
            Alert({
                detail: "Coluna movida!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
            queryClient.refetchQueries("UseSubzoneKanbanbff")

        },
    });

    

    interface PropsUpdate {
        id: number,
        body: PropsUpdateKanbanCard
    }
    const UpdateKanbanCardSubzoneMutation = useMutation(({ body, id }: PropsUpdate) => {
        // Chame a função UpdateIsFinishSubzoneTaskRequest com os dados
        return UpdateKanbanCardRequest(body, id);
    }, {
        onMutate: () => {
            // Coloque aqui a lógica que deseja executar antes da mutação, se necessário
        },
        onError: (error: any) => {
            // Coloque aqui a lógica para lidar com erros

            console.log(error);
        },
        onSuccess: (result) => {
            queryClient.refetchQueries("UseSubzoneKanbanbff");
            queryClient.refetchQueries("UseSubzoneKanbanCard")
            // Coloque aqui a lógica para lidar com o sucesso da mutação
        },
    });

    return {
        KanbanCardSubzoneMutation, UpdateKanbanCardSubzoneMutation,DeleteKanbanCardSubzoneMutation, MoveKanbanCardSubzoneMutation, MoveMouseKanbanCardSubzoneMutation, MoveMouseKanbanColumnSubzoneMutation
    }
}


