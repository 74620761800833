import React, { useContext } from 'react';
import { ZonaInfoContext } from '../../../../../contexts/Zone/ZoneInfo/context';
import { ZonaInfoContextType } from '../../../../../contexts/Zone/ZoneInfo/type';
import { Button, Input, Modal } from '../../../../../ludz.one_ui';
import { Form, Formik } from 'formik';
import { ZonaType } from '../../../../../ludzoneSDK/Zone/type';
import { Column, Row } from '../../../../../styles/globalStyles';
import { Padding } from '../../../../../ludz.one_ui/styles/styled-components';

// import { Container } from './styles';

type Props = {
    visibleTag?: boolean;
    onOpen?: any,
    zone?: ZonaType | any

}

const CreateTags = ({ visibleTag, onOpen, zone }: Props) => {
   const { HandleCreateTag, initialValuesTags, schema } = useContext(ZonaInfoContext) as ZonaInfoContextType;
    return (
    <Modal visible={visibleTag} onOpen={onOpen} title={"Criar Tag"}>
              <Formik
        initialValues={initialValuesTags}
        validationSchema={schema}
        onSubmit={(values) => HandleCreateTag({name: values.name, zone})}
      >
        {({ values, handleChange, errors, touched }) => {
          return (
            <Form>
              <Column>
                <Input
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  label="Nome da tag"
                />
              </Column>
              <Padding />
              {errors.name && touched.name && (
                <div style={{ color: "red" }}>{errors.name.toString()}</div>
              )}
              <Padding />
              <Row id="center">
                <Button title="Criar" type="submit" />
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Modal>
);
}

export default CreateTags;