import http from "../../../services/api";
import { logout } from "../../../services/localStorage";
import { uploadArchive } from "../../../services/upload-archives";
import { PropsCreateTagsZone, ZonaTypeInformation } from "./type";

// Requisições das informações da zona
export const UpdateZone = async (
  id: string | null,
  body: ZonaTypeInformation
) => {
  return await http
    .put(`/direct/zone/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      alert(err.response.data.message);
      throw err;
    });
};

export const DeleteZone = async (id: string | null) => {
  return await http
    .delete(`/bff/zone-bff`, { params: { zoneId: id } })
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      alert(err.response.data.message);
      throw err;
    });
};

export const UpdateAvatarZone = async (
  id: string | null,
  avatar: File | undefined
) => {
  if (avatar) {
    console.log(avatar, 'avatar')
    return await uploadArchive(avatar, `/direct/zone/${id}`, {}, "PUT");
  }
};

export const RemoveAvatarZone = async (id: string | null) => {
  return await http
    .put(`/bff/zone-bff/default-avatar?zoneId=${id}`)
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      alert(err.response.data.message);
      throw err;
    });
};

// Requisições da capa da zona
export const UpdateCoverZone = async (id: string | null, cover: File) => {
  if (cover) {
    return await uploadArchive(
      cover,
      `/bff/zone-bff/change-wallpaper/${id}`,
      { index: id?.toString(), isWallpaper: true },
      "PUT"
    );
  }
};

export const RemoveCoverZone = async (id: string | null) => {
  return await http
    .put(`/bff/zone-bff/default-wallpaper/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      alert(err.response.data.message);
      throw err;
    });
};

// Requisições das Tags da Zona
export const CreateZoneTagsRequest = async (body: PropsCreateTagsZone) => {
  return await http
    .post("/direct/zone-tags", body)
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }

      console.log(err);
      throw err;
    });
};

export const EditTagZoneRequest = async (
  body: { name: string },
  id: string
) => {
  return await http
    .put(`/direct/zone-tags/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      alert(err);
      throw err;
    });
};

export const DeleteTagZoneRequest = async (id: string) => {
  return await http
    .delete(`/direct/zone-tags/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      alert(err);
      throw err;
    });
};
