import { Card } from 'primereact/card';
interface CardLudzAttributis {
    title?: string,
    children?: React.ReactNode,
    style?: React.CSSProperties,
    className?: any
}

const CardLudz = ({ children, title, style, className }: CardLudzAttributis) => {
    return (
        <Card className={className} title={title} style={{ borderRadius: "24px", boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px", ...style}}>
            {children}
        </Card>
    )
}

export default CardLudz;