import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Logo from "../../../assets/images/Logo2.png";
import LudzLoading from "../../../ludz.one_ui/Loading";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { ConfirmEmailZoneController } from "../../../ludzoneSDK/ComfirmEmail/controller";
import { logout } from "../../../services/localStorage";
import * as C from "./style";
import { Toast } from "primereact/toast";

const ConfirmZoneInvite: React.FC = () => {
  const { id, idzone, token } = useParams();
  const toast = useRef<Toast>(null)
  const { ConfirmEmailzoneMutation } = ConfirmEmailZoneController({ toast: toast })

  const [loading, setLoading] = useState(true)


  useEffect(() => {
    if (loading) {

      setLoading(false)
      logout()
      setTimeout(() => {
        ConfirmEmailzoneMutation.mutate({id: id!, idzone: idzone!, token: token!})
      }, 3000);

    }
  }, [id, idzone, token, ConfirmEmailzoneMutation, loading])




  return (
    <C.Container>
      <img src={Logo} alt="Logo do Sistema" />
      <h1>Convite Confirmado com Sucesso</h1>
      <i>Você será redirecionado para fazer o Login<span>...</span></i>
      <Padding padding="32px" />
      <LudzLoading />
    </C.Container>
  );
};

export default ConfirmZoneInvite;
