import ForgetPasswordProvider from "../../../contexts/User/ForgetPassword/context"
import ForgetPasswordPage from "../../../screens/User/ForgetPassword"

const ForgetPassword = () => {
    return(
        <ForgetPasswordProvider>
            <ForgetPasswordPage />
        </ForgetPasswordProvider>
    )
}

export default ForgetPassword