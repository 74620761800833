import React, { useContext, useEffect, useRef, useState } from 'react';
import { PrivateRoutesContext } from '../../contexts/PrivateRoutes/context';
import { PrivateRoutesContextType } from '../../contexts/PrivateRoutes/types';
import { ChatMessageType } from '../../components/ChatGPT/ChatMessage/types';
import useChatToast from '../../components/ChatGPT/ChatToast';
import { handleSendMessage } from '../../ludzoneSDK/IA/request';
import ChatGPT from '../../components/ChatGPT';
import { ZonaContext } from '../../contexts/Zone/Zone/context';
import { ZonaContextType } from '../../contexts/Zone/Zone/type';
import { systemgpt } from './system.gpt';
import { getLudzFlow, removeLudzFlow, setLudzflow } from '../../services/localStorage';

// import { Container } from './styles';

const LudzIAZone = () => {
  const { zone } = useContext(ZonaContext) as ZonaContextType

  const [loading, setLoading] = useState(false);
  const propsPrivate = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;

  // Recupera as mensagens do localStorage
  const getStoredMessages = () => {
    const storedMessages = getLudzFlow();

    if (storedMessages) {
     

        return storedMessages;
    }

    return [
      { role: "system", content: systemgpt(propsPrivate.userProvider!) },
    ];
  };


  const [messages, setMessages] = useState<ChatMessageType[]>(getStoredMessages());
  const [inputValue, setInputValue] = useState<string>("");
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const { toast, showSuccess, showError } = useChatToast();

  // Salva as mensagens no localStorage sempre que mudarem
  useEffect(() => {
    setLudzflow(messages)
  }, [messages]);

  // Função para limpar o histórico
  const clearHistory = () => {
    removeLudzFlow()
    setMessages([{ role: "system", content: systemgpt(propsPrivate.userProvider!) }]); // Restaurar estado inicial
  };

  const handleSend = async (text: string) => {
    if (text.trim()) {
      setLoading(true);
      setMessages([...messages, { content: text, role: "user" }]);
      handleSendMessage(
        [...messages, { content: text, role: "user" }],
        setMessages,
        setInputValue,
        setLoading
      );
      setInputValue("");
      setTimeout(() => {
        if (chatBoxRef.current) {
          chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
      }, 60);
    }
  };

  const handleSendButton = async (text: string) => {
    if (text.trim()) {
      setLoading(true);
      setMessages([...messages, { content: text, role: "user" }]);
      let content = text;
      if (text === "Organização de Agenda.") {
        content =
          "Preciso que me ajude a organizar minha agenda de hoje. Pergunte quais são as minhas principais tarefas e compromissos que preciso concluir hoje e me ajude a organizar minha agenda de forma eficiente.";
      } else if (text === "Resumo de Texto.") {
        content =
          "Preciso que resuma um texto para mim.";
      } else if (text === "Dicas para Melhorar a Produtividade.") {
        content =
          "Quero melhorar minha produtividade e preciso de orientações. Primeiro, pergunte sobre minha atividade ou trabalho, depois sobre os maiores desafios que enfrento, e por fim, sobre o objetivo que desejo alcançar. Com essas informações, você pode me fornecer dicas e estratégias para melhora";
      } else if (text === "Escreva um E-mails") {
        content =
          "Preciso que escreva um email, mas primeiro pergunte para quem se destina e do que se trata.";
      } else if (text === "Estruturação de Apresentação.") {
        content = "Me ajude a estruturar uma apresentação, mas primeiro pergunte o tema e o público-alvo."
      } else if (text === "Atividades Interativas para Zona.") {
        content = "Sugira atividades interativas para minha zona, mas pergunte primeiro do que se trata a zona."
      } else if (text === "Crie Exercício de Múltipla Escolha.") {
        content = "Crie três exercícios de múltipla escolha, mas primeiro pergunte o tema desejado. Em seguida, apresente cada questão uma de cada vez, permitindo que o usuário responda e, logo após, informe se a resposta está correta ou incorreta. Ao final, pergunte ao usuário se ele gostaria de revisar os exercícios."
      } else if (text === "Revisão de Conteúdo.") {
        content = "Preciso que explique em termos simples sobre um tema."
      } else if (text === "Redação de Trabalhos.") {
        content = "Me ajude a estruturar uma redação, mas primeiro pergunte o tema."
      }
      handleSendMessage(
        [...messages, { content, role: "user" }],
        setMessages,
        setInputValue,
        setLoading
      );
      setInputValue("");
      setTimeout(() => {
        if (chatBoxRef.current) {
          chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
      }, 60);
    }
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(showSuccess, showError);
  };

  const getEmailShareLink = (text: string) => {
    const subject = encodeURIComponent("Compartilhamento de Texto da IA");
    const body = encodeURIComponent(text);
    return `mailto:?subject=${subject}&body=${body}`;
  };

  const handleShare = (text: string) => {
    if (navigator.share) {
      navigator
        .share({ title: "Compartilhamento de Texto da IA", text })
        .catch((error) => console.log("Erro ao compartilhar:", error));
    } else {
      window.location.href = getEmailShareLink(text);
    }
  };

  const optionsAwnsers = [
    {
      icon: "pi pi-calendar",
      color: "#f7b934",
      awnser: "Organização de Agenda.",
    },
    {
      icon: "pi pi-align-center",
      color: "#36c745",
      awnser: "Resumo de Texto.",
    },
    {
      icon: "pi pi-bolt",
      color: "#8d079e",
      awnser: "Dicas para Melhorar a Produtividade.",
    },
    {
      icon: "pi pi-envelope",
      color: "#62ADE4",
      awnser: "Escreva um E-mail",
    },
    // Novas opções:
    {
      icon: "pi pi-chart-line",
      color: "#ff9900",
      awnser: "Estruturação de Apresentação.",
    },
    {
      icon: "pi pi-face-smile",
      color: "#007acc",
      awnser: "Atividades Interativas para Zona.",
    },
    {
      icon: "pi pi-list-check",
      color: "#009688",
      awnser: "Crie Exercício de Múltipla Escolha.",
    },
    {
      icon: "pi pi-file-edit",
      color: "#4caf50",
      awnser: "Revisão de Conteúdo.",
    },
    {
      icon: "pi pi-book",
      color: "#e91e63",
      awnser: "Redação de Trabalhos.",
    }
  ];


  return (
    <ChatGPT
      messages={messages}
      isAcesso={propsPrivate.userProvider?.plan.artificialIntelligence! || zone?.isIntelligent!}
      handleCopy={handleCopy}
      handleShare={handleShare}
      handleSendButton={handleSendButton}
      handleSend={handleSend}
      inputValue={inputValue}
      setInputValue={setInputValue}
      loading={loading}
      setLoading={setLoading}
      optionsAwnsers={optionsAwnsers}
      toast={toast}
      chatBoxRef={chatBoxRef}
      clearHistory={clearHistory}
    />
  );
}

export default LudzIAZone;