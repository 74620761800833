import http from "../../../../../services/api";
import { logout } from "../../../../../services/localStorage";
import { PropsMemberCard } from "./type";

export const SubzoneMemberKanbanCardRequest = async (body: PropsMemberCard) => {
    return await http.post("/direct/kanban-card-members", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const DeleteMemberCardRequest = async (id: number) => {

    return await http.delete(`/direct/kanban-card-members/${id}`).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

