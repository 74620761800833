import styled from "styled-components";

interface PropsContainer {
    openMenu?: boolean
}

export const Container = styled.div<PropsContainer>`
    height: 100%;
    background-color: rgba(127, 100, 170, 0.8);
    grid-area: accordion;
    overflow-y: scroll;
    &::-webkit-scrollbar {
    display: none;
  }
    /* display: ${props => props.openMenu ? "flex" : "none"}; */
`;
