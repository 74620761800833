import { useContext, useState } from "react";
import { ObjectiveSubzonaContext } from "../../../../../contexts/Subzone/ObjectiveSubzone/context";
import { ObjectiveSubzonaContextType } from "../../../../../contexts/Subzone/ObjectiveSubzone/type";
import { Icon } from "../../../../../ludz.one_ui";
import CheckboxLudz from "../../../../../ludz.one_ui/Checkboxs";
import ConfirmationDialog from "../../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes";
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../../styles/globalStyles";
import { Status } from "../Objectivekey/style";
import { Line } from "../../../../User/Profile/ProfileInfo/style";
import { useNavigate, useParams } from "react-router-dom";


interface PropsTask {
    item: {
        kanbanCard: {
            id: number;
            title: string;
            kanban_fk: number;
            is_finished: boolean;
        }
    },
    idObjetictive: number

}

const Task = ({ item, idObjetictive }: PropsTask) => {
    const {id, idSubzone} = useParams();

    const [visible, setVisible] = useState(false)
    const { handleIsFinishTaskTask, handleDeleteTask, objective, setObjective } = useContext(ObjectiveSubzonaContext) as ObjectiveSubzonaContextType
    const history = useNavigate()
    const Redirect = () => {
        history(`/zona/${id}/subzona/${idSubzone}/kanban/${item.kanbanCard.id}`)
    }
    
    return (
        <Padding padding="8px 2px" >
            <Column>
                <Row id="space-between">
                    <Row>
                        <Column id="center">
                            <CheckboxLudz onChange={() => handleIsFinishTaskTask({ is_finished: !item.kanbanCard.is_finished }, item.kanbanCard.id, setObjective, objective, idObjetictive)} checked={item.kanbanCard.is_finished} />
                        </Column>
                        <Column id="center">
                            <h4 onClick={Redirect} style={{cursor: "pointer"}}>
                                {item.kanbanCard.title}
                            </h4>
                        </Column>
                    </Row>
                    <Row>
                        <Column id="center">
                        {item.kanbanCard.is_finished ? <Status isStatus={item.kanbanCard.is_finished}><Column id="center" style={{ alignItems: "center", height: "100%" }}><Icon style={{ margin: "auto" }} size="1rem" icon="pi pi-check-square" /></Column> </Status> : <Status isStatus={item.kanbanCard.is_finished}><Column id="center" style={{ alignItems: "center", height: "100%" }}><Icon style={{ margin: "auto" }} size="1rem" icon="pi pi-exclamation-triangle" /></Column></Status>}
                        </Column>
                        <Column id="center">
                            <Icon icon="pi pi-trash" size="1rem" style={{ cursor: "pointer" }} onClick={() => setVisible(true)} />
                        </Column>
                    </Row>
                </Row>
                <Padding />
                <Line />
            </Column>
            <ConfirmationDialog accept={() => handleDeleteTask(item.kanbanCard.id)} visible={visible} reject={() => setVisible(true)} message="Deseja Excluir?" onHide={() => setVisible(false)} />

        </Padding>
    )
}

export default Task