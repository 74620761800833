import { useContext } from "react";
import { ZonaContext } from "../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../contexts/Zone/Zone/type";
import MuralZone from "./Mural";
import ZoneInfo from "./ZoneInfo";
import CreateSubZone from "./CreateSubzone";
import CreateSubZonaProvider from "../../../contexts/Zone/CreateSubzone/context";
import StatusSubzoneScreen from "./SubzoneStatus";
import LudzLoading from "../../../ludz.one_ui/Loading";
import StatusSubzonaProvider from "../../../contexts/Zone/SubzoneStatus/contex";
import TimelineScreen from "./Timeline";
import PostZonaProvider from "../../../contexts/Zone/MuralZone/context";
import SessionZonaProvider from "../../../contexts/Zone/SessionZone/context";
import { Containergeral } from "../../style";
import ZonaInfoProvider from "../../../contexts/Zone/ZoneInfo/context";
import LudzIAZone from "../../LudzIAZone";

const ZoneScreens = () => {

    const { container, zone } = useContext(ZonaContext) as ZonaContextType

    return (
        <Containergeral>
            {zone ? <>
                {container === "ludzia" ?  <LudzIAZone /> :
                container === "painel" ? <ZonaInfoProvider><ZoneInfo /></ZonaInfoProvider> :
                    container === "mural" ? <PostZonaProvider><MuralZone /></PostZonaProvider> :
                        container === "criar-subzona" ? <CreateSubZonaProvider><CreateSubZone /></CreateSubZonaProvider> :
                            container === "status-subzona" ? <StatusSubzonaProvider><StatusSubzoneScreen /></StatusSubzonaProvider> :
                                container === "timeline" ? <SessionZonaProvider><TimelineScreen /></SessionZonaProvider> : null}
            </> : <LudzLoading />}
        </Containergeral>
    )
}

export default ZoneScreens;
