import { Formik } from "formik";
import { useContext } from "react";
import { CreateSubZonaContext } from "../../../../contexts/Zone/CreateSubzone/context";
import { CreateSubZonaContextType } from "../../../../contexts/Zone/CreateSubzone/types";
import { Button, EditorText, Input } from "../../../../ludz.one_ui";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../styles/globalStyles";
import { Container } from "../../../style";

const CreateSubZone = () => {
  const { initialValues, HandleSubmitEnviar, setFile, schema } = useContext(
    CreateSubZonaContext
  ) as CreateSubZonaContextType;

  const editorText = (e: any, setFieldValue: any) => {
    setFieldValue("description", e.editor.getHTML());
  };
  return (
    <Container>
      <Column style={{ width: "100%" }} className="card">
        <h1>Criar Subzona</h1>
        <Padding padding="16px" />
        <Formik
          initialValues={initialValues}
          onSubmit={(value) => HandleSubmitEnviar(value)}
          validationSchema={schema}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            handleSubmit,
            errors,
            touched,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Input
                  label="Nome"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder="Digite o nome da zona"
                  className={errors.name && touched.name ? "p-invalid" : ""}
                />
                <Padding />
                {errors.name && touched.name && (
                  <div style={{ color: "red" }}>{errors.name}</div>
                )}
                <Padding padding="8px" />
                <label>Descrição</label>
                <Padding />
                <EditorText
                  value={values.description}
                  name="description"
                  onTextChange={(e: any) => editorText(e, setFieldValue)}
                />
                <Padding />
                {errors.description && touched.description && (
                  <div style={{ color: "red" }}>{errors.description}</div>
                )}
                <Padding padding="16px" />
                <Input
                  type="file"
                  accept="application/"
                  onChange={(e: any) => setFile(e.target.files)}
                />
                <Padding padding="16px" />
                <Row id="end">
                  <Row>
                    <Button title="Enviar" type="submit" />
                  </Row>
                </Row>
                <Padding padding="8px" />
              </form>
            );
          }}
        </Formik>
      </Column>
    </Container>
  );
};

export default CreateSubZone;
