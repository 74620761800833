import parse from "html-react-parser";
import * as C from "./style";
import { Tooltip } from "../../ludz.one_ui";

interface Props {
  item: string;
  style?: React.CSSProperties;
  tooltip?: string
}

const ViewHTML = ({ item, style, tooltip }: Props) => {
  const text = item;
  return (
    <>
      {text ? (
        <C.Container style={{ wordBreak: "break-word" }}>
          <Tooltip title={tooltip}>
          <div style={{...style}} className="tiptap">{parse(text)}</div>
          </Tooltip>
        </C.Container>
      ) : null}
    </>
  );
};

export default ViewHTML;
