import { Children, cloneElement } from "react";
import styled from "styled-components";

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    
    #space-between{
        justify-content: space-between;
    }
    #center{
        justify-content: center;
    }
    #start{
        justify-content: start;
    }
    #end{
        justify-content: space-around;
    }
    #space-around{
        justify-content:space-around;
    }
    #align-items{
        align-items: center;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    #space-between{
        justify-content: space-between;
    }
    #center{
        justify-content: center;
    }
    #start{
        justify-content: start;
    }
    #end{
        justify-content: end;
    }
`;

interface PropsPadding {
    padding?: string
}

export const Padding = styled.div<PropsPadding>`
    padding: ${props => props.padding || "4px"};
`;

interface Props {
    children: React.ReactNode
}


export const Stack = ({ children }: Props) => {
    const arrayChildren = Children.toArray(children);

    return (
        <>
            {Children.map(arrayChildren, (child: any, index) => {
                return cloneElement(child, {
                    style: {
                        ...(child.props.style),
                        'zIndex': index * 100,
                        'position': 'absolute'
                    },
                })
            })}
        </>);
};