import React from "react";
import { TbCameraUp } from 'react-icons/tb';
import { Avatar } from "..";
import { ImageModal } from "./style";
import { useMediaQuery } from "@mui/material";

interface CustomImageInputProps {
  url: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
}

const CustomImageInput: React.FC<CustomImageInputProps> = ({ url, onChange, name }) => {
  let names = `${name}`;
  var initialName = names.substring(0, 2);
  const matchesMobileSmall = useMediaQuery("(max-width: 360px)");


  return (
    <ImageModal>
      <label htmlFor="file">
      <Avatar
            shape="circle"
            size={matchesMobileSmall ? "large" : "xlarge"}
            label={!url ? initialName : undefined}
            img={url ? url : undefined}
            style={{ border: "1px solid #CCC" }}
          />
          <div className="overlay">
            <TbCameraUp size={18} color="#fff"/>
          </div>
      </label>
      <input
        type="file"
        name={names}
        id="file"
        onChange={onChange}
      />
    </ImageModal>
  );
};



export default CustomImageInput;