import { HomeController } from "./controller";

export const StateHome = () => {
  // const [zonas, setZonas] = useState([]);
  // const [channels, setChannels] = useState([]);

  // useEffect(() => {
  //   if(mychannels){
  //     setChannels(mychannels)
  //   }
  //   if(myzonas){
  //     setZonas(myzonas)
  //   }
  // }, [mychannels, myzonas])

  const { user, isLoadingUser, errorUser } = HomeController()



  return (
    {
      user, isLoadingUser, errorUser
    }
  )
}