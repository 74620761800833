
import { Accordion, AccordionTab } from 'primereact/accordion';
import React from 'react';
import { Padding } from '../styles/styled-components';


interface PropsAccordion {
    data?: Array<any>,
    body: React.ComponentType<any>,
    onDelete?: (id: number) => void,
    header: React.ComponentType<any>,
    disabled?: boolean,
    activeIndex?: boolean
}
const AccordionLudz = ({ data, body: BodyComponent, onDelete, header: HeaderComponent, disabled, activeIndex }: PropsAccordion) => {
    



    return (
        <div>
            {data?.map((item, index) => {
                return (
                    <div key={item.id}>
                        <Accordion  multiple key={item.id} activeIndex={activeIndex ? 0 : null}>
                            <AccordionTab disabled={disabled} pt={{
                                headerTitle: { className: "w-full" },
                            }} header={
                                <HeaderComponent item={item} />
                            }>
                                <BodyComponent item={item} />
                            </AccordionTab>
                        </Accordion>
                        <Padding padding='4px' />
                    </div>
                )
            })}
        </div>
    )
}

export default AccordionLudz