import { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import * as Yup from 'yup';
import { ObjectiveSubzonePageController } from '../../../ludzoneSDK/Subzone/Objective/controller';
import { PropsGetSubzoneObjective, PropsSubzoneObjective } from '../../../ludzoneSDK/Subzone/Objective/type';
import { Toast } from 'primereact/toast';


export const ObjectiveSubzoneState = () => {

    const {idSubzone} = useParams();

    const [objective, setObjective] = useState<PropsGetSubzoneObjective[]>();

    const [visibleEditObjetive, setVisibleEditObjetive] = useState(false)

    const [accordionObjetivekey, setAccordionObjetivekey] = useState(false)

    const toast = useRef<Toast>(null)

    const { ObjectiveSubzoneMutation, objectiveRequests, DeleteObjectiveSubzoneMutation, UpdateObjectiveSubzoneMutation } = ObjectiveSubzonePageController(parseInt(idSubzone!), {toast: toast})

    const initialValue = {
        name: ""
    }

    useEffect(() => {
     if(objectiveRequests){
        setObjective(objectiveRequests)
     }
    }, [objectiveRequests])

    const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatória'),
      });
      
    const handleObjective = (data: PropsSubzoneObjective, id: number, onOpen: any) => {
        setObjective([
            ...objective!,
            {
                subZone: id,
                name: data.name,
                id: 999,
                objectiveKey:[]
            }
        ])
        ObjectiveSubzoneMutation.mutate({ subZone: id, name: data.name })
        onOpen(false)
    }

    const handleUpdateObjective = (body: {name: string}, id: string,) => {
        UpdateObjectiveSubzoneMutation.mutate({body: body, id: id})
       
    }

    const handleDeleteObjective = (id: number) => {
        
        DeleteObjectiveSubzoneMutation.mutate(id)
    }

    return {
        visibleEditObjetive, setVisibleEditObjetive, handleObjective, initialValue, schema, objective, setObjective, handleDeleteObjective, accordionObjetivekey, setAccordionObjetivekey, handleUpdateObjective, toast
    }
}