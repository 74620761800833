import { useQuery } from "react-query";
import {
  getAllFilesContentRequest,
  getChannelContentRequest,
  getChannelPlaylistContentRequest,
} from "./request";

export const useFetchChannelContent = (id: number | undefined) => {
  return useQuery(["UseChannelContent", id], () =>
    getChannelContentRequest(id!)
  );
};

export const useFetchChannelPlaylistContent = (id: number | undefined) => {
  return useQuery(["UseChannelPLaylistContent", id], () =>
    getChannelPlaylistContentRequest(id!)
  );
};

export const useFetchFileContent = (id: number) => {
  return useQuery(["UseFilesContent", id], () => getAllFilesContentRequest(id));
};
