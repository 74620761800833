import { ProgressSpinner } from "primereact/progressspinner";
import { useContext } from "react";
import { ZonesPublicContext } from "../../../contexts/Zone/ZonePublic/context";
import { ZonePublicContextType } from "../../../contexts/Zone/ZonePublic/type";
import { Input } from "../../../ludz.one_ui";
import LudzPaginator from "../../../ludz.one_ui/LudzPaginator";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import * as C from "./style";
import CardZona from "../../../components/Cards/CardZona";

const LIMIT = 12;

export default function ZonesPublicScreen() {;
  

  const { zonasPublics, page, setPage, refetch, zonaall, setInputValue, inputValue } = useContext(
    ZonesPublicContext
  ) as ZonePublicContextType;

  const pages = Math.ceil(zonaall / LIMIT);

  const onPageChange = (event: any) => {
    setPage(event.first + 1);
    refetch();
  };

  return (
    <C.Container>
      <C.TitlePublicRoom>Encontre uma Zona</C.TitlePublicRoom>
      <span className="p-input-icon-right">
        <i className="pi pi-search" />
        <Input
          className="p-inputtext-lg"
          value={inputValue}
          onChange={(e: any) => setInputValue(e.target.value)}
        />
      </span>
      <Padding padding="20px" />

      {zonasPublics ? (
        <>
          <C.WrapLista checkMockup={zonasPublics}>
            {zonasPublics.map((item: any, index: any) => {
              return <CardZona zona={item} width={"100%"} />;
            })}
          </C.WrapLista>
          {zonasPublics.length === 0 && <div
              style={{
                margin: "auto",
              }}
            >
              <h1>Sem zonas para serem exibidas</h1>
            </div>}
          <Padding padding="10px"/>
          <div style={{ marginBottom: "12px" }}>
            <LudzPaginator
              first={page - 1}
              rows={1}
              totalRecords={pages}
              onPageChange={onPageChange}
              template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            />
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <ProgressSpinner />
        </div>
      )}
    </C.Container>
  );
}
