import React from "react";
import { Avatar } from 'primereact/avatar';
import styles from "../styles";


interface PropsAvatar {
    size?: "normal" | "large" | "xlarge" | undefined,
    label?: string | null,
    shape?: "square" | "circle" | undefined,
    img?: string | null,
    style?: React.CSSProperties,
    props?: any,
    onClick?: any,
    className?: string
}

const LudzAvatar = ({ size, label, shape, img, style, onClick, className, props }: PropsAvatar) => {
    return (
        <Avatar onClick={onClick} className={className} label={label} size={size} shape={shape} image={img}  style={{ backgroundColor: `${styles.colors.grayClearOne}`, ...style}} {...props} />
    )
}

export default LudzAvatar;