import React from 'react';
import * as C from "./style";
import { Tooltip } from "../../ludz.one_ui";

interface Props {
  item: string;
  style?: React.CSSProperties;
  tooltip?: string;
}

const ViewHTMLComents: React.FC<Props> = ({ item, style, tooltip }) => {
  const text = item;

  // Divide o texto em partes com base no @
  const parts = text.split(/(@\w+)/g);

  return (
    <>
      {text && (
        <C.Container style={{ wordBreak: "break-word" }}>
          <Tooltip title={tooltip}>
            <div style={{...style}}>
              {parts.map((part, index) => 
                part.startsWith('@') ? 
                  <span key={index} className="text-blue-600 font-semibold">{part}</span> : 
                  part
              )}
            </div>
          </Tooltip>
        </C.Container>
      )}
    </>
  );
};

export default ViewHTMLComents;
