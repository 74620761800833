import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { ChannelListVideosYoutube, Item } from "../../../../contexts/Channel/ChannelComplet/type";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import { useFetchChannelVideosYoutube } from "../../../../ludzoneSDK/Channel/query";
import { Column, RowReverse } from "../../../../styles/globalStyles";
import { Container } from "../../../style";
import CardVideo from "./CardVideo";
import { useLocation, useNavigate } from "react-router-dom";
import LudzLoading from "../../../../ludz.one_ui/Loading";


const YoutubeContentState = (id: string) => {
    const [channelVideos, setChannelVideos] = useState<ChannelListVideosYoutube | undefined>()
    const { data: videosReques, isLoading } = useFetchChannelVideosYoutube(id)
    const [videoState, setVideo] = useState<Item | undefined>()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const idVideo = queryParams.get('video');

    useEffect(() => {
        if (videosReques) {
            setChannelVideos(videosReques)
            const video = channelVideos?.items.find(props => props.id.videoId === idVideo)
            if (video) {
                setVideo(video)
            }
        }


    }, [videosReques, idVideo, channelVideos?.items])

    return { channelVideos, videoState, isLoading }
}

const YoutubeContent = ({id}:{id: string}) => {


    const props = YoutubeContentState(id)

    const matchesSmall = useMediaQuery("(min-width: 800px)");
    const matchesExtraSmall = useMediaQuery("(min-width: 600px)");

    const history = useNavigate()

    if(props.isLoading) return <LudzLoading />
    return (
        <Container>
            <h3>{props.videoState?.snippet.channelTitle ?? props.channelVideos?.items[0].snippet.channelTitle}</h3>
            <Padding padding="16px" />
            <RowReverse >
                <ReactPlayer
                    url={props.videoState ? 'https://www.youtube.com/watch?v=' +  props.videoState?.id.videoId :'https://www.youtube.com/watch?v=' +  props.channelVideos?.items[0].id.videoId}
                    width={matchesSmall ? "60%" : "100%"}
                    height={
                        matchesSmall ? 500 : matchesExtraSmall ? 380 : 260
                    }
                    controls
                />
                <Padding />
                <Column>
                    <h3>{props.videoState?.snippet.title ?? props.channelVideos?.items[0].snippet.title}</h3>
                    <Padding padding="16px" />
                    <h4>Descrição</h4>
                    <Padding />
                    <p>
                        {props.videoState?.snippet.description ?? props.channelVideos?.items[0].snippet.description}
                    </p>
                </Column>

            </RowReverse>
            <Padding padding="32px" />
            <div className="grid">

                {props.channelVideos?.items.map((item) => {
                    return (
                        <div className="col-12 md:col-6 lg:col-4 xl:col-3" onClick={() => { history("?video=" + item.id.videoId);}}>
                            <CardVideo item={item} />
                        </div>
                    )
                })}
            </div>

        </Container>
    )
}

export default YoutubeContent