import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/Camaleão.png";
import { LoginContext } from "../../../contexts/User/Login/context";
import { LoginContextType } from "../../../contexts/User/Login/types";
import { Button, Input, Message, Progress, Tooltip } from "../../../ludz.one_ui";
import color from "../../../ludz.one_ui/styles/colors";
import { Column, Padding } from "../../../ludz.one_ui/styles/styled-components";
import { logout } from "../../../services/localStorage";
import { Row } from "../../../styles/globalStyles";
import { Box, ContainerLogin, P } from "../style";
import ModalForgetPassword from "./ModalForgetPassword";

const LoginScreen = () => {
  const { initialValues, error, loading, handleLogin, LoginSchema } =
    useContext(LoginContext) as LoginContextType;
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    logout();
  }, []);

  return (
    <ContainerLogin>
      <Column style={{ height: "100%" }}>
        <Column id="center" style={{ height: "100%" }}>
          <Box style={{ marginTop: "0%" }}>
            <Row id="center">
              <Padding padding="16px">
             <Tooltip title="Início" arrow>
             <Link to="/" style={{ textDecoration: 'none', cursor: 'pointer' }}>
                <img style={{ width: "100px" }} src={Logo} alt="" />
                </Link>
             </Tooltip>
              </Padding>
            </Row>
            <Column>
              {error && (
                <Row id="center">
                  <Message severity="error" text={error} />
                </Row>
              )}
              <Formik
                initialValues={initialValues}
                validationSchema={LoginSchema}
                onSubmit={(values: any) => handleLogin(values)}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                }: any) => (
                  <form onSubmit={handleSubmit}>
                    <Padding />
                    <Input
                      onChange={handleChange}
                      value={values.username}
                      type="text"
                      name="username"
                      placeholder="Digite E-mail ou Usuário"
                      className={
                        errors.username && touched.username ? "p-invalid" : ""
                      }
                    />
                    <Padding padding="2px" />
                    {errors.username && touched.username && (
                      <div style={{ color: "red" }}>{errors.username}</div>
                    )}
                    <Padding />
                    <Input
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      placeholder="Digite a senha"
                      className={
                        errors.password && touched.password ? "p-invalid" : ""
                      }
                    />
                    <Padding />
                    {errors.password && touched.password && (
                      <div style={{ color: "red" }}>{errors.password}</div>
                    )}
                    <Padding />
                    <Button style={{ width: "100%" }} type="submit">
                      {loading ? (
                        <Progress style={{ height: "20px", width: "20px" }} />
                      ) : (
                        "Entrar"
                      )}
                    </Button>
                    <Padding padding="4px" />
                    <Row id="center">
                      <P
                        onClick={() => setVisible(true)}
                        style={{ fontSize: "12px", cursor: "pointer" }}
                      >
                        Esqueceu a senha?
                      </P>
                    </Row>
                    <Padding padding="16px" />
                    <Row id="center">
                      <P>Não possui cadastro?</P>
                      <Link to="/register" style={{ textDecoration: "none" }}>
                        <P style={{ color: color.tertiary }}>Fazer cadastro</P>
                      </Link>
                    </Row>
                  </form>
                )}
              </Formik>
            </Column>
          </Box>
        </Column>
      </Column>
      <ModalForgetPassword
        visible={visible}
        onOpen={() => setVisible(!visible)}
      />
    </ContainerLogin>
  );
};

export default LoginScreen;
