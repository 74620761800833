import React, { useEffect } from "react";
import * as C from "./style";
import Logo from "../../../assets/images/Logo2.png"
import { useParams, useNavigate } from "react-router-dom";
import http from "../../../services/api";
import LudzLoading from "../../../ludz.one_ui/Loading";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { logout } from "../../../services/localStorage";

const ConfirmationEmail: React.FC = () => {
  const { id, token } = useParams();
  const history = useNavigate();

  useEffect(() => {
    logout()
  }, [])


  setTimeout(() => {
    http
      .put(`/bff/users-bff/verify-email/${id}/${token}`)
      .then((res: any) => {
        history("/login");
      })
      .catch((err: any) => { });
  }, 3000);

  return (
    <C.Container>
      <img src={Logo} alt="Logo do Sistema" />
      <h1>E-mail Confirmado com Sucesso</h1>
      <i>Você será redirecionado para fazer o Login<span>...</span></i>
      <Padding padding="32px" />
      <LudzLoading />
    </C.Container>
  );
};

export default ConfirmationEmail;
