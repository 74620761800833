import { Tooltip } from '@mui/material';
import { ReactElement, forwardRef } from 'react';

interface PropsTooltip {
    children: ReactElement<any, any>;
    title?: string;
    arrow?: boolean;
    style?: React.CSSProperties;
    placement?: "top" | "bottom" | "left" | "right" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start";
  }
  
  const LudzTooltip = forwardRef<HTMLDivElement, PropsTooltip>(
    ({ children, title, placement, arrow, style }: PropsTooltip, ref) => {
      return (
        <Tooltip title={title} arrow={arrow} placement={placement} style={{...style}} ref={ref}>
          {children}
        </Tooltip>
      );
    }
  );
  
  export default LudzTooltip;
