import { useState } from "react";
import { LoginController } from "../../../ludzoneSDK/Login/controller";
import { PropsLogin } from "../../../ludzoneSDK/Login/types";
import * as Yup from 'yup';
import { PropsInitialValue } from "./types";
import { ForgetPasswordController } from "../../../ludzoneSDK/Login/ForgetPassword/controller";


export const loginState = () => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [error, setError] = useState<string | undefined | null>();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loading, setLoading] = useState<Boolean | undefined | null>(false);

    const { LoginMutation } = LoginController({ setLoading, setError });

    const {ForgetPasswordMutation} = ForgetPasswordController({ setError })

    const LoginSchema = Yup.object().shape({
        username: Yup.string()
          .min(2, 'Muito curto!')
          .max(50, 'Muito Longo!')
          .required('Campo Obrigatório'),
        password: Yup.string().required('Campo Obrigatório'),
      });

    const initialValues: PropsInitialValue = {
        username: "",
        password: "",
    };
    

    const ForgetPassword = (body: {email: string}) => {
        ForgetPasswordMutation.mutate(body)
    }

    const handleLogin = (body: PropsLogin) => {

        LoginMutation.mutate(body)
    }

    return {
        initialValues, error, loading, handleLogin, LoginSchema, ForgetPassword
    }
}