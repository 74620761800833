
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import styles from "../../../../../../../ludz.one_ui/styles";
import { Padding } from "../../../../../../../ludz.one_ui/styles/styled-components";
import { Row } from "../../../../../../../styles/globalStyles";
import { Label } from "../style";
import * as C from "./style";

interface PropsDate {
  visible: boolean;
  setvisible: any;
  card: any;
}

const DateCard = ({ visible, setvisible, card }: PropsDate) => {
  const dateTime = card ? card.expectedDate : null;

  const data = new Date(`${dateTime}`);

  let dia: any = data.getDate(); // retorna o dia do mês
  let mes: any = data.getMonth() + 1; // retorna o mês (0 é Janeiro, então adicionamos 1)
  let ano = data.getFullYear(); // retorna o ano

  // Adiciona um zero à esquerda se o dia ou o mês for menor que 10
  if (dia < 10) dia = "0" + dia;
  if (mes < 10) mes = "0" + mes;

  let dataFormatada = dia + "/" + mes + "/" + ano;

  return (
    <C.Container>
      <h4>Data de Entrega</h4>
      <Padding />
      <Padding padding="8px">
        <Label style={{width: "auto", maxWidth: "130px", cursor: "pointer"}} color={styles.colors.primary} onClick={() => setvisible(!visible)}>
          <Row>
            <CalendarMonth />
            <h4 style={{ margin: "auto" }}>
              {dataFormatada}
            </h4>
          </Row>
        </Label>
      </Padding>
    </C.Container >
  );
};

export default DateCard;
