import ChannelsPublicContextProvider from "../../contexts/Channel/ChannelPublic/context";
import BuyChannelScreen from "../../screens/Channel/BuyChannel";

const BuyChannel = () => {

    return (
      <ChannelsPublicContextProvider>
        <BuyChannelScreen />
      </ChannelsPublicContextProvider>
    );
  };
  
  export default BuyChannel;