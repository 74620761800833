import { useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import { Button, Input, OverlayPanelLudz } from "../../..";
import { getId } from "../../../../services/localStorage";
import LudzAvatar from "../../../Avatar";
import LudzSkeleton from "../../../Skeleton";
import { Column, Padding, Row } from "../../../styles/styled-components";
import { P } from "../style";
import DeleteComments from "./DeleteComments";
import ViewHTMLComents from "../../../../components/ViewHTMLComents";

interface PropsComentarios {
  data: any;
  handleEditPostComment?: any;
  initialValueEdit?: any;
  handleDeletePostComment?: any;
  admin?: boolean;
  schema?: any;
}

const Comentarios = ({
  data,
  handleEditPostComment,
  handleDeletePostComment,
  initialValueEdit,
  schema,
  admin,
}: PropsComentarios) => {
  let user = data.owner;

  const [edit, setEdit] = useState(false);
  const [editComment, setEditComment] = useState(data.content);
  const matches = useMediaQuery("(max-width: 520px)");

  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = data?.content;
    const textContent = tempDiv.textContent || "";
    setCharCount(textContent.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCharCount(event.target.value.length);
  };

  useEffect(() => {
    if (data) {
      setEditComment(data.content);
    }
  }, [data]);

  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const formatDate = () => {
    if (data.createdAt) {
      const date = new Date(data.createdAt);

      var day: any = date.getDate();

      if (day < 10) {
        day = "0" + day;
      }

      var postDate = day + " de " + months[date.getMonth()].substring(0, 3);
      return postDate;
    } else {
      return "Não possui data";
    }
  };

  return (
    <Padding padding="16px 0">
      <Row>
        {user ? (
          <Row id="space-between" style={{ width: "100%" }}>
            <Row
              style={{
                flexDirection: matches ? "column" : "row",
                flex: matches ? "1" : "",
              }}
            >
              {user.aws.url ? (
                <LudzAvatar
                  img={user.aws.url}
                  shape="circle"
                  size="normal"
                  style={{
                    alignSelf: matches ? "center" : "normal",
                    marginLeft: matches ? "6%" : "4px",
                  }}
                />
              ) : (
                <LudzAvatar
                  label={user.name.substring(0, 2)}
                  shape="circle"
                  size="normal"
                  style={{
                    alignSelf: matches ? "center" : "normal",
                    marginLeft: matches ? "6%" : "4px",
                  }}
                />
              )}
              <Padding padding={matches ? "0px 0px 0px 16px" : "0px 10px"}>
                <Column
                  id="center"
                  style={{
                    width: matches ? "100%" : "40vw",
                    marginTop: matches ? "10px" : "0px",
                  }}
                >
                  <Row>
                    <h4>{user.username} </h4>
                    <Padding padding="2px" />
                    <P style={{ marginTop: "2px" }}>- {formatDate()}</P>
                  </Row>
                  <Padding padding="2px" />
                  {edit ? (
                    <Padding padding="4px">
                      <Formik
                        initialValues={initialValueEdit}
                        onSubmit={(value, { resetForm }) => {
                          const id = data.id.toString();
                          handleEditPostComment(id, value, setEdit);
                          resetForm();
                        }}
                        validationSchema={schema}
                      >
                        {({
                          values,
                          setFieldValue,
                          handleSubmit,
                          handleChange,
                          errors,
                          touched,
                        }) => {
                          return (
                            <form
                              onSubmit={handleSubmit}
                              style={{ width: "100%" }}
                            >
                              <Row
                                style={{
                                  flexDirection: matches ? "column" : "row",
                                }}
                              >
                                <>
                                  <Input
                                    defaultValue={editComment}
                                    placeholder={data?.content}
                                    maxLength={255}
                                    style={{
                                      width: "100%",
                                      position: "relative",
                                    }}
                                    name="content"
                                    onChange={(
                                      e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                      handleChange(e);
                                      handleTextChange(e);
                                    }}
                                    className={
                                      errors.content && touched.content
                                        ? "p-invalid"
                                        : ""
                                    }
                                  />
                                  {matches && (
                                    <div
                                      className="mt-1 font-semibold"
                                      style={{ alignSelf: "flex-end" }}
                                    >
                                      {charCount} / 255
                                    </div>
                                  )}
                                </>

                                <div
                                  style={{
                                    display: "flex",
                                    marginLeft: matches ? "0px" : "4px",
                                    justifyContent: matches
                                      ? "space-between"
                                      : "center",
                                    marginTop: matches ? "6px" : "4px",
                                  }}
                                >
                                  <Button
                                    outlined
                                    icon="pi pi-check"
                                    type="submit"
                                    style={{
                                      margin: "0px 4px 0px 0px",
                                      borderRadius: "10px",
                                    }}
                                    fontSize={matches ? "small" : "normal"}
                                  ></Button>
                                  <Button
                                    outlined
                                    icon="pi pi-times"
                                    severity="danger"
                                    style={{ borderRadius: "10px" }}
                                    fontSize={matches ? "small" : "normal"}
                                    onClick={() => {
                                      setEdit(false);
                                    }}
                                  ></Button>
                                </div>
                              </Row>
                              {!matches && (
                                <div className="mt-1 font-semibold">
                                  {charCount} / 255
                                </div>
                              )}
                            </form>
                          );
                        }}
                      </Formik>
                    </Padding>
                  ) : (
                    <ViewHTMLComents item={data.content} />
                  )}
                </Column>
              </Padding>
            </Row>
            {admin || data.owner.id === parseInt(getId()!) ? (
              <OverlayPanelLudz
                button={
                  <Column id="center" style={{ cursor: "pointer" }}>
                    <i
                      className="pi pi-ellipsis-v"
                      style={{ fontSize: "1rem", cursor: "pointer" }}
                    ></i>
                  </Column>
                }
                components={
                  <DeleteComments
                    data={data}
                    handleDeletePostComment={handleDeletePostComment}
                    setEdit={setEdit}
                  />
                }
              />
            ) : null}
          </Row>
        ) : (
          <div className="flex">
            <LudzSkeleton
              shape="circle"
              size="4rem"
              className="mr-2"
            ></LudzSkeleton>
            <div style={{ flex: "1" }}>
              <LudzSkeleton width="100%" className="mb-2"></LudzSkeleton>
              <LudzSkeleton width="75%"></LudzSkeleton>
            </div>
          </div>
        )}
      </Row>
    </Padding>
  );
};

export default Comentarios;
