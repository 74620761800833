// import { ZonaContext } from "../../../ludz.one_sdk/controllers/Zona/ControllerRoom/context";

import DataViewSubzone from "./DataViewSubzone";

const StatusSubzoneScreen = () => {

    return (
        <>
            <DataViewSubzone />
        </>
    )
}

export default StatusSubzoneScreen;