import { useContext, useState } from "react";
import { KanbanCardSubzonaContext } from "../../../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context";
import { KanbanCardSubzonaContextType } from "../../../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type";
import { KanbanSubzonaContext } from "../../../../../../../../../contexts/Subzone/KanbanSubzone/context";
import { KanbanSubzonaContextType } from "../../../../../../../../../contexts/Subzone/KanbanSubzone/type";
import CheckboxLudz from "../../../../../../../../../ludz.one_ui/Checkboxs";
import ConfirmationDialog from "../../../../../../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes";
import { Column, Row } from "../../../../../../../../../styles/globalStyles";
import { ButtonTags } from "../../../style";

const Tags = ({ item }: any) => {
    const [visibleDelete, setVisibleDelete] = useState(false)
    const { deleteTags } = useContext(KanbanSubzonaContext) as KanbanSubzonaContextType

    const { card, handleLabels, deleteLabels } = useContext(KanbanCardSubzonaContext) as KanbanCardSubzonaContextType

    const isLabel = card?.labels?.find((i: any) => i.label.id === item.id);

    function getContrastColor(bgColor: string) {
        if (!bgColor) { return ''; }
        const rgb = parseInt(bgColor.replace('#', ''), 16);   // convert rrggbb to decimal
        const r = (rgb >> 16) & 0xff;  // extract red
        const g = (rgb >>  8) & 0xff;  // extract green
        const b = (rgb >>  0) & 0xff;  // extract blue
    
        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    
        return luma < 128 ? 'white' : '#C0C0C0';
    }
    
    // No seu componente React
    const bgColor = item.color; // A cor de fundo que você quer verificar
    const textColor = getContrastColor(bgColor);


    return (

        <Row>
            <Column id="center">
                <CheckboxLudz checked={isLabel ? true : false} onChange={() => {!isLabel ? handleLabels({ label: item?.id, kanbanCard: card?.id }) : deleteLabels(isLabel.id)}} />
            </Column>

            <Column style={{width: "100%"}}>
                <ButtonTags
                    style={{ backgroundColor: `${item.color}` }}
                >
                    <h5 style={{ color: textColor }}>{item.name}</h5>
                </ButtonTags>
            </Column>
            <Column id="center">
                <i
                    className="pi pi-trash"
                    style={{ cursor: "pointer" }}
                    onClick={() => { setVisibleDelete(true) }}
                ></i>
                <ConfirmationDialog visible={visibleDelete} message="Deseja excluir essa tag?" accept={() => deleteTags(item.id)} reject={() => setVisibleDelete(!visibleDelete)} />
            </Column>
        </Row>

    );
};

export default Tags