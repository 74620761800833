import { Form, Formik } from "formik";
import { useContext } from "react";
import { KanbanSubzonaContext } from "../../../../../contexts/Subzone/KanbanSubzone/context";
import { KanbanSubzonaContextType } from "../../../../../contexts/Subzone/KanbanSubzone/type";
import { Button, Dropdown, ModalInsiderKanbanCard } from "../../../../../ludz.one_ui";
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components";
import { Column, Grid, Row } from "../../../../../styles/globalStyles";


interface PropsMoveCard {
  visible: boolean;
  setvisible: any;
  id: any;
}

interface PropsiniialValue {
  kanbanId?: number;
  newIndex?: { index: number; name: string };
}

const ModalMoveColumn = ({ visible, setvisible, id }: PropsMoveCard) => {
  const { kanban, moveMouserColumn} = useContext(
    KanbanSubzonaContext
  ) as KanbanSubzonaContextType;

  const positions = () => {
    const test: any = [];
   
      kanban?.forEach((element, index) => {
        if (element.id === id) {
          test.push({ index: index, name: `${index + 1} (atual)` });
        } else {
          test.push({ index: index, name: `${index + 1}` });
        }
      });
    // } else {
    //   listSelection!.kanbanCards.forEach((element, index) => {
    //     test.push({ index: index, name: `${index + 1}` });
    //   });
    //   test.push({
    //     index: listSelection!.kanbanCards.length + 1,
    //     name: `${listSelection!.kanbanCards.length + 1}`,
    //   });
    // }

    return test;
  };

  const iniialValue: PropsiniialValue = {
    kanbanId: undefined,
    newIndex: undefined,
  };

  return (
    <ModalInsiderKanbanCard
      visible={visible}
      title={"Mover"}
      onOpen={() => setvisible(!visible)}
    >
      <Padding padding="8px" />
      <Formik
        initialValues={iniialValue}
        onSubmit={(values) => {
          moveMouserColumn({
            ...values,
            kanbanId: id ?? 0,
            newIndex: values?.newIndex!.index,
          });
          setvisible(!visible);
        }}
      >
        {({ values, handleChange }) => {
          const position = positions();
          return (
            <Column>
              <Form style={{ width: "100%" }}>
                <Grid checkMockup={[{}, {}]}>
                  <Column style={{ width: "100%" }}>
                    <Dropdown
                      label="Posição"
                      name="newIndex"
                      optionLabel="name"
                      options={position}
                      value={values.newIndex}
                      onChange={handleChange}
                      placeholder="posição"
                    />
                  </Column>
                </Grid>
                <Padding padding="8px" />
                <Row id="center">
                  <Button title="Mover" type="submit" />
                </Row>
              </Form>
            </Column>
          );
        }}
      </Formik>
    </ModalInsiderKanbanCard>
  );
};

export default ModalMoveColumn;
