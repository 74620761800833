import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PrivateRoutesContext } from "../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../contexts/PrivateRoutes/types";
import styles from "../../ludz.one_ui/styles";

interface Props {
    notification: any,
}

const CardNotifications = ({ notification }: Props) => {

    const { UpdateNotifcation } = useContext(
        PrivateRoutesContext
    ) as PrivateRoutesContextType;

    const history = useNavigate()
    const months = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'];

    const date = new Date(notification?.createdAt);

    var day: any = date.getDate()

    if (day < 10) {
        day = '0' + day;
    }

    var postDate = day + " de " + months[date.getMonth()].substring(0, 3);

    const Redirect = () => {
        if (notification.fromLocal === 0 && notification?.links[0]?.zone_fk) {
            UpdateNotifcation(notification.id.toString(), { isViewed: true })
            history(`/zona/${notification?.links[0].zone_fk}/mural`)
        }
        if (notification.fromLocal === 3 && notification?.links[0]?.zone_fk) {
            UpdateNotifcation(notification.id.toString(), { isViewed: true })
            // history(`/zona/${notification?.links[0].zone_fk}/painel`)
        }
        if (notification.fromLocal === 1 && notification?.links[0]?.subZone_fk) {
            UpdateNotifcation(notification.id.toString(), { isViewed: true })
            history(`/zona/${notification?.links[0].zone_fk}/subzona/${notification?.links[0]?.subZone_fk}/mural`)
        }
        if (notification.fromLocal === 2 && notification?.links[0]?.subZone_fk) {
            UpdateNotifcation(notification.id.toString(), { isViewed: true })
            history(`/zona/${notification?.links[0].zone_fk}/subzona/${notification?.links[0]?.subZone_fk}/kanban/${notification?.links[0]?.kanban_card_fk}`)
        }

    }
    return (
        <li className="green">
          <div onClick={Redirect} className="event-content border-1 surface-border border-round p-2" style={{ background: notification?.isViewed! ? styles.colors.grayClearOne : "", cursor: 'pointer' }}>
            <span>
                {notification.whatMod}
            </span>
            <span className="time">
                {postDate}
            </span>
            </div>  
        </li>
        // <CardInvite onClick={Redirect} style={{ background: notification?.isViewed! ? styles.colors.grayClearOne : "" }}>
        //     <Column id="space-between" style={{ height: "100%" }}>
        //         <h4>{notification.whatMod}</h4>
        //         <p>{postDate}</p>
        //     </Column>
        // </CardInvite>
    )
}

export default CardNotifications