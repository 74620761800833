import { useContext } from "react";
import CheckboxLudz from "../../../../../../../ludz.one_ui/Checkboxs"
import { Column, Row } from "../../../../../../../styles/globalStyles"
import { Status } from "../style";
import { KanbanCardSubzonaContext } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context";
import { KanbanCardSubzonaContextType } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type";
import { Padding } from "../../../../../../../ludz.one_ui/styles/styled-components";

const IsFinish = () => {

    const { card, handleUpdateCard } = useContext(KanbanCardSubzonaContext) as KanbanCardSubzonaContextType


    return (
        <Column>
            <h4>
                Status
            </h4>
            <Padding />
            <Row>
                <Column id="center">
                    <CheckboxLudz onChange={() => { handleUpdateCard({is_finished: !card?.is_finished}, card?.id) }} checked={card?.is_finished} />
                </Column>
                {card?.is_finished ? <Status isStatus={card?.is_finished}>Finalizado </Status> : <Status isStatus={card?.is_finished}>Pedente</Status>}

            </Row>
        </Column>
    )
}

export default IsFinish;