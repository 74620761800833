import { useMediaQuery } from "@mui/material";

import { Dialog } from "primereact/dialog";
import { Line } from "./style";


interface PropsModalInsiderKanbanCard {
    visible?: boolean,
    onOpen: any,
    title?: any,
    children?: any
}

const ModalInsiderKanbanCard = ({ visible, onOpen, title, children }: PropsModalInsiderKanbanCard) => {


    const matches = useMediaQuery("(max-width:1150px)");

    const Header = () => {

        return (
          <>
          {title ? 
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <h4>{title}</h4>
          <span style={{ marginRight: '6px', fontSize: '1rem', color: 'gray' }}>(ESC)</span>
        </div>
          : 
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
            <span style={{ marginRight: '6px', fontSize: '1rem', color: 'gray' }}>(ESC)</span>
          </div>
          }
          </>
        )
      }

    return (
        <Dialog header={Header} visible={visible} style={{ width: matches ? '70vw' : '30vw' }} onHide={onOpen}>
            <Line  />
            {children}
        </Dialog>
    )
}


export default ModalInsiderKanbanCard;