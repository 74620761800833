import http from "../../../../../services/api";
import { logout } from "../../../../../services/localStorage";
import { PropsKanbanCardComments } from "./type";

export const SubzoneCommentsKanbanCardRequest = async (body: PropsKanbanCardComments) => {
    return await http.post("/bff/kanban-card-comments-bff", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}


export const DeleteSubzoneCommentsKanbanCardRequest = async (id: number) => {
    return await http.delete(`/bff/kanban-card-comments-bff`, { params: { kanbanCardCommentId: id } }).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}