import { RefObject, useContext } from "react"
import { TypeProfileVisible } from "./type"
import { UserProfileContext } from "../../../contexts/User/Profile/context"
import { UserProfileContextType } from "../../../contexts/User/Profile/type"
import { useMutation } from "react-query"
import { RemoveAvatarProfile, UpdateAvatarProfile, UpdateProfileUser } from "./request"
import { Toast } from "primereact/toast"
import { Alert } from "../../../components/Alert"
import queryClient from "../../../services/react-query"


export const ControllerProfile = ({
  toast,
}: {
  toast?: RefObject<Toast>;
}) => {

    interface PropsUpdateProvileUser {
        body: TypeProfileVisible,
    }


    const { refetch } = useContext(UserProfileContext) as UserProfileContextType;

    const UpdateProfileVisibleMutation = useMutation(
        ({ body }: PropsUpdateProvileUser) => UpdateProfileUser(body),
        {
          onMutate: () => {
          },
          onError: (error: any) => {
             Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
          },
          onSuccess: (result, veriables, context) => {
            // Swal.fire({
            //   title: "Perfil editado com sucesso!",
            //   icon: "success",
            //   showConfirmButton: false,
            //   timer: 2000
            // });
            // window.location.reload();
            Alert({
              detail: "Perfil editado!",
              severity: "success",
              summary: "Sucesso",
              toast: toast
            }).show();
            refetch();
          },
        }
      );


      interface PropsUpdateAvatarProfile {
        body: File;
      }

      const UpdateAvatarProfileMutation = useMutation(
        ({ body }: PropsUpdateAvatarProfile) => UpdateAvatarProfile(body),
        {
          onMutate: () => {},
          onError: (error: any) => {
             Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
          },
          onSuccess: (result, veriables, context) => {
            Alert({
              detail: "Imagem Salva!",
              severity: "success",
              summary: "Sucesso",
              toast: toast
            }).show();
            queryClient.refetchQueries('UseUserId')
            refetch();
          },
        }
      );

      interface PropsRemoveAvatarProfile {
        id: string;
      }
    
      const RemoveAvatarProfileMutation = useMutation(
        ({ id }: PropsRemoveAvatarProfile) => RemoveAvatarProfile(),
        {
          onMutate: () => {},
          onError: (error: any) => {
             Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
          },
          onSuccess: (result, veriables, context) => {
            Alert({
              detail: "Imagem Removida!",
              severity: "success",
              summary: "Sucesso",
              toast: toast
            }).show();
            queryClient.refetchQueries('UseUserId')
            window.location.reload()
            refetch();
            
          },
        }
      );

    return {
        UpdateProfileVisibleMutation,
        UpdateAvatarProfileMutation,
        RemoveAvatarProfileMutation

    }
}