import React, { useRef } from "react";
import Swal from "sweetalert2";
import { InviteZoneController } from "../../../ludzoneSDK/Zone/invite/controller";
import { ConfirmEmailZoneController } from "../../../ludzoneSDK/ComfirmEmail/controller";
import CardInvite from "../../../ludz.one_ui/Card/CardInvite";
import { Column } from "../../../styles/globalStyles";
import { Toast } from "primereact/toast";

interface Props {
  invites: any;
  key: any;
}

const CardsInvites: React.FC<Props> = ({ invites, key }) => {
  // const matchesTablet = useMediaQuery(
  //   "(min-width: 768px) and (max-width: 920px)"
  // );
  // const matchesMobile = useMediaQuery("(max-width: 520px)");


  const iduser = invites.user_fk;
  const idzone = invites.zone_fk;
  const token = invites.token;
  const id = invites.id;

  const toast = useRef<Toast>(null)

  const { DeleteInviteZoneMutation } = InviteZoneController({toast: toast})

  

  const { ConfirmInvitesZoneMutation } =
    ConfirmEmailZoneController({toast: toast});

  const InvitesOptions = () => {
    Swal.fire({
      title: 'Deseja Aceitar o Convite?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Aceitar',
      confirmButtonColor: '#33B270',
      denyButtonText: `Recusar`,
      cancelButtonText: "Cancelar"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        ConfirmInvitesZoneMutation.mutate({ id: iduser!, idzone: idzone!, token: token! });
      } else if (result.isDenied) {
        DeleteInviteZoneMutation.mutate(parseInt(id))
      }
    })
  }



  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'];

  const date = new Date(invites.createdAt);

  var day: any = date.getDate()

  if (day < 10) {
    day = '0' + day;
  }

  var postDate = day + " de " + months[date.getMonth()].substring(0, 3);

  return (
    <CardInvite onClick={InvitesOptions}>
      <Toast ref={toast}/>
      <Column id="space-between" style={{height: "100%"}}>
        <h4>{invites.description}</h4>
        <p>{postDate}</p>
      </Column>
    </CardInvite>
  );
};

export default CardsInvites;
