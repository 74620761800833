import { Icon } from "../../../ludz.one_ui"
import { Padding } from "../../../ludz.one_ui/styles/styled-components"
import { Column, Row } from "../../../styles/globalStyles"
import { ArchivesStyle } from "./style"


interface PropsListArchives {
    item: any
}

const ListArchives = ({ item }: PropsListArchives) => {
    const months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];

    const typeFormats = () => {
        if (item?.original_name?.includes('.pdf')) {
          return "pi pi-file-pdf"  
        }else if(item?.original_name?.includes(".jpg") || item?.original_name?.includes(".png") || item?.original_name?.includes(".gif") || item.original_name.includes(".svg") || item.original_name.includes(".webp") || item.original_name.includes(".tiff")) {
            return "pi pi-image"
        }else {
            return "pi pi-file"
        }
    }

    const formatDate = () => {
        if (item?.createdAt || item?.aws?.createdAt) {
            const date = new Date(item?.createdAt ?? item.aws.createdAt);

            var day: any = date.getDate();

            if (day < 10) {
                day = "0" + day;
            }

            var postDate = day + " de " + months[date.getMonth()].substring(0, 3);
            return postDate;
        } else {
            return "Não possui data";
        }
    };

    return (
        <ArchivesStyle >
            <Row onClick={() => window.open(item?.aws?.url)}>
                <Column id="center">
                <Icon icon={typeFormats()} />
                </Column>
                <Padding padding="1px" />
                <Column id="center">
                    <h4>{item?.original_name}</h4>
                    <p style={{ fontSize: "12px" }}>{formatDate()}</p>
                </Column>
            </Row>

        </ArchivesStyle>
    )
}
export default ListArchives;