import { BrowserRouter, Route, Routes } from "react-router-dom";
import BuyChannel from "../containers/BuyChannel";
import BuyZone from "../containers/BuyZone";
import ChannnelComplet from "../containers/Channel/ChannelComplet";
import ChannelsPublic from "../containers/ChannelsPublic";
import Home from "../containers/Home";
import LandingPage from "../containers/LandingPage";
import Subzone from "../containers/Subzone";
import ForgetPassword from "../containers/User/ForgetPassword";
import Login from "../containers/User/Login";
import Profile from "../containers/User/Profile";
import SignUp from "../containers/User/SignUp";
import CreateZone from "../containers/Zone/CreateZone";
import Zone from "../containers/Zone/Zone";
import ZonesPublic from "../containers/ZonesPublic";
import ConfirmationEmail from "../screens/ConfirmEmails/ConfirmationEmail";
import ConfirmZoneInvite from "../screens/ConfirmEmails/ConfirmZoneInvite";
import Dashboard from "../screens/TestFreya";
import Pass from "../screens/User/Pass";
import PrivateRoute from "./privateroutes";

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={<PrivateRoute typeLayout="home" Component={<Home />} />}
          path="/home"
        />
        <Route
          element={
            <PrivateRoute typeLayout="home" Component={<ZonesPublic />} />
          }
          path="/home/zonaspublicas"
        />
        <Route
          element={
            <PrivateRoute typeLayout="home" Component={<ChannelsPublic />} />
          }
          path="/home/canaispublicos"
        />
        <Route
          element={
            <PrivateRoute typeLayout="home" Component={<ChannnelComplet />} />
          }
          path="/canal/:id/:idVideo"
        />
        <Route
          element={
            <PrivateRoute typeLayout="home" Component={<ChannnelComplet />} />
          }
          path="/canal/:id"
        />
        <Route
          element={<PrivateRoute typeLayout="home" Component={<BuyZone />} />}
          path="/comprazonas/:id"
        />
        <Route
          element={
            <PrivateRoute typeLayout="home" Component={<BuyChannel />} />
          }
          path="/compracanal/:id"
        />
        <Route
          element={
            <PrivateRoute typeLayout="profile" Component={<Profile />} />
          }
          path="/perfil/:id"
        />
        <Route
          element={
            <PrivateRoute typeLayout="home" Component={<CreateZone />} />
          }
          path="/criar/zona"
        />
        <Route
          element={<PrivateRoute typeLayout="zone" Component={<Zone />} />}
          path="/zona/:id/:container"
        />
        <Route
          element={<PrivateRoute typeLayout="zone" Component={<Zone />} />}
          path="/zona/:id/:container/:idsessioncontent"
        />

        <Route
          element={
            <PrivateRoute typeLayout="subzone" Component={<Subzone />} />
          }
          path="/zona/:id/subzona/:idSubzone/:container"
        />
        <Route
          element={
            <PrivateRoute typeLayout="subzone" Component={<Subzone />} />
          }
          path="/zona/:id/subzona/:idSubzone/:container/:idcard"
        />
        <Route element={<LandingPage />} path="/" />
        <Route element={<Pass />} path="/pass/:token" />
        <Route element={<Pass />} path="/pass/profile/:id/:token" />
        <Route element={<Login />} path="/login" />
        <Route element={<SignUp />} path="/register" />
        <Route element={<Dashboard />} path="/test" />

        <Route element={<SignUp />} path="/invite-to-ludz/:token/:email" />
        <Route
          element={<ForgetPassword />}
          path="/forgot-password/:id/:token"
        />

        <Route
          element={<ConfirmationEmail />}
          path="/users/verify-email/:id/:token"
        />
        <Route
          element={<ConfirmZoneInvite />}
          path="/zone-user/confirm-invite/:id/:idzone/:token"
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
