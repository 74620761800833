import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ControllerInformation } from "../../../ludzoneSDK/Zone/Information/controller";
import { ZonaContext } from "../Zone/context";
import { ZonaContextType } from "../Zone/type";
import { PropsInitialValue } from "./type";
import * as Yup from "yup";
import { PropsCreateTagsZone } from "../../../ludzoneSDK/Zone/Information/type";
import { TypeTagZone } from "../../../ludzoneSDK/Zone/type";
import { Toast } from "primereact/toast";

export const ZoneInfoState = () => {
  const { id } = useParams();

  const toast = useRef<Toast>(null)

  const {
    UpdateAvatarZoneMutation,
    UpdateZoneMutation,
    RemoveAvatarZoneMutation,
    DeleteZoneMutation, 
    CreateZoneTagsMutation,
    UpdateTagZoneMutation,
    DeleteTagZoneMutation,
    UpdateCoverZoneMutation,
    RemoveCoverZoneMutation
  } = ControllerInformation({toast: toast});

  const { zone, refetch } = useContext(ZonaContext) as ZonaContextType;

  const [zonename, setZoneName] = useState(zone?.name);
  const [zonedescription, setZoneDescription] = useState(zone?.description);
  const [zoneispublic, setZoneIsPublic] = useState(zone?.isPublic);
  const [isediting, setIsEditing] = useState(false);
  const [visibleTag, setVisibleTag] = useState(false);
  const [fileAvatar, setFileAvatar] = useState<File | undefined>();
  const [fileCover, setFileCover] = useState<File | undefined>();
  const [tags, setTags] = useState<TypeTagZone[]>();

  useEffect(() => {
    if (zone?.tags) {
      setTags(zone.tags);
    }
  }, [zone?.tags, tags]);


  const initialValues: PropsInitialValue = {
    name: zonename,
    description: zonedescription,
    isPublic: zoneispublic,
  };

  const HandleRemoveAvatarInfo = () => {
    RemoveAvatarZoneMutation.mutate(
      { id: id! }
    );
  };

  const HandleSaveAvatarInfo = (image: File | undefined, set: any) => {
  
      const formData = new FormData()
      if (image) {
        formData.append('avatar', image)

        UpdateAvatarZoneMutation.mutate({ file: image, id: id! }, {
          onSuccess: (data, variables, context) => {
              // I will fire second!
              refetch();
            },
            onError: (error, variables, context) => {
              // I will fire second!
            },
        });
      }

      
    
    set(null);
  };

  const HandleSaveCoverZone = (image: File | null, set: any) => {
    const formData = new FormData()
    if (image != null) {
      formData.append('cover', image)
      UpdateCoverZoneMutation.mutate({ body: image, id: id! }, {
        onSuccess: (data, variables, context) => {
            // I will fire second!
            refetch();
          },
          onError: (error, variables, context) => {
            // I will fire second!
          },
      });
    }
    set(null);
  };

  const HandleRemoveCoverZone = () => {
    RemoveCoverZoneMutation.mutate(
      { id: id! }
    );
  };

  const HandleSaveInfo = (body: PropsInitialValue) => {
    UpdateZoneMutation.mutate({ body: body, id: id! });
    refetch();
    setIsEditing(false);
  };

  const initialValuesTags = {
    name: "",
    zone: 0
  }

  const schema = Yup.object().shape({
    name: Yup.string().required("O campo obrigatório"),
  });

  const HandleCreateTag = (body: PropsCreateTagsZone) => {
    CreateZoneTagsMutation.mutate(body)
    setVisibleTag(false);
  }

  const handleEditTag = (data: { name: string }, id: string) => {
    UpdateTagZoneMutation.mutate({ data: data, id: id });
  };

  const DeleteTag = (id: number) => {
    const updatedTag = tags!.filter((obj) => obj.id !== id);
    setTags(updatedTag);
    DeleteTagZoneMutation.mutate(id.toString());
  };

  const HandleDeleteZone = () => {
    DeleteZoneMutation.mutate({id: id!});
  }

  return {
    HandleSaveAvatarInfo,
    zonename,
    setZoneName,
    zonedescription,
    setZoneDescription,
    zoneispublic,
    setZoneIsPublic,
    HandleSaveInfo,
    HandleRemoveAvatarInfo,
    isediting,
    setIsEditing,
    initialValues,
    HandleDeleteZone,
    HandleCreateTag,
    initialValuesTags,
    schema,
    visibleTag, 
    setVisibleTag,
    tags, 
    setTags,
    handleEditTag,
    DeleteTag,
    toast,
    HandleSaveCoverZone,
    HandleRemoveCoverZone,
    fileAvatar,
    setFileAvatar,
    fileCover,
    setFileCover
  };
};
