import { useFetchUserBff } from "./query";





export const UserBffController = (id: string | undefined) => {

    const { data, refetch } = useFetchUserBff(id)

    return{
        data, refetch
    }
}