import http from "../../../services/api";
import { logout } from "../../../services/localStorage";
import { PropsSubzoneTasks } from "./type";

export const SubzoneTaskRequest = async (body: PropsSubzoneTasks) => {
    return await http.post("/bff/aux-bff/create-task", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const UpdateIsFinishSubzoneTaskRequest = async (body: {is_finished: boolean}, id: number) => {

    return await http.put(`/direct/kanban-card/${id}`, body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const DeleteTaskRequest = async (id: number) => {

    return await http.delete(`/direct/kanban-card/${id}`).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

// export const GetSubzonePostRequest = async (id: number) => {
//     return await http.get("/sub-zone-post", {
//         params: {
//             include: JSON.stringify({
//                 owner: true
//             }),
//             subZone_fk: id,
//         }
//     }).then(response => response.data)
//         .catch(err => {
//             if (err.response.status === 401) {
//                 logout()
//                 window.location.reload()
//             }
//             alert(err)
//             throw err;
//         });
// } 