const typography = {
    font: {
      extraSmall: "14px",
      small: "17px",
      medium: "20px",
      large: "5vh",
      extraLarge: "6vh",
    },
    types: {
      light: "Poppins Light",
      regular: "Poppins Regular",
      extraLight: "Poppins ExtraLight",
      semiBold: "Poppins Bold",
      bold: "Poppins SemiBold",
      inter: "Inter",
      fontfamilyP: "Renogare, Roboto,sans-serif",
      fontfamilyS: "Quicksand, Poppins,sans-serif",
      varelaRound: "Varela Round"
    }
  };

  export default typography;