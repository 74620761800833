import { useRef, useState } from 'react';
import * as Yup from 'yup';
import { ObjectiveKeySubzoneController } from '../../../../ludzoneSDK/Subzone/Objective/ObjectiveKey/controller';
import { PropsSubzoneObjectiveKey } from '../../../../ludzoneSDK/Subzone/Objective/ObjectiveKey/type';
import { Toast } from 'primereact/toast';


export const ObjectivekeySubzoneState = () => {

    const [id, setId] = useState<number>(0)
    const [color, setColor] = useState("gray");

    const toast = useRef<Toast>(null)

    const { ObjectiveKeySubzoneMutation, UpdateObjectiveKeySubzoneMutation, DeleteObjectiveKeySubzoneMutation } = ObjectiveKeySubzoneController(id!, {toast: toast})

    const initialValue = {
        content: "",
        description: "",
        isFinished: false
    }



    const schema = Yup.object().shape({
        content: Yup.string().required('Nome é obrigatória'),
        description: Yup.string().required('Descrição é obrigatória'),
    });



    const handleObjectiveKey = (data: PropsSubzoneObjectiveKey, id: number, onOpen: any) => {
        ObjectiveKeySubzoneMutation.mutate({
            objective: id, content: data.content, color: color, description: data.description,
            isFinished: data.isFinished,
        })
        onOpen(false)
    }

    const handleUpdateObjectiveKey = (body: {content: string, description: string}, id: string,) => {
        UpdateObjectiveKeySubzoneMutation.mutate({body: body, id: id})
       
    }

    const handleDeleteObjectivekey = (id: string) => {
        
        DeleteObjectiveKeySubzoneMutation.mutate(id)
    }

    return {
        handleObjectiveKey, initialValue, schema, setId, id, handleUpdateObjectiveKey, handleDeleteObjectivekey, color, setColor, toast
    }
}