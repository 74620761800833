import { useMutation } from "react-query";
import { DeleteSessionContentsZoneRequest } from "./InsiderSession/request";
import { useFetchZoneSessionsBff } from "./query";
import { DeleteSessionZoneRequest, EditTextSessionZoneRequest, SessionZoneRequest } from "./request";
import { PropsSession } from "./type";

export const SessionZoneController = (id: number) => {

    const { data: sessionsRequest, refetch } = useFetchZoneSessionsBff(id)


    const SessionZoneMutation = useMutation((data: PropsSession) => SessionZoneRequest(data), {
        onMutate: () => {
        },
        onError: (error: any) => {
            console.log(error)
        },
        onSuccess: (result: any) => {
            refetch()
        },
    });

    interface PropsUpdateSession {
        data: {name: string},
        id: string
    }

    const UpdateSessionZoneMutation = useMutation(({data, id}: PropsUpdateSession) => EditTextSessionZoneRequest(data, id), {
        onMutate: () => {
        },
        onError: (error: any) => {
            console.log(error)
        },
        onSuccess: (result: any) => {
            refetch()
        },
    });
    

    const DeleteSessionContentsZoneMutation = useMutation((id: string) => DeleteSessionContentsZoneRequest(id), {
        onMutate: () => {
        },
        onError: (error: any) => {
            console.log(error)
        },
        onSuccess: (result: any) => {
            refetch()
            window.location.reload();
        },
    });

    const DeleteSessionZoneMutation = useMutation((id: string) => DeleteSessionZoneRequest(id), {
        onMutate: () => {
        },
        onError: (error: any) => {
            console.log(error)
        },
        onSuccess: (result: any) => {
            refetch()
        },
    });
    

    return {
        SessionZoneMutation, sessionsRequest, DeleteSessionContentsZoneMutation, DeleteSessionZoneMutation, refetch, UpdateSessionZoneMutation
    }
}