import { useContext } from "react"
import { ZonaContext } from "../../../../contexts/Zone/Zone/context"
import { ZonaContextType } from "../../../../contexts/Zone/Zone/type"
import MenuPrime from "../../../../ludz.one_ui/Menu/MenuPrime"

const OverlayPanelUserMember = ({item}: {item: any}) => {

    const { zone, HandleRemoveMember, HandleUpdateMember } = useContext(ZonaContext) as ZonaContextType


    let items = [
        { label: `${item.isAdmin ? "Remover" : "Tornar"} administrador`, command: () => { HandleUpdateMember(item.user.id, zone!.id, !item.isAdmin) }, icon: 'pi pi-fw pi-pencil' },
        { label: 'Remover da zona', command: () => { HandleRemoveMember(zone!.id.toString(), item.user.id.toString()) }, icon: 'pi pi-fw pi-trash' },
      ]

    return(
        <MenuPrime items={items} />
    )
}

export default OverlayPanelUserMember