import { useEffect, useState } from 'react';
import { Progress } from '../../../../ludz.one_ui';
import { Padding } from '../../../../ludz.one_ui/styles/styled-components';
import { PropsSessionContent } from '../../../../ludzoneSDK/Zone/Session/type';
import { Row } from '../../../../styles/globalStyles';
import { Inline } from '../../Zone/Timeline/BodySession/style';

// import { Container } from './styles';

interface PropsContenText {
    item: PropsSessionContent;
}

const ContentText = ({item}: PropsContenText) => {
    const [content, setContent] = useState<PropsSessionContent>();

    useEffect(() => {
      if (item) {
        setContent(item);
      }
    }, [item]);

  return (
    <div>
        {content ? 
            <>
              <Padding padding="8px">
              <Row id='space-between'>
                <h5>{item.title ?? "Sem titulo"}</h5>
                {item.original_name && <i className='pi pi-file' />}
                {item.playlist_content && <i className='pi pi-video' />}
              </Row>
            </Padding> 
            <Inline /> 
            </>
        : <Progress />}
    </div>
);
}

export default ContentText;