import styled from "styled-components";
import styles from "../../../../ludz.one_ui/styles";

export const AddObjective = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  cursor: pointer;
  color: ${styles.colors.secondary};
  &:hover {
    color: ${styles.colors.secondaryHover};

    button {
      background-color: ${styles.colors.secondaryHover};
    }
  }
`;

export const Inline = styled.div`
  height: 1px;
  margin: 16px 0;
  background-color: #d5d5d5;
`;