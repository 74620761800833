import http from "../../../services/api";
import { logout } from "../../../services/localStorage";
import { TypeInviteEmail, TypeMembersZone } from "./type";

export const UpdateZoneUser = async (body: TypeMembersZone) => {
     return await http.put(`/direct/zone-user`, body).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               alert(err.response.data.message)
               throw err;
          })
}

export const InviteforEmailZoneUser = async (body: TypeInviteEmail) => {
     return await http.post(`/bff/aux-bff/invite-user-platform`, body).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               alert(err.response.data.message)
               throw err;
          })
}

export const GetZoneUser = async (id: string) => {
     return await http.get(`/direct/zone-user`, {
          params: {
               zone_fk: id, 
               include: JSON.stringify({
                    user: true
                }),
          }
     }).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               alert(err.response.data.message)
               throw err;
          })
}

export const RemoveUserZone = async (id: string | null, idZone: string) => {
     return await http.delete(`/bff/zone-user-bff/remove-user`,{params: {zoneId: idZone, userId: id }}).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               alert(err.response.data.message)
               throw err;
          })
}

export const ExitUserZone = async (id: string | null, idZone: string) => {
     return await http.delete(`/bff/zone-user-bff/exit-zone`,{params: {zoneId: idZone }}).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               
               throw err;
          })
}
