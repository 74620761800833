import { useQuery } from "react-query";
import { GetPageObjectiveBffRequest, GetSubzoneObjectiveRequest } from "./request";

export const useFetchSubzoneObjective = (id: number | undefined) => {
    return useQuery(["UseSubzoneObjective", id], () => GetSubzoneObjectiveRequest(id!));
};

export const useFetchSubzonePageObjectiveBff = (id: number | undefined) => {
    return useQuery(["UseSubzoneObjectiveBff", id], () => GetPageObjectiveBffRequest(id!));
};

