import styled from "styled-components";
import styles from "../../../../../ludz.one_ui/styles";

export const Container = styled.div``;

export const Arq = styled.div`
  cursor: pointer;
  :hover {
    color: ${styles.colors.primary};
  }
`;

export const AreaHeaderList = styled.div`
    width: 25%;

    @media (max-width: 992px) {
      width: 22%;
    }

    @media (max-width: 520px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      text-align: center;
    }
`;

export const AreaDescriptionList = styled.div`
 
  align-self: center;
`;

export const CardGrid = styled.div`
  width: 96%;
  height: 466px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;


    @media (max-width: 1024px) {
      height: 475px;
    }

    @media (max-width: 520px) {
      height: 490px;
      width: 94%;
      
    }

    @media (max-width: 380px) {
      height: 530px;
    }
`;