import { useMediaQuery } from "@mui/material"
import { PropsGetBffSession } from "../../../../ludzoneSDK/Zone/Session/type"
import { Column, Row } from "../../../../styles/globalStyles"


type PropsHeaderAccordion = {
    item: PropsGetBffSession
}

const HeaderAccordion = ({ item }: PropsHeaderAccordion) => {
    const matches = useMediaQuery("(max-width: 520px)");

    return (
        <Column style={{ height: "36px" }} id='center'>
            <Row id='space-between' >
                {matches ? <h4 className="vertical-align-middle">{item!.name}</h4>: <h3 className="vertical-align-middle">{item!.name}</h3>}
                {/* {adminorowner ? <Row>
                    <Padding />
                    <Column id='center'>
                        <Icon size="1rem" onClick={(e: any) => { setVisibleEditSection(!visibleEditSection); e.stopPropagation() }} icon="pi pi-pencil mr-2"></Icon>
                    </Column>
                    <Padding />
                    <Column id='center'>
                        <Icon size="1rem" onClick={(e: any) => { setVisible(true); e.stopPropagation() }} icon="pi pi-trash mr-2"></Icon>
                    </Column>
                </Row> : null} */}
            </Row>
        </Column>
    )
}

export default HeaderAccordion;