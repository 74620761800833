import React from 'react';
import FormBusinessProvider from '../../contexts/LandingPage/context';
import LandingPageScreen from '../../screens/NewLandingPage';

// import { Container } from './styles';

const LandingPage = () => {
  return (
    <FormBusinessProvider>
        <LandingPageScreen />
    </FormBusinessProvider>
    );
}

export default LandingPage;