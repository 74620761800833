import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { KanbanSubzonaContext } from "../../../../../../../../contexts/Subzone/KanbanSubzone/context";
import { KanbanSubzonaContextType } from "../../../../../../../../contexts/Subzone/KanbanSubzone/type";
import {
  Button,
  Input,
  ModalInsiderKanbanCard,
} from "../../../../../../../../ludz.one_ui";
import LudzColors from "../../../../../../../../ludz.one_ui/LudzColors";
import { Padding } from "../../../../../../../../ludz.one_ui/styles/styled-components";
import { Row } from "../../../../../../../../styles/globalStyles";
import Tags from "./Tags";

interface PropsModalMembers {
  visible: boolean;
  setvisible: any;
}

const ModalTags = ({ visible, setvisible }: PropsModalMembers) => {
  const [createTagsOpen, setcreateTagsOpen] = useState(false);
  const { CreateTag, kanbanTags } = useContext(
    KanbanSubzonaContext
  ) as KanbanSubzonaContextType;
  const { idSubzone } = useParams();
  const [color, setColor] = useState("gray");

  const [searchUser, setSearchUser] = useState("");

  var TagsSearch: any = kanbanTags;

  if (searchUser !== "") {
    const buscaLowerCase = searchUser.toLowerCase();

    TagsSearch = TagsSearch.filter((member: any) =>
      member.name.toLowerCase().includes(buscaLowerCase)
    );
  }

  return (
    <ModalInsiderKanbanCard
      visible={visible}
      title={"Tags"}
      onOpen={() => setvisible(!visible)}
    >
      {createTagsOpen ? (
        <Formik
          initialValues={{ name: "" }}
          onSubmit={(values) => {
            CreateTag({
              color: color,
              name: values.name,
              subZone: parseInt(idSubzone!),
            });
            setcreateTagsOpen(false);
          }}
        >
          {({ values, handleChange }) => {
            return (
              <Form>
                <div>
                  <h5 style={{ color: "#808080", marginTop: "10px" }}>
                    Criar Tags
                  </h5>
                  <Padding />
                  <Input
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    placeholder="Título da Tag"
                  />
                  <Padding />
                  <h5>Selecione a cor:</h5>
                  <Padding />
                   <LudzColors setColor={setColor} /> 
                  <Padding padding="10px" />
                  <Row id="end">
                    <Button title="Criar" type="submit" />

                    <Button
                      onClick={() => setcreateTagsOpen(false)}
                      raised
                      text
                    >
                      Cancelar
                    </Button>
                  </Row>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <>
          <Input
            placeholder="Pesquisar Tags"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            onChange={(e: any) => setSearchUser(e.target.value)}
          />
          <h5 style={{ color: "#808080", marginBottom: "10px" }}>Tags</h5>
          {kanbanTags?.length > 0 ? (
            <>
              {TagsSearch?.map((item: any, index: number) => {
                return <Tags key={index} item={item} />;
              })}
            </>
          ) : (
            <Row id="center">
              <h3>Sem Tags</h3>
            </Row>
          )}

          <Padding padding="8px" />
          <Row id="center">
            <Button
              title="Criar tags"
              onClick={() => setcreateTagsOpen(true)}
            />
          </Row>
        </>
      )}
    </ModalInsiderKanbanCard>
  );
};

export default ModalTags;
