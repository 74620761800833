import { Formik } from "formik"
import { useContext } from "react"
import { LoginContext } from "../../../../contexts/User/Login/context"
import { LoginContextType } from "../../../../contexts/User/Login/types"
import { Button, Input, Modal } from "../../../../ludz.one_ui"
import { Padding } from "../../../../ludz.one_ui/styles/styled-components"
import { Row } from "../../../../styles/globalStyles"

interface PropsModalForgetPassword {
    visible: boolean,
    onOpen: any
}

const ModalForgetPassword = ({ visible, onOpen }: PropsModalForgetPassword) => {

    const { ForgetPassword } = useContext(LoginContext) as LoginContextType
    return (
        <Modal title={"Recuperar senha"} visible={visible} onOpen={onOpen} >
            <Formik initialValues={{ email: "" }} onSubmit={(values) => { ForgetPassword(values);onOpen() }}>
                {({ values, handleChange, handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Input value={values.email} onChange={handleChange} name="email" label="Email" placeholder="Digite o email da sua conta" />
                            <Padding padding="8px" />
                            <Row id="center">
                                <Button title={"Enviar"} type="submit" />
                            </Row>
                        </form>
                    )
                }}
            </Formik>
        </Modal>
    )
}

export default ModalForgetPassword