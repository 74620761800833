import { useNavigate } from "react-router-dom";
import { Container, ImageRoom } from "./style";
import { TypeZoneSubzone } from "../../../ludzoneSDK/Zone/type";
import { Tooltip } from "../../../ludz.one_ui";

interface PropsSubzonaMenu {
  subzona?: TypeZoneSubzone;
  refetch: any
}

const SubzonasMenu = ({ subzona, refetch }: PropsSubzonaMenu) => {
  let history = useNavigate();

  function linkHistory() {
    history(`/zona/${subzona?.zone_fk}/subzona/${subzona?.id}/painel`);
    window.location.reload()
  }

  return (
    <Container>
      <Tooltip title={subzona?.name} placement="right" arrow>
        <ImageRoom onClick={linkHistory}>
          <img src={subzona?.aws?.url!} alt="" />
        </ImageRoom>
      </Tooltip>
    </Container>
  );
};

export default SubzonasMenu;
