import { Menu, MenuDirection } from '@szhsin/react-menu';
import { Padding } from "../styles/styled-components";
import MenuPrime from './MenuPrime';


interface PropsMenu {
    button?: any,
    items?: any,
    direction?: MenuDirection,
    width?: string
}

const LudzMenu = ({ button, items, direction, width }: PropsMenu) => {

    // exemple items = [{label: name, command: () => {}}]

    return (
        <>
            <Menu menuButton={button} direction={direction}>
                <Padding padding='8px' />
                <div style={{ background: "white", boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }}>
                    <MenuPrime width={width}  items={items} />
                </div>
            </Menu>
        </>
    )
}

export default LudzMenu;