import Lottie from "lottie-react";
import LudzIAIcon from "../../../assets/LudzIA.json";
import ReactMarkdown from "react-markdown";
import { ChatMessageType } from "./types";
import { Avatar, Tooltip } from "../../../ludz.one_ui";
import { IAMessage, Message, UserMessage } from "../IAstyles";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialOceanic } from "react-syntax-highlighter/dist/esm/styles/prism";

interface ChatMessageProps {
  message: ChatMessageType;
  index: number;
  userImage: string | undefined;
  handleCopy: (text: string) => void;
  handleShare: (text: string) => void;
}

const ChatMessage = ({
  message,
  index,
  userImage,
  handleCopy,
  handleShare,
}: ChatMessageProps) => {

  const formatMessageContentCopy = (content: string) => {
    // Substituir ### por ponto final
    let formattedContent = content.replace(/###/g, "•");

    // Remover a formatação de **texto** para copiar sem HTML
    formattedContent = formattedContent.replace(/\*\*(.*?)\*\*/g, "$1");

    // Remover a formatação de `código` inline para copiar sem HTML
    formattedContent = formattedContent.replace(/`([^`]+)`/g, "$1");

    // Remover a formatação de ```código``` para copiar sem HTML
    formattedContent = formattedContent.replace(/```([\s\S]*?)```/g, "$1");

    return formattedContent;
  };

  return (
    <Message key={index} sender={message.role}>
      {message.role === "user" ? (
        <UserMessage lang="pt-br">
          <div className="flex align-items-center align-self-end mb-2">
            <h5>Você</h5>
            <Avatar
              shape="circle"
              img={userImage}
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                marginLeft: "8px",
                cursor: "pointer",
                alignSelf: "flex-end",
                flexShrink: "0",
              }}
            />
          </div>
          <p>{message.content}</p>
        </UserMessage>
      ) : message.role === "assistant" ? (
        <IAMessage lang="pt-br">
          <div className="flex align-items-center align-self-start mb-2">
            <Lottie
              style={{
                marginRight: "8px",
                width: "30px",
                height: "30px",
                alignSelf: "flex-start",
              }}
              className="lottie-avatar"
              animationData={LudzIAIcon}
            />
            <h5>Ludz IA</h5>
          </div>
          <div className="markdown-content">
            <ReactMarkdown
                         components={{
                          code({ node, inline, className, children, ...props }: any) {
                            const match = /language-(\w+)/.exec(className || '');
                            return !inline && match ? (
                              <SyntaxHighlighter style={materialOceanic} language={match[1]} PreTag="div" {...props}>
                                {String(children).replace(/\n$/, '')}
                              </SyntaxHighlighter>
                            ) : (
                              <code className={className} {...props}>
                                {children}
                              </code>
                            );
                          }
                        }}
            >
              {message.content}
            </ReactMarkdown>
          </div>

          <div
            className="flex align-self-start mt-2 gap-2"
            style={{ color: "#333333" }}
          >
            <Tooltip title="copiar">
              <i
                onClick={() =>
                  handleCopy(formatMessageContentCopy(message.content))
                }
                className="pi pi-copy cursor-pointer"
              />
            </Tooltip>
            <Tooltip title="compartilhar">
              <i
                onClick={() =>
                  handleShare(formatMessageContentCopy(message.content))
                }
                className="pi pi-share-alt cursor-pointer"
              />
            </Tooltip>
          </div>
        </IAMessage>
      ) : null}
    </Message>
  );
};

export default ChatMessage;
