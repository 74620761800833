import http from "../../../services/api";
import { logout } from "../../../services/localStorage";
import { TypeEditPassword } from "./type";


export const EditPasswordRequest = async (body: TypeEditPassword) => {
    return await http.put("/bff/users-bff/change-password", body).then(response => response.data)
         .catch(err => {
              if (err.response.status === 401) {
                   logout()
                   window.location.reload()
              }
              throw err;
         });
}