import { useParams } from "react-router-dom";
import { ControllerProfile } from "../../../../ludzoneSDK/Profile/ProfileVisible/controller";
import { useContext, useRef, useState } from "react";
import { UserProfileContext } from "../context";
import { UserProfileContextType } from "../type";
import { PropsInitialValue } from "./type";
import { Toast } from "primereact/toast";

export const ProfileState = () => {
  const { id } = useParams();

  const toast = useRef<Toast>(null)

  const {
    UpdateProfileVisibleMutation,
    UpdateAvatarProfileMutation,
    RemoveAvatarProfileMutation,
  } = ControllerProfile({toast: toast});

  const { userprofile, refetch } = useContext(
    UserProfileContext
  ) as UserProfileContextType;

  const [name, setName] = useState(userprofile?.name);
  const [username, setUserName] = useState(userprofile?.username);
  const [isediting, setIsEditing] = useState(false);

  const initialValues: PropsInitialValue = {
    name: name,
    username: username,
  };

  const HandleSaveInfo = (body: PropsInitialValue) => {
    UpdateProfileVisibleMutation.mutate({ body: body });
    refetch();
    setIsEditing(false);
  };

  const HandleRemoveAvatar = () => {
    RemoveAvatarProfileMutation.mutate(
      { id: id! }
    );
  };

  const HandleSaveAvatar = (image: File | null, set: any) => {
    if (image != null) {
    

      UpdateAvatarProfileMutation.mutate(
        { body: image }
      );
    }
    set(null);
  };

  return {
    name,
    setName,
    username,
    setUserName,
    isediting,
    setIsEditing,
    HandleSaveInfo,
    HandleSaveAvatar,
    HandleRemoveAvatar,
    initialValues,
    toast
  };
};
