import React from "react";

import { Skeleton } from 'primereact/skeleton';

interface PropsLudzSkeleton {
    width?: string,
    shape?: "rectangle" | "circle",
    size?: string,
    className?: string,
    props?: any
}


const LudzSkeleton = ({ width, shape, size, className, props }: PropsLudzSkeleton) => {
    return <Skeleton width={width} shape={shape} size={size} className={className} {...props} />
}

export default LudzSkeleton;