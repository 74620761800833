import { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import * as Yup from 'yup';
import { KanbanSubzoneController } from '../../../ludzoneSDK/Subzone/Kanban/controller';
import { PropsSubzoneKanban } from '../../../ludzoneSDK/Subzone/Kanban/type';
import { KanbanTagsController } from '../../../ludzoneSDK/Subzone/Kanban/KanbanTags/controller';
import { PropsKanbanTags } from '../../../ludzoneSDK/Subzone/Kanban/KanbanTags/type';
import { useFetchSubzoneKanbanTags } from '../../../ludzoneSDK/Subzone/Kanban/KanbanTags/query';
import { Toast } from 'primereact/toast';


export const KanbanSubzoneState = () => {

    const {idSubzone} = useParams()

    const [kanban, setkanban] = useState()

    const [kanbanTags, setKanbanTags] = useState()

    const {KanbanTagsSubzoneMutation, DeleteKanbanTagsSubzoneMutation} = KanbanTagsController()

    const {data: tagsRequest} = useFetchSubzoneKanbanTags(parseInt(idSubzone!));

    const toastKanbanList = useRef<Toast>(null)

    const { KanbanSubzoneMutation, kanbanRequests, DeleteKanbanSubzoneMutation, UpdateSubzoneMutation} = KanbanSubzoneController(parseInt(idSubzone!), {toast: toastKanbanList})

    const initialValue = {
        title: ""
    }

    useEffect(() => {
     if(kanbanRequests){
        setkanban(kanbanRequests)
     }
     if(tagsRequest){
        setKanbanTags(tagsRequest)
     }
    }, [kanbanRequests, tagsRequest])
    

    const schema = Yup.object().shape({
        title: Yup.string().required('Campo obrigatório'),
      });
      
    const handleKanban = (data: PropsSubzoneKanban, id: number, index: number) => {
        
        KanbanSubzoneMutation.mutate({ subZone: id, title: data.title, index: index })
    }

    const handleUpdateKanban = (data: {title: string}, id: number) => {
        
        UpdateSubzoneMutation.mutate({ body: data, id: id})
    }

    const CreateTag = (body: PropsKanbanTags) => {
        KanbanTagsSubzoneMutation.mutate(body)
    }


    const handleDeleteKanban = (id: number) => {
        DeleteKanbanSubzoneMutation.mutate(id)
    }

    const deleteTags = (id: number) => {
        DeleteKanbanTagsSubzoneMutation.mutate(id)
    }
    return {
        handleKanban, initialValue, schema, kanban, setkanban, handleDeleteKanban, CreateTag, kanbanTags, deleteTags, handleUpdateKanban, toastKanbanList
    }
}