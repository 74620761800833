import { useContext, useState } from "react"
import { Button } from "../../../../../ludz.one_ui"
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components"
import { PropsGetSubzoneObjectiveKeyBff } from "../../../../../ludzoneSDK/Subzone/Objective/type"
import { Column } from "../../../../../styles/globalStyles"
import CreateTask from "../CreateTask"
import Task from "../Tasks"
import { AddObjective } from "../style"
import { Box } from "./style"
import { ObjectiveSubzonaContextType } from "../../../../../contexts/Subzone/ObjectiveSubzone/type"
import { ObjectiveSubzonaContext } from "../../../../../contexts/Subzone/ObjectiveSubzone/context"
import { Toast } from "primereact/toast"

interface PropsObjectiveKey {
    item?: PropsGetSubzoneObjectiveKeyBff,
}

const ObjectiveKey = ({ item }: PropsObjectiveKey) => {


    const [visible, setVisible] = useState(false);
    const { toastTask } = useContext(ObjectiveSubzonaContext) as ObjectiveSubzonaContextType

   
    return (
        <Box>
            <Toast ref={toastTask} />
            {/* <h3>Meta</h3>
            <Padding />
            <h5>{item?.content}</h5>
            <Padding /> */}
            <h3>Tarefas</h3>
            <Padding />
            {item!.kanbanCardObjectives?.map((data) => {
                return (
                    <Task item={data} idObjetictive={item?.objective_fk!} />
                )
            })}
            <Padding />
            {true ? (
                <AddObjective
                    onClick={() => setVisible(!visible)}
                >
                    <Button icon="pi pi-plus" rounded style={{ width: "30px", height: "30px" }} aria-label="Filter" />
                    <Padding />
                    <Column id="center">
                        <h4>Adicionar Tarefas</h4>
                    </Column>
                </AddObjective>
            ) : null}
            <CreateTask visible={visible} onOpen={() => setVisible(!visible)} data={item} id={item!.objective_fk}  />
        </Box>
    )
}

export default ObjectiveKey;