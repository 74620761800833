import React, { useState } from "react";

import * as C from "./style";
import CustomImageInput from "../../../ludz.one_ui/CustomImage";
import { Tooltip } from "../../../ludz.one_ui";
import { Column } from "../../../styles/globalStyles";

interface AvatarEditorProps {
  initialAvatarUrl: string | undefined;
  HandleSaveAvatar: any;
  isediting: boolean | undefined;
  setIsEditing: any;
  remove: () => void;
}

const EditAvatar: React.FC<AvatarEditorProps> = ({
  initialAvatarUrl,
  HandleSaveAvatar,
  isediting,
  setIsEditing,
  remove,
}) => {
  const [avatarUrl, setAvatarUrl] = useState(initialAvatarUrl);
  const [image, setImage] = useState<File | null>(null);

  return (
    <C.Container>
      <Column>
        {image ? (
          <C.AreaImage>
            <CustomImageInput
              name="file"
              url={avatarUrl}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.files) {
                  const file = event.target.files[0];
                  setAvatarUrl(URL.createObjectURL(file));
                  setImage(file);
                }
              }}
            />
            <C.AreaButtons>
              <Tooltip title="Salvar" placement="bottom" arrow>
                <i
                  className="pi pi-check"
                  style={{
                    marginRight: "10px",
                    color: "green",
                    fontSize: "1rem",
                  }}
                  onClick={() => HandleSaveAvatar(image, setImage)}
                ></i>
              </Tooltip>
              <Tooltip title="Remover" placement="bottom" arrow>
                <i
                  onClick={remove}
                  className="pi pi-trash"
                  style={{
                    marginRight: "10px",
                    color: "orange",
                    fontSize: "1rem",
                  }}
                ></i>
              </Tooltip>

              <Tooltip title="Cancelar" placement="bottom" arrow>
                <i
                  onClick={() => {
                    setImage(null);
                    setAvatarUrl(initialAvatarUrl);
                  }}
                  className="pi pi-times"
                  style={{ color: "red", fontSize: "1rem" }}
                ></i>
              </Tooltip>
            </C.AreaButtons>
          </C.AreaImage>
        ) : (
          <C.AreaImage>
            <CustomImageInput
              name="file"
              url={avatarUrl}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.files) {
                  const file = event.target.files[0];
                  setAvatarUrl(URL.createObjectURL(file));
                  setImage(file);
                }
              }}
            />
          </C.AreaImage>
        )}
      </Column>
    </C.Container>
  );
};

export default EditAvatar;
