import { useMutation } from "react-query";
import {  ExitUserZone, InviteforEmailZoneUser, RemoveUserZone, UpdateZoneUser } from "./request";
import queryClient from "../../../services/react-query";
import { TypeInviteEmail, TypeMembersZone } from "./type";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../../components/Alert";

export const ControllerZoneUser = ({
    toast,
  }: {
    toast?: RefObject<Toast>;
  }) => {
    const history = useNavigate()
    interface PropsRemoveUserZone {
        id: string,
        idZone: string
    }

    const RemoveUserZoneMutation = useMutation(({idZone, id}: PropsRemoveUserZone) => RemoveUserZone(id, idZone), {
        onMutate: () => {

        }, 
        onError: (error: any) => {
            console.log(error)
        },
        onSuccess: (result, veriables, context) => {
            queryClient.refetchQueries("UseZoneIdBff");

        }
    });

    
    const ExitUserZoneMutation = useMutation(({idZone, id}: PropsRemoveUserZone) => ExitUserZone(id, idZone), {
        onMutate: () => {

        }, 
        onError: (error: any) => {
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
        },
        onSuccess: (result, veriables, context) => {
            queryClient.refetchQueries("UseZoneIdBff");
            queryClient.refetchQueries("UseUserHome")
            history("/home")
            Swal.fire({
                icon: "success",
                title: "Você saiu da zona",
                showConfirmButton: false,
                timer: 2000
              });
        }
    });
    // interface PropsUpdateAvatarZone {
    //     body: FormData,
    //     id: string
    // }

    const UpdateZoneUserMutation = useMutation((body: TypeMembersZone) => UpdateZoneUser(body), {
        onMutate: () => {

        }, 
        onError: (error: any) => {
            console.log(error)
        },
        onSuccess: (result, veriables, context) => {
            queryClient.refetchQueries("UseZoneIdBff");
        }
    });

    const InviteforEmailZoneUserMutation = useMutation((body: TypeInviteEmail) => InviteforEmailZoneUser(body), {
        onMutate: () => {

        }, 
        onError: (error: any) => {
            console.log(error)
        },
        onSuccess: (result, veriables, context) => {
            queryClient.refetchQueries("UseZoneIdBff");
            Swal.fire({
                icon: "success",
                title: "Convite feito com sucesso!",
                showConfirmButton: false,
                timer: 2000
              });

        }
    });

    
    return {
        RemoveUserZoneMutation, UpdateZoneUserMutation, ExitUserZoneMutation, InviteforEmailZoneUserMutation
    }
}