const color = {
    white: "#FFF",
    background: "#ebedf1",
    grayClearOne: "#e5e6ea",
    grayClear: "#868080",
    grayClearOneHover: "#D1D1D1",
    gray: "#5f5b5b",
    purple: "#B06FF1",
    purpleDark: "#9F5FDE",
    yellow:" #f59e0b",
    greenSucess:" #22c55e",
    pink: "#F270FC",
    blue: "#7070FC",
    red: "#ED5A68",
    green: "#05ec02",
    blueClear: "#dcefed",
    primary: "#7F64AA",
    secondary: "#4caf50",
    secondaryHover: "#449e48",
    tertiary: "#33B270",
  };
  
  export default color;