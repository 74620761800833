import { useContext, useEffect, useRef, useState } from "react";
import { PrivateRoutesContext } from "../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../contexts/PrivateRoutes/types";
import { Avatar, Icon, OverlayPanelLudz, Tooltip } from "../../ludz.one_ui";
import { Padding } from "../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../styles/globalStyles";
import Invites from "./Invites";
import { ButtonIA, ButtonIcon, CircleOpenMenu, TopbarStyle } from "./style";

import { Badge } from "primereact/badge";
import User from "./User";
import Notifications from "./Notifications";
import { useMediaQuery } from "@mui/material";
import { getToken } from "../../services/localStorage";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import LudzIAIcon from "../../assets/LudzIA.json";
import LudzIA from "./LudzIA";

interface PropsTopBar {
  openModal?: boolean;
  setOpen?: any;
}

const TopBar = ({ openModal, setOpen }: PropsTopBar) => {
  const { userProvider, invites, notifications } = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;

  const matchesMobileSmall = useMediaQuery("(max-width: 360px)");
  const matchesMobileMedium = useMediaQuery("(max-width: 520px)");

  const countNotification = notifications?.filter(
    (props) => props.isViewed === false
  );
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  const [isHovered, setIsHovered] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (lottieRef.current) {
      // Ajuste a velocidade da animação para durar 1,5 segundos
      lottieRef.current.setSpeed(1.5);
    }
  }, [lottieRef, isHovered]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (lottieRef.current) {
      lottieRef.current.play(); // Inicia a animação ao entrar no hover
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (lottieRef.current) {
      lottieRef.current.stop(); // Para a animação ao sair do hover
    }
  };

  return (
    <TopbarStyle>
      {setOpen! ? (
        <Row style={{ cursor: "pointer" }}>
          {matchesMobileSmall ? 
          <Padding padding="2px" />
          : 
          <Padding padding="8px" />
          }
          
          <CircleOpenMenu>
            <Icon
              icon="pi pi-bars"
              onClick={() => {
                if (setOpen) {
                  setOpen(!openModal);
                }
              }}
            />
          </CircleOpenMenu>
        </Row>
      ) : (
        <div></div>
      )}
      {userProvider && invites && notifications && countNotification ? (
        <Row id="end" style={{ alignItems: "center" }}>
          <>
            {userProvider?.role === "ADMIN" ? (
              <Tooltip title="Ir para Interface Admin">
                <Column id="center">
                  <i
                    style={{
                      fontSize: matchesMobileSmall ? "1.2rem" : "1.4rem",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      (window.location.href =
                        process.env.REACT_APP_PATH_FRONT_ADMIN +
                        "#/pass/" +
                        getToken())
                    }
                    className="pi pi-arrow-up-right"
                  ></i>
                </Column>
              </Tooltip>
            ) : userProvider?.role === "BUSINESS" ? (
              <Tooltip title="Ir para Interface Business">
                <Column id="center">
                  <i
                    style={{
                      fontSize: matchesMobileSmall ? "1.2rem" : "1.4rem",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      (window.location.href =
                        process.env.REACT_APP_PATH_FRONT_BUSINESS +
                        "pass/" +
                        getToken())
                    }
                    className="pi pi-arrow-up-right"
                  ></i>
                </Column>
              </Tooltip>
            ) : null}
          </>
          {!matchesMobileSmall && <Padding padding="4px" />}

          <Column id="center" style={{ cursor: "pointer" }}>
            <ButtonIA
              onClick={() => setVisible(!visible)}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {!matchesMobileMedium && <h4>Deixe nossa IA te ajudar!</h4>}
              
              <Lottie
                lottieRef={lottieRef}
                loop={isHovered}
                className="lottie-button"
                animationData={LudzIAIcon}
              />
            </ButtonIA>
            <LudzIA visible={visible} onOpen={setVisible} />
          </Column>

         {!matchesMobileSmall &&  <Padding padding="2px" />}
          <Tooltip title="convites" placement="bottom" arrow>
            <Column id="center">
              <OverlayPanelLudz
                button={
                  <ButtonIcon>
                    <i
                      className="pi pi-user-plus p-overlay-badge"
                      style={{
                        fontSize: "1.1rem",
                        cursor: "pointer",
                      }}
                    >
                      {invites?.length > 0 ? (
                        <Badge value={invites?.length}></Badge>
                      ) : null}
                    </i>
                  </ButtonIcon>
                }
                components={<Invites />}
              />
            </Column>
          </Tooltip>
          <Padding padding="4px" />
          <Tooltip title="notificações" placement="bottom" arrow>
            <Column id="center">
              <OverlayPanelLudz
                button={
                  <ButtonIcon>
                    <i
                      className="pi pi-bell p-overlay-badge"
                      style={{
                        fontSize: "1.1rem",
                        cursor: "pointer",
                        marginTop: "2px",
                      }}
                    >
                      {countNotification?.length > 0 ? (
                        <Badge value={countNotification?.length}></Badge>
                      ) : null}
                    </i>
                  </ButtonIcon>
                }
                components={<Notifications />}
              />
            </Column>
          </Tooltip>
          {matchesMobileSmall ? <Padding padding="6px" /> :<Padding padding="8px" />}
          <OverlayPanelLudz
            button={
              <Tooltip title="Perfil e visibilidade" placement="bottom" arrow>
                <Column id="center" style={{ cursor: "pointer" }}>
                  <Avatar
                    shape="circle"
                    label={
                      !userProvider.aws.url
                        ? userProvider.name.substring(0, 1)
                        : undefined
                    }
                    img={
                      userProvider.aws.url ? userProvider.aws.url : undefined
                    }
                  />
                </Column>
              </Tooltip>
            }
            components={<User userProvider={userProvider} />}
          />

          <Padding padding={matchesMobileSmall ? "4px" : "16px"} />
        </Row>
      ) : null}
    </TopbarStyle>
  );
};

export default TopBar;
