import { Button, ButtonPassThroughOptions, ButtonProps } from "primereact/button";
import { IconType } from "primereact/utils";

interface ButtonAttributis {
  title?: string;
  onClick?: any;
  fontSize?: string;
  type?: "submit" | "reset" | "button" | undefined;
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  tooltip?: string;
  style?: React.CSSProperties;
  rounded?: boolean | undefined;
  icon?: IconType<ButtonProps>;
  iconPos?: "top" | "bottom" | "left" | "right";
  severity?: "secondary" | "success" | "info" | "warning" | "danger" | "help";
  outlined?: boolean | undefined;
  text?: boolean | undefined;
  raised?: boolean | undefined;
  pt?: ButtonPassThroughOptions | undefined
}

const ButtonPrime = ({
  title,
  className,
  onClick,
  fontSize,
  type,
  disabled,
  icon,
  severity,
  children,
  tooltip,
  rounded,
  iconPos,
  style,
  outlined,
  text,
  raised,
  pt,
  ...props
}: ButtonAttributis) => {
  return (
    <div>
      <Button
        pt={{
          label: { style: { fontWeight: "600" } },
          ...pt
        }}
        disabled={disabled}
        text={text}
        
        icon={icon}
        iconPos={iconPos}
        severity={severity}
        tooltip={tooltip}
        className={className}
        outlined={outlined}
        rounded={rounded}
        type={type}
        label={title}
        
        style={{ fontSize: fontSize, justifyContent: "center", ...style }}
        {...props}
        onClick={onClick}
      >
        {children}
      </Button>
    </div>
  );
};

export default ButtonPrime;
