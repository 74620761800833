import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  EditSessionContentsText,
  SessionContentsZoneController,
} from "../../../ludzoneSDK/Zone/Session/InsiderSession/controller";
import { SessionZoneController } from "../../../ludzoneSDK/Zone/Session/controller";
import {
  PropsGetBffSession,
  PropsSession,
} from "../../../ludzoneSDK/Zone/Session/type";
import { AllContentSessions } from "./type";
import { SessionWatchedZoneController } from "../../../ludzoneSDK/Zone/Session/WatchedSessionContents/controller";

export const SessionZoneState = () => {
  const { id } = useParams();

  const [session, setSession] = useState<PropsGetBffSession[]>();
  const [allContentSession, setallContentSession] =
    useState<AllContentSessions[]>();

  const [accordionDisa, setAccordionDisa] = useState(true);

  const initialValues = {
    name: "",
    isToShow: false,
    index: 0,
    zone: 0,
  };

  const initialValuesContent = {
    textContent: "",
    isToShow: false,
    title: "",
    index: "0",
    session: 0,
  };

  const schema = Yup.object().shape({
    title: Yup.string().required("O campo obrigatório"),
  });

  const {
    SessionZoneMutation,
    sessionsRequest,
    DeleteSessionContentsZoneMutation,
    DeleteSessionZoneMutation,
    refetch,
    UpdateSessionZoneMutation,
  } = SessionZoneController(parseInt(id!));

  const {
    DeleteSessionWatchedContentsZoneMutation,
    SessionWatchedZoneMutation,
  } = SessionWatchedZoneController();

  const { SessionContentsZoneMutation } = SessionContentsZoneController();

  useEffect(() => {
    if (sessionsRequest) {
      setSession(sessionsRequest);
      var array: Array<AllContentSessions> = [];
      session?.forEach((element) => {
        element.contents.forEach((i) => {
          array.push({ id: i.id });
        });
      });
      setallContentSession(array);
    }
  }, [sessionsRequest, session]);

  const handleSession = (
    data: PropsSession,
    id: number,
    setModal: (boolean: boolean) => void
  ) => {
    setSession([
      ...session!,
      {
        name: data.name,
        isToShow: false,
        id: 9999999,
        contents: [],
        index: data.index,
        zone_fk: id,
      },
    ]);
    SessionZoneMutation.mutate({ ...data, zone: id });
    setModal(false);
  };

  const deleteSessionContent = (id: string) => {
    DeleteSessionContentsZoneMutation.mutate(id);
  };

  const deleteSessionContentWatched = (id: string) => {
    DeleteSessionWatchedContentsZoneMutation.mutate(id);
  };

  const handleWatched = (id: string) => {
    SessionWatchedZoneMutation.mutate({ sessionContentId: parseInt(id) });
  };

  const handleSessionContentsFile = (
    id: number,
    index: number,
    file: File | undefined,
    setModal: (boolean: boolean) => void,
    title: string,
    playlist_content?: number
  ) => {
    const typeContent = 1;
    if (file) {
      const form = new FormData();
      form.append("session", id.toString());
      form.append("index", index.toString());
      form.append("contentType", typeContent.toString());
      form.append("isToShow", "" + false);
      form.append("title", title);

      const arq = file;
      form.append("file", arq);
      SessionContentsZoneMutation.mutate({
        data: {
          index: index.toString(),
          session: id,
          contentType: typeContent,
          isToShow: false,
          title: title,
        },
        file: file,
      });
      setModal(false);
    }
    if (!file) {
      SessionContentsZoneMutation.mutate({data:{
        title: title,
        session: id,
        index: index.toString(),
        textContent: "Escreva aqui",
        isToShow: false,
        playlist_content: playlist_content,
      }});
      setModal(false);
    }

    setTimeout(() => {
      refetch();
    }, 300);
  };

  const { EditSessionContentsZoneMutation } = EditSessionContentsText();

  const handleEditSessionContent = (
    data: { textContent?: string | null; title?: string },
    id: number
  ) => {
    EditSessionContentsZoneMutation.mutate({ body: data, id: id });
  };

  const handleEditSession = (data: { name: string }, id: string) => {
    UpdateSessionZoneMutation.mutate({ data: data, id: id });
  };

  const DeleteSession = (id: number) => {
    const updatedSession = session!.filter((obj) => obj.id !== id);
    setSession(updatedSession);
    DeleteSessionZoneMutation.mutate(id.toString());
  };

  return {
    handleSession,
    initialValues,
    session,
    initialValuesContent,
    handleSessionContentsFile,
    DeleteSession,
    schema,
    deleteSessionContent,
    handleEditSessionContent,
    handleEditSession,
    accordionDisa,
    setAccordionDisa,
    allContentSession,
    deleteSessionContentWatched,
    handleWatched,
  };
};
