import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../styles/globalStyles";
// import LudzCardFile from "../../../ludz.one_ui/Card/CardFile";
import { Form, Formik } from "formik";
import {
  Avatar,
  Button,
  Card,
  EditorText,
  OverlayPanelLudz,
  Skeleton,
} from "../../../ludz.one_ui";
import LudzCardFile from "../../../ludz.one_ui/Card/CardFile";
import LudzLoading from "../../../ludz.one_ui/Loading";
import Comentarios from "../../../ludz.one_ui/Posts/PostsMural/Comentarios";
import SendComments from "../../../ludz.one_ui/Posts/PostsMural/SendComments";
import {
  Line,
  MoreComments,
} from "../../../ludz.one_ui/Posts/PostsMural/style";
import { getId } from "../../../services/localStorage";
import ViewHTML from "../../ViewHTML";
import EditPost from "./EditPost";
import { P } from "./style";

interface PropsPostMural {
  data: any;
  admin?: boolean;
  handleDeletePost?: any;
  handleSendComments?: any;
  handleEditPostComment?: any;
  handleDeletePostComment?: any;
  members?: any;
  handleEditPost?: any;
  initialValueEdit?: any;
  initialValueComment?: any;
}

const PostMural = ({
  data,
  admin,
  handleDeletePost,
  handleEditPost,
  initialValueEdit,
  initialValueComment,
  handleSendComments,
  handleEditPostComment,
  handleDeletePostComment,
  members
}: PropsPostMural) => {
  const [edit, setEdit] = useState(false);
  const [viewdComments, setViewdComments] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const matches = useMediaQuery("(max-width:950px)");

  const schema = Yup.object().shape({
    content: Yup.string().required("A descrição é obrigatória"),
  });

  useEffect(() => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = data?.content;
    const textContent = (tempDiv.textContent || '').trim();
    setCharCount(textContent.length);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editorText = (e: any, setFieldValue: any, values: any) => {
    const currentContent = e.editor.getHTML();
    const currentTextContent = e.editor.getText(); // Obtém o texto puro sem tags HTML
    const currentCharCount = currentTextContent.length;
  
    if (currentCharCount <= 2000) {
      setFieldValue("content", currentContent);
      setCharCount(currentCharCount);
    } else {
      // Remove o último caractere inserido para manter o limite
      const truncatedContent = values.content.slice(0, -1);
      e.editor.commands.setContent(truncatedContent);
      setFieldValue("content", truncatedContent);
      setCharCount(2000); // Atualiza o contador de caracteres para refletir o limite
    }
  };
  



  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  // const { data: user } = useFetchUserId({ id: data.owner })

  const formatDate = () => {
    if (data.createdAt) {
      const date = new Date(data.createdAt);

      var day: any = date.getDate();

      if (day < 10) {
        day = "0" + day;
      }

      var postDate = day + " de " + months[date.getMonth()].substring(0, 3);
      return postDate;
    } else {
      return "Não possui data";
    }
  };

  return (
    <Padding padding="10px 0">
      {data ? (
        <Card>
          <Padding padding={matches ? "0px" : "0 16px 16px 16px"}>
            {data.owner ? (
              <Row id="space-between">
                <Row style={{ flex: "1" }}>
                  {data.owner.aws.url ? (
                    <Avatar
                      img={data.owner.aws.url}
                      shape="circle"
                      size="large"
                    />
                  ) : (
                    <Avatar
                      label={data.owner.name.substring(0, 2)}
                      shape="circle"
                      size="large"
                    />
                  )}
                  <Column id="center">
                    <h4 style={{ marginLeft: "8px" }}>{data.owner.username}</h4>
                    <P style={{ marginLeft: "8px" }}>{formatDate()}</P>
                  </Column>
                </Row>
                {(admin || data.owner.id === parseInt(getId()!)) && (
                  <OverlayPanelLudz
                    button={
                      <Column id="center" style={{ cursor: "pointer" }}>
                        <i
                          className="pi pi-ellipsis-v"
                          style={{ fontSize: "1rem", cursor: "pointer" }}
                        ></i>
                      </Column>
                    }
                    components={
                      <EditPost
                        data={data}
                        handleDeletePost={handleDeletePost}
                        setEdit={setEdit}
                      />
                    }
                  />
                )}
              </Row>
            ) : (
              <div className="flex">
                <Skeleton
                  shape="circle"
                  size="4rem"
                  className="mr-2"
                ></Skeleton>
                <div style={{ flex: "1" }}>
                  <Skeleton width="100%" className="mb-2"></Skeleton>
                  <Skeleton width="75%"></Skeleton>
                </div>
              </div>
            )}
            {!data.archives ? (
              <>
                {edit ? (
                  <Padding padding="16px 0">
                    <Formik
                      initialValues={{content: data?.content}}
                      onSubmit={(value, { resetForm }) => {
                        const idpost = data.id.toString();
                        handleEditPost(idpost, value, setEdit);
                        resetForm();
                      }}
                      validationSchema={schema}
                    >
                      {({
                        values,
                        setFieldValue,
                        handleSubmit,
                        errors,
                        touched,
                      }) => {
                        return (
                          <Form>
                            <EditorText
                              value={values.content}
                              name="content"
                              onTextChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                editorText(e, setFieldValue, values)
                              }
                            />
                            <p className="font-semibold">{charCount} / 2000</p>
                            <Padding />
                            {errors.content && touched.content && (
                              <div style={{ color: "red" }}>
                                {errors.content.toString()}
                              </div>
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "end",
                                marginTop: matches ? "8px" : "12px",
                                marginBottom: matches ? "-2px" : "7px",
                              }}
                            >
                              <Row>
                                <Button
                                  style={{ padding: "15px" }}
                                  icon="pi pi-times"
                                  severity="danger"
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    setEdit(false);
                                  }}
                                  type="button"
                                />
                                <Padding />
                                <Button
                                  icon={"pi pi-save"}
                                  style={{ padding: "15px" }}
                                  iconPos={"right"}
                                  type="submit"
                                />
                              </Row>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Padding>
                ) : (
                  <ViewHTML item={data.content} />
                )}
              </>
            ) : (
              <>
                {edit ? (
                  <Padding padding={matches ? "0px" : "16px 0 0 0"}>
                    <Formik
                      initialValues={{content: data?.content}}
                      onSubmit={(value, { resetForm }) => {
                        const idpost = data.id.toString();
                        handleEditPost(idpost, value, setEdit);
                        resetForm();
                      }}
                      validationSchema={schema}
                    >
                      {({
                        values,
                        setFieldValue,
                        handleSubmit,
                        errors,
                        touched,
                      }) => {
                        return (
                          <Form>
                            {matches && <Padding padding="6px" />}
                            <EditorText
                              value={values.content}
                              name="content"
                              onTextChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                editorText(e, setFieldValue, values)
                              }
                            />
                           <p className="font-semibold">{charCount} / 2000</p>
                            <Padding />
                            {errors.content && touched.content && (
                              <div style={{ color: "red" }}>
                                {errors.content.toString()}
                              </div>
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "end",
                                marginTop: matches ? "6px" : "12px",
                                marginBottom: matches ? "6px" : "7px",
                              }}
                            >
                              <Row>
                                <Button
                                  className="h-3rem"
                                  tooltip="Cancelar"
                                  icon="pi pi-times"
                                  severity="danger"
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    padding: "15px",
                                  }}
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    setEdit(false);
                                  }}
                                />
                                <Button
                                  tooltip="Salvar"
                                  icon={"pi pi-save"}
                                  className="h-3rem"
                                  iconPos={"left"}
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    padding: "15px",
                                  }}
                                />
                              </Row>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Padding>
                ) : (
                  <>
                    {data.content && (
                      <Padding padding="16px 0px">
                        <ViewHTML item={data.content} />
                      </Padding>
                    )}
                    {data.archives &&
                      data.archives.map((item: any, key: any) => {
                        return (
                          <LudzCardFile
                            link={`${item.aws.url}`}
                            title={item.original_name}
                          />
                        );
                      })}
                  </>
                )}
              </>
            )}
          </Padding>
          <Line />
          {data.comments.length > 0 && (
            <>
              <Padding padding="0 0 0 0">
                <MoreComments onClick={() => setViewdComments(!viewdComments)}>
                  <i
                    className="pi pi-users"
                    style={{ fontSize: "1rem", marginRight: "4px" }}
                  ></i>
                  <P> {data.comments.length} Comentarios</P>
                </MoreComments>
                {viewdComments && (
                  <>
                    {data.comments.map((item: any, index: any) => {
                      return (
                        <Comentarios
                          data={item}
                          initialValueEdit={initialValueEdit}
                          schema={schema}
                          handleEditPostComment={handleEditPostComment}
                          admin={admin}
                          handleDeletePostComment={handleDeletePostComment}
                        />
                      );
                    })}
                    <Line />
                  </>
                )}
              </Padding>
            </>
          )}
          <SendComments
            handleSendComments={handleSendComments}
            initialValueComment={initialValueComment}
            data={data}
            members={members}
          />
        </Card>
      ) : (
        <LudzLoading />
      )}
    </Padding>
  );
};

export default PostMural;
