
import React from 'react'; 
import { Message } from 'primereact/message';

export interface PropsMessage  {
    text: string, 
    severity: "success" | "info" | "warn" | "error" | undefined,
}

const LudzMessage = ({severity, text}: PropsMessage) =>  {
    return (
        <div>
            <Message severity={severity} text={text} />
        </div>
    )
}

export default LudzMessage;
        