import { createContext } from "react";
import { ZoneState } from "./state";
import { ZonaContextType } from "./type";

export const ZonaContext = createContext<ZonaContextType | null>(null);

interface Props {
    children: React.ReactNode
}


const ZonaProvider = ({ children }: Props) => {

    const { zone, container, adminorowner, refetch, openMenu, setOpen, HandleRemoveMember, HandleUpdateMember, HandleExitZone, HandleInviteEmailMember, toastZone } = ZoneState();


    return (
        <ZonaContext.Provider value={{ zone, container, adminorowner, refetch, openMenu, setOpen, HandleRemoveMember, HandleUpdateMember, HandleExitZone, HandleInviteEmailMember, toastZone }}>
            {children}
        </ZonaContext.Provider>
    )
}

export default ZonaProvider;