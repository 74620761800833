import { Tooltip, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { Avatar, Input } from "../../../ludz.one_ui";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../styles/globalStyles";
import { ContainerModal, DataUsers } from "./style";

const LudzModalMentions = ({
  members,
  onChange,
  value,
  ids,
  onClose,
  input,
}: {
  input?: boolean;
  onClose?: any;
  members?: any;
  value: string;
  onChange?: any;
  ids?: Array<number>;
}) => {
  const [search, setSearch] = useState("");
  const matchesMobile = useMediaQuery("(max-width: 375px)");
  var userFilter = members;
  if (search !== "") {
    const buscaLowerCase = search.toLowerCase();
    userFilter = members?.filter(
      (members: any) =>
        members?.user.username.toLowerCase().includes(buscaLowerCase) ||
        members?.user.name.toLowerCase().includes(buscaLowerCase)
    );
  }

  const showAllMembers = members.map((member: any) => {
    return member?.user?.id;
  });

  let usernameall = 'everyone'


  // function marcar(key, username) {
  //   // const colorname = use
  //   const post = type === "editor" ? `${newPost.substring(0, newPost.length - 1) + `<b>${username.replace(/[\r]+/gm, "")}</b>`}` : `${newPost + `${username.replace(/[\r]+/gm, "")}`}`;
  //   if (isButton) {
  //     setNewPost(`${newPost}${username}`);
  //   } else {
  //     setNewPost(post);
  //   }
  //   setOpenMention(false);
  // }

  // function markAllUsers() {
  //   const post = type === "editor" ? `${newPost.substring(0, newPost.length - 1) + `<b>everyone</b>`}` : `${newPost + `everyone`}`;

  //   if (isButton) {
  //     setNewPost(newPost + ` @everyone`)
  //   } else {
  //     setNewPost(post)
  //   }

  //   setIdUserMarked(showAllMembers);
  //   setOpenMention(false);
  // }

  const Marcation = (username: string, id: number) => {
    var array = ids;
    array?.push(id);
    onChange("mentions", array);
    if (input) {
      onChange(
        "content",
        `${
          value?.substring(0, value.length - 1) + ' ' +
          `@${username?.replace(/[\r]+/gm, " ")}`
        }`
      );
    } else {
      onChange(
        "content",
        `${value.substring(0, value.length - 2) + `><span className="text-blue-600 font-semibold">${' ' + username + ' '}</span>`}`
      );
    }

    onClose();
  };

  
  function markAllUsers(username: string) {
    var array = showAllMembers;

    onChange("mentions", array);
    if (input) {
      onChange(
        "content",
        `${
          value?.substring(0, value.length - 1) +
          `@${username?.replace(/[\r]+/gm, "")}`
        }`
      );
    } else {
      onChange(
        "content",
        `${value.substring(0, value.length - 2) + `><span className="text-blue-600 font-semibold">${username}</span>`}`
      );
    }

    onClose();
  }

  return (
    <ContainerModal>
      <>
        <Input
          placeholder="Digitar usuário"
          value={search}
          onChange={(ev: any) => setSearch(ev.target.value)}
        />
        <hr style={{ width: "100%", marginTop: "10px" }} />
        <h3>Membros</h3>
        {userFilter.length !== 0 ? (
          <div id="contentUserFixed">
            {userFilter.map((item: any, key: number) => (
              <DataUsers
                key={key}
                onClick={() => {
                  Marcation(item.user.username, item.user.id);
                }}
              >
                <Row>
                  <Avatar
                    shape="circle"
                    size={matchesMobile ? "normal" : "large"}
                    img={item?.user?.aws?.url}
                  />
                  {matchesMobile ? null : <Padding padding="2px" />}
                  <Column id="center">
                    <Tooltip title={item.user.name}>
                      <h4>{item?.user?.name}</h4>
                    </Tooltip>
                    <p>@{item?.user?.username}</p>
                  </Column>
                </Row>
              </DataUsers>
            ))}
            {/* <hr style={{ width: "100%", marginTop: "10px" }} /> */}
          </div>
        ) : search === "" ? (
          <>
            {/* <div id="contentUserFixed">
              {fewMembers.map((item, key) => (
                <DataUsers
                  onClick={() => {
                    marcar(item._id, item.username);
                    setIdUserMarked([item._id].concat(idusermaked));
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <img src={item.avatar_url} alt={item.username} />
                    <p>{item.username}</p>
                  </div>
                  <Tooltip title={item.name}>
                    <span>{item.name.substring(0, 15)}</span>
                  </Tooltip>
                </DataUsers>
              ))}
              <hr style={{ width: "100%", marginTop: "10px" }} />
            </div> */}
          </>
        ) : (
          <>
            {" "}
            <p style={{ textAlign: "center", marginTop: "10px" }}>
              Usuario não encontrado
            </p>
            {/* <hr style={{ width: "100%", marginTop: "10px" }} /> */}
          </>
        )}
        <hr style={{ width: "100%", marginTop: "10px" }} />
        <div id="contentUserFixed" >
          <DataUsers onClick={() => markAllUsers(usernameall)}>
            <span>@everyone</span>
            <span style={{ marginLeft: "30px", fontWeight: "lighter" }}>Menciona todos os membros</span>
          </DataUsers>
        </div>
      </>
    </ContainerModal>
  );
};

export default LudzModalMentions;
