import { Formik } from "formik";
import { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Logo from "../../../assets/images/Camaleão.png";
import { SignUpContext } from "../../../contexts/User/SignUp/context";
import { SignUpContextType } from "../../../contexts/User/SignUp/types";
import { Button, Input, Message, Tooltip } from "../../../ludz.one_ui";
import {
  Column,
  Padding,
  Row,
} from "../../../ludz.one_ui/styles/styled-components";
import { logout } from "../../../services/localStorage";
import { Box, ContainerLogin, LinkText, P } from "../style";

const SignUpScreen = () => {
  const {
    handleSignUp,
    SignupSchema,
    initialValues,
    error,
    handleSignUpInviteZone,
  } = useContext(SignUpContext) as SignUpContextType;
  const { token, email } = useParams();

  useEffect(() => {
    logout();
  }, []);

  const iniviteemail: any = email

  const initial = token
    ? { ...initialValues, token: token, email: iniviteemail }
    : initialValues;

  return (
    <ContainerLogin>
      <Column id="center" style={{ height: "100%" }}>
        <Column id="center" style={{ height: "100%" }}>
          <Box>
            <Row id="center">
              <Padding padding="16px">
              <Tooltip title="Início" arrow>
             <Link to="/" style={{ textDecoration: 'none', cursor: 'pointer' }}>
                <img style={{ width: "100px" }} src={Logo} alt="" />
                </Link>
             </Tooltip>
              </Padding>
            </Row>
            <Padding />
            {error && (
              <Row id="center">
                <Message severity="error" text={error} />
              </Row>
            )}
            <Padding />
            <Formik
              initialValues={initial}
              validationSchema={SignupSchema}
              onSubmit={(values) => {
                token ? handleSignUpInviteZone(values) : handleSignUp(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
              }: any) => (
                <form onSubmit={handleSubmit}>
                  <Input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    placeholder="Digite o nome *"
                    className={errors.name && touched.name ? "p-invalid" : ""}
                  />
                  <Padding />
                  {errors.name && touched.name && (
                    <div style={{ color: "red" }}>{errors.name}</div>
                  )}
                  <Padding />
                  <Input
                    onChange={handleChange}
                    value={values.username}
                    type="text"
                    name="username"
                    placeholder="Digite o nome de usuário *"
                    className={
                      errors.username && touched.username ? "p-invalid" : ""
                    }
                  />
                  <Padding />
                  {errors.username && touched.username && (
                    <div style={{ color: "red" }}>{errors.username}</div>
                  )}
                  {token ? 
                  <>
                  <Padding />
                  <Input
                    value={values.email}
                    type="email"
                    name="email"
                    onChange={handleChange}
                    placeholder="Digite o email *"
                    className={
                      errors.email && touched.email ? "p-invalid" : ""
                    }
                  />
                  <Padding />
                  {errors.email && touched.email && (
                    <div style={{ color: "red" }}>{errors.email}</div>
                  )}
                </>
                  : (
                    <>
                      <Padding />
                      <Input
                        value={values.email}
                        type="email"
                        name="email"
                        onChange={handleChange}
                        placeholder="Digite o email *"
                        className={
                          errors.email && touched.email ? "p-invalid" : ""
                        }
                      />
                      <Padding />
                      {errors.email && touched.email && (
                        <div style={{ color: "red" }}>{errors.email}</div>
                      )}
                    </>
                  )}
                  <Padding />
                  <Input
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    placeholder="Digite a senha *"
                    className={
                      errors.password && touched.password ? "p-invalid" : ""
                    }
                  />
                  <Padding />
                  {errors.password && touched.password && (
                    <div style={{ color: "red" }}>{errors.password}</div>
                  )}
                  <Padding />
                  <Input
                    type="password"
                    name="passwordConfirm"
                    onChange={handleChange}
                    placeholder="Confirmar senha *"
                    className={
                      errors.passwordConfirm && touched.passwordConfirm
                        ? "p-invalid"
                        : ""
                    }
                  />
                  <Padding />
                  {errors.passwordConfirm && touched.passwordConfirm && (
                    <div style={{ color: "red" }}>{errors.passwordConfirm}</div>
                  )}
                  <Padding padding="8px" />
                  <Button
                    style={{ width: "100%" }}
                    title="Cadastrar"
                    type="submit"
                  />
                  <Padding padding="16px" />
                  {token ? null : (
                    <>
                      <Row id="center">
                        <P>Já possui cadastro? </P>
                        <Padding padding="2px" />
                        <Link to="/login" style={{ textDecoration: "none" }}>
                          <LinkText> Entrar</LinkText>
                        </Link>
                      </Row>
                      <Padding padding="16px" />
                    </>
                  )}
                </form>
              )}
            </Formik>
            <Padding padding="16px" />
          </Box>
        </Column>
      </Column>
    </ContainerLogin>
  );
};

export default SignUpScreen;
