
import { Calendar } from 'primereact/calendar';
import { Column } from "../../styles/globalStyles";
import { Nullable } from 'primereact/ts-helpers';

interface PropsCalendar {
    label?: string, 
    date?: Nullable<Date>,
    onChange?(event: any): void,
    name?: string,
    style?: React.CSSProperties
    dateFormat: string,
    placeholder: any,
    minDate: any
}

const CalendarLudz = ({label, date,onChange, name, style, dateFormat, placeholder, minDate}: PropsCalendar) => {

    return (
        <Column>
            <label>
                {label}
            </label>
            <Calendar minDate={minDate} placeholder={placeholder} name={name}  value={date} style={{ width: "100%", background: "#F6F7F7", ...style }} onChange={onChange} dateFormat={dateFormat} showIcon />
        </Column>
    )
}

export default CalendarLudz;