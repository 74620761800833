import styled from "styled-components";
import styles from "../../../../../../ludz.one_ui/styles";

export const ContentCard = styled.div<{ active?: boolean }>`
  padding: 4px;
  border-radius: 16px;
  cursor: pointer;
  background-color: ${(props) =>
    props.active ? styles.colors.grayClearOneHover : null};

  &:hover {
    background-color: ${styles.colors.grayClearOneHover};
    border-radius: 16px;
  }
`;
