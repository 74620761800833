import ChannelCompletContextProvider from "../../../contexts/Channel/ChannelComplet/context";
import ChannelPage from "../../../screens/Channel/ChannelComplet";

const ChannnelComplet = () => {
  return (
    <ChannelCompletContextProvider>
      <ChannelPage />
    </ChannelCompletContextProvider>
  );
};

export default ChannnelComplet;
