import { Tooltip } from "@mui/material";
import { Column, Padding } from "../../styles/styled-components";
import { Card } from "./style";

interface LudzCardFileProps {
    link: any,
    title: string,
}

const LudzCardFile = ({ link, title }: LudzCardFileProps) => {


    const typeFormats = () => {
        if (title.includes('.pdf')) {
          return "pi pi-file-pdf text-primary"  
        }else {
            return "pi pi-image text-primary"
        }
    }

    const Name = (name: string) => {
  
        if (name.length > 30) {
          return (name.substring(0, 30) + "...");
        }
        return name
      };

    return (
        <Tooltip title={title}>
            <Card onClick={() => {
                window.open(`${link}`);
            }}>
                <i  className={typeFormats()} style={{ 'fontSize': '2em', }}></i>
                <Padding />
                <Column id="center">
                    <h5 style={{margin: "auto",  wordBreak: 'break-all' }}>{Name(title)}</h5>
                </Column>
            </Card>
        </Tooltip>
    )
}

export default LudzCardFile;