import { RefObject, useContext } from 'react'
import { TypeEditPassword } from "./type"
import { UserProfileContext } from '../../../contexts/User/Profile/context'
import { UserProfileContextType } from '../../../contexts/User/Profile/type'
import { useMutation } from 'react-query'
import { EditPasswordRequest } from './request'
import { Toast } from 'primereact/toast'
import { Alert } from '../../../components/Alert'


export const ControllerEditPassword = ({
  toast,
}: {
  toast?: RefObject<Toast>;
}) => {

    interface PropsEditPassword {
        body: TypeEditPassword
    }


    const { refetch} = useContext(UserProfileContext) as UserProfileContextType;

    const EditPasswordMutation = useMutation(
        ({ body }: PropsEditPassword) => EditPasswordRequest(body),
        {
          onMutate: () => {
          },
          onError: (error: any) => {
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
          },
          onSuccess: (result, veriables, context) => {
            Alert({
              detail: "Senha editada!",
              severity: "success",
              summary: "Sucesso",
              toast: toast
            }).show();
            refetch();
            setTimeout(() => {
              window.location.reload()
            }, 1500)
          },
        }
      );

    return {
        EditPasswordMutation
    }
}