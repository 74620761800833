import { useContext } from "react";
import { Form, Formik } from "formik";
import { Button, Input, Modal } from "../../../../ludz.one_ui";
import { Column, Row } from "../../../../styles/globalStyles";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import { ObjectiveSubzonaContext } from "../../../../contexts/Subzone/ObjectiveSubzone/context";
import { ObjectiveSubzonaContextType } from "../../../../contexts/Subzone/ObjectiveSubzone/type";
import { SubzonaContext } from "../../../../contexts/Subzone/Subzone/context";
import { SubzonaContextType } from "../../../../contexts/Subzone/Subzone/type";

interface PropsCreateSection {
  visible?: boolean;
  onOpen?: any;
}

const CreateObjective = ({ visible, onOpen }: PropsCreateSection) => {
  const { subzone } = useContext(SubzonaContext) as SubzonaContextType;

  const { initialValue, schema, handleObjective } = useContext(
    ObjectiveSubzonaContext
  ) as ObjectiveSubzonaContextType;

  return (
    <Modal visible={visible} onOpen={onOpen} title={"Criar Objetivo"}>
      <Formik
        initialValues={initialValue}
        validationSchema={schema}
        onSubmit={(values) => {
          handleObjective(values, subzone!.id, onOpen);
        }}
      >
        {({ values, handleChange, errors, touched, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Column>
                <Input
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  label="Nome do Objetivo"
                />
              </Column>
              <Padding padding="16px" />
              {errors.name && touched.name && (
                <div style={{ color: "red" }}>{errors.name.toString()}</div>
              )}
              <Row id="center">
                <Button title="Criar" type="submit" />
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateObjective;
