import { useQuery } from "react-query";
import { GetChannelsPublicBff, GetChannelBffRequest } from "./request";


export const useFetchChannelsPublic = (page: number | undefined, channelname: string) => {
    return useQuery(["ChannelsPublicBff", page, channelname], () => GetChannelsPublicBff(page, channelname));
};

export const useFetchChannelPublic = (id: string | undefined) => {
    return useQuery(["ChannelPublicBff", id], () => GetChannelBffRequest(id));
};