import { useState } from "react";

import { Form, Formik } from "formik";
import { Button, Input } from "../../../../../../../../ludz.one_ui";
import CheckboxLudz from "../../../../../../../../ludz.one_ui/Checkboxs";
import { Padding } from "../../../../../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../../../../../styles/globalStyles";
import { Line } from "../../../../../../../User/Profile/ProfileInfo/style";
import InplaceItem from "./Inplace";
import * as C from "./style";
import ConfirmationDialog from "../../../../../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes";

interface PropsChecklistItems {
  data: any;
  handleChecklistItem: any;
  handleUpdateChecklistItem: any;
  deleteChecklistItems: any;
}

const ChecklistItems = ({
  data,
  handleChecklistItem,
  handleUpdateChecklistItem,
  deleteChecklistItems,
}: PropsChecklistItems) => {
  const [visible, setVisibile] = useState(false);

  const [visibleitem, setVisibileItem] = useState<any>(false);

  return (
    <C.Container>
      <Padding />
      <Column>
        {data?.KanbanCardChecklistItems.map((item: any, key: number) => {
          return (
            <>
              <C.AreaItems>
                <Row>
                  <CheckboxLudz
                    onChange={() => {
                      const body = {
                        checklist: data.id,
                        isDone: !item.isDone,
                        description: item.description,
                      };
                      const id = item.id.toString();
                      handleUpdateChecklistItem(id, body);
                    }}
                    checked={item.isDone}
                    style={{ padding: "0px", alignSelf: "center" }}
                  />
                  <InplaceItem
                    data={data}
                    item={item}
                    handleUpdateChecklistItem={handleUpdateChecklistItem}
                  />
                </Row>
                <p
                  onClick={() => setVisibileItem(item)}
                  style={{
                    fontSize: "12px",
                    marginTop: "4px",
                    cursor: "pointer",
                  }}
                >
                  <i id="trash" className="pi pi-trash"></i>
                </p>
              </C.AreaItems>
              <Line />
              <Padding />
            </>
          );
        })}
      </Column>
      {visible ? (
        <Formik
          initialValues={{ description: "" }}
          onSubmit={(values) => {
            handleChecklistItem({
              checklist: parseInt(data.id!),
              isDone: false,
              description: values.description,
            });
            setVisibile(false);
          }}
        >
          {({ values, handleChange }) => {
            return (
              <Row style={{ alignItems: "center" }}>
                <Form style={{ width: "100%", display: "flex" }}>
                  <Input
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    placeholder="Digite o item"
                    style={{ marginRight: "5px" }}
                  />
                  <Button icon="pi pi-check" type="submit" />
                </Form>
                <p
                  onClick={() => setVisibile(false)}
                  style={{
                    fontSize: "12px",
                    marginTop: "4px",
                    cursor: "pointer",
                  }}
                >
                  <i id="additem" className="pi pi-times"></i>
                </p>
              </Row>
            );
          }}
        </Formik>
      ) : null}
      <Padding />
      {!visible ? (
        <Button title="Adicionar um item" onClick={() => setVisibile(true)} />
      ) : null}
      <ConfirmationDialog
        accept={() => deleteChecklistItems(visibleitem!.id)}
        visible={visibleitem}
        reject={() => setVisibileItem(true)}
        message="Deseja Excluir?"
        onHide={() => setVisibileItem(false)}
      />
    </C.Container>
  );
};

export default ChecklistItems;
