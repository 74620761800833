import React, { useContext } from "react";
import * as C from "./style";
import { Column, Row } from "../../../../styles/globalStyles";

import background from "../../../../assets/images/Logo2.png";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import { UserProfileContext } from "../../../../contexts/User/Profile/context";
import { UserProfileContextType } from "../../../../contexts/User/Profile/type";
import { ProfileContext } from "../../../../contexts/User/Profile/ProfileVisible/context";
import { ProfileContextType } from "../../../../contexts/User/Profile/ProfileVisible/type";
import { Formik } from "formik";
import { Button, Input } from "../../../../ludz.one_ui";
import { Toast } from "primereact/toast";

const ProfileInfo = () => {
  const { userprofile } = useContext(
    UserProfileContext
  ) as UserProfileContextType;

  const { HandleSaveInfo, initialValues, toast } = useContext(
    ProfileContext
  ) as ProfileContextType;

  return (
    <C.Container>
      <Toast ref={toast} />
      <Column>
        <Row id="center">
          <img src={background} style={{ width: "128px", height: "100px" }} alt="imagem ilustrativa" />
        </Row>
        <h2>Gerencie aqui suas informações pessoais</h2>
        <Padding padding="20px" />
        <h3>Sobre</h3>
        <Padding padding="4px" />
        <C.Line />      
        <Padding padding="20px 0px">
          <span className="font-bold">{userprofile?.email}</span>
        </Padding>
        <Formik
          initialValues={initialValues}
          onSubmit={(value) => HandleSaveInfo(value)}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            handleSubmit,
            errors,
            touched,
          }) => {
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <Column id="center">
                    <Input
                      name="name"
                      defaultValue={userprofile?.name}
                      value={values.name}
                      onChange={handleChange}
                      placeholder={userprofile?.name}
                      label="Nome Completo"
                      className={errors.name && touched.name ? "p-invalid" : ""}
                    />
                    <Padding padding="10px" />
                    <Input
                      name="username"
                      defaultValue={userprofile?.username}
                      value={values.username}
                      onChange={handleChange}
                      placeholder={userprofile?.username}
                      label="Nome de Usuário"
                      className={errors.name && touched.name ? "p-invalid" : ""}
                    />
                    {(errors.name && touched.name) &&  <div style={{ color: "red" }}>{errors.name}</div>}
                    <Padding padding="20px" />
                    <Button
                      title="Salvar"
                      type="submit"
                      style={{ width: "100%" }}
                    ></Button>
                  </Column>
                </form>
              </>
            );
          }}
        </Formik>
      </Column>
    </C.Container>
  );
};

export default ProfileInfo;
