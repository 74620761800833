import { createContext } from "react";
import { SubZonaInfoContextType } from "./type";
import { SubZoneInfoState } from "./state";

export const SubzonaInfoContext = createContext<SubZonaInfoContextType | null>(
  null
);

interface Props {
  children: React.ReactNode;
}

const SubZonaInfoProvider = ({ children }: Props) => {
  const {
    HandleSaveAvatarInfo,
    subzonename,
    setSubZoneName,
    subzonedescription,
    setSubZoneDescription,
    isediting,
    setIsEditing,
    HandleSaveInfo,
    HandleRemoveAvatarInfo,
    HandleDeleteSubZone,
    initialValues,
    setFiles,
    HandleArchivesSubzone,
    HandleDeleteArchives,
    toast,
  } = SubZoneInfoState();

  return (
    <SubzonaInfoContext.Provider
      value={{
        HandleDeleteArchives,
        HandleSaveAvatarInfo,
        subzonename,
        setSubZoneName,
        subzonedescription,
        setSubZoneDescription,
        HandleSaveInfo,
        HandleRemoveAvatarInfo,
        HandleDeleteSubZone,
        isediting,
        setIsEditing,
        initialValues,
        setFiles,
        HandleArchivesSubzone,
        toast,
      }}
    >
      {children}
    </SubzonaInfoContext.Provider>
  );
};

export default SubZonaInfoProvider;
