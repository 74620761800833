import ZonesPublicContextProvider from "../../contexts/Zone/ZonePublic/context";
import ZonesPublicScreen from "../../screens/Zone/ZonesPublic";

const ZonesPublic = () => {
  return (
    <ZonesPublicContextProvider>
      <ZonesPublicScreen />
    </ZonesPublicContextProvider>
  );
};

export default ZonesPublic;
