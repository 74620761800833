import { useContext, useState } from "react";
import { ObjectiveSubzonaContext } from "../../../../../../../../contexts/Subzone/ObjectiveSubzone/context";
import { ObjectiveSubzonaContextType } from "../../../../../../../../contexts/Subzone/ObjectiveSubzone/type";
import { Input, ModalInsiderKanbanCard } from "../../../../../../../../ludz.one_ui";
import Metas from "./metas";

interface PropsModalMembers {
    visible: boolean,
    setvisible: any,
}

const ModalMetas = ({ visible, setvisible }: PropsModalMembers) => {
    
    const { objective } = useContext(ObjectiveSubzonaContext) as ObjectiveSubzonaContextType



    const [searchUser, setSearchUser] = useState('')



    const getmetas = () => {
        const teste: any = []
        objective?.forEach((element: any) => {
            element.objectiveKeys.forEach((element: any) => {
                teste.push(element)
            });
        });
        return teste
    }
    const metas = getmetas()

    var UserSearch: any = metas;

    if (searchUser !== "") {
        const buscaLowerCase = searchUser.toLowerCase();

        UserSearch = UserSearch.filter((member: any) =>
            member.user.name.toLowerCase().includes(buscaLowerCase)
        );
    }
    return (
        <ModalInsiderKanbanCard visible={visible} title={"Metas"} onOpen={() => setvisible(!visible)}>
            <Input
                style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                }}
                onChange={(e: any) => setSearchUser(e.target.value)}
                placeholder="Pesquisar metas"
            />
            <h5 style={{ color: '#808080', marginBottom: '10px' }}>Metas do projeto</h5>

            {UserSearch.map((item: any, index: number) => {
                return (
                    <Metas item={item} key={index} />
                )
            })}
        </ModalInsiderKanbanCard>

    )
}

export default ModalMetas