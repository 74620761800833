import { useMutation } from "react-query";
import { useFetchSubzoneKanban } from "./query";
import { DeleteSubzoneKanbanRequest, SubzoneKanbanRequest, UpdateSubzoneKanbanRequest } from "./request";
import { PropsSubzoneKanban } from "./type";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../../components/Alert";

export const KanbanSubzoneController = (id: number, {
    toast,
  }: {
    toast?: RefObject<Toast>;
  }) => {

    const { data: kanbanRequests, refetch } = useFetchSubzoneKanban(id)

    const KanbanSubzoneMutation = useMutation((data: PropsSubzoneKanban) => SubzoneKanbanRequest(data), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result) => {
            refetch()
            Alert({
                detail: "Lista criada!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
        },
    });

    const UpdateSubzoneMutation = useMutation((data: {
        id: number,
        body: {
            title: string;
        }
    }) => UpdateSubzoneKanbanRequest(data.id, data.body), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result) => {
            Alert({
                detail: "Lista editada!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
            refetch()
        },
    });

    const DeleteKanbanSubzoneMutation = useMutation((id: number) => DeleteSubzoneKanbanRequest(id), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result) => {
            Alert({
                detail: "Lista excluida!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
            refetch()
        },
    });



    return {
        KanbanSubzoneMutation, kanbanRequests, DeleteKanbanSubzoneMutation,UpdateSubzoneMutation
    }
}


