import { Button, Input, ModalInsiderKanbanCard } from '../../../../../../../../ludz.one_ui';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import { Padding } from '../../../../../../../../ludz.one_ui/styles/styled-components';
import { Row } from '../../../../../../../../styles/globalStyles';

// import { Container } from './styles';

interface PropsModalChecklist {
    visible: boolean,
    setvisible: any,
    handleChecklist: any
}

const ModalChecklist = ({ visible, setvisible, handleChecklist }: PropsModalChecklist) => {
    const { idcard } = useParams();

  return (
    <ModalInsiderKanbanCard visible={visible} title={"Checklist"} onOpen={() => setvisible(!visible)}>
        <Formik
        initialValues={{ description: "" }} onSubmit={(values) => {handleChecklist({ kanbanCard: parseInt(idcard!), isToShow: false,description: values.description }); setvisible(false)}}
        >
            {({ values, handleChange }) => {
                return (
                    <Form>
                    <div>
                        <h5 style={{ color: "#808080", marginTop: "10px" }}>Criar Checklist</h5>
                        <Padding />
                        <Input
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            placeholder="Nome do Checklist"
                        />
                        <Padding />
                        <Row
                            id="end"
                        >
                            <Button
                                title="Criar"
                                type="submit"
                            />
                        </Row>
                    </div>
                </Form>
                )
            }}
        </Formik>
    </ModalInsiderKanbanCard>
  );
}

export default ModalChecklist;