import http from "../../../services/api";
import { logout } from "../../../services/localStorage";

export const getChannelContentRequest = async (id: number) => {
  return await http
    .get("/bff/playlist-contents-bff", { params: { playlistContentId: id } })
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      throw err;
    });
};

export const getChannelPlaylistContentRequest = async (id: number) => {
  return await http
    .get("/bff/playlist-contents-bff/all", { params: { playlistId: id } })
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      alert(err.response.data.message);
      throw err;
    });
};

export const getAllFilesContentRequest = async (id: number) => {
  if (id) {
    return await http
      .get("/bff/playlist-contents-document-bff/all", {
        params: { playlistContentsId: id },
      })
      .then((response) => response.data)
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
          window.location.reload();
        }
        throw err;
      });
  }
};
