import { createContext } from "react";
import { MuralSubzoneState } from "./state";
import { PostSubzonaContextType } from "./type";

export const PostSubzonaContext = createContext<PostSubzonaContextType | null>(null);

interface Props {
    children: React.ReactNode
}


const PostSubonaProvider = ({ children }: Props) => {

    const { handlePost, initialValue, schema, posts, setFile, handleEditPost, initialValueEdit, handleDeletePost, handleSendComments, initialValueComment, handleEditPostComment, handleDeletePostComment, toast } = MuralSubzoneState()
    return (
        <PostSubzonaContext.Provider value={{ handlePost, initialValue, schema, posts, setFile, handleEditPost, initialValueEdit, handleDeletePost, handleSendComments, initialValueComment, handleEditPostComment, handleDeletePostComment, toast }}>
            {children}
        </PostSubzonaContext.Provider>
    )
}

export default PostSubonaProvider;