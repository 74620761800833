
import { ConfirmDialog } from 'primereact/confirmdialog';


interface PropsConfirmationDialog {
    visible?: boolean,
    onHide?: any,
    accept?: any, 
    reject?: any,
    message?: string
}

const ConfirmationDialog = ({visible, onHide, accept, reject, message}: PropsConfirmationDialog) => {
    return(
        <ConfirmDialog style={{minWidth: "40%"}} visible={visible} onHide={onHide} message={message}
            acceptLabel='Sim'
            rejectLabel='Não'
            header="Confirmação" icon="pi pi-exclamation-triangle" 
            accept={accept} reject={reject} />
    )
}

export default ConfirmationDialog;