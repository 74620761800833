import { useMutation } from "react-query";
import queryClient from "../../../../../services/react-query";
import { ArchivesKanbanCardRequest, DeleteArchivesKanbanCardRequest } from "./request";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../../../../components/Alert";

export const ArchivesKanbanCardController = ({
    toast,
  }: {
    toast?: RefObject<Toast>;
  }) => {
    const ArchivesKanbanCardMutation = useMutation(({data, id}:{data: File, id: string}) => ArchivesKanbanCardRequest(data, id), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            Alert({ detail: "Error", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result) => {
            Alert({
                detail: "Arquivo enviado!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
            queryClient.refetchQueries("UseSubzoneKanbanbff")
            queryClient.refetchQueries("UseSubzoneKanbanCard")

        },
    });

    const DeleteArchivesKanbanCardMutation = useMutation((id: string) => DeleteArchivesKanbanCardRequest(id), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            Alert({ detail: "Error", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result) => {
            Alert({
                detail: "Arquivo excluido!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
            queryClient.refetchQueries("UseSubzoneKanbanbff")
            queryClient.refetchQueries("UseSubzoneKanbanCard")

        },
    });


    return{
        ArchivesKanbanCardMutation,DeleteArchivesKanbanCardMutation
    }
}