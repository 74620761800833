import { useQuery } from "react-query";
import {
  GetUserBusinessChannel,
  GetUserChannel,
  GetUserHome,
  GetUserId,
  GetUserInvites,
  GetUserNotifications,
  getAllUsersRequest,
} from "./request";

export const useFetchUserId = (
  id: string | null,
  query: object | undefined
) => {
  return useQuery(["UseUserId", id], () => GetUserId(id, query));
};
export const useFetchUserAll = () => {
  return useQuery(["UseUserAll"], getAllUsersRequest);
};

export const useFetchUserHome = () => {
  return useQuery(["UseUserHome"], GetUserHome);
};

export const useFetchUserInvites = () => {
  return useQuery(["UseUserInvites"], GetUserInvites);
};

export const useFetchUserNotifications = () => {
  return useQuery(["UseUserNotifications"], GetUserNotifications);
};

export const useFetchUserChannel = () => {
  return useQuery(["UseUserChannel"], GetUserChannel);
};

export const useFetchUserBusinessChannel = (role: string) => {
  return useQuery(["UseUserBusinessChannel", role], () => GetUserBusinessChannel(role));
};
