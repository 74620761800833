import React, { useContext, useState } from "react";
import ViewHTML from "../../../../../components/ViewHTML";
import { AreaSobre } from "./style";
import { Progress, Tooltip } from "../../../../../ludz.one_ui";
import { PostZonaContext } from "../../../../../contexts/Zone/MuralZone/context";
import { PostZonaContextType } from "../../../../../contexts/Zone/MuralZone/type";
import Activities from "../../../../../components/Activities";
import { useNavigate, useParams } from "react-router-dom";
import { PrivateRoutesContext } from "../../../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../../../../contexts/PrivateRoutes/types";
import { getId } from "../../../../../services/localStorage";
import CardNotifications from "../../../../../components/CardNotifications";
import AreaMembers from "../../../../../components/AreaMembers";

// import { Container } from './styles';

interface Painel {
  zone: any;
}

const ZonePainel = ({ zone }: Painel) => {
  const { id } = useParams();
  const history = useNavigate();

  const { posts } = useContext(PostZonaContext) as PostZonaContextType;
  const { notifications } = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;
  const [visible, setVisible] = useState<boolean>(false);

  const notificationZone = notifications?.filter((notification) => {
    if (!notification?.links?.length) {
      return false;
    }
    const link = notification.links[0];
    return link?.zone_fk?.toString() === id;
  });

  const postReverse = posts ? [...posts].reverse() : [];

  const totalMembros = () => {
    let total = zone?.zoneMembers?.length;
    let valor;

    if (total > 6) {
      return (valor = total - 6);
    } else {
      return valor;
    }
  };

  const displayedMembers = zone?.zoneMembers?.slice(0, 6);

  return (
    <div className="layout-dashboard">
      <div className="grid">
        <div className="col-12 pb-0">
          <div className="grid">
            <div className="col-12 sm:col">
              <div
                className="card text-0 flex justify-content-between  h-full"
                style={{
                  backgroundColor: "var(--primary-color)",
                }}
              >
                <div className="overview-info">
                  <h5 className="m-0 mb-1 text-0">Membros</h5>
                  <h1 className="m-0 text-0">{zone?.zoneMembers?.length}</h1>
                </div>
                <i className="pi pi-users text-3xl"></i>
              </div>
            </div>
            <div className="col-12 sm:col">
              <div className="card overview-box blue text-white flex justify-content-between h-full">
                <div className="overview-info">
                  <h5 className="m-0 mb-1 text-white">Subzonas</h5>
                  <h1 className="m-0 text-white">{zone?.subzones?.length}</h1>
                </div>
                <i className="pi pi-sitemap text-3xl"></i>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 md:col-6">
          <div className="grid">
            <div className="col-12">
              <AreaSobre className="card device-status">
                <div className="grid">
                  <div className="col-12 xl:col-9">
                    <div className="card-header">
                      <div className="card-title">
                        <h5>Sobre</h5>
                      </div>
                    </div>
                    <ViewHTML item={zone?.description.toString()} />
                  </div>
                </div>
              </AreaSobre>
            </div>
            <div className="col-12 desktop-teams">
              <div className="card team">
                <div className="card-header">
                  <div className="card-title">
                    <h5>Membros</h5>
                    <h6 className="subtitle">
                      {zone?.zoneMembers?.length > 1
                        ? `${zone?.zoneMembers?.length} pessoas`
                        : `${zone?.zoneMembers?.length} pessoa`}
                    </h6>
                  </div>
                </div>
                <div className="peoples">
                  {zone?.zoneMembers?.length <= 6 ? (
                    zone?.zoneMembers.map((item: any, index: any) => {
                      return (
                        <>
                          <Tooltip title={item?.user?.username} arrow>
                            <img
                              src={item?.user?.aws?.url}
                              alt="freya-layout"
                            />
                          </Tooltip>
                        </>
                      );
                    })
                  ) : (
                    <>
                      {displayedMembers?.map((item: any, index: any) => {
                        return (
                          <>
                            <Tooltip title={item?.user?.username} arrow>
                              <img
                                src={item?.user?.aws?.url}
                                alt="freya-layout"
                              />
                            </Tooltip>
                          </>
                        );
                      })}
                      <div className="no-picture ">
                        <span
                          onClick={() => setVisible(true)}
                        >{`+${totalMembros()}`}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 md:col-6 xl:col-3">
          <div className="card timeline">
            <div className="card-header">
              <div className="card-title">
                <h5>Atividades</h5>
                <h6 className="subtitle">Todas da Zona</h6>
              </div>
            </div>
            <ul>
              {posts ? (
                <>
                  {postReverse.map((item: any, index: any) => {
                    return <Activities item={item} key={index} />;
                  })}
                </>
              ) : (
                <Progress />
              )}
            </ul>
            <button
              onClick={() => history(`/zona/${id}/mural`)}
              className="p-link"
            >
              +Detalhes
            </button>
          </div>
        </div>

        <div className="col-12 md:col-6 xl:col-3">
          <div className="card timeline px-3">
            <div className="card-header">
              <div className="card-title">
                <h5>Notificações</h5>
                <h6 className="subtitle">Todas notificações</h6>
              </div>
            </div>
            <ul>
              {notifications ? (
                <>
                  {notificationZone?.map((item: any, index: any) => {
                    return (
                      <CardNotifications key={index} notification={item} />
                    );
                  })}
                </>
              ) : (
                <Progress />
              )}
            </ul>
            <button
              onClick={() => history(`/perfil/${getId()}`)}
              className="p-link"
            >
              +Detalhes
            </button>
          </div>
        </div>

        <div className="col-12 md:col-6 mobile-teams">
          <div className="card team" style={{ height: "auto" }}>
            <div className="card-header">
              <div className="card-title">
                <h5>Membros</h5>
                <h6 className="subtitle">
                  {zone?.zoneMembers?.length > 1
                    ? `${zone?.zoneMembers?.length} pessoas`
                    : `${zone?.zoneMembers?.length} pessoa`}
                </h6>
              </div>
            </div>
            <div className="peoples" style={{ alignSelf: "flex-end" }}>
              {zone?.zoneMembers?.length <= 6 ? (
                zone?.zoneMembers.map((item: any, index: any) => {
                  return (
                    <>
                      <Tooltip title={item?.user?.username} arrow>
                        <img src={item?.user?.aws?.url} alt="freya-layout" />
                      </Tooltip>
                    </>
                  );
                })
              ) : (
                <>
                  {displayedMembers?.map((item: any, index: any) => {
                    return (
                      <>
                        <Tooltip title={item?.user?.username} arrow>
                          <img src={item?.user?.aws?.url} alt="freya-layout" />
                        </Tooltip>
                      </>
                    );
                  })}
                  <div className="no-picture ">
                    <span
                      onClick={() => setVisible(true)}
                    >{`+${totalMembros()}`}</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <AreaMembers
        visible={visible}
        setVisible={setVisible}
        members={zone?.zoneMembers}
      />
    </div>
  );
};

export default ZonePainel;
