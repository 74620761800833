
import { useMediaQuery } from "@mui/material";
import ReactPlayer from "react-player";

// import { Container } from './styles';

const Playlists = ({ item }: any) => {
  const matches = useMediaQuery("(max-width: 520px)");
  const matchesSmall = useMediaQuery("(max-width: 375px)");

  return (
    <>
      {item?.contents.map((item: any, index: any) => {
        return (
          <>
            {item?.isPublic ? (
              <>
                <h4
                  style={{ textAlign: matches ? "justify" : "left" }}
                  className="m-0 mb-4 flex align-items-center text-green-500"
                >
                  <i className="pi pi-video mr-2"></i>
                  {item?.name}
                </h4>
                <div
                  className="flex"
                  style={{
                    flexDirection: matches ? "column" : "row",
                    alignItems: matches ? "center" : "flex-start",
                    justifyContent: matches ? "center" : "normal",
                  }}
                >
                  <ReactPlayer
                    light
                    url={item.video_url ?? item.aws_2.url}
                    controls={true}
                    width={matchesSmall ? "216px" : "256px"}
                    height="140px"
                  />{" "}
                  <div
                    
                    className={matches ? "ml-2" : "ml-2 w-8"}
                  >
                    <div style={{ padding: "0 2% 2% 2%" }}>
                      <p
                        style={{
                          fontSize: "small",
                          textAlign: matches ? "justify" : "left",
                        }}
                        className="p-0 m-0"
                      >
                        {item?.description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="p-2" />
              </>
            ) : (
              <>
                <div
                  className="flex"
                  style={{
                    flexDirection: matches ? "column" : "row",
                    alignItems: matches ? "center" : "flex-start",
                    justifyContent: matches ? "center" : "normal",
                  }}
                >
                  <div
                    style={{ marginTop: matches ? "10px" : "0px" }}
                    className="w-full"
                  >
                    <div className="flex align-items-center">
                      <i className="pi pi-video mr-2"></i>
                      <h4
                        style={{ textAlign: matches ? "justify" : "left" }}
                        className="m-0 mb-1"
                      >
                        {item?.name}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="p-2" />
              </>
            )}
          </>
        );
      })}
    </>
  );
};

export default Playlists;
