import styled from "styled-components";
import styles from "../../ludz.one_ui/styles";


export const MenuLeftStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: Menu;
    background-color: ${styles.colors.primary};
    overflow-y: auto;
    max-height: 100%;
    &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;

`;

export const Separator = styled.div`
  padding: 10px;
  width: 50%;
  border-bottom: 2px solid ${styles.colors.tertiary};
`;