import { useContext } from "react";
import { PrivateRoutesContext } from "../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../../contexts/PrivateRoutes/types";
import CardNotifications from "../../Cards/CardNotifications";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { Row } from "../../../styles/globalStyles";
import { Button } from "../../../ludz.one_ui";
import { getId } from "../../../services/localStorage";
import { useNavigate } from "react-router-dom";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";

const Notifications = () => {

    const { notifications, setActiveIndex, UpdateAllNotification, toast } = useContext(
        PrivateRoutesContext
    ) as PrivateRoutesContextType;
    const history = useNavigate();
    const arrNotifications = notifications?.slice(0, 10);

    return (

        <div >
            <Toast ref={toast} />
            <div style={{ maxWidth: "300px", minWidth: "200px", maxHeight: "300px", overflowY: "auto", padding: "16px" }}>
                <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h3>Notificações</h3>
                
                <Row onClick={UpdateAllNotification} style={{marginTop:"3px", cursor:"pointer"}}>
                <Tag value='Marcar todas' className="mr-0 cursor-pointer" rounded></Tag>
                </Row>
                </Row>

                {arrNotifications?.map((item, index) => {
                    return (
                        <CardNotifications key={index} notification={item} showElement={false}/>
                    )
                })}
                {notifications?.length === 0 ? <>
                    <Padding />
                    <Row id="center"><h5 style={{ margin: "auto" }}>Sem Notificações</h5></Row>
                    <Padding />
                </>
                    : null}
                {notifications!.length > 0 ? <>
                    <Row id="center">
                        <Button title="Ver mais" rounded onClick={() => {
                            history(`/perfil/${getId()}`);
                            setActiveIndex(1);
                        }} />
                    </Row>
                </>
                    : null}
                <Padding />
            </div>
        </div>
    )
}

export default Notifications;